import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { CartService } from '@services/cart.service';

import { IPostCatalogResponse } from '../contextualized-catalog/dtos/contextualized-product-output.dto';
import { CatalogCtxService } from '../contextualized-catalog/services/catalog-ctx.service';
import {
  ConsulterCatalogueContextualiseAvecPanierBodyRequest,
  ConsulterCatalogueContextualiseAvecPanierQuery,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { CatalogOutputHelperDto } from '../contextualized-catalog/dtos/catalog-output-helper.dto';
import { map, tap } from 'rxjs/operators';

interface ICache {
  nbTotal: number;
  data: BehaviorSubject<IPostCatalogResponse[]>;
}

@Injectable({ providedIn: 'root' })
export class ProductListService {
  public nbAllPhones: number;
  private equipmentsCache = new Map<string, ICache>();

  constructor(
    private cartService: CartService,
    private catalogCtxService: CatalogCtxService,
  ) {
    this.cartService.afterRefresh.subscribe(() => {
      this.equipmentsCache = new Map<string, ICache>();
    });
  }

  public getEquipments(
    bodyCatalog: ConsulterCatalogueContextualiseAvecPanierBodyRequest,
    queryParamCatalog: ConsulterCatalogueContextualiseAvecPanierQuery,
  ): Observable<IPostCatalogResponse[]> {
    const cacheKey = this.buildCacheKey(bodyCatalog, queryParamCatalog);

    const cache = this.equipmentsCache.get(cacheKey);
    if (!!cache) {
      this.nbAllPhones = cache.nbTotal;
      return cache.data;
    }

    return this.catalogCtxService.postCatalogCtx(bodyCatalog, queryParamCatalog).pipe(
      tap(catalogResult => (this.nbAllPhones = catalogResult.nombreTotalProduits)),
      map(catalogResult => CatalogOutputHelperDto.convertCatalogResponse(catalogResult)),
      tap(data =>
        this.equipmentsCache.set(cacheKey, {
          nbTotal: this.nbAllPhones,
          data: new BehaviorSubject<IPostCatalogResponse[]>(data),
        }),
      ),
    );
  }

  private buildCacheKey(
    bodyCatalog: ConsulterCatalogueContextualiseAvecPanierBodyRequest,
    queryParamCatalog: ConsulterCatalogueContextualiseAvecPanierQuery,
  ): string {
    const bodyCatalogClone = JSON.parse(JSON.stringify(bodyCatalog));
    const sortedPostBody = this.sortNestedObject(bodyCatalogClone);
    const postBody = JSON.stringify(sortedPostBody);
    const queryParam = JSON.stringify(queryParamCatalog);
    return queryParam.concat(postBody);
  }

  // eslint-disable-next-line
  private sortNestedObject(object: any) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === 'object') {
        object[key] = this.sortNestedObject(value);
      }
    }
    return this.sortSimpleObject(object);
  }

  private sortSimpleObject(
    unordered: Record<string, unknown>,
    sortFunction?: () => -1 | 0 | 1,
  ): Record<string, unknown> {
    return Object.keys(unordered)
      .sort(sortFunction)
      .reduce((acc, key) => {
        acc[key] = unordered[key];
        return acc;
      }, {});
  }
}
