import { Plan } from '../../plan';
import { ICheckCart, IPlanConfiguration } from '../../IPlanConfiguration';
import { CompatibilityMessages } from '../../../product';
import { BrowseType } from '../../../../../../constants/browse-type';
import { AddResult, ResultAddMessage, TypeResult } from '@interfaces/cart.interfaces';

export class FaimUnlimited extends Plan {
  public static readonly planType = 'fai';
  public messageFraisPrevisionnels: boolean;
  public linkedSku: string[];
  public switchOc: string;
  public typeDegroupage: string;
  public switchItemIdOc: number;
  public idPanierWebShop: string;
  public checkCart: ICheckCart;
  public isVoipReserved: boolean;
  public isLoginReserved: boolean;

  // todo voir si ocPriceData est toujours utilisé
  // public updateSchemeOnAdd(scheme: Scheme): Scheme {
  //
  //   scheme.productContext.ocPriceData = {
  //     rentalPrice: this.data['rental_fees'] || 0,
  //     fmdPrice: this.data['fmd'] || 0,
  //     zndPrice: Number(this.data['additional_wide_area']) || 0,
  //   };
  //   scheme.updateContext(scheme.productContext);
  //   return scheme;
  // }

  public getConfiguration(): IPlanConfiguration {
    return <IPlanConfiguration>{
      messageFraisPrevisionnels: this.messageFraisPrevisionnels,
      linkedSku: this.linkedSku,
      switchOc: this.switchOc,
      switchItemIdOc: this.switchItemIdOc,
      typeDegroupage: this.typeDegroupage,
      idPanierWebShop: this.idPanierWebShop,
      checkCart: this.checkCart,
      isVoipReserved: this.isVoipReserved,
      isLoginReserved: this.isLoginReserved,
      ...super.getConfiguration(),
    };
  }

  public setConfiguration(data: IPlanConfiguration): void {
    super.setConfiguration(data);
    this.logins = data.logins || this.logins;
    this.isLoginReserved = data.isLoginReserved || this.isLoginReserved || false;
    this.isVoipReserved = data.isVoipReserved || this.isVoipReserved || false;
    this.eligTech = data.eligTech || this.eligTech;
    this.eligTechTokyo = data.eligTechTokyo || this.eligTechTokyo;
    this.structureVerticale = data.structureVerticale || this.structureVerticale;
    this.messageFraisPrevisionnels = data.messageFraisPrevisionnels || this.messageFraisPrevisionnels;
    this.linkedSku = data.linkedSku || this.linkedSku;
    this.switchOc = data.switchOc || this.switchOc;
    this.switchItemIdOc = data.switchItemIdOc || this.switchItemIdOc;
    this.typeDegroupage = data.typeDegroupage || this.typeDegroupage;
    this.checkCart = data.checkCart || this.checkCart;
  }

  public canAdd(browseType: BrowseType, isPrepaid: boolean, isFnb: boolean): AddResult {
    const result: AddResult = { status: true, productsConflict: [] };
    if (browseType === BrowseType.acquisitionFix) {
      return result;
    }

    result.status = false;
    result.addCheck = { type: TypeResult.error, message: CompatibilityMessages.boxFai } as ResultAddMessage;

    return result;
  }
}
