import { Inject, Injectable } from '@angular/core';

import { Observable, of, Subscriber } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import { Coordinates } from '../address/interfaces/marker.interfaces';

@Injectable({
  providedIn: 'root',
})
export class GmapsService {
  private static readonly googleApiKey: string = 'AIzaSyDbkUhgcWVw3rIjOLspjKG3Ugkn3G1ZbXw';

  private autocompleteService: google.maps.places.AutocompleteService;
  private placeService: google.maps.places.PlacesService;
  private geocoderService: google.maps.Geocoder;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly httpClient: HttpClient,
  ) {}

  public resolve(): Observable<boolean> {
    if (!!this.document.defaultView.window?.google?.maps) {
      this.initServices();
      return of(true);
    }

    return this.httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=' + GmapsService.googleApiKey,
        'callback',
      )
      .pipe(
        tap(() => {
          this.initServices();
        }),
        mapTo(true),
      );
  }

  public geocodeAddress(address: string): Observable<Coordinates> {
    return of(null).pipe(
      switchMap(
        () =>
          new Observable((observer: Subscriber<Coordinates>) => {
            this.geocoderService.geocode({ address }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                observer.next({
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                });
              } else {
                observer.next({ lat: 0, lng: 0 });
              }
              observer.complete();
            });
          }),
      ),
    );
  }

  private initServices(): void {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.geocoderService = new google.maps.Geocoder();
    this.placeService = new google.maps.places.PlacesService(this.document.createElement('div'));
  }
}
