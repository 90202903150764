<section class="log-details">
  <h1 class="title">
    <span class="icon is-medium" aria-label="Icone de taille large">
      <i class="tri-alert" aria-hidden="true"></i>
    </span>
    <span> (Panier N°{{ cartService.cart.cartId }}) </span>
  </h1>
  <pre class="code code-log">
    <code>
      @if (controlErrors.length === 0) {
        <span>Aucune erreur dans le parcours</span>
        }<br>
        @for (control of controlErrors; track control) {
          <span>{{ control.time }} => Request: {{ control.request | json : nospacing}}   Reponse: {{ control.response | json : nospacing}} </span>
          }<br>
        </code>
      </pre>

      <div class="actions">
        <button class="button is-primary" (click)="close()">Fermer</button>
        @if (controlErrors.length > 0) {
          <button class="button is-info" (click)="copy()">Copier</button>
        }
      </div>
    </section>
