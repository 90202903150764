import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '@services/cart.service';
import { CartSerialized } from '@interfaces/cart.interface';
import { ConfigurationService } from '@services/configurationService';
import { interval, Subscription } from 'rxjs';
import { catchError, repeat, take, tap } from 'rxjs/operators';
import { FaiStorageService } from '@fai-widget/fai-storage.service';
import { Router } from '@angular/router';
import { Fai } from '@model/catalog/products/subscription/plan/fai/fai';
import { BoxFaiGenerique } from '@model/catalog/products/subscription/box-fai-generique';
import { GlobalLoaderService } from '@base/services/global-loader.service';

const LOADING_ACTIONS = {
  modifyFaiPlan: '[PlanComponent] modifyFaiPlan',
  deletePlan: '[PlanComponent] deletePlan',
  deleteSim: '[PlanComponent] deleteSim',
};
@Component({
  selector: 'rcbt-reprise-panier',
  templateUrl: './reprise-panier.component.html',
  standalone: true,
  styleUrls: ['./reprise-panier.component.scss'],
})
export class ReprisePanierComponent implements OnInit, OnDestroy {
  public counter = 0;
  public isFaiConsistent = true;
  private delay = 0;
  private subscription = new Subscription();
  private intervalHandler;

  constructor(
    private activeModal: NgbActiveModal,
    private cartService: CartService,
    private configurationService: ConfigurationService,
    private router: Router,
    private globalLoaderService: GlobalLoaderService,
    private faiStorageService: FaiStorageService,
  ) {}

  public ngOnInit(): void {
    const defaultTimeout = 20;
    if (this.configurationService.reprisePanierDelay) {
      this.delay = (this.configurationService.reprisePanierDelay || defaultTimeout) * 1000;
    }
    interval(this.delay / 100)
      .pipe(take(101), repeat(1))
      .subscribe(x => (this.counter = x));
    this.intervalHandler = setInterval(() => {
      this.runReprise();
    }, this.delay);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public runReprise(): void {
    this.subscription.add(
      this.cartService
        .refreshCart()
        .pipe(
          catchError(() =>
            this.cartService.getCurrentCart().pipe(
              tap(() => (cart: CartSerialized): void => {
                this.activeModal.close();
                this.faiStorageService.clear();
                const finalUrl: string = window.location.origin + '/dispatch#' + this.getRepriseFragment(cart);
                window.top.location.href = finalUrl;
              }),
            ),
          ),
          tap(() => {
            this.isFaiConsistent = this.checkFaiConsistency();
            if (this.isFaiConsistent) {
              clearInterval(this.intervalHandler);
              if (this.cartService.cart.schemes.every(({ verrouiller }) => verrouiller)) {
                this.router.navigate(['/panier', 'recapitulatif']);
              } else {
                this.router.navigate(['/panier']);
              }
              this.activeModal.close();
            }
          }),
        )
        .subscribe(),
    );
  }

  public checkFaiConsistency(): boolean {
    const hasFAi = this.cartService.getProductsByType(Fai)?.length > 0;
    return hasFAi ? this.cartService.getProductsByType(BoxFaiGenerique)?.length > 0 : true;
  }

  public goBackToTechs(): void {
    this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.modifyFaiPlan, isLoading: true });
    this.router.navigate(['/fai/techs'], { queryParams: { back: true } });
    this.activeModal.close();
  }

  private getRepriseFragment(cart: CartSerialized): string {
    let finalUrl = '';
    const typeVisiteur = cart.parcours[0].contextProduit.contextService.client.type;
    const idPU = cart.personId;
    const categorieProspect = cart.parcours[0].contextProduit.contextService.client.categorie;
    const idPanierCommande = cart.idPanierCommande;

    finalUrl += '';
    if (typeVisiteur === 'CLIENT') {
      finalUrl += `idPU=${idPU}`;
    } else {
      finalUrl += `categorieProspect=${categorieProspect}`;
      if (categorieProspect === 'GP') {
        if (cart.parcours[0].contextProduit.contextService.client) {
          const nomProspect = cart.parcours[0].contextProduit.contextService.client.nom;
          const prenomProspect = cart.parcours[0].contextProduit.contextService.client.prenom;
          const dateNaissanceProspect = cart.parcours[0].contextProduit.contextService.client.dateNaissance;
          const lieuNaissanceProspect = cart.parcours[0].contextProduit.contextService.client.departementNaissance;
          const adresseMailProspect = cart.parcours[0].contextProduit.contextService.client.email;
          const telephoneProspect = cart.parcours[0].contextProduit.contextService.client.telephone;
          finalUrl +=
            `&nomProspect=${nomProspect}&prenomProspect=${prenomProspect}&` +
            `dateNaissanceProspect=${dateNaissanceProspect}&` +
            `lieuNaissanceProspect=${lieuNaissanceProspect ? lieuNaissanceProspect.toString() : lieuNaissanceProspect}&` +
            `emailContact=${adresseMailProspect}&telephoneContact=${telephoneProspect}`;
        }
      } else {
        const raisonSocialeProspect =
          cart.parcours[0].contextProduit.contextService.parcours &&
          cart.parcours[0].contextProduit.contextService.parcours.raisonSocialeProspect;
        const adresseMailProspect =
          cart.parcours[0].contextProduit.contextService.parcours &&
          cart.parcours[0].contextProduit.contextService.parcours.emailContact;
        finalUrl += `&raisonSocialeProspect=${raisonSocialeProspect}&emailContact=${adresseMailProspect}`;
      }
    }

    const idDevice =
      cart.parcours[0].contextProduit.contextService.parcours &&
      cart.parcours[0].contextProduit.contextService.parcours.referenceAppareil;
    finalUrl += `&idDevice=${idDevice}&idPanierCommande=${idPanierCommande}`;
    return finalUrl;
  }
}
