<div class="dialog-modal-container no-gutters is-tri">
  <div class="modal-header is-centered">
    <h2 class="title is-level-1">Suppression assurance MEDI7</h2>
  </div>
  <div class="modal-text">
    @if (switchToTPV) {
      <span>
        Attention, vous venez de reprendre un panier contenant une assurance MEDI7 sur TPV.<br>
        Pour garder l’assurance et y souscrire
      </span>
    }
    @if (!switchToTPV) {
      <span>
        Suite à la modification du panier la demateralisation sera invalidée.<br>
        Pour conserver l’assurance Assurant
      </span>
    }
    , veuillez fermer manuellement ce navigateur, puis reprendre votre panier depuis une tablette.<br>
    En continuant sur TPV l’assurance sera automatiquement supprimée du panier.<br>
    Voulez-vous continuer sur TPV ?
  </div>
  <div class="modal-actions">
    <button data-cy="consent-valid" class="button is-info" (click)="continue()" [disabled]="loading">Continuer</button>
  </div>
</div>
