import { EventEmitter, Injectable } from '@angular/core';
import { JsonProduct } from '@model/catalog/products/interface/context';
import { CartService, PaymentMode } from '@services/cart.service';
import { Sowo } from '@model/catalog/products/subscription/plan/sowo';
import { forkJoin, Observable } from 'rxjs';
import { RenewScheme } from '@model/renew-scheme';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { VirtualScheme } from '../contextualized-catalog/models/virtual-scheme';
import {
  IPostCatalogResponse,
  IVirtualProductCtx,
} from '../contextualized-catalog/dtos/contextualized-product-output.dto';
import { CatalogCtxService } from '../contextualized-catalog/services/catalog-ctx.service';
import {
  ConsulterCatalogueContextualiseAvecPanierBodyRequest,
  ConsulterCatalogueContextualiseAvecPanierQuery,
  ProductsContextRequestProductDto,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { CatalogInputHelperDto } from '../contextualized-catalog/dtos/catalog-input-helper.dto';
import { CatalogOutputHelperDto } from '../contextualized-catalog/dtos/catalog-output-helper.dto';
import { map } from 'rxjs/operators';
import { Configurations } from '@model/catalog/products/interface/configurable';

@Injectable({
  providedIn: 'root',
})
export class ComparatorService {
  public $comparatorActive: EventEmitter<void> = new EventEmitter<null>();
  public static phoneAlone = 'Téléphone seul';
  public availablePlans: JsonProduct[] = [];
  public selectedPhones: IPostCatalogResponse[] = [];
  public active = false;
  public virtualSchemes: {
    [planGencode: string | 'Téléphone seul']: {
      [phoneGencode: string]: { scheme: VirtualScheme; schemeEdp?: VirtualScheme; schemeCredit?: VirtualScheme };
    };
  } = {};
  public parentsConfig: { [key: string]: Configurations } = {};

  constructor(
    private readonly cartService: CartService,
    private customerService: CustomerService,
    private catalogCtxService: CatalogCtxService,
  ) {}

  public selectProduct(ctxPhone: IPostCatalogResponse): void {
    if (this.selectedPhones.length < 3) {
      this.selectedPhones.push(ctxPhone);
    } else {
      this.selectedPhones[0] = ctxPhone;
    }
  }

  public removeProduct(gencode: string): void {
    this.selectedPhones = this.selectedPhones.filter(tmpPhone => tmpPhone.gencode !== gencode);
  }

  public empty(): void {
    this.parentsConfig = {};
    this.selectedPhones = [];
  }

  public loadComparatorData(): Observable<void> {
    return forkJoin(this.loadContextualizedProductsData()).pipe(
      map((contextualizedProducts: IPostCatalogResponse[][]) => {
        const phoneOnlyContextualizedProducts = contextualizedProducts.pop();
        if (phoneOnlyContextualizedProducts) {
          this.parsePhoneOnlyResult(phoneOnlyContextualizedProducts);
        }
        this.parsePlansResult(contextualizedProducts);
      }),
    );
  }

  public reset(): void {
    this.active = false;
    this.empty();
  }

  public isCompareButtonEnabled(): boolean {
    return this.selectedPhones.length > 1;
  }

  /**
   * get All Avaiblable Plans for the comparator
   */
  public processPlansForCompare(planList: JsonProduct[]): void {
    const currentScheme = this.cartService.getCurrentScheme();
    if (currentScheme instanceof RenewScheme) {
      if (currentScheme.planType === Sowo.planType) {
        planList = planList.filter(p => p.gencode !== currentScheme.gencodeOffre);
      }
    }
    this.availablePlans = planList;
    this.highlightRenewPlan();
  }

  private highlightRenewPlan(): void {
    this.availablePlans.forEach((plan: JsonProduct) => {
      if (
        plan.name !== ComparatorService.phoneAlone &&
        plan.gencode === (this.cartService.getCurrentScheme() as RenewScheme).gencodeOffre
      ) {
        plan['highlighted'] = true;
      }
    });
  }

  private parsePhoneOnlyResult(phoneOnlyContextualizedProducts: IPostCatalogResponse[]): void {
    phoneOnlyContextualizedProducts.forEach(phonesOnlyContextualizedProduct => {
      if (!this.virtualSchemes[ComparatorService.phoneAlone]) {
        this.virtualSchemes[ComparatorService.phoneAlone] = {};
      }
      this.virtualSchemes[ComparatorService.phoneAlone][phonesOnlyContextualizedProduct.gencode] = {
        scheme: this.createVirtualSchemeInstance(
          phonesOnlyContextualizedProduct,
          PaymentMode.cash,
          phonesOnlyContextualizedProduct.gencode,
        ),
        schemeEdp: this.createVirtualSchemeInstance(
          phonesOnlyContextualizedProduct,
          PaymentMode.edp,
          phonesOnlyContextualizedProduct.gencode,
        ),
        schemeCredit: this.createVirtualSchemeInstance(
          phonesOnlyContextualizedProduct,
          PaymentMode.credit,
          phonesOnlyContextualizedProduct.gencode,
        ),
      };
    });
  }

  private createVirtualSchemeInstance(
    phoneOnlyContextualizedProducts: IPostCatalogResponse,
    fundingMode: PaymentMode,
    gencode: string,
  ): VirtualScheme {
    const sapicScheme = new VirtualScheme();
    sapicScheme.setEquipmentData(phoneOnlyContextualizedProducts, fundingMode, gencode);
    return sapicScheme;
  }

  private parsePlansResult(plansContextualizedProducts: IPostCatalogResponse[][]): void {
    this.availablePlans.forEach(currentPlan => {
      const currentPlanContextualizedProduct = plansContextualizedProducts[this.availablePlans.indexOf(currentPlan)];
      currentPlanContextualizedProduct.forEach(phoneWithPlanContextualizedProduct => {
        const currentSapicScheme = phoneWithPlanContextualizedProduct.panierSimule.parcours.find(
          parcours => parcours.estCourant,
        );
        const sapicPlan: IVirtualProductCtx = currentSapicScheme.produits.find(
          planContextualised => planContextualised.gencode === currentPlan.gencode,
        );
        if (!this.virtualSchemes[currentPlan.gencode]) {
          this.virtualSchemes[currentPlan.gencode] = {};
        }
        this.virtualSchemes[currentPlan.gencode][phoneWithPlanContextualizedProduct.gencode] = {
          scheme: this.createVirtualSchemeInstance(
            phoneWithPlanContextualizedProduct,
            PaymentMode.cash,
            phoneWithPlanContextualizedProduct.gencode,
          ).setPlanData(sapicPlan),
          schemeEdp: this.createVirtualSchemeInstance(
            phoneWithPlanContextualizedProduct,
            PaymentMode.edp,
            phoneWithPlanContextualizedProduct.gencode,
          ).setPlanData(sapicPlan),
          schemeCredit: this.createVirtualSchemeInstance(
            phoneWithPlanContextualizedProduct,
            PaymentMode.credit,
            phoneWithPlanContextualizedProduct.gencode,
          ).setPlanData(sapicPlan),
        };
      });
    });
  }

  /**
   * Préparer les appels à sapic : 1 pour chaque plan selectionné + 1 appel pour tél seul
   * @private
   */
  private loadContextualizedProductsData(): Observable<IPostCatalogResponse[]>[] {
    const contextualizedDataObs: Observable<IPostCatalogResponse[]>[] = [];
    const queryParamCtx: ConsulterCatalogueContextualiseAvecPanierQuery = {
      modePourFinancement: 'min-one-off',
      limite: 20,
      detail: 'true',
      gencodes: this.selectedPhones.map(phone => phone.gencode).join(','),
      forceEdp: 'true',
    };

    let catalogBodyWithCart: ConsulterCatalogueContextualiseAvecPanierBodyRequest;
    this.availablePlans.forEach((plan: JsonProduct) => {
      catalogBodyWithCart = CatalogInputHelperDto.buildBodyCtxWithCart(
        this.customerService.customer,
        this.cartService.cart,
        this.getPayloadProductForPlan(plan.gencode),
        this.cartService.getCurrentScheme()?.uniqueId,
      );

      contextualizedDataObs.push(
        this.catalogCtxService
          .postCatalogCtx(catalogBodyWithCart, queryParamCtx)
          .pipe(map(catalogResult => CatalogOutputHelperDto.convertCatalogResponse(catalogResult))),
      );
    });
    catalogBodyWithCart = CatalogInputHelperDto.buildBodyCtxWithCart(
      this.customerService.customer,
      this.cartService.cart,
      null,
      this.cartService.getCurrentScheme()?.uniqueId,
    );
    const queryParamCtxForPhones: ConsulterCatalogueContextualiseAvecPanierQuery = {
      modePourFinancement: 'min-one-off',
      limite: 20,
      detail: 'true',
      gencodes: this.selectedPhones.map(phone => phone.gencode).join(','),
      forceEdp: 'true',
    };
    contextualizedDataObs.push(
      this.catalogCtxService
        .postCatalogCtx(catalogBodyWithCart, queryParamCtxForPhones)
        .pipe(map(catalogResult => CatalogOutputHelperDto.convertCatalogResponse(catalogResult))),
    );
    return contextualizedDataObs;
  }

  private getPayloadProductForPlan(planGencode: string): ProductsContextRequestProductDto {
    return {
      gencode: planGencode,
      catalogue: 'BYTEL',
    };
  }
}
