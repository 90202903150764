import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '@services/config.service';
import { AlertService } from '@services/alert.service';
import { Subscription } from 'rxjs/index';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'rcbt-modal-consent',
  templateUrl: './modal-consent.component.html',
  styleUrls: ['./modal-consent.component.scss'],
  imports: [FormsModule],
})
export class ModalConsentComponent implements OnInit, OnDestroy {
  @Input()
  public onValidClick: () => void;

  @Input()
  public onCloseClick: () => void;

  @Input()
  public hideCancelButton = false;

  @Input()
  public hideValidButton = false;

  @Input()
  public cancelButtonText = 'Précédent';

  @Input()
  public validButtonText = 'Suivant';

  public message: string;
  public consentAgreed: boolean;
  public loading = false;
  public loadingSubscription: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private readonly configService: ConfigService,
    private readonly alertService: AlertService,
  ) {
    if (!this.onValidClick) {
      this.onValidClick = this.closeModal;
    }
    this.loadingSubscription = this.alertService.isLoading.subscribe((load: boolean) => (this.loading = load));
  }

  public ngOnInit(): void {
    this.consentAgreed = false;
    this.message = this.configService.data.textPopupCredit;
  }

  public ngOnDestroy(): void {
    this.loadingSubscription?.unsubscribe();
  }

  public closeModal(onlyClose?: boolean): void {
    if (this.onCloseClick && !onlyClose) {
      this.onCloseClick();
    }
    this.activeModal.close();
  }
}
