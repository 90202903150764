
<div class="product-item is-flex is-fullwidth is-vertical" rcbtQview (click)="productClick($event, equipment)">
  @if (equipment?.etiquetteAnimCo) {
    <div class="device-tag is-flex is-fullwidth is-justified-center m-t-5">
      <rcbt-bonus-reprise [etiquetteAnimCo]="equipment.etiquetteAnimCo"></rcbt-bonus-reprise>
    </div>
  }
  @if (equipment.odr) {
    <div class="device-tag is-flex is-fullwidth is-justified-center m-t-5">
      <span class="tag has-text-weight-semibold has-background-info has-text-white">
        ODR -{{equipment.odr.amount}}€
      </span>
    </div>
  }

  <div class="device-display is-flex is-fullwidth is-justified-center is-aligned-center">
    <div class="image-container">
      @if (connectivityIconMap[equipment?.connectivite]) {
        <span class="icon icon-left is-stretched is-small"><i [class]="connectivityIconMap[equipment?.connectivite]" aria-hidden="true"></i></span>
      }
      @if (equipment.financement.hasCredit) {
        <span class="icon icon-right is-medium"><i class="tri-mobile-financing" aria-hidden="true"></i></span>
      }
      <img alt="{{equipment.nom}}" [src]="equipment.image | media">
    </div>
  </div>

  <div class="device-info p-l-5 is-flex is-vertical is-aligned-center">
    <div class="has-text-weight-semibold" [ngClass]="{'accessory' : equipment.type == 'accessory'}">
      <span>{{ equipment.marque}} {{ equipment.nom }}</span>
    </div>
    @if (equipment.financement.edp) {
      <div>
        <span>à partir de</span>
      </div>
    }
    @if (equipment.financement) {
      <div>
        @if (!equipment.financement.edp) {
          <span class="price is-main-price is-level-5"
          [innerHTML]="equipment.financement.cash.price | customCurrency"></span>
        }
        @if (equipment.financement.edp) {
          <span class="price is-main-price is-level-5"
          [innerHTML]="equipment.financement.edp.edp_apportInitial | customCurrency"></span>
        }
      </div>
    }
    @if (equipment.financement.edp) {
      <div class="has-text-weight-semibold">
        +<span [innerHTML]="equipment.financement.edp.edp_price | customCurrency"></span>
        /mois /{{equipment.financement.edp.edp_duration}} mois
      </div>
    }
  </div>
</div>
@if (comparatorService.active) {
  <div class="is-flex is-justified-center is-aligned-center is-vcentered">
    <div class="is-flex is-aligned-center checkbox" [ngClass]="{'is-invisible': !equipment.enfants?.length}">
      <input aria-checked="true" type="checkbox"
        id="checkbox-compare-{{equipment.gencode}}"
        checked="{{isParentSelected(equipment) ? 'checked' : ''}}"
        (click)="productClick($event, equipment)"
        />
      <label for="checkbox-compare-{{equipment.gencode}}" class="checkbox-label checkbox mt-0">Comparer</label>
    </div>
  </div>
}
