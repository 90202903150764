import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, viewChild } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { FilterService } from '@services/filter.service';
import { debounceTime } from 'rxjs/operators';
import { FilterAllValue, IFilterItems, PhoneFiltersLists } from '@model/filter.model';
import { CartService } from '@services/cart.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'rcbt-side-bar-filter',
  templateUrl: './side-bar-filter.component.html',
  styleUrls: ['./side-bar-filter.component.scss'],
  standalone: true,
  imports: [FormsModule],
})
export class SideBarFilterComponent implements AfterViewInit, OnDestroy {
  readonly filterByText = viewChild<ElementRef>('filterByText');
  @Output() filtersChangedEmitter = new EventEmitter<void>();

  public inputStr: string;
  public isRenew: boolean;
  public nbMinBrand = 4;
  public allBrand = false;
  private subscription = new Subscription();

  constructor(
    public filterService: FilterService,
    private cartService: CartService,
  ) {
    this.isRenew = this.cartService.getCurrentScheme().isRenew();
  }

  public ngAfterViewInit(): void {
    this.subscription.add(
      fromEvent(this.filterByText().nativeElement, 'keyup')
        .pipe(debounceTime(800))
        .subscribe(() => this.filtersChangedEmitter.emit()),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public emitFiltersAndSort(): void {
    this.filtersChangedEmitter.emit();
  }

  public clearAllFilters(): void {
    this.filterService.initializeFiltersAndSort();
    this.filtersChangedEmitter.emit();
  }

  public updateAndEmitFilters(filterType: keyof PhoneFiltersLists, filterValue: FilterAllValue): void {
    this.filterService.updateFilters(filterType, filterValue);
    return this.filtersChangedEmitter.emit();
  }

  public isItemCodeDisplay(item: IFilterItems, filterCode: string, index: number): boolean {
    if (this.isRenew && item.renewForbidden) {
      return false;
    }
    if (
      filterCode === 'brand' &&
      !this.allBrand &&
      index >= this.nbMinBrand &&
      !this.filterService.isFilterOn(filterCode, item.code)
    ) {
      return false;
    }
    return true;
  }
}
