import { SchemeSerialized } from '@interfaces/scheme.interface';
import { ICartTotal, IPromotion } from '@promotions/promotions.interfaces';
import { InsuranceConfig } from '../partner/partner.dto';
import { FundingEnum } from './funding.interface';

export interface CartSerialized {
  parcours?: SchemeSerialized[];
  idPanier: number;
  parcourCourant: string;
  idPanierCrypte: string;
  idDossier: string;
  dossierToken: string;
  cleDeCryptage: string;
  idCommande: string;
  contratSigne: boolean;
  canal: string;
  actorType?: string;
  consent?: Consent;
  uuid?: string;
  fingerprint?: string;
  adresseIp?: string;
  personId?: string;
  idPanierCommande: string;
  totals?: ICartTotal;
  bytelTotals?: ICartTotal;
  caTotals?: ICartTotal;
  promotions?: IPromotion[];
  addedInsurances?: InsuranceConfig[];
  contractPrinted: boolean;
}

export enum AppErrorCodes {
  error = 'error',
  msisdn = 'msisdn',
  rum = 'rum',
  conflict = 'conflict',
  shopdetail = 'shopdetail',
  lockedCart = 'locked_cart',
}

interface ICartCustomerSummary {
  firstname: string;
  lastname: string;
  dob: Date;
  phone: string;
  email: string;
  siren: string;
}

export interface ICartSummary {
  id: number;
  ctime: Date;
  signCode: string;
  pointOfSaleCode: string;
  customer: ICartCustomerSummary;
}

export interface IRBank {
  banque?: string;
  bic?: string;
  codeBanque?: string;
  codeGuichet?: string;
  codePays?: string;
  nomBanque?: string;
  typeCompte?: string;
}

export class Consent {
  toWantConsumerLoanQuotes?: boolean;
  toWantLoanDataTransfert?: boolean;
}

export enum PaymentType {
  cash = 'COMPTANT',
  edp = 'EDP',
  credit3 = 'YOUNITED_X3',
  credit12 = 'YOUNITED_X12',
  credit24 = 'YOUNITED_X24',
  credit36 = 'YOUNITED_X36',
}

export interface CreditData {
  type?: FundingEnum;
}
