import { Component, Input } from '@angular/core';

import { EquipmentItem } from '../product-list/product-list.component';
import { ComparatorService } from '@services/comparator.service';
import { ViewDirective } from '@directive/view.directive';
import { IPostCatalogResponse } from '../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { CONNECTIVITY_ICON_MAP } from '../../../constants/product-list.constant';
import { BonusRepriseComponent } from '@base/components/bonus-reprise/bonus-reprise.component';
import { MediaPipe } from '@base/pipes/media.pipe';
import { NgClass } from '@angular/common';
import { CustomCurrencyPipe } from '@base/pipes/currency.pipe';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ComparatorSelectionModalComponent } from '@components/comparator/comparator-selection-modal/comparator-selection-modal.component';

@Component({
  selector: 'rcbt-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
  standalone: true,
  imports: [BonusRepriseComponent, MediaPipe, NgClass, CustomCurrencyPipe, ViewDirective],
})
export class ProductItemComponent {
  constructor(
    public comparatorService: ComparatorService,
    private modalService: NgbModal,
  ) {}

  @Input() public readonly index: number;
  @Input() readonly equipment: EquipmentItem;

  public connectivityIconMap = CONNECTIVITY_ICON_MAP;

  public isParentSelected(parentEquipment: EquipmentItem): boolean {
    return !!this.getSelectedChild(parentEquipment);
  }

  // todo déplacer le code qui cherche les déclinaisons de produits ici, ce composant décidera d'afficher ou pas la popin
  public productClick(event: MouseEvent, parentEquipment: EquipmentItem): void {
    if (!this.comparatorService.active) {
      return;
    }
    const selectedChild = this.getSelectedChild(parentEquipment);
    if (selectedChild) {
      this.comparatorService.removeProduct(selectedChild.gencode);
      return;
    }
    event.preventDefault();
    const options = {
      backdrop: 'static',
      size: 'lg',
      backdropClass: 'semi-opacity',
      windowClass: 'modal-comparator-selection',
      keyboard: false,
    } as NgbModalOptions;
    const componentInstance: ComparatorSelectionModalComponent = this.modalService.open(
      ComparatorSelectionModalComponent,
      options,
    ).componentInstance;
    componentInstance.childrenCodes = parentEquipment.enfants;
    componentInstance.parentCode = parentEquipment.gencode;
    componentInstance.bestChild = parentEquipment.meilleurEnfant;
  }

  private getSelectedChild(parentEquipment: EquipmentItem): IPostCatalogResponse {
    if (!parentEquipment.enfants) {
      return null;
    }
    return this.comparatorService.selectedPhones.find(phone => parentEquipment.enfants.includes(phone.gencode));
  }
}
