import { CheckoutNs } from '@interfaces/checkout.interface';

export enum CustomerStepEnum {
  identity = 'identity',
  address = 'address',
  payment = 'payment',
}

export enum LastStepEnum {
  identity = 1,
  address = 2,
  payment = 3,
}

export enum CustomerType {
  client = 'CLIENT',
  prospect = 'PROSPECT',
}

export enum CustomerCategory {
  gp = 'GP',
  pro = 'PRO',
  entreprise = 'ENTREPRISE',
}

export interface CustomerStep {
  order: number;
  value: CustomerStepEnum;
  label: string;
  disabled?: boolean;
  submit?: boolean;
}

export interface InsuranceDataIdentity {
  civility?: string;
  lastname?: string;
  firstname?: string;
  dob?: string;
  phoneNumber?: string;
  insuranceEmail?: string;
  birthplaceCity?: string;
  birthplaceCountry?: string;
  nationality?: string;
  address?: AddressData;
}

export interface InsuranceDataPayment {
  paymentMode?: CheckoutNs.PaymentMode;
  iban?: string;
  bic?: string;
}

export interface AddressData {
  country?: string;
  zipCode?: string;
  streetNumber?: string;
  street?: string;
  city?: string;
  additionalInfo?: string;
}

export interface InsuranceDataSubscription {
  identity?: InsuranceDataIdentity;
  payment?: InsuranceDataPayment;
}

export interface IEntiteReseauDistribution {
  codeEnseigne: string;
  codePdv: string;
}

export interface IActeurCreateur {
  codeOrias?: string;
  entiteReseauDistribution: IEntiteReseauDistribution;
  login: string;
  matricule: string;
  type: string;
}

export interface ICompteBancaire {
  bic: string;
  iban: string;
}

export interface ICoordonnee {
  _type: string;
  email?: string;
  codePays?: string;
  cp?: string;
  num?: string;
  rue?: string;
  ville?: string;
  complementAdr1?: string;
  noTel?: string;
}

export interface IClient {
  civilite: string;
  codePaysNaissance: string;
  dateNaissance: string;
  nationalite: string;
  nom: string;
  prenom: string;
  villeNaissance: string;
  compteBancaire: ICompteBancaire;
  coordonnees: ICoordonnee[];
}
export interface InsuranceSubsciptionDTO {
  _type: string;
  acteurCreateur: IActeurCreateur;
  client: IClient;
  idServicePartenaire: string;
  noOffre: string;
  noPersonne: string;
  modePaiement: string;
}

export interface MetadataInputDTO {
  assurance?: InsuranceMetadataInputDTO;
}

export interface InsuranceMetadataInputDTO {
  civilite: string;
  nom: string;
  prenom: string;
  dateNaissance: string;
  telephone: string;
  adresse?: {
    rue: string;
    numeroRue: string;
    codePostal: string;
    complementAdresse?: string;
    ville: string;
    pays: string;
  };
  emailAssurance: string;
  villeNaissance: string;
  paysNaissance: string;
  nationalite: string;
  modePaiementAssurance: string;
  ibanAssurance?: string;
  bicAssurance?: string;
}

export interface MetaDataOutputDTO {
  fraudePotentielle?: boolean;
  assurance: {
    identite: {
      emailAssurance: string;
      villeNaissance: string;
      paysNaissance: string;
      dateNaissance: string;
      nationalite: string;
      civilite: string;
      nom: string;
      prenom: string;
      telephone: string;
      adresse: {
        rue: string;
        numeroRue: string;
        codePostal: string;
        complementAdresse?: string;
        ville: string;
        pays: string;
      };
    };
    paiement: {
      ibanAssurance?: string;
      bicAssurance?: string;
      modePaiementAssurance: string;
    };
  };
}

export interface AdresseFacturationResponse {
  codePostal: string;
  complementAdresse1: string;
  numero: string;
  pays: string;
  rue: string;
  ville: string;
  compteBancaire: ICompteBancaire;
  modePaiement: CheckoutNs.PaymentMode;
}

export interface SimulatorParams {
  IdPu?: string;
  Civilite?: string;
  Nom?: string;
  Prenom?: string;
  Email?: string;
  Telephone?: string;
  Siren?: string;
  RaisonSociale?: string;
  TypeVisiteur?: CustomerType;
  TypeClient?: CustomerCategory;
  CodEnseigne?: string;
  CodePDV?: string;
  NomEnseigne?: string;
  IdProspect?: string;
}
