import { CustomerCategory } from '@checkout/cart/customer/customer.interface';

export enum BrowseType {
  acquisition = 'ACQUISITION',
  acquisitionfix = 'ACQUISITIONFIX',
  renouvellement = 'RENOUVELLEMENT',
  replaceSim = 'REPLACE_SIM',
  continuiteDeService = 'CONTINUITE_DE_SERVICE',
  souscriptionOffreAdditionnelle = 'SOUSCRIPTION_OFFRE_ADDITIONNELLE',
  migration = 'MIGRATION',
  demenagement = 'DEMENAGEMENT',
  migrationOp = 'MIGRATION_OP',
  rechargeData = 'RECHARGE_DATA',
}

export enum CatalogSimType {
  sim = 'sim',
  esim = 'esim',
  qrEsim = 'qr_esim',
  simReplace = 'sim_replace',
  esimReplace = 'esim_replace',
  qrEsimReplace = 'qr_esim_replace',
  simCbt = 'sim_cbt',
  simSav = 'sim_sav',
}

export enum FrontSimType {
  simPhysique = 'sim_physique',
  qrEsim = 'qr_esim',
}

export enum Catalog {
  ca = 'CA',
  bytel = 'BYTEL',
}

export enum PriceType {
  today = 'IMMEDIAT',
  month = 'MENSUEL',
  other = 'AUTRE',
  differred = 'DIFFERE',
}

export interface SimsContextualisesRequest {
  idPU?: string;
  clientCategorie?: CustomerCategory;
  parcours: SimsContextualisesParcourRequest[];
}

export interface SimsContextualisesParcourRequest {
  id?: string;
  idContrat?: string;
  produits: SimsContextualisesProduitRequest[];
  estCourant: boolean;
  type: string;
}

export interface SimsContextualisesProduitRequest {
  id?: string;
  gencode: string;
  catalogue?: Catalog;
}

export interface SimsContextualisesResponse {
  parcours: SimsContextualisesParcourResponse[];
}

export interface SimsContextualisesParcourResponse {
  id: string;
  estCourant: boolean;
  simCompatible?: boolean;
  simsCompatibles: SimCompatible[];
}

export interface SimCompatible {
  gencode: string;
  prefere: boolean;
  terminalCompatible?: boolean;
  prix: number;
  typePrix: PriceType;
  type: CatalogSimType;
}

export interface SimsState {
  simPhysiqueCompatible: boolean;
  qrEsimCompatible: boolean;
  qrEsimGencode?: string;
  qresimPreferred: boolean;
  simExistanteCompatible?: boolean;
  simsCompatibles: SimCompatible[];
}

export interface DataStorage {
  request: SimsContextualisesRequest;
  responce: SimsContextualisesResponse;
  simsState: SimsState;
  simChoice: FrontSimType;
}
