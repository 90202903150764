import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
  standalone: true,
  pure: false,
})
export class PricePipe implements PipeTransform {
  public transform(value: unknown, args?: string): unknown {
    if (!value && value !== 0) {
      return '';
    }
    const price: string[] = parseFloat(value.toString()).toFixed(2).split('.');

    if (args === 'cents') {
      return price[1];
    } else {
      return price[0];
    }
  }
}
