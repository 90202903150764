import { Injectable } from '@angular/core';
import { Step } from '@components/stepper/step.abstract';
import { EnumFaiSteps } from '../../../shared/prisme/prisme-logger.service';

@Injectable({
  providedIn: 'root',
})
export class EligibilityStepService extends Step {
  public isActive = true;
  public code = 'eligibility';
  public path = '/fai/eligibility';
  public label = 'Test d’éligibilité aux offres fixes';
  public stepPrisme: string = EnumFaiSteps.stepeligibility;
  public stepReturnPrisme: string = EnumFaiSteps.stepeligibility;
}
