@if (ctxPhonesOnly?.length > 1) {
  <div class="is-tri is-flex is-vertical details">
    <div class="columns">
      <p class="title is-centered column">
        Choisir la version du mobile
      </p>
      <a (click)="close($event)" href="#">
        <span class="icon is-large">
          <i class="tri-times-circle" aria-hidden="true"></i>
        </span>
      </a>
    </div>
    <div class="is-fullwidth">
      @if (loadingCtxCatalogError) {
        <div class="row is-justified-center">
          <button class="button is-info" (click)="loadProducts()" [disabled]="globalLoaderService.isAppOnLoadingStatus$ | async">
            <span class="icon is-small"><i class="tri-exclamation-redo" aria-hidden="true"></i></span> Recharger
          </button>
        </div>
      } @else {
        <div class="is-fullwidth is-flex">
          <section class="device-section">
            <div class="row">
              <div class="column is-3 device-visual">
                <div class="selected-image-frame">
                  <img class="selected-image" [src]="currentEquipment?.image | media" alt="{{currentEquipment?.nom}}">
                </div>
              </div>
              <div class="column is-7 device-data">
                <div class="name-brand">
                  <h3 class="title is-size-3">
                    <span class="title is-size-5">{{currentEquipment?.marque}}</span><br>
                    {{currentEquipment?.nom}}
                  </h3>
                </div>
                <div class="divider m-0 has-background-grey-20"></div>
                <div class="p-10">
                  <div class="row">
              <span class="icon is-success iconStyle">
                  <i class="tri-check-circle" aria-hidden='true'></i>
              </span>
                    <span class="text is-level-1 has-text-weight-semibold stock-margin">Disponibilité : {{ currentEquipment.quantite }}</span>
                  </div>
                </div>
                <div class="divider m-0 has-background-grey-20"></div>
              </div>
            </div>
            <div class="p-t-35">
            </div>
          </section>

          <section class="selection-section">
            <div class="p-0">
              <span class="text is-level-1 has-text-weight-semibold">Veuillez choisir une couleur et une mémoire pour continuer</span>
              <div class="divider m-2 has-background-grey-20"></div>
              @if (configuration.colors.length) {
                <div class="p-y-10">
                  <span class="text is-level-2 has-text-weight-semibold">Couleur</span>
                  <div class="columns is-multiline is-variable is-1 product-colors">
                    @for (color of this.configuration.colors; track color) {
                      <div class="item-color highlighted"
                           (click)="updateConfiguration('color', color);"
                           [ngStyle]="{'background-color': color}"
                           [ngClass]="{'selected': selectedConfiguration.color === color}">
                      </div>
                    }
                  </div>
                </div>
              }
              @if (configuration.capacities.length) {
                <div class="p-y-10">
                  <span class="text is-level-2 has-text-weight-semibold">Mémoire</span>
                  <div class="radio-list">
                    @for (capacity of this.configuration.capacities; track capacity) {
                      <div class="radio-tile is-horizontal m-t-0 m-b-10 m-r-10">
                        <input type="radio" name="capacity"
                               [(ngModel)]="selectedConfiguration.capacity"
                               value="{{capacity}}"
                               id="{{capacity.split(' ').join('')}}"
                               (ngModelChange)="updateConfiguration('capacity', capacity)">
                        <label class="radio-label has-text-weight-semibold is-nowrap"
                               for="{{capacity.split(' ').join('')}}">
                          {{capacity}}
                        </label>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </section>
        </div>
      }
    </div>
    <div class="is-flex is-justified-end">
      <button class="button is-secondary" (click)="selectProductToCompare(currentEquipment)">Valider</button>
    </div>
  </div>
}
