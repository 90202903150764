import { Injectable } from '@angular/core';
import { EnumFaiSteps } from '../../../shared/prisme/prisme-logger.service';
import { Step } from '@components/stepper/step.abstract';

@Injectable({
  providedIn: 'root',
})
export class TechsStepService extends Step {
  public isActive = false;
  public code = 'techs';
  public path = '/fai/techs';
  public label = 'Technologies disponibles pour ce client';
  public stepPrisme: string = EnumFaiSteps.steptechs;
  public stepReturnPrisme: string = EnumFaiSteps.steptechsreturn;
}
