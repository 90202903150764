import { Injectable } from '@angular/core';
import { IPostCatalogResponse } from '../contextualized-catalog/dtos/contextualized-product-output.dto';
import { CatalogCtxService } from '../contextualized-catalog/services/catalog-ctx.service';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { CartService } from '@services/cart.service';
import { Observable, of } from 'rxjs';
import {
  ConsulterCatalogueContextualiseAvecPanierBodyRequest,
  ConsulterCatalogueContextualiseAvecPanierQuery,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { CatalogInputHelperDto } from '../contextualized-catalog/dtos/catalog-input-helper.dto';
import { CatalogOutputHelperDto } from '../contextualized-catalog/dtos/catalog-output-helper.dto';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CrvService {
  private static category = 'crv';
  private ctxCrvListCache = new Map<string, IPostCatalogResponse[]>();

  constructor(
    private catalogCtxService: CatalogCtxService,
    private customerService: CustomerService,
    private cartService: CartService,
  ) {}

  public loadCrvList(): Observable<IPostCatalogResponse[]> {
    const crvLists: IPostCatalogResponse[] = this.ctxCrvListCache.get(CrvService.category);
    if (crvLists) {
      return of(crvLists);
    }
    return this.catalogCtxService.postCatalogCtx(this.getRequestBody(), this.getRequestQueryParams()).pipe(
      map(catalogResult => {
        const result = CatalogOutputHelperDto.convertCatalogResponse(catalogResult);
        this.ctxCrvListCache.set(CrvService.category, result);
        return result;
      }),
    );
  }

  private getRequestBody(): ConsulterCatalogueContextualiseAvecPanierBodyRequest {
    return CatalogInputHelperDto.buildBodyCtxWithCart(
      this.customerService.customer,
      this.cartService.cart,
      null,
      this.cartService.getCurrentScheme()?.uniqueId,
    );
  }

  private getRequestQueryParams(): ConsulterCatalogueContextualiseAvecPanierQuery {
    return {
      modePourFinancement: 'auto',
      categorie: CrvService.category,
    };
  }
}
