import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { ConfigurationService } from '@services/configurationService';
import { ToolsService } from '@base/services/tools.service';

@Directive({ standalone: true, selector: '[rcbtIbanInput]' })
export class IbanInputDirective {
  @Output() calculatedIbanLength = new EventEmitter<{ iban: string; longueur: number }>();

  constructor(protected configService: ConfigurationService) {}

  @HostListener('input', ['$event']) public onInput(event): void {
    const ibanSaisi: string = event.target.value;
    let ibanObject: { iban: string; longueur: number } = { iban: '', longueur: 0 };
    const ibanMask = this.configService.ibanMasks;

    if (ibanSaisi.length > 5 && !ibanSaisi.includes(' ')) {
      ibanObject = ToolsService.validateIban(ibanSaisi, ibanMask);
    } else {
      ibanObject.iban = ibanSaisi;
      ibanObject = ToolsService.validateLastCharacter(ibanObject, ibanMask);
    }
    event.target.value = ibanObject.iban;
    this.calculatedIbanLength.emit(ibanObject);
  }
}
