import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
@Injectable()
export class ConfigService {
  public data = this.document.defaultView.appConfig;
  // TODO: To be removed
  public isLegacy = false;

  constructor(@Inject(DOCUMENT) private document: Document) {
    window.webComponentConfig = {
      ...window.webComponentConfig,
      ...{
        envPortailVente: this.data.envPortailVente,
        localSalesApi: this.data.localSalesApi,
        oauth2: this.data.oauth2,
        isPrismeActive: this.data?.prisme.monitoring?.active,
        isAccapActive: this.isAccapActive(),
        isSimulatorActive: this.isSimulatorActive(),
      },
    };
  }

  public static asBoolean(config: string | boolean): boolean {
    if (config === true || !config) {
      return !!config;
    }
    return config !== '0' && config.toLowerCase() !== 'false';
  }

  public getEnvTrv(): string {
    return this.data.oauth2.envTrv;
  }

  public getGitlabToken(): string {
    return this.data.gitlab_token_part1 + this.data.gitlab_token_part2;
  }

  public getEnv(): string {
    return this.data.envPortailVente;
  }

  public getResourceUrl(): string {
    return this.data.oauth2.api + (this.data.oauth2.envTrv ? '/' + this.data.oauth2.envTrv : '');
  }

  public getFailureUrl(): string {
    return this.getBaseUrl() + this.data.oauth2.failure_url;
  }

  public getBaseUrl(): string {
    return this.data.oauth2.base + (this.data.oauth2.envTrv ? '/' + this.data.oauth2.envTrv : '');
  }

  public isAccapActive(): boolean {
    return this.data?.isAccapActive ?? true;
  }

  public isSimulatorActive(): boolean {
    return this.data?.simulator?.active ?? true;
  }

  public isToutatisActive(): boolean {
    return this.data?.isToutatisActive ?? true;
  }

  public getSimulatorUrl(): { url: string; icon: string; title: string } {
    return this.data.toolsBox?.simulator;
  }

  public getScanditLicense(): string {
    return this.data.scanditLicense ?? '';
  }
}
