import { PriceTypes } from '@model/catalog/products/interface/price-type.enum';
import { IFundingCtx, IVirtualProductCtx } from '../dtos/contextualized-product-output.dto';

export class VirtualProduct {
  public oldPrice: number;
  public price: number;
  public credits: IFundingCtx[];
  public edp: IFundingCtx;
  public ctxProduct: IVirtualProductCtx;
  public categories: string[];
  public eligibilites: string[];

  constructor(public priceType: PriceTypes) {}

  public setData(planResult: IVirtualProductCtx): this {
    this.price = planResult.prix.final;
    this.oldPrice = planResult.prix.initial;
    this.ctxProduct = planResult;
    this.categories = planResult.categories;
    this.eligibilites = planResult.eligibilites;
    return this;
  }
}
