import { Injectable } from '@angular/core';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { ConfigService } from './config.service';
import { Observable, of } from 'rxjs';
import { mergeMap, tap, catchError } from 'rxjs/operators';
import { IConfiguration, IPrismeConfiguration } from '@interfaces/configuration.interfaces';
import { IAiguillage } from '@services/gaf-interfaces';
import { Tools } from '@base/class/tools.class';
import { Oauth2StorageService } from '../oauth2/oauth2-storage.service';
import { LocationService } from '@base/services/location.service';
import { BrowseConfigService } from '../context/browse-config.service';
import { UserService } from './user.service';
import { BasicObject } from '@base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  public ibanMasks: { [countryCode: string]: number } = null;
  public callBackPtoActive = false;
  public maxOtherScan: number;
  public reprisePanierDelay: number;
  public typeProspect: string;
  public eligibleCredit = false;
  public prismeConfig: IPrismeConfiguration;
  public cancelCallBackUrl: string;

  constructor(
    protected oauth2RessourceService: Oauth2RessourceService,
    protected configService: ConfigService,
    protected oauth2StorageService: Oauth2StorageService,
    protected locationService: LocationService,
    private browseConfigService: BrowseConfigService,
    private userService: UserService,
  ) {}

  public init(): Observable<void> {
    let obs: Observable<BasicObject> = of({ ibanMasks: this.ibanMasks, callBackPtoActive: this.callBackPtoActive });
    if (!this.configService.isLegacy) {
      const config = this.configService.data;
      this.callBackPtoActive = config.callBackPtoActive;
      this.ibanMasks = config.maskIban;
      this.maxOtherScan = config.maxOtherScan;
      this.reprisePanierDelay = config.reprisePanierDelay;
      this.typeProspect = config.typeProspect;
      this.eligibleCredit = config.eligibleCredit;
      this.prismeConfig = config.prisme;
    } else {
      if (!this.ibanMasks) {
        obs = this.loadConfiguration().pipe(
          catchError(error => of({})),
          tap((config: IConfiguration) => {
            this.callBackPtoActive = config.callBackPtoActive;
            this.ibanMasks = config.maskIban;
            this.maxOtherScan = config.maxOtherScan;
            delete config.oauth;
            delete config.env;
            this.configService.data = Object.assign(this.configService.data, config);
            this.reprisePanierDelay = config.reprisePanierDelay;
            this.typeProspect = config.typeProspect;
            this.eligibleCredit = config.eligibleCredit;
            this.prismeConfig = config.prisme;
          }),
        );
      }
    }

    return obs.pipe(mergeMap(() => this.initGafConfig()));
  }

  public loadConfiguration(): Observable<IConfiguration> {
    return this.oauth2RessourceService.setLocalService().useSalesApi().ventes().configuration().get<IConfiguration>();
  }

  private initGafConfig(): Observable<void> {
    const data = this.oauth2RessourceService.getUserInfo();
    const browseConfig = this.browseConfigService.browseConfig;
    return this.oauth2RessourceService
      .conf()
      .aiguillage()
      .setParams({
        codeEns: data['enseigne'],
        codePdv: data['point_vente'],
      })
      .get()
      .pipe(
        tap((gafData: IAiguillage) => {
          const suffix = escape(
            '?idPDC=' +
              browseConfig.cartOrderId +
              '&TrackerId=' +
              (this.locationService.params.TrackerId ? this.locationService.params.TrackerId : ''),
          );

          browseConfig.callBackUrl =
            gafData.fronts.find(front => front.idFront === 'WELCOME').parametrage.parametres.urlAuthentification +
            gafData.fronts.find(front => front.idFront === 'WELCOME').parametrage.parametres.pageRecap +
            suffix;
          this.userService.user.eligibleCredit =
            this.configService.data.eligibleCredit ||
            gafData.fronts.find(front => front.idFront === 'MAGENTO').parametrage.parametres?.eligibleCredit === '1';
          browseConfig.cancelCallBackUrl = browseConfig.callBackUrl;
          return of(null);
        }),
        catchError(err => {
          const suffix = escape(
            'idPDC=' +
              browseConfig.cartOrderId +
              '&TrackerId=' +
              (this.locationService.params.TrackerId ? this.locationService.params.TrackerId : ''),
          );

          browseConfig.callBackUrl =
            (this.configService.data.gaf.nomDomEdv.replace(/ap[1-4]/, this.configService.data.oauth2.envTrv) || '') +
            (this.configService.data.gaf.complementUrlEdv || '');
          browseConfig.callBackUrl += (browseConfig.callBackUrl.includes('?') ? '&' : '?') + suffix;
          browseConfig.cancelCallBackUrl = browseConfig.callBackUrl;
          return of(null);
        }),
        tap(() => {
          browseConfig.trackerId = this.locationService.params.TrackerId || browseConfig.trackerId;
          if (!this.locationService.params.TrackerId && !browseConfig.trackerId) {
            browseConfig.trackerId = Tools.newGuid();
          }
          window.webComponentConfig.trackerId.next(browseConfig.trackerId);
          this.oauth2StorageService.setItem(this.oauth2StorageService.key['trackerId'], browseConfig.trackerId);
        }),
      );
  }
}
