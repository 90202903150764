import { BasicObject } from '@base/base.interfaces';
import { IProductConfiguration } from './IProductConfiguration';
import { Product } from './product';

export class Avc extends Product {
  public setConfiguration(data: IProductConfiguration): void {
    if (data['prix']) {
      this.price = data['prix'];
      this.oldPrice = this.price;
    }
  }

  public getConfiguration(): BasicObject {
    return {
      prix: this.price,
    };
  }

  // todo vérifier le prix de l'avc posté au back
  // public updatePrice(): void {
  //   this.price = this.getConfiguration()['prix'];
  //   this.oldPrice = this.price;
  // }
}
