import { BehaviorSubject, Observable } from 'rxjs';
import { DocTypeBTCode, MzrValidationCode, ScanStateCode, UploadStateCode } from '../constants/IDocumentCode';
import { Tools } from '@base/class/tools.class';
import { IDocumentsScanSerialize } from '@interfaces/IDocumentsScanSerialize';
import { IPandoraDocumentSerialize } from '@interfaces/IPandoraDocumentSerialize';
import { BasicObject } from '@base/base.interfaces';

declare let docIDReturn: BasicObject;

export class DocumentsScan {
  public guid: string;
  public scanState = 0;
  public mrzValidation = 'NONE';
  public uploadState = 0;
  public changeStatus: BehaviorSubject<DocumentsScan> = new BehaviorSubject(null);
  public onChangeStatus: Observable<DocumentsScan> = this.changeStatus.asObservable();

  constructor(
    public docType: number,
    public docTypeBT: DocTypeBTCode,
    public docLabel: string,
    public docName: string,
  ) {
    this.guid = Tools.newGuid();
    this.setCallbacks();
  }

  public static unserialize(data: IDocumentsScanSerialize): DocumentsScan {
    const guid: string = data.guid;
    const instance: DocumentsScan = new DocumentsScan(data.docType, data.docTypeBT, data.docLabel, data.docName);
    instance.uploadState = data.uploadState;
    instance.scanState = data.scanState;
    instance.mrzValidation = data.mrzValidation;
    if (!!guid) {
      instance.guid = guid;
    }
    return instance;
  }

  public reset(): void {
    this.guid = Tools.newGuid();
    this.scanState = 0;
    this.mrzValidation = 'NONE';
    this.uploadState = 0;
    this.setCallbacks();
  }

  public serialize(): IDocumentsScanSerialize {
    return {
      docType: this.docType,
      docTypeBT: this.docTypeBT,
      docLabel: this.docLabel,
      docName: this.docName,
      scanState: this.scanState,
      mrzValidation: this.mrzValidation,
      uploadState: this.uploadState,
    };
  }

  private setCallbacks(): void {
    docIDReturn[this.guid] = {
      onScanEnd: (scanState: ScanStateCode, mrzValidation: MzrValidationCode): void => {
        this.scanState = scanState;
        this.mrzValidation = mrzValidation;
        // TODO: Check with others
        // if (scanState === 0) {
        //     this.uploadState = 0;
        // }
        this.changeStatus.next(this);
      },
      onUploadEnd: (uploadState: UploadStateCode, documentType: DocTypeBTCode): void => {
        this.uploadState = uploadState;
        this.changeStatus.next(this);
      },
    };
  }
}

export class PandoraDocumentBase {
  public bothSides = false;
  public name: string;
  public documentsScan: DocumentsScan[];

  public static unserialize(data: IPandoraDocumentSerialize): PandoraDocument {
    const instance: PandoraDocument = new PandoraDocument();
    instance.bothSides = data.bothSides;
    instance.name = data.name;
    instance.documentsScan = data.documentsScan.map(dataDocumentScan => DocumentsScan.unserialize(dataDocumentScan));
    return instance;
  }

  public serialize(): IPandoraDocumentSerialize {
    return {
      bothSides: this.bothSides,
      name: this.name,
      documentsScan: this.documentsScan.map(obj => obj.serialize()),
    };
  }
}

export class PandoraDocument extends PandoraDocumentBase {}
