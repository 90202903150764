@if (errorMsg) {
  <div class="has-text-danger">{{errorMsg}}</div>
}
@if (hasRefusedOpenBanking) {
  <div class="has-text-danger">Vous avez refusé l'Open Banking</div>
}

@if (!errorMsg && (isOpenBankingEligible || isOpenBankingDone)) {
  <div>
    <p>Ce panier nécessite un contrôle OpenBanking</p>
    <div class="columns">
      <div class="column">
        <p class="has-text-weight-bold m-b-5">Récupérer l'IBAN via la banque du client</p>
        <button class="button is-info button-high" (click)="createOpenBankingProcess()" [disabled]="isOpenBankingDone || hasRefusedOpenBanking">
          <span class="icon is-white"><i class="tri-credit-card" aria-hidden="true"></i></span>
          Se connecter
        </button>
      </div>
      <div class="column is-narrow is-aligned-center is-flex has-text-weight-bold">Ou</div>
      <div class="column">
        <p class="has-text-weight-bold m-b-5">Envoyer le lien d'Open Banking au client</p>
        <form [formGroup]="obForm">
          <div class="columns p-r-10">
            <div class="column">
              <div class="control has-icons-right has-dynamic-placeholder">
                <input id="input-mail-phone" data-cy="input-mail-tel" autocomplete="off" type="text" class="input" formControlName="phoneAndEmail"
                  [ngClass]="{'is-success' : obForm.controls.phoneAndEmail.valid, 'is-danger' :obForm.errors?.phoneAndEmail?.errors?.length}" [disabled]="isOpenBankingDone || hasRefusedOpenBanking">
                <label for="input-mail-phone" rcbtAsterisk [formcontrol]="obForm.controls.phoneAndEmail">Email ou Numéro de téléphone mobile</label>
                <span class="icon">
                  @if (obForm.controls.phoneAndEmail.valid && obForm.controls.phoneAndEmail.value?.length !== 0 && !obForm.errors?.phoneAndEmail?.errors) {
                    <i class="tri-check-circle has-background-success-60"
                    ></i>
                  }
                  @if (obForm.controls.phoneAndEmail.errors || obForm.errors?.phoneAndEmail?.errors) {
                    <i class="tri-exclamation-circle has-background-error-60"
                    ></i>
                  }
                </span>
              </div>
              <small class="has-text-danger" [hidden]="!obForm.controls.phoneAndEmail.errors || obForm.controls.phoneAndEmail.valid">
                {{ obForm.controls.phoneAndEmail?.errors?.value }}
              </small>
            </div>
            <div class="column is-narrow is-aligned-center is-flex p-0">
              <button class="button is-info button-high" type="submit" [disabled]="obForm.invalid || isOpenBankingDone || hasRefusedOpenBanking" (click)="sendLinkOpenBanking()">
                <span class="icon is-white"><i class="tri-envelope" aria-hidden="true"></i></span>
                Envoyer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    @if (isOpenBankingEligible && !hasRefusedOpenBanking) {
      <button class="button is-warning button-high m-b-10" (click)="cancelOpenBankingProcess()"
      [disabled]="isOpenBankingDone">Refuser l'Open Banking</button>
    }
    @if (hasRefusedOpenBanking) {
      <button class="button is-warning button-high m-b-10" (click)="ReOpenBankingProcess()">Reprendre l'OPEN BANKING</button>
    }
  </div>
}
