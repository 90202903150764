import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { CartService } from '@services/cart.service';
import {
  BrowseType,
  SimsContextualisesProduitRequest,
  SimsContextualisesRequest,
  SimsContextualisesResponse,
  Catalog,
} from '@interfaces/sim.interfaces';
import { SimReplace } from '@model/catalog/products/equipement/sim/sim-replace';
import { EsimReplace } from '@model/catalog/products/equipement/sim/esim-replace';
import { QrEsimReplace } from '@model/catalog/products/equipement/sim/qr-esim-replace';
import { Scheme } from '@model/scheme.class';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { CustomerCategory } from '@checkout/cart/customer/customer.interface';

@Injectable({ providedIn: 'root' })
export class SimService {
  public loadContextualizedSim$: EventEmitter<void> = new EventEmitter();

  constructor(
    private oauth2Resource: Oauth2RessourceService,
    private cartService: CartService,
    private customerService: CustomerService,
  ) {}

  public simsContextualises(isReplaceSim = false): Observable<SimsContextualisesResponse> {
    const body: SimsContextualisesRequest = {
      idPU: this.customerService.customer.personId?.toString(),
      clientCategorie:
        this.customerService.customer.category === CustomerCategory.gp ? CustomerCategory.gp : CustomerCategory.pro,
      parcours: [],
    };

    this.cartService.cart.schemes.forEach(scheme => {
      const isCurrentScheme = scheme.uniqueId === this.cartService.currentSchemeUniqueId;
      const produits: SimsContextualisesProduitRequest[] = [];
      scheme.products.forEach(product => {
        produits.push({
          id: product.itemId.toString(),
          gencode: product.gencode,
          catalogue: product.type_id === 'produit_ca' ? Catalog.ca : Catalog.bytel,
        });
      });
      body.parcours.push({
        id: scheme.quoteId.toString(),
        produits,
        estCourant: isCurrentScheme,
        type: this.getBrowseType(scheme, isCurrentScheme, isReplaceSim),
        idContrat: this.cartService.getCurrentScheme().contractId?.toString(),
      });
    });

    return this.oauth2Resource.simsContextualises().post(body);
  }

  private getBrowseType(scheme: Scheme, isCurrentScheme: boolean, isReplaceSimScheme?: boolean): string {
    const hasReplaceSimProduct =
      !!scheme.getProductByType(SimReplace) ||
      !!scheme.getProductByType(EsimReplace) ||
      !!scheme.getProductByType(QrEsimReplace);
    if ((isCurrentScheme && isReplaceSimScheme) || hasReplaceSimProduct) {
      return BrowseType.replaceSim;
    }
    return scheme.browseType;
  }
}
