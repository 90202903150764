import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StepperService } from '@services/stepper.service';
import { Step } from '@components/stepper/step.abstract';
import { CartStepService } from '@services/steps/cart-step.service';
import { AppointmentStepService } from '@services/steps/appointment-step.service';
import { EquipementsStepService } from '@services/steps/equipements-step.service';
import { OptionsStepService } from '@services/steps/options-step.service';
import { CustomerStepService } from '@services/steps/customer-step.service';
import { SummaryStepService } from '@services/steps/summary-step.service';
import { OrderingStepService } from '@services/steps/ordering-step.service';
import { CheckoutStorageService } from './checkout-storage.service';
import { ReplaceSimStepService } from '@services/steps/replace-sim-step.service';
import { JustificatoryV2StepService } from '@services/steps/justificatory-V2-step.service';
import { DeliveryStepService } from '@services/steps/delivery-step.service';
import { Location } from '@angular/common';
import { MtbStepService } from '@services/steps/mtb-step.service';
import { CrossSellingStepService } from '@services/steps/cross-selling-step.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutStepperService extends StepperService {
  public steps: Step[] = [];
  public valideSubmit: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected stepperStorageService: CheckoutStorageService,
    protected router: Router,
    protected location: Location,
    private cartStepService: CartStepService,
    private appointmentStepService: AppointmentStepService,
    private equipementsStepService: EquipementsStepService,
    private deliveryStepService: DeliveryStepService,
    private optionsStepService: OptionsStepService,
    private replaceSimStepService: ReplaceSimStepService,
    private summaryStepService: SummaryStepService,
    private crossSellingStepService: CrossSellingStepService,
    private orderingStepService: OrderingStepService,
    private customerStepService: CustomerStepService,
    private justifV2StepService: JustificatoryV2StepService,
    private mtbStepService: MtbStepService,
  ) {
    super(stepperStorageService, router, location);
    if (this.steps.length === 0) {
      this.steps = [
        this.cartStepService,
        this.appointmentStepService,
        this.optionsStepService,
        this.mtbStepService,
        this.equipementsStepService,
        this.deliveryStepService,
        this.crossSellingStepService,
        this.replaceSimStepService,
        this.customerStepService,
        this.summaryStepService,
        this.justifV2StepService,
        this.orderingStepService,
      ];
      for (const step of this.steps) {
        this.stepsOrder.push(step.code);
      }
    }
    this.unserialize();
  }

  public redirectIfEmptyScheme(isSchemeEmpty: boolean): boolean {
    const route = this.getEmptySchemeRedirectionRoutes(this.router.url);
    if (route && isSchemeEmpty) {
      this.router.navigate(route);
      return true;
    }
    return false;
  }

  private getEmptySchemeRedirectionRoutes(currentUrl: string): string[] {
    const emptySchemeRedirectionRoutes = new Map<string, string[]>([
      ['/panier/reprise-mobile', ['/category', 'telephones']],
      ['/panier/ventes-complementaires', ['/category', 'ventes-complementaires']],
      ['/panier/insurances', ['/category', 'assurances']],
      ['/panier/accessories', ['/category', 'accessoires']],
      ['/panier/replace-sim', ['/category', 'replace-sim']],
      ['/panier/services', ['/category', 'services']],
      ['/panier/crv', ['/category', 'carte-rechargeable']],
      ['/panier', ['/category', 'telephones']],
    ]);
    return emptySchemeRedirectionRoutes.get(currentUrl);
  }
}
