import { finalize, mergeMap, tap } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Scheme } from '@model/scheme.class';
import { CartService } from '@services/cart.service';
import { JsonCatalog } from '@model/catalog/products/interface/context';
import { Accessory } from '@model/catalog/products/equipement/accessory';
import { Step } from '@components/stepper/step.abstract';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { Oauth2RessourceService } from '../../../oauth2/oauth2-resources.service';
import { AccessOnlineOrder } from '@model/catalog/products/equipement/accessory/accessOnlineOrder';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CaProduct } from '@model/catalog/products/caProduct/caProduct';
import { Catalog } from '@model/catalog/products/catalog';
import { CustomerCategory, CustomerType } from '@checkout/cart/customer/customer.interface';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { UserService } from '@services/user.service';
import { BasicObject } from '@base/base.interfaces';
import { NgClass } from '@angular/common';
import { PriceComponent } from '@base/price/price.component';
import { Nl2BrPipe } from '../../../pipe/nl2br.pipe';

@Component({
  selector: 'rcbt-category-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss'],
  standalone: true,
  imports: [NgClass, PriceComponent, Nl2BrPipe],
})
export class AccessoriesComponent extends Step implements OnInit, OnDestroy {
  @Input()
  public showHeader = false;

  /**
   * [products description]
   * @type {JsonCatalog}
   */
  public products: JsonCatalog;
  public scheme: Scheme;
  public schemeAccessories: Accessory[] = [];

  public accessOnlineSessionId = '';
  public subscription;
  public loading = false;
  public showAccessOnline = true;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public cartService: CartService,
    private stepperService: CheckoutStepperService,
    private oauth2service: Oauth2RessourceService,
    private customerService: CustomerService,
    private userService: UserService,
    private checkoutStepperService: CheckoutStepperService,
  ) {
    super();
    this.scheme = this.cartService.getCurrentScheme();
    this.setCurrentStepComponent(this.stepperService);
    this.updateSchemeAccessories();
  }

  public ngOnInit(): void {
    this.subscription = this.cartService.onChangesSchemes.subscribe(() => this.updateSchemeAccessories());
    const queryParams: Params = this.route.snapshot.queryParams;
    const hasQueryParams: boolean = Object.keys(queryParams).length > 0;
    if (hasQueryParams && queryParams.session && queryParams.status === 'OK') {
      this.accessOnlineSessionId = this.route.snapshot.queryParams.session;
      this.updateAccessOnline();
    } else if (hasQueryParams) {
      this.router.navigateByUrl(this.router.url.substring(0, this.router.url.indexOf('?')));
    }
    if (this.userService.user.isCA()) {
      this.showAccessOnline = false;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * [getSchemeAccessories description]
   * @param  {Scheme}      scheme [description]
   * @return {Accessory[]}        [description]
   */
  public updateSchemeAccessories(): void {
    this.schemeAccessories = <Accessory[]>(
      this.cartService
        .getCurrentScheme()
        .products.filter(
          product => (product instanceof Accessory && !product.deviceElement) || product instanceof CaProduct,
        )
    );
  }

  /**
   * [remove description]
   * @param  {Accessory} accessory
   * @param productUniqueId string
   * @param  {Scheme} scheme
   */
  public remove(accessory: Accessory, productUniqueId: string, scheme: Scheme): void {
    this.loading = true;
    accessory.setScanCode(accessory.gencode);
    this.cartService
      .remove(productUniqueId, scheme.uniqueId)
      .pipe(
        mergeMap(() => this.cartService.refreshCart()),
        tap(() => this.checkoutStepperService.redirectIfEmptyScheme(this.cartService.getCurrentScheme().isEmpty())),
        finalize(() => (this.loading = false)),
      )
      .subscribe();
  }

  public goAccessOnline(): void {
    this.loading = true;
    const customerContextService = this.customerService.customer;
    const userContextService = this.userService.user;
    const object = {
      partenaireMarketPlace: {
        idClub: userContextService.codeEns + userContextService.codePdv,
        nom: customerContextService.lastname ? customerContextService.lastname : '',
        prenom: customerContextService.firstname ? customerContextService.firstname : '',
        telephone: customerContextService.phoneNumber ? customerContextService.phoneNumber : undefined,
        urlCallBack: location.href.toString(),
      },
    };
    if (
      customerContextService.category === CustomerCategory.pro &&
      customerContextService.type === CustomerType.prospect
    ) {
      object.partenaireMarketPlace.nom = 'SOCIETE';
      object.partenaireMarketPlace.prenom = customerContextService.company.raisonSociale;
    }

    this.oauth2service
      .gestionUrlSitePartenaire()
      .post(object)
      .subscribe(
        (response: BasicObject) => {
          if (response.url) {
            this.accessOnlineSessionId = response.token;
            location.href = `${response.url}?session=${response.token}`;
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
  }

  public goAccessODR(): void {
    const ACCESSORIES_ODR = 'https://www.bouyguestelecom.fr/bons-plans/offres-de-remboursement/accessoires';
    window.open(ACCESSORIES_ODR, '_blank');
  }

  public updateAccessOnline(): void {
    this.loading = true;
    const object = { idSession: this.accessOnlineSessionId };
    this.oauth2service
      .consultationCommandeAccessoires()
      .post(object)
      .pipe(
        mergeMap(response => {
          const cmdLabelreducer = (accumulator, currentValue): string =>
            accumulator + '\n' + currentValue.libelleCommercial + '\n' + currentValue.codeEanPartenaire + '\n';
          const productsList = response.commandeAccessOnLine.detailProduitsAOL.produitsAOL;
          const numCmd: number = this.schemeAccessories.length + 1;

          const data = {
            name: 'Commande AccessOnline ' + numCmd,
            longName:
              productsList.reduce(cmdLabelreducer, 'Commande AccessOnline ' + numCmd + ' \n') +
              '\n' +
              (response.commandeAccessOnLine.modeLivraison === 'CLUB'
                ? 'LIVRAISON CLUB BOUYGUES TELECOM'
                : 'CHEZ LE CLIENT'),
            price: response.commandeAccessOnLine.montantTotalHT,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            final_price: response.commandeAccessOnLine.montantTotalTTC,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            type_id: 'access_online',
            sku: 'ACCESS_ONLINE',
            gencode: Catalog.accessOnlineGencode,
            innerResponse: response,
            sessionId: this.accessOnlineSessionId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            plan_bonus: null,
          };
          const product = new AccessOnlineOrder(data);
          this.accessOnlineSessionId = '';
          return this.cartService.add(product);
        }),
        mergeMap(() => this.cartService.refreshCart()),
        finalize(() => (this.loading = false)),
      )
      .subscribe();
  }
}
