import { AsyncPipe, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  viewChild,
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalLoaderService } from '@base/services/global-loader.service';

// eslint-disable-next-line
type IntersectionObserverOption = {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
};

@Component({
  selector: 'rcbt-infinite-scroll',
  standalone: true,
  templateUrl: 'infinite-scroll.component.html',
  imports: [AsyncPipe],
})
export class InfiniteScrollComponent implements AfterViewInit, OnDestroy {
  @Input() options: IntersectionObserverOption = {};
  @Input() hasFetchedAllEquipments: boolean;
  @Input() scrollInProgress: boolean;

  @Output() scrolled = new EventEmitter();

  readonly lastElementAnchor = viewChild<ElementRef<HTMLElement>>('lastElementAnchor');

  private debounce$: Subject<string> = new Subject<string>();
  private subscription: Subscription = new Subscription();
  private firstIntersectingDone = false;

  private observer: IntersectionObserver;
  constructor(
    public globalLoaderService: GlobalLoaderService,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.subscription.add(this.debounce$.pipe(debounceTime(100)).subscribe(value => this.scrolled.emit()));
  }

  public ngAfterViewInit(): void {
    const options: IntersectionObserverInit = {
      root: this.isHostScrollable() ? this.elementRef.nativeElement : null,
      threshold: 1,
      ...this.options,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (this.firstIntersectingDone) {
          this.debounce$.next();
        } else {
          this.firstIntersectingDone = true;
        }
      }
    }, options);

    this.observer.observe(this.lastElementAnchor().nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private isHostScrollable(): boolean {
    const style = this.document.defaultView.getComputedStyle(this.elementRef.nativeElement);
    return style.getPropertyValue('overflow') === 'auto' || style.getPropertyValue('overflow-y') === 'scroll';
  }
}
