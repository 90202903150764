import { Component, HostBinding, Input } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-open-banking-modal',
  templateUrl: './open-banking-modal.component.html',
  styleUrls: ['./open-banking-modal.component.scss'],
  standalone: true,
  imports: [],
})
export class OpenBankingModalComponent {
  @HostBinding('class.is-tri') hasClass = true;
  @Input()
  public onGetIbanClick: () => void;
  public onCloseModal: () => void;
  public onChangeFundingClick: () => void;
  public onCloseModalFundingClick: () => void;
  public isRecoveryUser = false;
  public errorMsg = '';

  public static options: NgbModalOptions = <NgbModalOptions>{
    backdrop: 'static',
    size: 'lg',
    windowClass: 'open-banking-modal',
    keyboard: false,
  };

  constructor(protected activeModal: NgbActiveModal) {}

  public closeModal(): void {
    this.activeModal.close();
  }
}
