import { IGenericDeviceConfiguration } from '../IGenericDeviceConfiguration';
import { CompatibilityMessages } from '../../product';
import { Sim } from '../sim';
import { BrowseType } from '../../../../../constants/browse-type';
import { AddResult, ResultAddMessage, TypeResult } from '@interfaces/cart.interfaces';
import { ProductSerialized } from '../../interface/configurable';

export class SimCbt extends Sim {
  public ericssonData: Iericsson;

  public setConfiguration(data): void {
    super.setConfiguration(data);
    this.ericssonData = data.ericssonData;
  }

  public getConfiguration(): IGenericDeviceConfiguration {
    return <IGenericDeviceConfiguration>{
      ericssonData: this.ericssonData,
      ...super.getConfiguration(),
    };
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), {
      ericssonData: this.ericssonData,
    });
  }

  public canAdd(browseType: BrowseType, isPrepaid: boolean, isFnb: boolean): AddResult {
    const result: AddResult = { status: true, productsConflict: [] };
    if (!isPrepaid) {
      result.status = false;
      result.addCheck = <ResultAddMessage>{
        type: TypeResult.error,
        message: CompatibilityMessages.simTypeNotCompatible,
      };
    }
    return result;
  }
}

export interface Iericsson {
  returnCode?: string;
  description?: string;
  msisdn?: string;
  contractId?: string;
  gencode?: string;
  offer?: string;
}
