import { catchError } from 'rxjs/operators';
import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Oauth2RessourceService } from '../../../../../../oauth2/oauth2-resources.service';
import { CartService } from '@services/cart.service';
import { IScreening } from '@interfaces/screening.interface';
import { StepCustomerComponent } from '../../../step-customer.component';
import { CustomerService } from '../../../customer.service';
import { CustomerStepEnum } from '../../../customer.interface';
import { UserService } from '@services/user.service';
import { AsteriskDirective } from '@base/directive/asterisk.directive';
import { NgClass } from '@angular/common';

@Component({
  selector: 'rcbt-cart-customer-identity-pro-siren',
  templateUrl: './identity.siren.component.html',
  styleUrls: ['./identity.siren.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, AsteriskDirective],
})
export class IdentitySirenComponent extends StepCustomerComponent implements OnInit {
  @Input() public errorMessage = '';
  @Output() public screeningDataEvent: EventEmitter<IScreening> = new EventEmitter<IScreening>();
  @Output() public sirenOkEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public sirenForm: UntypedFormGroup;

  constructor(
    protected oauth2Ressource: Oauth2RessourceService,
    protected cartService: CartService,
    protected formBuilder: UntypedFormBuilder,
    protected customerService: CustomerService,
    protected userService: UserService,
  ) {
    super(oauth2Ressource, cartService, customerService, userService);
  }

  public ngOnInit(): void {
    this.sirenForm = this.formBuilder.group({
      siren: [
        this.customerService.customer.company.noSiren,
        [Validators.required, Validators.pattern(new RegExp(/\d{9}/))],
      ],
    });
    this.customerService.changeValidityStep(CustomerStepEnum.identity, false);
  }

  public onSirenSubmit(): void {
    this.customerService.setIsLoading(true, 'IdentitySiren');
    const siren: string = this.sirenForm.value.siren;
    this.oauth2Ressource
      .controleSiren(siren)
      .get()
      .pipe(
        catchError(error => {
          this.errorMessage = error;
          if (error.message) {
            // si erreur technique
            this.errorMessage = '* Le contrôle SIREN est indisponible.';
          }
          throw error;
        }),
      )
      .subscribe(
        (data: IScreening) => {
          this.customerService.setIsLoading(false, 'IdentitySiren');
          this.screeningDataEvent.emit(data);
          this.sirenOkEvent.emit(true);
        },
        error => {
          this.customerService.setIsLoading(false, 'IdentitySiren');
          if (!!error.error && !!error.error.error_description) {
            this.errorMessage = '* Veuillez saisir un SIREN valide';
            this.sirenOkEvent.emit(false);
          }
        },
      );
  }
}
