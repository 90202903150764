import {
  PromoActionTypes,
  PromotionTypes,
  PromotionTypologies,
  UpdatePromoParams,
} from '@promotions/promotions.interfaces';
import { IPromotion, IPromotionApplication, IPromotionRule } from './promotions.interfaces';
import { PromotionsHelper } from './promotionsHelper';

export abstract class Promotion implements IPromotion {
  public id: number;
  public name: string;
  public description: string;
  public siren: string;
  public condition: IPromotionRule;
  public application: IPromotionApplication;
  public priority: number;
  public incompatibilities: number[];
  public opsCode: string;
  public odr: boolean;
  public abstract type: PromotionTypes;
  public abstract typology: PromotionTypologies;

  public isApplied(): boolean {
    return false;
  }

  public isDeferred(): boolean {
    return !!this.application.actionPromotion.isDeferred;
  }

  public calculateDiscount(baseAmount: number): void {
    if (this.application.actionPromotion.type === PromoActionTypes.byPercent) {
      this.application.actionPromotion.amount = PromotionsHelper.getDiscountAmount(
        baseAmount,
        this.application.actionPromotion,
      );
    }
  }

  public concernsCart(): boolean {
    return !!this.application.panier;
  }

  public concernsSchemes(): boolean {
    return !!this.application.parcours;
  }

  public isMobileTakeBack(): boolean {
    return false;
  }

  public isRegularization(): boolean {
    return this.type === PromotionTypes.regularization;
  }

  public isAgility(): boolean {
    return this.type === PromotionTypes.agility;
  }

  public isAgilityCa(): boolean {
    return this.type === PromotionTypes.agilityCa;
  }

  public isOdr(): boolean {
    return this.odr;
  }

  public isProrata(): boolean {
    return this.application.actionPromotion.minPrice >= 0;
  }

  public abstract setParams(params: UpdatePromoParams): void;
}
