import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { forkJoin as observableForkJoin, forkJoin, Observable, of as observableOf, of } from 'rxjs';
import { BrowseConfig } from '../../../context/child/browse-config.class';
import { SchemeHelper } from '../scheme.helper';
import { ProspectService } from '@services/prospect.service';
import { catchError, finalize, map, mergeMap, share, tap } from 'rxjs/operators';
import { PandoraDocument } from '@model/PandoraDocument.abstract';
import { Scheme } from '@model/scheme.class';
import { StepCustomerComponent } from './step-customer.component';
import {
  AdresseFacturationResponse,
  CustomerCategory,
  CustomerStep,
  CustomerStepEnum,
  CustomerType,
  IClient,
  InsuranceDataIdentity,
  InsuranceDataPayment,
  InsuranceDataSubscription,
  InsuranceMetadataInputDTO,
  MetaDataOutputDTO,
  InsuranceSubsciptionDTO,
  LastStepEnum,
  MetadataInputDTO,
  SimulatorParams,
} from './customer.interface';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { InsurancePartner } from '@model/catalog/products/subscription/insurance-partner';
import { CheckoutNs } from '@interfaces/checkout.interface';
import { Oauth2RessourceService } from '../../../oauth2/oauth2-resources.service';
import { PartnerPaymentMethod } from '../../../partner/partner.dto';
import * as _ from 'lodash';
import { ConfigService } from '@services/config.service';
import { UntypedFormGroup } from '@angular/forms';
import { RCheckMail } from './identity/identity.interface';
import { ContractInterface } from '../../../context/contract.interface';
import { ContextError } from '../../../context/contextError';
import { DateUniversal } from '@base/class/date-universal.class';
import { CustomerContextSerializedInterface } from '../../../context/child/customer-context-serialized.interface';
import { Customer } from './customer';
import { IPandoraDocumentSerialize } from '@interfaces/IPandoraDocumentSerialize';
import { User } from '../../../user/user';
import { ContexteOperateur } from '@promotions/interfaces/convergence.interface';
import { BrowseConfigService } from '../../../context/browse-config.service';
import { BasicObject } from '@base/base.interfaces';
import { IScreening, IAddressPro } from '@interfaces/screening.interface';

export enum EmailType {
  bytel = 'bytel',
  insurance = 'insurance',
}

export enum EmailErrorMessage {
  invalidInssuranceEmail = "L’adresse e-mail pour l'assurance est invalide.",
  bboxEmail = "L'adresse saisie est une adresse bbox.fr. Veuillez renseigner un autre type d'adresse email pour continuer.",
  nonExistingEmail = 'L’adresse e-mail renseignée n’existe pas, veuillez saisir une nouvelle adresse e-mail.',
  dbExistingEmail = 'L’e-mail saisi existe déjà dans la base de données clients, ' +
    'veuillez saisir un e-mail différent ou laisser le champ vide',
}

export enum SearchPersonQueryParam {
  emailContact = 'emailContact',
  login = 'login',
}

export interface ValidateEmailResult {
  searchPersonQueryParam: SearchPersonQueryParam;
  error: string;
}

export const OPEN_BANKING_SOURCE = 'Openbanking';

@Injectable({ providedIn: 'root' })
export class CustomerService implements OnDestroy {
  public readonly customer: Customer = new Customer();
  public errorMessage: string;
  public shouldForceDisabledAddress = false;
  public forceSubmitAddress: EventEmitter<boolean> = new EventEmitter<boolean>();
  public changeStepRequest: EventEmitter<CustomerStep> = new EventEmitter<CustomerStep>();
  public changeStepNotif: EventEmitter<CustomerStep> = new EventEmitter<CustomerStep>();
  public addressProEvent: EventEmitter<IAddressPro> = new EventEmitter<null>(null);
  public valideStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  public forceDisableAddress: EventEmitter<boolean> = new EventEmitter<boolean>();
  public steps: CustomerStep[] = [
    { order: 1, value: CustomerStepEnum.identity, label: 'Identité' },
    { order: 2, value: CustomerStepEnum.address, label: 'Facturation' },
    { order: 3, value: CustomerStepEnum.payment, label: 'Paiement' },
  ];
  public metadataChanged = new EventEmitter<boolean>();
  public nbSteps = 3;
  public currentStep: CustomerStep = this.steps[0];
  public readonly customerSteps = CustomerStepEnum;
  public insuranceMetaData: MetaDataOutputDTO;
  private isLoadingIds: string[] = [];
  private readonly isLoadingPrefix = 'customerService';
  private insuranceDataSubscription: InsuranceDataSubscription = {};

  constructor(
    private prospectService: ProspectService,
    private globalLoaderService: GlobalLoaderService,
    private oauth2RessourceService: Oauth2RessourceService,
    private configService: ConfigService,
    private browseConfigService: BrowseConfigService,
  ) {}

  public ngOnDestroy(): void {
    this.globalLoaderService.deleteStatusByActionTypes(this.isLoadingIds);
  }

  public loadCustomerData(personId: number): Observable<Customer> {
    this.customer.personId = personId;
    const contractsSignedRequest: Observable<{ items: ContractInterface[] }> = this.oauth2RessourceService
      .personnes(this.customer.personId)
      .contratsSignes()
      .get();
    const comptesFacturationRequest: Observable<{ items: BasicObject[] }> =
      this.customer.type === CustomerType.client && !this.browseConfigService.browseConfig.contractId
        ? this.oauth2RessourceService.personnes(this.customer.personId).comptesFacturation().get()
        : observableOf({ items: null });

    return observableForkJoin([
      this.loadPerson(),
      contractsSignedRequest,
      this.oauth2RessourceService.personnes(this.customer.personId).coordonnees().get(),
      comptesFacturationRequest,
    ]).pipe(
      map((responses: BasicObject[]) => {
        this.parseGetContractsResponse(responses[1]);
        this.parseCoordinatesResponse(responses[2]);
        this.parseBillingAccountsResponse(responses[3]);
        const siInfo: CustomerContextSerializedInterface = this.customer.serialize();
        this.customer.lockedInfo = Object.keys(siInfo).filter(data => siInfo[data] && typeof data !== 'function');
        return this.customer;
      }),
      mergeMap(() => {
        // add default adress = first ADRESS FNB else FIXE
        if (this.customer.type === CustomerType.client && !this.browseConfigService.browseConfig.contractId) {
          let sortedContracts = this.customer.contracts
            .filter((item: ContractInterface) => item.ligneMarche === 'FNB')
            .sort(function (contract1: ContractInterface, contract2: ContractInterface) {
              return new DateUniversal(contract1.abonnement.dateActivation) <
                new DateUniversal(contract2.abonnement.dateActivation)
                ? -1
                : 1;
            });
          if (!sortedContracts || sortedContracts.length === 0) {
            sortedContracts = this.customer.contracts
              .filter((item: ContractInterface) => item.ligneMarche === 'FIXE')
              .sort(function (contract1: ContractInterface, contract2: ContractInterface) {
                return new DateUniversal(contract1.abonnement.dateActivation) <
                  new DateUniversal(contract2.abonnement.dateActivation)
                  ? -1
                  : 1;
              });
          }
          return sortedContracts[0] && sortedContracts[0]._links
            ? this.addDefaultAdress(sortedContracts[0], this.customer)
            : observableOf(this.customer);
        }
        return observableOf(this.customer);
      }),
      mergeMap(() => {
        this.customer.contracts = this.customer.contracts.map((item: ContractInterface) => {
          item.id = Number(item.id);
          delete item._actions;
          delete item._links;
          return item;
        });
        this.customer.mobileContractsCount = this.customer.contracts.filter(
          (item: ContractInterface) => item.typeLigne === 'MOBILE',
        ).length;
        this.customer.fixContractsCount = this.customer.contracts.filter(
          (item: ContractInterface) => item.typeLigne === 'FIXE',
        ).length;
        return observableOf(this.customer);
      }),
      share(),
    );
  }

  public loadContractData(contractId: number): Observable<void> {
    return forkJoin([
      this.oauth2RessourceService.contracts(contractId).get(),
      this.oauth2RessourceService.contracts(contractId).lignes().get(),
    ]).pipe(
      mergeMap((responses: BasicObject[]) => {
        this.customer.idCt = responses[0].idCt;
        this.customer.ligneMarche = responses[0].ligneMarche;

        if (this.browseConfigService.browseConfig.browseType === 'RENEW') {
          try {
            this.customer.phoneNumber = responses[1].items[0].numeroTel;
          } catch (e) {}
          this.customer.phoneNumber = this.customer.phoneNumber.replace('+33', '0');
        }

        this.customer.personId = responses[0]._links.titulaire.href.split('/').pop();
        return this.addDefaultAdress(responses[0] as ContractInterface, this.customer);
      }),
      mergeMap(() => of(null)),
    );
  }

  public loadPerson(): Observable<void> {
    return this.oauth2RessourceService
      .personnes(this.customer.personId)
      .get()
      .pipe(
        tap(
          response => this.parseLoadPersonResponse(response),
          e => {
            throw new ContextError('personne_id non valide', Oauth2RessourceService.httpNotFound);
          },
        ),
      );
  }

  /**
   * todo voir a quoi ça sert schemes ajouté pour palier à une dépendance circulaire
   * @param data
   * @param browseConfig
   * @param currentScheme
   * @param schemesCount
   */
  public updateCustomer(
    data: CustomerContextSerializedInterface,
    browseConfig: BrowseConfig,
    currentScheme: Scheme,
    schemes: Scheme[],
  ): Observable<boolean> {
    this.setInsuranceDataIdentity(data);
    this.loadInput(data, browseConfig, true);
    this.updateDocuments(data);
    if (this.currentStep.value === CustomerStepEnum.identity) {
      if (!this.customer.personId && SchemeHelper.hasPlan(currentScheme) && !SchemeHelper.isPrepaid(currentScheme)) {
        return this.prospectService.create(ProspectService.formatData(this.customer)).pipe(
          tap(res => {
            if (res.noPersonne) {
              this.customer.personId = res.noPersonne;
              browseConfig.personId = res.noPersonne;
            }
          }),
          map(() => null),
        );
      } else if (this.customer.editable && this.customer.personId && schemes.length < 2) {
        return this.prospectService
          .update(ProspectService.formatData(this.customer), this.customer.personId)
          .pipe(map(() => null));
      }
    }
    return of(null);
  }

  public updateDataOnStepEvent(stepComponent: StepCustomerComponent): void {
    return stepComponent.update();
  }

  // todo supprimer cette méthode
  public isClient(): boolean {
    return this.customer.isClient;
  }

  public changeCustomerStep(param: { step: CustomerStepEnum; submit?: boolean; ignoreIsLoading?: boolean }): void {
    if (param.ignoreIsLoading || !this.getIsLoading()) {
      const step: CustomerStep = this.steps.find(s => s.value === param.step);
      if (!!step) {
        step.submit = !!param.submit;
        this.changeStepRequest.emit(step);
      }
    }
  }

  public changeValidityStep(stepVal: CustomerStepEnum, valid: boolean): void {
    if (stepVal === this.currentStep.value && !!this.currentStep.disabled !== !valid) {
      this.currentStep.disabled = !valid;
      this.valideStep.emit(valid);
    }
  }

  public getNextStep(): CustomerStep {
    if (this.currentStep.order + 1 > this.nbSteps) {
      return null;
    }
    return this.steps.find(s => s.order === this.currentStep.order + 1);
  }

  public setIsLoading(value: boolean, id?: string): void {
    if (!!id) {
      const fullId = this.isLoadingPrefix + id;
      if (!this.isLoadingIds.includes(fullId)) {
        this.isLoadingIds.push(fullId);
      }
      this.globalLoaderService.dispatchLoadingStatus({ actionType: fullId, isLoading: value });
    } else {
      this.isLoadingIds.forEach(i => {
        this.globalLoaderService.dispatchLoadingStatus({ actionType: i, isLoading: value });
      });
    }
  }

  public getIsLoading(id?: string): boolean {
    if (!!id) {
      const fullId = this.isLoadingPrefix + id;
      if (!this.isLoadingIds.includes(fullId)) {
        return false;
      }
      return this.globalLoaderService.getLoadingStatusByAction(fullId);
    }
    return this.isLoadingIds.some(i => this.globalLoaderService.getLoadingStatusByAction(i));
  }

  public resetCurrentStep(index: number = 0): void {
    this.currentStep = this.steps[index];
  }

  /**
   * todo refactoriser cette méthode
   * @param insuranceProduct
   */
  public setNbSteps(currentScheme: Scheme): void {
    if (!!currentScheme.getProductByType<InsurancePartner>(InsurancePartner)) {
      this.nbSteps = LastStepEnum.payment;
      return;
    }
    if (!SchemeHelper.hasPlan(currentScheme) || SchemeHelper.isPrepaid(currentScheme)) {
      this.nbSteps = LastStepEnum.address;
      return;
    }
    if (currentScheme.isRenew() || currentScheme.isBigtrustSav()) {
      this.nbSteps = LastStepEnum.identity;
      return;
    }
    this.nbSteps = LastStepEnum.payment;
  }

  public isAddressTheLastForm(): boolean {
    return this.nbSteps === LastStepEnum.address;
  }

  public setInsuranceDataIdentity(data: CustomerContextSerializedInterface): void {
    if (!!data.emailAssurance || !!data.paysNaissance || !!data.villeNaissance || !!data.nationalite) {
      const dataIdentity: InsuranceDataIdentity = {
        birthplaceCity: data.villeNaissance,
        birthplaceCountry: data.paysNaissance?.split(' ')[0],
        nationality: data.nationalite?.split(' ')[0],
        insuranceEmail: data.specificInsuranceEmail ? data.emailAssurance : data.email,
      };
      this.insuranceDataSubscription.identity = dataIdentity;
    }
  }

  public setInsuranceDataPaiment(data: InsuranceDataPayment): void {
    this.insuranceDataSubscription.payment = data;
  }

  public generateInsuranceDataSubscription(paymentAccount: CheckoutNs.IPayment): InsuranceDataSubscription {
    const data: InsuranceDataSubscription = {};
    if (!!this.insuranceDataSubscription.identity) {
      data.identity = this.insuranceDataSubscription.identity;
      if (!data.identity.insuranceEmail && !!this.customer.email) {
        data.identity.insuranceEmail = this.customer.email;
      }
    }
    if (!!this.insuranceDataSubscription.payment) {
      data.payment = this.insuranceDataSubscription.payment;
      if (this.insuranceDataSubscription.payment.iban) {
        data.payment.iban = this.insuranceDataSubscription.payment.iban.replace(/ /g, '');
      }
    } else {
      data.payment = {
        paymentMode: CheckoutNs.PaymentMode.prelevement,
        iban: paymentAccount.iban,
        bic: paymentAccount.bic,
      };
    }
    data.identity = {
      ...data.identity,
      civility: this.customer.civility,
      lastname: this.customer.lastname,
      firstname: this.customer.firstname,
      dob: this.customer.dob,
      phoneNumber: `+33${this.customer.phoneNumber.slice(1)}`,
      address: {
        country: this.customer.country,
        zipCode: this.customer.postcode,
        streetNumber: this.customer.streetNumber,
        street: this.customer.street,
        city: this.customer.city,
        additionalInfo: this.customer.street2,
      },
    };
    return data;
  }

  public isInsuranceDataSubscriptionChanged(user: User, currentScheme: Scheme): boolean {
    if (!this.insuranceMetaData) {
      return true;
    }
    const localOutPutData = this.mapMetaDataInputToOutput(
      this.mapInsuranceSubscriptionToMetaDataDTO(this.mapInsuranceDataToDTO(user, currentScheme)),
    );
    return !(
      _.isEqual(
        _.omitBy(this.insuranceMetaData.assurance.identite, _.isNil),
        _.omitBy(localOutPutData.assurance.identite, _.isNil),
      ) &&
      _.isEqual(
        _.omitBy(this.insuranceMetaData.assurance.paiement, _.isNil),
        _.omitBy(localOutPutData.assurance.paiement, _.isNil),
      )
    );
  }

  public subscribeMedi7Partner(user: User, currentScheme: Scheme, cartId: number): Observable<InsurancePartner> {
    const data = this.mapInsuranceDataToDTO(user, currentScheme);
    // Store insurance data on pev before susbcribing to medi7
    return this.oauth2RessourceService
      .metadonnees(cartId.toString())
      .post(this.mapInsuranceSubscriptionToMetaDataDTO(data))
      .pipe(
        mergeMap(() => this.oauth2RessourceService.souscrireAssuranceMedi7().post(data)),
        map(_data => {
          const insuranceProduct: InsurancePartner = currentScheme.getProductByType<InsurancePartner>(InsurancePartner);
          insuranceProduct.idContratAssureur = _data.idContratClientChezPartenaire;
          insuranceProduct.idDemandePartenaire = _data.idDemandePartenaire;
          return insuranceProduct;
        }),
      );
  }

  // todo utiliser le loadMetaData global
  public getInsuranceMetadata(cartId: number): Observable<MetaDataOutputDTO> {
    return this.oauth2RessourceService
      .metadonnees(cartId.toString())
      .get()
      .pipe(
        tap((data: MetaDataOutputDTO) => {
          if (data.assurance.paiement.modePaiementAssurance === 'CHEQUE') {
            delete data.assurance.paiement.bicAssurance;
            delete data.assurance.paiement.ibanAssurance;
          }
          this.insuranceMetaData = data;
        }),
        finalize(() => this.metadataChanged.emit()),
        catchError(_error => of(null)),
      );
  }

  public validateIdentityEmails(
    identityForm: UntypedFormGroup,
    hasMedi7 = false,
  ): Observable<Map<EmailType, ValidateEmailResult>> {
    const emailObs: Observable<ValidateEmailResult>[] = [of(null)];
    const emailErrorStruct: Map<EmailType, ValidateEmailResult> = new Map<EmailType, ValidateEmailResult>();
    if (!this.isRcheckMailActive()) {
      return of(emailErrorStruct);
    }
    if (!!identityForm.value.email) {
      emailObs.push(
        this.validateEmail(identityForm.value.email, EmailType.bytel).pipe(
          tap((result: ValidateEmailResult) => {
            if (result) {
              emailErrorStruct.set(EmailType.bytel, result);
            }
          }),
        ),
      );
    }
    if (
      hasMedi7 &&
      identityForm.controls.specificInsuranceEmail?.value &&
      !!identityForm.value.emailAssurance &&
      identityForm.value.email !== identityForm.value.emailAssurance
    ) {
      emailObs.push(
        this.validateEmail(identityForm.value.emailAssurance, EmailType.insurance).pipe(
          tap((result: ValidateEmailResult) => {
            if (result) {
              emailErrorStruct.set(EmailType.insurance, result);
            }
          }),
        ),
      );
    }
    return forkJoin(emailObs).pipe(map(() => emailErrorStruct));
  }

  public updateCustomerWithUrlData(): void {
    const browseConfig = this.browseConfigService.browseConfig;
    this.customer.type = browseConfig.visitorType || CustomerType.prospect;
    this.customer.category = browseConfig.category;
    this.customer.civility = browseConfig.civility;
    this.customer.firstname = browseConfig.firstname;
    this.customer.lastname = browseConfig.lastname;
    this.customer.dob = browseConfig.dob;
    this.customer.birthplace = browseConfig.birthplace;
    this.customer.phoneNumber = browseConfig.phone;
    this.customer.postcode = browseConfig.postcode;
    this.customer.street = browseConfig.street;
    this.customer.street2 = browseConfig.street2;
    this.customer.streetNumber = browseConfig.streetNumber;
    this.customer.city = browseConfig.city;
    this.customer.email = browseConfig.email;
    this.customer.isClient = this.customer.type === CustomerType.client;
    this.customer.ligneMarche = browseConfig.lineSector;
    this.customer.telephonePrepaye = browseConfig.prepaidPhone;
    this.customer.company = this.companyValue(null, browseConfig);
    this.customer.idProspect = browseConfig.idProspect;
  }

  /**
   * todo refactoriser cette méthode
   * @param data
   * @param browseConfig
   * @param forceData
   */
  public loadInput(
    data?: CustomerContextSerializedInterface,
    browseConfig: BrowseConfig = new BrowseConfig(),
    forceData: boolean = false,
  ): this {
    const customer = this.customer;
    customer.contracts =
      !forceData || !data.contrats ? data.contrats || customer.contracts || browseConfig.contracts : data.contrats;
    customer.ligneMarche =
      !forceData || !data.ligneMarche
        ? customer.ligneMarche || data.ligneMarche || browseConfig.lineSector
        : data.ligneMarche;
    customer.telephonePrepaye =
      !forceData || !data.telephonePrepaye
        ? customer.telephonePrepaye || data.telephonePrepaye || browseConfig.prepaidPhone
        : data.telephonePrepaye;
    customer.lockedInfo = customer.lockedInfo || data.lockedInfo || [];
    customer.type = !forceData || !data.type ? customer.type || data.type || CustomerType.prospect : data.type;
    customer.category =
      !forceData || !data.categorie ? customer.category || data.categorie || browseConfig.category : data.categorie;
    customer.lastname = !forceData || !data.nom ? customer.lastname || data.nom || browseConfig.lastname : data.nom;
    customer.firstname =
      !forceData || !data.prenom ? customer.firstname || data.prenom || browseConfig.firstname : data.prenom;
    customer.civility =
      !forceData || !data.civilite ? customer.civility || data.civilite || browseConfig.civility : data.civilite;
    customer.email = !forceData || (!data.email && data.email !== '') ? customer.email || data.email : data.email;
    customer.dob =
      !forceData || !data.dateNaissance ? customer.dob || data.dateNaissance || browseConfig.dob : data.dateNaissance;
    customer.birthplace =
      !forceData || !data.departementNaissance
        ? customer.birthplace || data.departementNaissance || browseConfig.birthplace
        : data.departementNaissance;
    customer.streetNumber = this.getStreetNumber(forceData, browseConfig, data);

    customer.street =
      !forceData || data.rue === undefined ? customer.street || data.rue || browseConfig.street : data.rue;
    if (forceData && data.hasOwnProperty('complementAdresse')) {
      customer.street2 = data.complementAdresse;
    } else {
      customer.street2 = customer.street2 || data.complementAdresse || browseConfig.street2;
    }
    customer.residence = !forceData || !data.residence ? customer.residence || data.residence : undefined;
    customer.building = !forceData || !data.batiment ? customer.building || data.batiment : undefined;
    customer.stair = !forceData || !data.escalier ? customer.stair || data.escalier : undefined;
    customer.floor = !forceData || !data.etage ? customer.floor || data.etage : undefined;
    // eslint-disable-next-line max-len
    customer.postcode =
      !forceData || data.codePostal === undefined
        ? customer.postcode || data.codePostal || browseConfig.postcode
        : data.codePostal;
    customer.city =
      !forceData || data.ville === undefined ? customer.city || data.ville || browseConfig.city : data.ville;
    customer.country = forceData ? 'FRA' : customer.country || data.idPays || 'FRA';
    customer.phoneNumber =
      !forceData || (!data.telephone && data.telephone !== '')
        ? customer.phoneNumber || data.telephone || browseConfig.phone
        : data.telephone;
    customer.personId =
      !forceData || !data.personId ? customer.personId || data.personId || browseConfig.personId : data.personId;
    // eslint-disable-next-line max-len
    customer.identityDocumentType = data.documentIdentite
      ? PandoraDocument.unserialize(data.documentIdentite)
      : customer.identityDocumentType;
    customer.identityDocumentNumber =
      !forceData || !data.documentIdentiteNumero
        ? customer.identityDocumentNumber || data.documentIdentiteNumero || browseConfig.identityDocumentNumber
        : data.documentIdentiteNumero;
    customer.addressDocument = data.documentAdresse
      ? PandoraDocument.unserialize(data.documentAdresse)
      : customer.addressDocument;
    if (data.documentAdresse === null) {
      customer.addressDocument = undefined;
    }
    customer.paymentDocumentType = data.documentPaiement ? PandoraDocument.unserialize(data.documentPaiement) : null;
    customer.powerletter = data.powerletter === undefined ? customer.powerletter : data.powerletter;
    customer.otherDocuments = data.otherDocuments
      ? this.unserializeAllDocuments(data.otherDocuments)
      : customer.otherDocuments;
    customer.controlAddress =
      !forceData || !data.controlAddress ? customer.controlAddress || data.controlAddress : data.controlAddress;
    customer.isClient = customer.isClient || customer.type === CustomerType.client;
    customer.company = this.companyValue(data, browseConfig);
    customer.dateFromSI = !customer.dateFromSI || customer.dateFromSI ? customer.dateFromSI : data.dateFromSI;
    customer.vip = !data.vip ? false : customer.vip;
    customer.rna = data.rna || customer.rna;
    return this;
  }

  public companyValue(
    data?: CustomerContextSerializedInterface,
    browseConfig: BrowseConfig = new BrowseConfig(),
  ): IScreening {
    if (browseConfig.category === CustomerCategory.gp || this.customer.category === CustomerCategory.gp) {
      return {};
    }

    if (data?.company && data.company.raisonSociale) {
      return data.company;
    }

    if (this.customer.company && this.customer.company.raisonSociale) {
      return this.customer.company;
    }

    return {
      dateCreation: browseConfig.companyCreationDate,
      noSiren: browseConfig.companyRegistrationNumber,
      raisonSociale: browseConfig.companyName,
      representantLegal: {
        civilite: browseConfig.civility,
        nom: browseConfig.lastname,
        prenom: browseConfig.firstname,
        dateNaissance: browseConfig.dob,
        departementNaissance: browseConfig.birthplace?.split(' ')[0],
      },
    };
  }

  public checkConvergenceEligibility(personId): Observable<boolean> {
    if (!personId) {
      return observableOf(false);
    }
    return this.oauth2RessourceService
      .personnes(personId)
      .consulterContexteOperateur()
      .get()
      .pipe(
        map((contexteOperateur: ContexteOperateur) => {
          this.parseConvergenceResult(contexteOperateur);
          return this.customer.isEligibleConvergence;
        }),
        catchError(() => observableOf(false)),
      );
  }

  public unserializeAllDocuments(documents: IPandoraDocumentSerialize[]): PandoraDocument[] {
    const result: PandoraDocument[] = [];
    for (const document of documents) {
      result.push(PandoraDocument.unserialize(document));
    }
    return result;
  }

  private getStreetNumber(
    forceData: boolean,
    browseConfig: BrowseConfig,
    data?: CustomerContextSerializedInterface,
  ): string {
    if (!forceData || typeof data?.numeroRue === 'undefined') {
      return typeof this.customer.streetNumber !== 'undefined'
        ? this.customer.streetNumber
        : data?.numeroRue || browseConfig.streetNumber;
    } else {
      return data?.numeroRue;
    }
  }

  private validateEmail(email: string, emailType: EmailType): Observable<ValidateEmailResult> {
    return this.oauth2RessourceService
      .adresses()
      .validationEmail(encodeURIComponent(email))
      .get()
      .pipe(
        map((data: RCheckMail) => this.manageEmailError(data, email, emailType)),
        catchError(err => (Number(err.status) >= 500 ? of(null) : of("Erreur lors de la validation de l'email."))),
      );
  }

  private manageEmailError(data: RCheckMail, email: string, emailType: EmailType): ValidateEmailResult {
    if (!data) {
      return null;
    }
    if (emailType !== EmailType.bytel) {
      return data.emailValide ? null : ({ error: EmailErrorMessage.invalidInssuranceEmail } as ValidateEmailResult);
    }
    if (data?.domaine?.domaineBbox) {
      return { error: EmailErrorMessage.bboxEmail } as ValidateEmailResult;
    }
    if (
      !data.domaine ||
      !data.domaine.domaineExistant ||
      data.emailTemporaire ||
      !data.emailValide ||
      !data.syntaxeEmailCorrecte ||
      data.utilisateurGenerique
    ) {
      return { error: EmailErrorMessage.nonExistingEmail } as ValidateEmailResult;
    }
    if (data.emailContact || data.emailLogin) {
      return this.customer.email === email && this.customer.personId
        ? null
        : ({
            error: EmailErrorMessage.dbExistingEmail,
            searchPersonQueryParam: data.emailContact
              ? SearchPersonQueryParam.emailContact
              : SearchPersonQueryParam.login,
          } as ValidateEmailResult);
    }
    return null;
  }

  private isRcheckMailActive(): boolean {
    return !this.configService.data.rcheckMail || this.configService.data.rcheckMail.active !== false;
  }

  private mapInsuranceSubscriptionToMetaDataDTO({ client, modePaiement }: InsuranceSubsciptionDTO): MetadataInputDTO {
    const insuranceMetadataInputDTO: InsuranceMetadataInputDTO = {
      emailAssurance: client.coordonnees.find(coo => coo._type === 'AdresseElectronique')?.email,
      villeNaissance: client.villeNaissance,
      paysNaissance: client.codePaysNaissance?.split(' ')[0],
      dateNaissance: client.dateNaissance,
      nationalite: client.nationalite?.split(' ')[0],
      civilite: client.civilite,
      nom: client.nom,
      prenom: client.prenom,
      telephone: client.coordonnees.find(coo => coo._type === 'CoordonneesTelephoniques').noTel,
      modePaiementAssurance: modePaiement,
    };
    const adressePostale = client.coordonnees.find(coo => coo._type === 'AdressePostale');
    insuranceMetadataInputDTO.adresse = {
      rue: adressePostale.rue,
      numeroRue: adressePostale.num || undefined,
      codePostal: adressePostale.cp,
      complementAdresse: adressePostale.complementAdr1,
      ville: adressePostale.ville,
      pays: adressePostale.codePays,
    };
    if (modePaiement === PartnerPaymentMethod.prelevement.toUpperCase()) {
      insuranceMetadataInputDTO.ibanAssurance = client.compteBancaire.iban;
      insuranceMetadataInputDTO.bicAssurance = client.compteBancaire.bic;
    }
    const metadataInputDTO: MetadataInputDTO = {
      assurance: insuranceMetadataInputDTO,
    };
    return metadataInputDTO;
  }

  private mapInsuranceDataToDTO(user: User, currentScheme: Scheme): InsuranceSubsciptionDTO {
    const data = this.generateInsuranceDataSubscription(currentScheme.paymentAccount);
    const acteurCreateur = {
      entiteReseauDistribution: {
        codeEnseigne: user.codeEns,
        codePdv: user.codePdv,
      },
      login: user.login,
      matricule: user.registrationNumber,
      type: user.user_type,
    };
    const adresseElectronique = {
      _type: 'AdresseElectronique',
      email: data.identity.insuranceEmail,
    };
    const adressePostale = {
      _type: 'AdressePostale',
      codePays: 'FR',
      cp: data.identity.address.zipCode,
      num: data.identity.address.streetNumber || undefined,
      rue: data.identity.address.street,
      ville: data.identity.address.city,
      complementAdr1: data.identity.address.additionalInfo,
    };
    const coordonneesTelephoniques = {
      _type: 'CoordonneesTelephoniques',
      noTel: data.identity.phoneNumber,
    };
    const client: IClient = {
      civilite: data.identity.civility,
      nom: data.identity.lastname,
      prenom: data.identity.firstname,
      codePaysNaissance: data.identity.birthplaceCountry,
      dateNaissance: data.identity.dob,
      nationalite: data.identity.nationality,
      villeNaissance: data.identity.birthplaceCity,
      compteBancaire: {
        bic: data.payment.bic,
        iban: data.payment.iban,
      },
      coordonnees: [adressePostale, coordonneesTelephoniques],
    };
    if (!!adresseElectronique.email) {
      client.coordonnees.push(adresseElectronique);
    }
    if (data.payment.paymentMode !== CheckoutNs.PaymentMode.prelevement) {
      delete client.compteBancaire;
    }
    const insuranceDto: InsuranceSubsciptionDTO = {
      _type: '',
      acteurCreateur: undefined,
      client: undefined,
      idServicePartenaire: '',
      noOffre: '',
      noPersonne: '',
      modePaiement: PartnerPaymentMethod.prelevement.toUpperCase(),
    };
    Object.assign(insuranceDto, { acteurCreateur, client });
    insuranceDto._type = 'SOUSCRIPTION_SERVICE';
    insuranceDto.modePaiement =
      data.payment.paymentMode === CheckoutNs.PaymentMode.prelevement
        ? PartnerPaymentMethod.prelevement.toUpperCase()
        : PartnerPaymentMethod.cheque.toUpperCase();
    insuranceDto.idServicePartenaire = currentScheme
      .getProductByType<InsurancePartner>(InsurancePartner)
      ?.getData('gencode');
    insuranceDto.noOffre = insuranceDto.idServicePartenaire;
    insuranceDto.noPersonne = this.customer.personId?.toString();
    return insuranceDto;
  }

  private mapMetaDataInputToOutput(metadataInputDTO: MetadataInputDTO): MetaDataOutputDTO {
    return {
      assurance: {
        identite: {
          emailAssurance: metadataInputDTO.assurance.emailAssurance,
          villeNaissance: metadataInputDTO.assurance.villeNaissance,
          paysNaissance: metadataInputDTO.assurance.paysNaissance,
          dateNaissance: metadataInputDTO.assurance.dateNaissance,
          nationalite: metadataInputDTO.assurance.nationalite,
          civilite: metadataInputDTO.assurance.civilite,
          nom: metadataInputDTO.assurance.nom,
          prenom: metadataInputDTO.assurance.prenom,
          telephone: metadataInputDTO.assurance.telephone,
          adresse: metadataInputDTO.assurance.adresse,
        },
        paiement: {
          ibanAssurance: metadataInputDTO.assurance.ibanAssurance || null,
          bicAssurance: metadataInputDTO.assurance.bicAssurance || null,
          modePaiementAssurance: metadataInputDTO.assurance.modePaiementAssurance,
        },
      },
    };
  }

  private updateDocuments(data: CustomerContextSerializedInterface): void {
    if (data.documentIdentite) {
      this.customer.identityDocumentType = PandoraDocument.unserialize(data.documentIdentite);
    }
    if (data.documentAdresse) {
      this.customer.addressDocument = PandoraDocument.unserialize(data.documentAdresse);
    }
    if (data.documentPaiement) {
      this.customer.paymentDocumentType = PandoraDocument.unserialize(data.documentPaiement);
    }
  }

  private addDefaultAdress(contract: ContractInterface, customer: Customer): Observable<Customer> {
    return this.oauth2RessourceService
      .setUrl(contract._links.compteFacturation.href)
      .get()
      .pipe(
        mergeMap(response => this.oauth2RessourceService.setUrl(response._links.adresseFacturation.href).get()),
        map((response: AdresseFacturationResponse) => {
          if (response) {
            customer.streetNumber = response.numero || null;
            customer.street = response.rue;
            customer.street2 = response.complementAdresse1 || '';
            if (!customer.street2) {
              customer.street2 = null;
            }
            customer.postcode = response.codePostal;
            customer.city = response.ville;
            customer.country = 'FRA';
            if (
              response.modePaiement === 'PRELEVEMENT' &&
              response.compteBancaire.iban &&
              response.compteBancaire.bic
            ) {
              customer.paymentAccount = {
                iban: response.compteBancaire.iban,
                bic: response.compteBancaire.bic,
              };
            }
          }
          return customer;
        }),
      );
  }

  private serializeDocuments(documents: PandoraDocument[]): IPandoraDocumentSerialize[] {
    const result: IPandoraDocumentSerialize[] = [];
    for (const document of documents) {
      result.push(document.serialize());
    }
    return result;
  }

  private parseBillingAccountsResponse(response: BasicObject): void {
    if (response.items) {
      const accounts = response.items.filter(
        account =>
          account.statut === 'ACTIF' &&
          account.modePaiement === 'PRELEVEMENT' &&
          account.compteBancaire &&
          account.compteBancaire.iban,
      );
      accounts.map((account: BasicObject) => {
        if (this.customer.possibleIbans.every(compte => compte !== account.compteBancaire.iban)) {
          this.customer.possibleIbans.push(account.compteBancaire.iban);
        }
      });
    }
  }

  private parseCoordinatesResponse(response: BasicObject): void {
    if (response.telephones) {
      const phones = response.telephones.filter(phone => phone.telephonePrincipal === true);
      if (phones[0]) {
        this.customer.phoneNumber = phones[0].numero.replace('+33', '0');
      }
    }

    if (response.emails) {
      const emails = response.emails.filter(email => email.emailPrincipal === true);
      if (emails[0]) {
        this.customer.email = emails[0].email;
      }
    }
    this.parseAddresses(response);
  }

  private parseAddresses(response: BasicObject): void {
    if (this.customer.type !== CustomerType.client && response.adressesPostales) {
      const addresses = response.adressesPostales.sort((address1, address2) =>
        new DateUniversal(address1.dateMiseAJour) > new DateUniversal(address2.dateMiseAJour) ? -1 : 1,
      );
      if (addresses && addresses[0]) {
        this.customer.streetNumber = addresses[0].numero || null;
        this.customer.street = addresses[0].rue;
        this.customer.street2 = addresses[0].complementAdresse1 || '';
        if (!this.customer.street2) {
          this.customer.street2 = null;
        }
        this.customer.postcode = addresses[0].codePostal;
        this.customer.city = addresses[0].ville;
        this.customer.country = addresses[0].codePays;
      }
    }
  }

  private parseGetContractsResponse(response: BasicObject): void {
    this.customer.contracts = response.items.filter((item: BasicObject) => item.statut === 'ACTIF');
    if (this.customer.contracts.length) {
      this.customer.isClient = true;
      this.customer.type = CustomerType.client;
    }
  }

  private parseLoadPersonResponse(response: BasicObject): void {
    this.customer.editable = !!(response._actions && response._actions.modifierprospect);
    if (response.type === CustomerCategory.entreprise) {
      this.customer.category = CustomerCategory.pro;
    }
    if (response.representantLegal) {
      this.customer.firstname = response.representantLegal.prenom;
      this.customer.lastname = response.representantLegal.nom;
      this.customer.civility = response.representantLegal.civilite;
      this.customer.dob = response.representantLegal.dateNaissance;
      this.customer.birthplace = response.representantLegal.codeDepartementNaissance;
      this.customer.company = {
        noSiren: response.siren,
        raisonSociale: response.raisonSociale,
        formeJuridique: response.dateCreation,
        representantLegal: {
          civilite: this.customer.civility,
          nom: this.customer.lastname,
          prenom: this.customer.firstname,
          dateNaissance: this.customer.dob,
          departementNaissance: this.customer.birthplace?.split(' ')[0],
        },
      };
    } else {
      this.customer.firstname = response.prenom;
      this.customer.lastname = response.nom;
      this.customer.civility = response.civilite;
      this.customer.dob = response.dateNaissance;
      this.customer.birthplace = response.codeDepartementNaissance;
      this.customer.category = CustomerCategory.gp;
      this.customer.dateFromSI = !!response.dateNaissance && !!response.codeDepartementNaissance;
      this.customer.vip = !!response.vip;
    }
    // todo code à revoir avec la refonte de l'étape titulaire (ne plus griser dans le 2ème acte ?)
    const siInfo: CustomerContextSerializedInterface = this.customer.serialize();
    this.customer.lockedInfo = Object.keys(siInfo).filter(data => siInfo[data] && typeof data !== 'function');
  }

  private parseConvergenceResult(contexteOperateur: ContexteOperateur): void {
    let isEligible = false;

    if (contexteOperateur) {
      if (contexteOperateur.commandesCommercialesEnCours) {
        contexteOperateur.commandesCommercialesEnCours.forEach(commandesCommerciale => {
          isEligible = commandesCommerciale.offresPrincipales.some(
            offre => offre.ligneMarcheConvergent === 'FAI' && offre.niveauGammeConvergent === 'PREMIUM',
          );
        });
      }
      if (!isEligible) {
        if (contexteOperateur.contratsServiceNonResilies) {
          isEligible = contexteOperateur.contratsServiceNonResilies.some(function (contrats) {
            return (
              contrats.offrePrincipale.ligneMarcheConvergent === 'FAI' &&
              contrats.offrePrincipale.niveauGammeConvergent === 'PREMIUM'
            );
          });
        }
      }
      if (contexteOperateur.personneUnique?.remisesPotentielles) {
        this.customer.isEligibleConvergenceMultiline = contexteOperateur.personneUnique.remisesPotentielles.some(
          remise => remise.type === 'MULTILIGNE',
        );
        this.customer.isEligiblePackFamily = contexteOperateur.personneUnique.remisesPotentielles.some(remise =>
          ['PACK_FAMILLE_FIXE', 'PACK_FAMILLE_MOBILE'].includes(remise.type),
        );
      }
    }

    this.customer.isEligibleConvergence = isEligible;
  }

  public mapToSimulatorCustomer(): SimulatorParams {
    const simulatorCustomer: SimulatorParams = {};
    if (this.customer?.isClient) {
      simulatorCustomer.IdPu = this.customer?.personId.toString();
    } else {
      simulatorCustomer.IdPu = this.customer?.personId?.toString() || null;
      simulatorCustomer.Civilite = this.customer?.civility?.split('.')[0] || null;
      simulatorCustomer.Nom = this.customer?.lastname || null;
      simulatorCustomer.Prenom = this.customer?.firstname || null;
      simulatorCustomer.Email = this.customer?.email || null;
      simulatorCustomer.Telephone = this.customer?.phoneNumber || null;
      simulatorCustomer.Siren = this.customer?.isPro() ? this.customer?.company?.noSiren : null;
      simulatorCustomer.RaisonSociale = this.customer?.isPro() ? this.customer?.company?.raisonSociale : null;
      simulatorCustomer.TypeVisiteur = this.customer?.type || null;
      simulatorCustomer.TypeClient = this.customer?.category || null;
      simulatorCustomer.IdProspect = this.customer?.idProspect || null;
    }
    return simulatorCustomer;
  }
}
