import { Scheme } from './scheme.class';
import { BrowseType } from '../constants/browse-type';
import { SchemeSerialized } from '@interfaces/scheme.interface';

export class AcquisitionScheme extends Scheme {
  public browseType = BrowseType.acquisition;

  public unserializeSpecificData(schemeSerialized: SchemeSerialized): void {
    this.contractId = schemeSerialized.contextProduit.contextService.acquisition.idContrat;
  }
}
