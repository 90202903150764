<div (mouseleave)="debounceCloseDropdown()" (mouseenter)="undebounceCloseDropdown()">
  <div class="dropdown" (click)="toggleDropdown()">
    <div class="overselect"></div>
    <select class="form-control">
      <option value="">{{ title }}</option>
    </select>
  </div>
  @if (show) {
    <div class="multiselect">
      <ul>
        @for (option of options; track option; let i = $index) {
          <li (click)="select(selection[i])">
            <input type="checkbox" [checked]="selection[i].selected" [disabled]="checkMaxSelected(selection[i].selected)">
            <label [ngClass]="{ 'highlighted': option.highlighted }">
              {{ keyLabel ? option[keyLabel] : option }}
            </label> <!-- ne pas lier le label sinon ca va bugguer -->
          </li>
        }
      </ul>
    </div>
  }
</div>
