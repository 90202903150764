import { Injectable } from '@angular/core';
import { EnumFaiSteps } from '../../../shared/prisme/prisme-logger.service';
import { Step } from '@components/stepper/step.abstract';

@Injectable({
  providedIn: 'root',
})
export class PtoStepService extends Step {
  public isActive = false;
  public code = 'pto';
  public path = '/fai/pto';
  public label = 'Recherche de PTO';
  public stepPrisme: string = EnumFaiSteps.steppto;
  public stepReturnPrisme: string = EnumFaiSteps.stepptoreturn;
}
