import { Directive, ComponentRef, ViewContainerRef, ComponentFactoryResolver, Inject, OnDestroy } from '@angular/core';
import { ViewComponent } from '@components/category/view/view.component';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Directive({ standalone: true, selector: '[rcbtQview]' })
export class ViewDirective implements OnDestroy {
  private bodyOverflow: string | null = null;
  private compRef: ComponentRef<ViewComponent> | null = null;
  private closeSubscription: Subscription | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  public ngOnDestroy(): void {
    this.closeSubscription?.unsubscribe();
    this.setBodyOverflow(null);
  }

  public createView(): ComponentRef<ViewComponent> {
    this.viewContainer.clear();

    const compFact = this.componentFactoryResolver.resolveComponentFactory(ViewComponent);

    this.compRef = this.viewContainer.createComponent(compFact);
    this.setBodyOverflow('hidden');
    this.listenToCloseEvent();

    return this.compRef;
  }

  private setBodyOverflow(value: string | null): void {
    if (value !== this.bodyOverflow) {
      this.bodyOverflow = value;
      this.document.body.style.overflow = value === 'hidden' ? 'hidden' : '';
    }
  }

  private listenToCloseEvent(): void {
    this.closeSubscription = this.compRef?.instance.close.subscribe(() => {
      this.setBodyOverflow(null);
      this.compRef?.destroy();
      this.closeSubscription?.unsubscribe();
    });
  }
}
