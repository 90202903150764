import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '@services/cart.service';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IdentityForm } from './identity.form.class';
import { Oauth2RessourceService } from '../../../../oauth2/oauth2-resources.service';
import { SchemeHelper } from '../../scheme.helper';
import { countries, departments } from '../form/select-options';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { finalize, mergeMap, tap, catchError, map, switchMap } from 'rxjs/operators';
import { ToolsService } from '@base/services/tools.service';
import { AppErrorCodes } from '@interfaces/cart.interface';
import { AlertService } from '@services/alert.service';
import { ConfigService } from '@services/config.service';
import { MobileTakeBackService } from '@components/top-bar/scan/mobileTakeBackService';
import { MobileTakeBack } from '@model/catalog/products/mobileTakeBack';
import { IDisposalTicket } from '@model/catalog/products/mobileTakeBack.interfaces';
import { StepCustomerComponent } from '../step-customer.component';
import { CustomerService, EmailType, SearchPersonQueryParam, ValidateEmailResult } from '../customer.service';
import { birthDateEmancipeValidate, emailCheck } from '../form/validators';
import { FundingService } from '../../../../fundings/services/funding.service';
import { CustomerStepEnum, CustomerType, InsuranceDataIdentity, LastStepEnum } from '../customer.interface';
import { IntraCommunicationService } from '@services/intra-communication.service';
import { InsurancePartner } from '@model/catalog/products/subscription/insurance-partner';
import { BrowseConfigService } from '../../../../context/browse-config.service';
import { UserService } from '@services/user.service';
import { SimReplace } from '@model/catalog/products/equipement/sim/sim-replace';
import { FraudService } from '../../../fraud/fraud.service';
import { DematService } from '@services/demat.service';
import { ScanditService } from '@services/scandit.service';
import { Person, SearchPersonsResult } from './identity.interface';
import { IdentityService } from './identity.service';
import { ContextStorageService } from '../../../../context/context-storage.service';
import { FraudComponent } from '../../../fraud/fraud/fraud.component';
import { NgxMaskDirective } from 'ngx-mask';
import { AutoCompleteComponent } from '@base/components/auto-complete/auto-complete.component';
import { AsteriskDirective } from '@base/directive/asterisk.directive';
import { AutoCompleteScanComponent } from '../auto-complete-scan/auto-complete-scan.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'rcbt-cart-customer-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    AutoCompleteScanComponent,
    FormsModule,
    ReactiveFormsModule,
    AsteriskDirective,
    AutoCompleteComponent,
    NgxMaskDirective,
    FraudComponent,
  ],
})
export class IdentityComponent extends StepCustomerComponent implements OnInit, OnDestroy {
  public identityForm: UntypedFormGroup;
  public typeScheme: string;
  public rCheckMailError: string;
  public rCheckInsuranceMailError: string;
  public departments = departments;
  public countries = countries;
  public identityNumberMandatory = false;
  public emailAndPhoneNumberMandatory = false;
  public subscriptions: Subscription = new Subscription();
  public cartHasCredit = false;
  public isSimOnly = false;
  public isAutoCompleteEnabled = false;
  private isRattachedPu = false;
  private person: Person;
  private customerComplementInfos: {
    address: string;
    numeroRue: string;
    rue: string;
    complementAdresse: string;
    codePostal: string;
    ville: string;
    idPays: string;
    email: string;
    payment: {
      iban?: string;
      bic?: string;
    } | null;
  };
  public birthDateEmancipeWarning: string;

  constructor(
    protected oauth2RessourceService: Oauth2RessourceService,
    protected stepperService: CheckoutStepperService,
    protected cartService: CartService,
    protected formBuilder: UntypedFormBuilder,
    protected alertService: AlertService,
    protected configService: ConfigService,
    protected mobileTakeBackService: MobileTakeBackService,
    public customerService: CustomerService,
    protected fundingService: FundingService,
    private intraCommunicationService: IntraCommunicationService,
    private browseConfigService: BrowseConfigService,
    protected userService: UserService,
    private fraudService: FraudService,
    private dematService: DematService,
    private scanditService: ScanditService,
    private identityService: IdentityService,
    private contextStorageService: ContextStorageService,
  ) {
    super(oauth2RessourceService, cartService, customerService, userService);
  }

  public ngOnInit(): void {
    this.isSimOnly = SchemeHelper.isSimOnly(this.cartService.getCurrentScheme());
    this.isSaleOnly =
      (!SchemeHelper.hasPlan(this.cartService.getCurrentScheme()) && !this.medi7Product) || this.isSimOnly;
    const rootForm: IdentityForm = new IdentityForm(this.formBuilder, this.customer, this.cartService);
    this.identityNumberMandatory = this.cartService.getCurrentScheme().hasRepriseMobile();
    this.emailAndPhoneNumberMandatory =
      SchemeHelper.hasPlan(this.cartService.getCurrentScheme()) ||
      !!this.medi7Product ||
      this.cartService.getCurrentScheme().isAcquisitionFix();

    this.subscribeMedi7Change();

    this.subscriptions.add(
      this.dematService.mapScannedDataEvent.subscribe(() => {
        this.setIdentityForm(rootForm).subscribe();
      }),
    );

    this.subscriptions.add(this.setIdentityForm(rootForm).subscribe());

    this.subscriptions.add(
      this.identityService.attachPerson$
        .pipe(
          switchMap(p => {
            this.customerService.setIsLoading(true, 'IdentityGP');
            this.person = p;
            this.customer.personId = p.idPu;
            this.browseConfigService.browseConfig.personId = p.idPu;
            this.contextStorageService.setItem(
              this.contextStorageService.key['browseConfig'],
              this.browseConfigService.browseConfig.serialize(),
            );
            return this.customerService.loadPerson();
          }),
          catchError(err => {
            this.alertService.errorEmitter.next(err.error);
            return throwError(err);
          }),
          finalize(() => this.customerService.setIsLoading(false, 'IdentityGP')),
        )
        .subscribe(() => {
          this.attachPerson(this.person);
          if (!this.customerService.customer.editable) {
            this.disableAllInput();
            this.customerService.shouldForceDisabledAddress = true;
            this.customerService.forceDisableAddress.next(true);
          }
          this.customerService.setIsLoading(false, 'IdentityGP');
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public isPrepaid(): boolean {
    return SchemeHelper.isPrepaid(this.cartService.getCurrentScheme());
  }

  public hasReplaceSim(): boolean {
    return this.cartService.getProductsByType(SimReplace)?.length > 0;
  }

  public onIdentitySubmit(): Observable<boolean> {
    this.isSubmitted = false;
    this.rCheckMailError = null;
    this.rCheckInsuranceMailError = null;
    if (
      this.identityForm.valid ||
      this.identityForm.disabled ||
      !SchemeHelper.hasPlan(this.cartService.getCurrentScheme())
    ) {
      this.customerService.setIsLoading(true, 'IdentityGP');
      this.setDefaultEmailForInsurance();

      if (this.isRattachedPu) {
        return this.updateCustomer();
      }

      return this.customerService
        .validateIdentityEmails(this.identityForm, !!this.cartService.getProductsByType(InsurancePartner).length)
        .pipe(
          mergeMap((res: Map<EmailType, ValidateEmailResult>) => {
            if (res.size) {
              if (res.get(EmailType.bytel)?.error) {
                this.alertService.errorEmitter.next(res.get(EmailType.bytel).error);
                if (
                  res.get(EmailType.bytel).searchPersonQueryParam &&
                  !this.cartService.getCurrentScheme().isRenew() &&
                  (!this.customer.personId || this.customer.editable)
                ) {
                  return this.searchPerson(res.get(EmailType.bytel).searchPersonQueryParam);
                }
                this.rCheckMailError = res.get(EmailType.bytel).error;
              }
              if (res.get(EmailType.insurance)?.error) {
                this.alertService.errorEmitter.next(res.get(EmailType.insurance).error);
                this.rCheckInsuranceMailError = res.get(EmailType.insurance).error;
              }
              return of(false);
            }
            return this.updateCustomer();
          }),
          finalize(() => this.customerService.setIsLoading(false, 'IdentityGP')),
        );
    }
    return of(false);
  }

  public submit(): Observable<boolean> {
    return this.onIdentitySubmit();
  }

  public valideSubmit(): boolean {
    if (this.identityForm?.dirty && this.customerService.nbSteps !== LastStepEnum.identity) {
      return this.identityForm.valid && this.isSubmitted;
    }
    return this.identityForm?.valid;
  }

  public submitDisabled(): boolean {
    return super.submitFormDisabled(this.identityForm);
  }

  public validateIdentityDocumentNumber(): void {
    this.identityForm.get('documentIdentiteNumero').updateValueAndValidity();
  }

  public handleSpecificInsuranceEmail(): void {
    this.identityForm.controls.emailAssurance.setValidators(
      this.identityForm.value.specificInsuranceEmail &&
        this.identityForm.value.specificInsuranceEmail !== this.identityForm.value.email
        ? [Validators.required, emailCheck]
        : [Validators.nullValidator],
    );
    this.identityForm.controls.emailAssurance.updateValueAndValidity();
  }

  protected isRcheckMailActive(): boolean {
    return !this.configService.data.rcheckMail || this.configService.data.rcheckMail.active !== false;
  }

  private setDefaultEmailForInsurance(): void {
    if (this.medi7Product && !this.identityForm.value.specificInsuranceEmail) {
      this.identityForm.controls['emailAssurance'].setValue(this.identityForm.getRawValue()['email']);
    }
  }

  private handleDisposalTicket(): Observable<void> {
    const user = this.userService.user;
    const mobileTakeBack: MobileTakeBack = this.cartService
      .getCurrentScheme()
      .getProductByType<MobileTakeBack>(MobileTakeBack);
    return this.mobileTakeBackService
      .callCheckDisposalTicket(
        mobileTakeBack.getScanCode(),
        this.identityForm.controls.nom.value,
        this.identityForm.controls.prenom.value,
      )
      .pipe(
        mergeMap((dt: IDisposalTicket) =>
          this.mobileTakeBackService.updateMtbCommand(dt.idCommandePartenaire, user.login, user.codeEns, user.codePdv),
        ),
        catchError(err => {
          const codeError = err.error ? err.error.error : '';
          err.error.error_description = this.mobileTakeBackService.handleErrorDisposalTicket(err.status, codeError);
          return throwError(err);
        }),
      );
  }

  private openNextStep(): void {
    if (this.shouldAddressBeDisabled() && !this.customerService.isAddressTheLastForm()) {
      this.customerService.forceSubmitAddress.emit(true);
    }

    if (this.isActive) {
      this.customerService.changeCustomerStep({ step: CustomerStepEnum.address, ignoreIsLoading: true });
    }
  }

  private handleSubscriptions(): void {
    this.customerService.changeValidityStep(CustomerStepEnum.identity, !this.submitDisabled());
    this.identityForm.valueChanges.subscribe(val => {
      this.isSubmitted = false;
      this.errorMessage = '';
      this.customerService.changeValidityStep(CustomerStepEnum.identity, !this.submitDisabled());
      this.stepperService.valideSubmit.emit();
    });
    this.subscriptions.add(
      this.fundingService.stateCredit?.subscribe((state: boolean) => {
        this.cartHasCredit = state;
        this.identityForm.get('email').setValidators(state ? [Validators.required, emailCheck] : [emailCheck]);
        this.identityForm.get('email').updateValueAndValidity();
      }),
    );
  }

  private buildIdentityForm(tmpForm: IdentityForm, insuranceData?: InsuranceDataIdentity): void {
    this.identityForm = tmpForm.build(
      this.identityDocuments,
      this.identityNumberMandatory,
      this.emailAndPhoneNumberMandatory,
      insuranceData,
    );

    this.identityForm.get('dateNaissance').valueChanges.subscribe(value => {
      this.birthDateEmancipeWarning = birthDateEmancipeValidate(value);
    });

    if (!this.isSaleOnly) {
      this.identityForm.controls.telephone.markAsTouched();
    }

    this.isAutoCompleteEnabled =
      this.scanditService.isOnTablet() &&
      this.configService.data.demat.autoCompleteEnabled &&
      this.cartService.cart.schemes.length < 2 &&
      this.customer.type === CustomerType.prospect &&
      !this.isSaleOnly &&
      !this.identityForm.disabled &&
      !this.medi7Product;
  }

  private updateCustomer(): Observable<boolean> {
    this.identityForm.value.dateNaissance = ToolsService.dateFormat(this.identityForm.value.dateNaissance);
    this.identityForm.value.documentIdentite = this.identityDocuments.filter(
      doc => doc.name === this.identityForm.value?.documentIdentite?.name,
    )[0];
    return this.customerService
      .updateCustomer(
        {
          ...this.identityForm.value,
          ...this.customerComplementInfos,
        },
        this.browseConfigService.browseConfig,
        this.cartService.getCurrentScheme(),
        this.cartService.cart.schemes,
      )
      .pipe(
        mergeMap(() =>
          forkJoin([
            this.cartService.updateSchemes(),
            this.cartService.updateCart(),
            this.fraudService.saveFraudData(this.identityForm.getRawValue().fraudForm?.fraud),
          ]),
        ),
        tap(
          () => null,
          e => {
            if (e.error && e.error.code === AppErrorCodes.shopdetail) {
              this.alertService.errorEmitter.next(
                'Impossible de récupérer les informations de la boutique, veuillez réessayer.',
              );
            }
          },
        ),
        mergeMap(() => {
          if (
            this.customerService.nbSteps === LastStepEnum.identity &&
            this.cartService.getCurrentScheme().hasRepriseMobile()
          ) {
            return this.orderRepriseMobile();
          }
          if (this.cartService.getCurrentScheme().hasDisposalTicket()) {
            return this.handleDisposalTicket().pipe(map(() => true));
          }
          this.isSubmitted = true;
          return of(true);
        }),
        map(() => {
          if (this.customerService.nbSteps === LastStepEnum.identity) {
            this.isSubmitted = true;
          } else {
            this.openNextStep();
          }
          return true;
        }),
        catchError(error => {
          if (error?.error?.error_description) {
            this.errorMessage = error?.error?.error_description;
          } else {
            this.errorMessage = 'Une erreur technique est survenue';
          }
          return of(false);
        }),
        finalize(() => this.customerService.setIsLoading(false, 'IdentityGP')),
      );
  }

  private getMedi7Data(): Observable<InsuranceDataIdentity> {
    if (this.medi7Product) {
      return this.customerService.getInsuranceMetadata(this.cartService.cart.cartId).pipe(
        map(_data => {
          if (_data) {
            return {
              birthplaceCity: _data.assurance.identite.villeNaissance,
              birthplaceCountry: _data.assurance.identite.paysNaissance,
              nationality: _data.assurance.identite.nationalite,
              insuranceEmail: _data.assurance.identite.emailAssurance,
            } as InsuranceDataIdentity;
          } else {
            return {};
          }
        }),
      );
    }
    return of(null);
  }

  private subscribeMedi7Change(): void {
    this.subscriptions.add(
      this.intraCommunicationService.emitMedi7Refresh.subscribe(() => {
        this.medi7Product = this.cartService.getCurrentScheme().getProductByType<InsurancePartner>(InsurancePartner);

        if (!this.medi7Product) {
          this.identityForm.controls.emailAssurance.setValidators([Validators.nullValidator]);
          this.identityForm.controls.paysNaissance.setValidators([Validators.nullValidator]);
          this.identityForm.controls.villeNaissance.setValidators([Validators.nullValidator]);
          this.identityForm.controls.nationalite.setValidators([Validators.nullValidator]);
          this.identityForm.controls.emailAssurance.updateValueAndValidity();
          this.identityForm.controls.paysNaissance.updateValueAndValidity();
          this.identityForm.controls.villeNaissance.updateValueAndValidity();
          this.identityForm.controls.nationalite.updateValueAndValidity();
        }
      }),
    );
  }

  private setIdentityForm(rootForm: IdentityForm): Observable<InsuranceDataIdentity> {
    return of(this.medi7Product).pipe(
      mergeMap(() => this.getMedi7Data()),
      tap(insuranceDataIdentity => this.buildIdentityForm(rootForm, insuranceDataIdentity)),
      finalize(() => {
        this.cartHasCredit = !!this.cartService.cart.creditData;
        this.handleSubscriptions();
      }),
    );
  }
  private searchPerson(queryParamName: SearchPersonQueryParam): Observable<boolean> {
    return this.identityService.getSearchPersonData(this.identityForm.value.email, queryParamName).pipe(
      tap((result: SearchPersonsResult) => {
        const validPUs = result.personne?.filter(p => p.idPersonneUnique);
        if (validPUs?.length) {
          this.identityService.openAssociationPopin(validPUs);
        }
      }),
      map(() => false),
    );
  }

  private attachPerson(pu: Person): void {
    this.identityForm.controls['civilite'].setValue(pu.civility);
    this.identityForm.controls['prenom'].setValue(pu.firstname);
    this.identityForm.controls['nom'].setValue(pu.name);
    this.identityForm.controls['dateNaissance'].setValue(pu.birthDate);
    this.identityForm.controls['departementNaissance'].setValue(pu.departmentOfBirth);

    this.customerComplementInfos = {
      address: pu.address.addressLinear || '',
      numeroRue: pu.address.streetNumber || '',
      rue: pu.address.street || '',
      complementAdresse: pu.address.street2 || '',
      codePostal: pu.address.postalcode || '',
      ville: pu.address.city || '',
      idPays: pu.address.country || '',
      email: pu.email,
      payment: !pu.areAllContractsTerminated
        ? {
            iban: pu.paymentAccount.iban,
            bic: pu.paymentAccount.bic,
          }
        : null,
    };
    this.isRattachedPu = true;
    this.rCheckMailError = null;
  }

  private disableAllInput(): void {
    ['civilite', 'prenom', 'nom', 'dateNaissance', 'departementNaissance', 'telephone', 'email'].forEach(key => {
      this.identityForm.controls[key].disable();
    });
  }
}
