import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StockType } from '@interfaces/types';
import { PrismeLoggerService } from '../../../shared/prisme/prisme-logger.service';
import { PrismeLogType } from '../../../shared/prisme/prisme-log-type.enum';
import { NgClass } from '@angular/common';
import { SimComponent } from '@components/cart-items/plan/sim/sim.component';
@Component({
  selector: 'rcbt-category-replace-sim',
  templateUrl: './replace-sim.component.html',
  standalone: true,
  styleUrls: ['./replace-sim.component.scss', '../category.component.scss'],
  imports: [NgClass, SimComponent],
})
export class ReplaceSimComponent implements OnInit, OnDestroy {
  @Input()
  public showHeader = false;

  public simKeys: string[] = [];
  public simNumberForm: UntypedFormGroup;
  public stockData: StockType;
  public message: string;
  public onTablete: boolean;
  public loading = false;
  public scanListener: Subscription;
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected route: ActivatedRoute,
    private prismeLogger: PrismeLoggerService,
  ) {
    // super(route);
  }

  public ngOnInit(): void {
    this.prismeLogger.sendDataToPrisme(PrismeLogType.customLog, { message: 'Replace Sim' });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
