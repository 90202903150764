import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RenewService } from '@services/renew.service';
import { CartService } from '@services/cart.service';
import { RenewScheme } from '@model/renew-scheme';

export const renewResolver: ResolveFn<Observable<void>> = () => {
  const cartService = inject(CartService);
  const renewService = inject(RenewService);
  if (cartService.getCurrentScheme() instanceof RenewScheme) {
    return renewService.getRenewData(cartService.getCurrentScheme() as RenewScheme);
  }
  return of(null);
};
