<article class="accordion" [ngClass]="{'is-active': isActive }">
  <div class="accordion-header" (click)="onClickTab(customerService.customerSteps.payment);">
    <div class="title is-size-1" data-cy="title-payment">Paiement</div>
    <button class="toggle" aria-label="toggle"></button>
  </div>
  <div class="accordion-body">
    <hr>
      <div class="accordion-content">
        @if (customerService.currentStep.value === customerService.customerSteps.payment) {
          <rcbt-open-banking #openBankingComponent (openBankingDataEvent)="onOpenBankingEvent($event)" (hideWordingEvent)="onUpdateHideWording($event)"></rcbt-open-banking>
        }
        <form class="data-form" [formGroup]="paymentForm" novalidate data-cy="payment-form">
          <div class="fields-row">
            <div class="field">
              <label class="radio-title-label">Mode de prélèvement des factures :</label>
              <div class="radio">
                <input id="radio-mode-1" type="radio" value="PRELEVEMENT" formControlName="paymentMode" name="radio-mode"
                  class="active"
                  [ngClass]="{'disabled': paymentForm.get('paymentMode').disabled}">
                <label for="radio-mode-1" class="radio-label" tabIndex="0">Prélèvement automatique</label>
              </div>
            </div>
            <small class="has-text-danger" [hidden]="!paymentForm.controls.paymentMode.errors || paymentForm.controls.paymentMode.valid || !hasPlan">
              {{ paymentForm.controls.paymentMode.errors }}
            </small>
          </div>

          @if (hasFMS) {
            <div class="fields-row">
              <div class="field">
                <label class="radio-title-label">Choix de l'échéance de paiement pour les frais de mise en service :</label>
                @if (false) {
                  <div class="radio">
                    <input id="radio-modeFms-1" data-cy="radio-modeFms" type="radio" value="0" formControlName="paymentModeFMS" name="radio-modeFms" (change)="handlePaymentModeFMSChange($event)"
                      [ngClass]="{'active': paymentForm.get('paymentModeFMS').value === '0', 'disabled': paymentForm.get('paymentModeFMS').disabled}">
                    <label for="radio-modeFms-1" class="radio-label" tabIndex="0">Paiement immédiat</label>
                  </div>
                }
                <div class="radio">
                  <input id="radio-modeFms-2" data-cy="radio-modeFms" type="radio" value="3" formControlName="paymentModeFMS" name="radio-modeFms" (change)="handlePaymentModeFMSChange($event)"
                    [ngClass]="{'active': paymentForm.get('paymentModeFMS').value === '3', 'disabled': paymentForm.get('paymentModeFMS').disabled}">
                  <label for="radio-modeFms-2" class="radio-label" tabIndex="1">Paiement 1ère facture</label>
                </div>
              </div>
              <small class="has-text-danger" [hidden]="!paymentForm.controls.paymentModeFMS.errors || paymentForm.controls.paymentModeFMS.valid || !hasPlan">
                {{ paymentForm.controls.paymentModeFMS.errors }}
              </small>
            </div>
          }

          <div class="fields-row">
            <div class="field p-0">
              <div class="columns">
                <div class="data column">
                  @if (isNewIban) {
                    <div class="control has-dynamic-placeholder has-icons-right">
                      <input id="input-iban" data-cy="input-iban" autocomplete="off" type="text" class="input uppercase" formControlName="iban" placeholder="IBAN"
                        rcbtIbanInput (calculatedIbanLength)="updateIbanLength($event)"
                        [minlength]="ibanLength" (focus)="errorMessage = ''" (input)="onIbanInput()"
                                        [ngClass]="{'is-success' : paymentForm.controls.iban.valid && paymentForm.value.iban.length !== 0,
                                        'is-danger' : paymentForm.controls.iban.errors}"
                        #iban>
                      <label for="input-iban" rcbtAsterisk [formcontrol]="paymentForm.controls.iban">IBAN</label>
                      <span class="icon">
                        @if (paymentForm.controls.iban.valid && paymentForm.value.iban.length !== 0) {
                          <i class="tri-check-circle has-background-success-60"></i>
                        }
                        @if (paymentForm.controls.iban.errors) {
                          <i class="tri-exclamation-circle has-background-error-60"></i>
                        }
                      </span>
                    </div>
                  }
                  @if (!isNewIban) {
                    <div class="control has-dynamic-placeholder">
                      <select id="input-iban" data-cy="select-iban" formControlName="iban" placeholder="IBAN" (change)="updateVisibilityAndValidityProofPayment()" #iban
                        class="select has-text-tertiary"
                                        [ngClass]="{'is-success' : paymentForm.controls.iban.valid && paymentForm.value.iban.length !== 0,
                                        'is-danger' : paymentForm.controls.iban.errors}">
                        @if (accounts.length > 1 && paymentForm.get('iban').value === '') {
                          <option [selected]="paymentForm.get('iban').value !== ''" value="">Choisir un Iban</option>
                        }
                        @for (account of accounts; track account) {
                          <option value="{{account.iban}}" [selected]="selectedAccount && selectedAccount.iban === account.iban">{{account.maskIban}}</option>
                        }
                      </select>
                      <label for="input-iban" rcbtAsterisk [formcontrol]="paymentForm.controls.iban">IBAN</label>
                    </div>
                  }
                </div>
                @if (showEditIbanBtn) {
                  <div class="column is-narrow is-aligned-center is-flex p-0">
                    <button class="button is-info button-high" (click)="editIban()">
                      <span class="icon is-white"><i class="tri-refresh" aria-hidden="true"></i></span>
                      Modifier l'IBAN
                    </button>
                  </div>
                }
              </div>
            </div>
            <small class="has-text-danger" [hidden]="paymentForm.controls.iban.pristine || !paymentForm.controls.iban.errors || paymentForm.controls.iban.valid || !hasPlan || isIbanIncorrect">
              IBAN incorrect, veuillez vérifier votre saisie !
            </small>
            <small class="has-text-danger" [hidden]="errorMessageIban.length === 0">
              {{ errorMessageIban }}
            </small>

            @if (!hideNewIbanCheckbox && (!isNewIban || displayInputSelect)) {
              <div class="check-otherIban">
                <input type="checkbox" [checked]="isNewIban" (click)="handleSwitchIban()">Utiliser un autre IBAN
              </div>
            }
          </div>

          @if (showProofpayment) {
            <div class="fields-row">
              <div class="field">
                <label class="radio-title-label">Justificatif de paiement :</label>
                <div class="radio">
                  <input id="radio-proof-1" data-cy="radio-proof" type="radio" value="cheque" formControlName="proofOfPayment" name="radio-proof"
                    [ngClass]="{'active': paymentForm.get('proofOfPayment').value === 'cheque', 'disabled': paymentForm.get('proofOfPayment').disabled}">
                  <label for="radio-proof-1" class="radio-label" tabIndex="0">Chèque annulé</label>
                </div>
                <div class="radio">
                  <input id="radio-proof-2" data-cy="radio-proof" type="radio" value="cb" formControlName="proofOfPayment" name="radio-proof"
                    [ngClass]="{'active': paymentForm.get('proofOfPayment').value === 'cb', 'disabled': paymentForm.get('proofOfPayment').disabled}">
                  <label for="radio-proof-2" class="radio-label" tabIndex="1">Carte Bancaire</label>
                </div>
              </div>
              <small class="has-text-danger" [hidden]="paymentForm.controls.iban.pristine || !paymentForm.controls.proofOfPayment.errors || paymentForm.controls.proofOfPayment.valid || !hasPlan">
                Veuillez choisir un justificatif de paiement !
              </small>
              @if (paymentForm.get('proofOfPayment').value === 'cb' && !hideWordingProofOfPaymentCb) {
                <label class="has-text-danger">
                  Le client doit régler à minima 1€ du panier avec sa carte bancaire.
                </label>
              }
            </div>
          }
          @if (isIbanWarned) {
            <span class="has-text-danger">{{warningIbanMsg}}</span>
          }

          @if (!!medi7Product) {
            <div class="fields-row medi7-section">
              <hr>
                <label class="radio-title-label"><strong>Choix du mode de paiement de l'assurance MEDI7 :</strong></label>
                <div class="radio">
                  <input id="radio-medi7-1" data-cy="radio-medi7" type="radio" [value]="medi7PaymentMode.bytelIban" formControlName="insurancePaymentMode" name="radio-medi7"
                    [ngClass]="{'active': paymentForm.get('insurancePaymentMode').value === medi7PaymentMode.bytelIban, 'disabled': paymentForm.get('insurancePaymentMode').disabled}"
                    (change)="handleInsurancePaymentMode(partnerPaymentMethod.prelevement)" [attr.disabled]="loading ? '' : null">
                  <label for="radio-medi7-1" class="radio-label" tabIndex="0">Cotisation mensuelle sur le même IBAN que Bouygues Telecom</label>
                </div>
                <div class="radio">
                  <input id="radio-medi7-2" data-cy="radio-medi7" type="radio" [value]="medi7PaymentMode.specificIban" formControlName="insurancePaymentMode" name="radio-medi7"
                    [ngClass]="{'active': paymentForm.get('insurancePaymentMode').value === medi7PaymentMode.specificIban, 'disabled': paymentForm.get('insurancePaymentMode').disabled}"
                    (change)="handleInsurancePaymentMode(partnerPaymentMethod.prelevement)" [attr.disabled]="loading ? '' : null">
                  <label for="radio-medi7-2" class="radio-label" tabIndex="1">Cotisation mensuelle de l'assurance sur un autre IBAN</label>
                </div>
                @if (paymentForm.get('insurancePaymentMode').value === medi7PaymentMode.specificIban) {
                  <div class="field">
                    <div class="data">
                      <div class="control has-icons-right has-dynamic-placeholder">
                        <input id="input-insuranceIban" data-cy="input-insuranceIban" autocomplete="off" type="text" class="input uppercase" formControlName="insuranceIban" placeholder="IBAN pour l'assurance"
                          rcbtIbanInput (calculatedIbanLength)="updateInsuranceIbanLength($event)" [minlength]="insuranceIbanLength"
                                    [ngClass]="{'is-success' : paymentForm.controls.insuranceIban.valid && paymentForm.value.insuranceIban.length !== 0,
                                    'is-danger' : paymentForm.controls.insuranceIban.errors || paymentForm.value.insuranceIban.length === 0}"
                          #insuranceIban>
                        <label for="input-insuranceIban" rcbtAsterisk [formcontrol]="paymentForm.controls.insuranceIban">IBAN pour l'assurance</label>
                        <span class="icon">
                          @if (paymentForm.controls.insuranceIban.valid && paymentForm.value.insuranceIban.length !== 0) {
                            <i class="tri-check-circle has-background-success-60"></i>
                          }
                          @if (paymentForm.controls.insuranceIban.errors || paymentForm.value.insuranceIban.length === 0) {
                            <i class="tri-exclamation-circle has-background-error-60"></i>
                          }
                        </span>
                      </div>
                    </div>
                    <div class="data short">
                      <div class="control has-icons-right has-dynamic-placeholder">
                        <input id="input-insuranceBic" data-cy="input-insuranceBic" autocomplete="off" type="text" class="input uppercase" formControlName="insuranceBic" placeholder="BIC"
                          [minlength]="8" [maxlength]="11"
                                    [ngClass]="{'is-success' : paymentForm.controls.insuranceBic.valid && paymentForm.value.insuranceBic.length !== 0,
                                    'is-danger' : paymentForm.controls.insuranceBic.errors || paymentForm.value.insuranceBic.length === 0}"
                          #insuranceBic>
                        <label for="input-insuranceBic" rcbtAsterisk [formcontrol]="paymentForm.controls.insuranceBic">BIC</label>
                        <span class="icon">
                          @if (paymentForm.controls.insuranceBic.valid && paymentForm.value.insuranceBic.length !== 0) {
                            <i class="tri-check-circle has-background-success-60"></i>
                          }
                          @if (paymentForm.controls.insuranceBic.errors || paymentForm.value.insuranceBic.length === 0) {
                            <i class="tri-exclamation-circle has-background-error-60"></i>
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                }
                <div class="radio">
                  <input id="radio-medi7-3" data-cy="radio-medi7" type="radio" [value]="medi7PaymentMode.cheque" formControlName="insurancePaymentMode" name="radio-medi7"
                    [ngClass]="{'active': paymentForm.get('insurancePaymentMode').value === medi7PaymentMode.cheque, 'disabled': paymentForm.get('insurancePaymentMode').disabled}"
                    (change)="handleInsurancePaymentMode(partnerPaymentMethod.cheque)" [attr.disabled]="loading ? '' : null">
                  <label for="radio-medi7-3" class="radio-label" tabIndex="1">Cotisation annuelle de l'assurance par chèque
                    <span> ({{medi7Product.price * 12}}€/an)</span>
                  </label>
                </div>
              </div>
            }

            @if (errorMessage.length > 0) {
              <div class="error-message">
                <hr>
                  <small class="has-text-danger">{{ errorMessage }}</small>
                </div>
              }

            </form>
          </div>
        </div>
      </article>
