import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { ICartHasReservedProdResponse } from '@interfaces/types';
import { User } from '../../../../../user/user';
import { UserService } from '@services/user.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'rcbt-modal-product-unreservation',
  templateUrl: './modal-product-unreservation.component.html',
  styleUrls: ['./modal-product-unreservation.component.scss'],
  standalone: true,
  imports: [DatePipe],
})
export class ModalProductUnreservationComponent implements OnInit {
  @Input()
  productUnreservationDto: ICartHasReservedProdResponse;

  public cancelButtonText = 'Non';

  public validButtonText = 'Oui';

  public user: User;

  constructor(
    private activeModal: NgbActiveModal,
    private globalLoaderService: GlobalLoaderService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.user = this.userService.user;
  }

  public onCloseClick(): void {
    this.globalLoaderService.setForceLoadingStatusOff(false);
    this.activeModal.close();
  }

  public onValidClick(): void {
    this.globalLoaderService.setForceLoadingStatusOff(false);
    this.activeModal.close('OK');
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }
}
