import { Scheme } from './scheme.class';
import { BrowseType } from '../constants/browse-type';
import { SchemeSerialized } from '@interfaces/scheme.interface';

export class SavScheme extends Scheme {
  public browseType = BrowseType.sav;
  public ignoreElig: boolean;
  public eligible = false;

  public unserializeSpecificData(schemeSerialized: SchemeSerialized): void {
    this.contractId = schemeSerialized.contextProduit.contextService.sav.contractId;
    this.eligible = schemeSerialized.contextProduit.contextService.sav.eligible;
    this.ignoreElig = schemeSerialized.contextProduit.contextService.sav.ignorerElig;
  }
}
