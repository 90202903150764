import { Component, OnInit } from '@angular/core';
import { PlansComponent } from '../plans.component';
import { IPostCatalogResponse } from '../../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { NgClass, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PriceComponent } from '@base/price/price.component';
import { CustomCurrencyPipe } from '@base/pipes/currency.pipe';

@Component({
  selector: 'rcbt-catalog-category-view-renew',
  templateUrl: '../plans.component.html',
  styleUrls: ['../plans.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, PriceComponent, AsyncPipe, CustomCurrencyPipe],
})
export class PlansRenewComponent extends PlansComponent implements OnInit {
  public isRenew = true;

  public ngOnInit(): void {
    super.ngOnInit();
    this.renewOwnedPlan = this.promoRenewService.renewPromotion.opSource.idOffre;
  }

  public filterPlans(): void {
    this.produitsFiltres = this.produitsContextualises;
    const planTypesToDisplay = ['plan_premium', 'plan_premium_renew', 'plan_ideo', 'plan_ideo_pro'];
    this.produitsFiltres = this.produitsFiltres.filter((prod: IPostCatalogResponse) =>
      planTypesToDisplay.includes(prod.type),
    );
    if (this.customerService.customer.isPro()) {
      this.produitsFiltres = this.produitsFiltres.filter(
        (p: IPostCatalogResponse) =>
          (p.excluPro && this.marketLine === 'PRO') || (!p.excluPro && this.marketLine === 'GP'),
      );
    }
  }
}
