import { Subscription } from '../subscription';
import { IAppointment, IGenericBoxConfiguration, IInformation } from './IGenericBoxConfiguration';
import { webShopEligibility } from '@fai-widget/fai-webshop.interface';
import { ProductSerialized } from '../interface/configurable';

export class BoxFaiGenerique extends Subscription {
  public play: string;
  public information: IInformation;
  public appointement: IAppointment;
  public eligTech: webShopEligibility.EligeTech;
  public technology: string;
  public schemeId: string;

  public setConfiguration(data: IGenericBoxConfiguration): void {
    super.setConfiguration(data);
    this.information = data.information || this.information;
    this.appointement = data.appointement || this.appointement;
    this.eligTech = data.eligTech || this.eligTech;
    this.technology = data.technology || this.technology;
    this.play = data.play || this.play;
    this.schemeId = data.schemeId || this.schemeId;
  }

  public getConfiguration(): IGenericBoxConfiguration {
    return <IGenericBoxConfiguration>{
      play: this.play,
      information: this.information,
      appointement: this.appointement,
      eligTech: this.eligTech,
      schemeId: this.schemeId,
      technology: this.technology,
      ...super.getConfiguration(),
    };
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }
}
