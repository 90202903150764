import { Scheme } from './scheme.class';
import { PandoraDocument } from './PandoraDocument.abstract';
import { Plan } from './catalog/products/subscription/plan';
import { SchemeHelper } from '@checkout/cart/scheme.helper';
import {
  CheckCanceled,
  DriverLicence,
  EDFInvoice,
  GrayCard,
  Id,
  IdPro,
  InsuranceCertificate,
  Passport,
  PassportPro,
  PhoneIvoice,
  Rent,
  RIB,
  TaxNotice,
  TitleOfStay,
  TitleOfStayPro,
} from './pandoraDocument';
import { SimReplace } from './catalog/products/equipement/sim/sim-replace';
import { IPandoraDocumentSerialize } from '@interfaces/IPandoraDocumentSerialize';
import { Customer } from '@checkout/cart/customer/customer';
import { CustomerCategory, CustomerType } from '@checkout/cart/customer/customer.interface';

export class PandoraDocumentsFactory {
  private scheme: Scheme;
  private customer: Customer;
  private isPro: boolean;

  constructor(scheme: Scheme, customer: Customer) {
    this.customer = customer;
    this.scheme = scheme;
    this.isPro = this.customer.category === CustomerCategory.pro;
  }
  public getIdentity(): IPandoraDocumentSerialize[] {
    if (
      !this.scheme.getProductByType(Plan) &&
      !this.scheme.hasEdp &&
      !this.scheme.hasRepriseMobile() &&
      !this.scheme.getProductByType(SimReplace)
    ) {
      return [];
    }

    if (this.customer.type === CustomerType.client) {
      const documents: PandoraDocument[] = [];
      documents.push(this.isPro ? new IdPro() : new Id());
      documents.push(this.isPro ? new PassportPro() : new Passport());
      documents.push(this.isPro ? new TitleOfStayPro() : new TitleOfStay());
      documents.push(new DriverLicence()); // Permis de conduire;
      return documents.map(x => x.serialize());
    }

    return [
      this.isPro ? new IdPro() : new Id(),
      this.isPro ? new PassportPro() : new Passport(),
      this.isPro ? new TitleOfStayPro() : new TitleOfStay(),
      new DriverLicence(),
    ].map(x => x.serialize());
  }

  public getIdentitySignature(): IPandoraDocumentSerialize[] {
    return [new Id(), new Passport(), new TitleOfStay()].map(x => x.serialize());
  }

  public getRib(): PandoraDocument {
    if (this.scheme.getProductByType(Plan)) {
      return new RIB();
    }
    return null;
  }

  public getBankCheckCanceled(): PandoraDocument {
    if (this.scheme.getProductByType(Plan)) {
      return new CheckCanceled();
    }
    return null;
  }

  public getProofOfAddress(): PandoraDocument[] {
    if (!SchemeHelper.hasSubscription(this.scheme)) {
      return [];
    }
    return [
      new Rent(),
      new GrayCard(),
      new EDFInvoice(),
      new PhoneIvoice(),
      new TaxNotice(),
      new InsuranceCertificate(),
    ];
  }
}
