import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { ConfigService } from '@services/config.service';
import { Oauth2Service } from '../../oauth2/oauth2.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ContractInterface } from '../../context/contract.interface';
import { FaiStepperService } from '@fai-widget/fai-stepper/fai-stepper.service';
import { ContextStorageService } from '../../context/context-storage.service';
import { AlertService } from '@services/alert.service';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FaiStorageService } from '@fai-widget/fai-storage.service';
import { ContextService } from '../../context/context.service';
import { CheckoutStorageService } from '@services/checkout-storage.service';
import { CartService } from '@services/cart.service';
import { Scheme } from '@model/scheme.class';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';
import { LogComponent } from '@components/log/log.component';
import { DebugTabService } from '@components/debug-tab/debug-tab.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { DialogComponent } from './dialog/dialog.component';
import { AppErrorCodes } from '@interfaces/cart.interface';
import { of, Subscription } from 'rxjs';
import { ComparatorService } from '@services/comparator.service';
import { IntraCommunicationService } from '@services/intra-communication.service';
import { Customer } from '@checkout/cart/customer/customer';
import { User } from '../../user/user';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { UserService } from '@services/user.service';
import { BrowseConfigService } from '../../context/browse-config.service';
import { TopBarService } from '@services/top-bar.service';
import { PrismeLogType } from '../../shared/prisme/prisme-log-type.enum';
import { PrismeLoggerService } from '../../shared/prisme/prisme-logger.service';
import { NgClass } from '@angular/common';
import { ToolLinksComponent } from './tool-links/tool-links.component';
import { ScanComponent } from './scan/scan.component';
import { AlertComponent } from '@components/alert/alert.component';
import { NotificationBoardComponent } from './notification-board/notification-board.component';
import { TelephonePipe } from '@base/pipes/telephone.pipe';
import { ScanditService } from '@services/scandit.service';
import { ScannerWrapperComponent } from '@components/scanner-wrapper/scanner-wrapper.component';
import { ScanditFieldInterface } from '@interfaces/scandit.interface';

@Component({
  selector: 'rcbt-top-bar',
  templateUrl: 'top-bar.component.html',
  standalone: true,
  styleUrls: ['./top-bar.component.scss'],
  imports: [
    NgClass,
    ToolLinksComponent,
    ScanComponent,
    AlertComponent,
    NotificationBoardComponent,
    TelephonePipe,
    ScannerWrapperComponent,
  ],
})
export class TopBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public isConsultAccessoryPricePage = false;
  @Input() public isEligStandalonePage = false;
  @Input() public isCustomWelcomePage = false;
  @Input() public onCustomRecapPage = false;
  @Input() public isSummaryPage = false;
  public customerHeader: Customer;
  public oauth2log: string;
  public contract: ContractInterface;
  public currentSchemeProductsCount: number;
  public cancelConfirmDisplay = false;
  public loading = false;
  public hideAccessoryPriceCancelButton = false;
  public isFaiScheme = false;
  public scanOpened = false;
  public rcbtScanOpened = false;
  public isCartdisplay = false;
  public activeDebugTab = false;
  public scanEnable = false;
  public cancelType: string;
  public isNotificationBoardButton = false;
  public nbNotificationBoardMessages = 0;
  public isNotificationBoardDisplay = false;
  public isNotificationBoardUpdated = false;
  public user: User;
  public shouldShowLogButton = false;
  public showSimulatorLink: boolean;
  public scanCode: string;
  public isOnTablet = false;

  private subscriptions: Subscription = new Subscription();
  private modalOptions = <NgbModalOptions>{
    backdrop: 'static',
    size: 'lg',
    backdropClass: 'cancel-confirm',
    windowClass: 'modal-size-xs',
    keyboard: false,
  };
  protected isDispatchPage = false;

  constructor(
    private oauth2: Oauth2Service,
    public cartService: CartService,
    private contextService: ContextService,
    private contextStorageService: ContextStorageService,
    private checkoutStorageService: CheckoutStorageService,
    private router: Router,
    private faiStorageService: FaiStorageService,
    private faiStepperService: FaiStepperService,
    private checkoutStepperService: CheckoutStepperService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private debugTabService: DebugTabService,
    private comparatorService: ComparatorService,
    private intraCommunicationService: IntraCommunicationService,
    private configService: ConfigService,
    private browseConfigService: BrowseConfigService,
    private customerService: CustomerService,
    private userService: UserService,
    private topBarService: TopBarService,
    private prismeLogger: PrismeLoggerService,
    private scanditService: ScanditService,
  ) {
    this._updateCartItemsCount(this.cartService.getCurrentScheme());
  }

  public ngOnInit(): void {
    const currentScheme = this.cartService.getCurrentScheme();
    this.initIsScanEnabled();
    this.oauth2log = `${this.oauth2.isConnected()} || ${this.oauth2.accessToken}`;
    this.customerHeader = this.customerService.customer;
    this.subscriptions.add(this.cartService.onChangesSchemes.subscribe(() => this.onChangesSchemes()));
    this.subscriptions.add(this.alertService.isLoading.subscribe((isLoading: boolean) => (this.loading = isLoading)));
    this.setIsCartDisplay();
    this.subscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.setIsCartDisplay();
        }
      }),
    );

    this.scanditService.openingScanForField.subscribe((data: ScanditFieldInterface) => {
      this.rcbtScanOpened = data?.openingScan;
    });

    this.subscriptions.add(this.intraCommunicationService.emitCancelCart.subscribe(() => this.doCancelCart()));
    this.subscriptions.add(this.intraCommunicationService.emitCancelScheme.subscribe(() => this.doCancelScheme()));

    if (['IHMSD', 'IHMPRIXACCESSOIRE'].includes(this.browseConfigService.browseConfig.browseActionType)) {
      this.hideAccessoryPriceCancelButton = this.browseConfigService.browseConfig.callBackUrl.indexOf('undefined') > -1;
    } else {
      this.isFaiScheme = currentScheme?.isAcquisitionFix();
    }

    if (currentScheme?.isRenew()) {
      this.contract = this.getContractByID(currentScheme.contractId);
    } else if (currentScheme?.isAcquisitionMobile()) {
      this.contract = this.getContractByID(currentScheme.contractId);
    } else {
      this.contract = this.customerService.customer.contracts[0];
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.scanOpened = false;
      }
    });
    this.genereCancelType();
    this.user = this.userService.user;
    this.shouldShowLogButton =
      !this.isEligStandalonePage &&
      !this.isConsultAccessoryPricePage &&
      !(this.isCustomWelcomePage || this.onCustomRecapPage);
    this.showSimulatorLink =
      this.configService.data.simulator?.active && !this.isCustomWelcomePage && !this.onCustomRecapPage;
    this.isOnTablet = this.scanditService.isOnTablet();
  }

  public ngOnChanges(): void {
    this.genereCancelType();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngDoCheck(): void {
    this.genereCancelType();
  }

  public openCategories(): void {
    if (
      this.isDispatchPage ||
      this.isSummaryPage ||
      this.isFaiScheme ||
      this.isEligStandalonePage ||
      !this.cartService.currentSchemeUniqueId
    ) {
      return;
    }
    this.router.navigate(this.contextService.getBaseRoute(this.cartService.getCurrentScheme().browseType));
  }

  public openCart(): void {
    if (this.loading) {
      return;
    }
    this.checkoutStepperService.reset();
    if (this.cartService.getCurrentScheme(false)) {
      this.router.navigate(['/panier']);
    } else {
      this.router.navigate(['/panier', 'recapitulatif']);
    }
  }

  /**
   * openFrontLog
   */
  public openFrontLog(): void {
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      size: 'lg',
      windowClass: 'log-details-modal',
      keyboard: false,
    };
    this.modalService.open(LogComponent, options);
  }

  public openMenu(): void {
    alert('en developpement');
  }

  public toggleErrorConsole(): void {
    this.activeDebugTab = !this.activeDebugTab;
    this.debugTabService.emitActivationDebug(this.activeDebugTab);
  }

  public gotoElig(): void {
    this.faiStorageService.clear();
    const eligUrl: string = window.location.origin + '/fai/eligibility?typeAction=ELIG';
    window.location.href = eligUrl;
  }

  public cancelAction(onCustomRecapPage?: boolean): void {
    this.genereCancelType();

    this.prismeLogger.sendDataToPrisme(PrismeLogType.customLog, {
      message: 'clic bouton annulation',
      type_event: 'click',
      step: 'ANNULATION' + this.cancelType,
    });

    if (this.cancelType === 'accessory') {
      return this.quitAccessoryPricePage();
    } else if (this.cancelType === 'cart') {
      return this.cancelCart(onCustomRecapPage);
    } else {
      return this.cancelScheme();
    }
  }

  public cancelCart(onCustomRecapPage?: boolean): void {
    if (this.isEligStandalonePage) {
      this.contextStorageService.clear();
      this.checkoutStorageService.clear();
      window.close();
    } else if (onCustomRecapPage) {
      const modal: NgbModalRef = this.modalService.open(DialogComponent, this.modalOptions);
      modal.componentInstance.message =
        "Si vous continuez, l'intégralité de votre " +
        '<span class="cart-wording">panier</span> sera supprimé.<br>Voulez-vous continuer ?';
      modal.componentInstance.onValidClick = (): void => {
        this.loading = true;
        const url = this.router.url.split('/') || '';
        this.subscriptions.add(
          this.cartService
            .deleteCartByCommandId(url[4], url[3], {
              statut: 'ANNULE',
            })
            .pipe(
              mergeMap(res => {
                window.location.href = location.origin + '/accap/welcome?typeAction=CUSTOM_WELCOME';
                return of({ requestOK: res });
              }),
            )
            .subscribe(),
        );
        modal.close();
      };
    } else {
      const modal: NgbModalRef = this.modalService.open(DialogComponent, this.modalOptions);
      modal.componentInstance.message =
        "Si vous continuez, l'intégralité de votre " +
        '<span class="cart-wording">panier</span> sera supprimé.<br>Voulez-vous continuer ?';
      modal.componentInstance.onValidClick = (): void => {
        modal.close();
        this.doCancelCart();
      };
    }
  }

  public cancelScheme(): void {
    const modal: NgbModalRef = this.modalService.open(DialogComponent, this.modalOptions);
    modal.componentInstance.message =
      'Si vous continuez, votre ' +
      '<span class="scheme-wording">acte</span> en cours sera supprimé.<br>Voulez-vous continuer ?';
    modal.componentInstance.onValidClick = (): void => {
      modal.close();
      this.doCancelScheme();
    };
  }

  public doCancelCart(): void {
    this.loading = true;
    this.cartService.cancelBasket(this.configService.isAccapActive()).subscribe();
  }

  public doCancelScheme(): void {
    this.comparatorService.reset();
    this.loading = true;

    this.cartService
      .addToObsQueue(
        this.cartService.deleteScheme(this.cartService.getCurrentScheme()).pipe(
          map(() => true),
          catchError(err => {
            if (err?.error?.code === AppErrorCodes.lockedCart) {
              this.contextStorageService.clear();
              this.checkoutStorageService.clear();
              window.location.href = this.browseConfigService.browseConfig.cancelCallBackUrl;
              throw err;
            }
            return of(false);
          }),
        ),
      )
      .pipe(
        tap(() => {
          this.faiStepperService.reset();
          this.faiStorageService.clear();
          this.checkoutStepperService.reset();
          this.checkoutStorageService.clear();
          this.router.navigate(['/dispatch']);
          this.loading = false;
        }),
      )
      .subscribe();
  }

  public changeNotificationBoardVisibility(): void {
    this.isNotificationBoardDisplay = !this.isNotificationBoardDisplay;
  }

  public recupNotificationBoardNbMsg(nbMsg: number): void {
    this.nbNotificationBoardMessages = nbMsg;
  }

  public recupNotificationBoardActive(active: boolean): void {
    this.isNotificationBoardButton = active;
  }

  public recupNotificationBoardUpdated(updated: boolean): void {
    this.isNotificationBoardUpdated = updated;
  }

  public logout(): void {
    return this.oauth2.logout();
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  public onScan(event: string): void {
    this.scanCode = event;
  }
  public onClose(): void {
    this.scanCode = '';
    this.rcbtScanOpened = false;
  }

  public changeScanStatus(status): void {
    this.rcbtScanOpened = this.scanOpened = status;
  }

  /**
   * [_updateCartItemsCount description]
   * @param {Scheme[]} schemes [description]
   */
  protected _updateCartItemsCount(scheme: Scheme): void {
    this.currentSchemeProductsCount = 0;
    if (!scheme) {
      return;
    }
    this.currentSchemeProductsCount += scheme.products.length;
  }

  protected onChangesSchemes(): void {
    const scheme: Scheme = this.cartService.getCurrentScheme();
    this._updateCartItemsCount(scheme);
    if (scheme) {
      this.isFaiScheme = scheme.isAcquisitionFix();
      this.customerHeader = this.customerService.customer;
    }
  }

  private quitAccessoryPricePage(): void {
    this.loading = true;
    window.location.href = this.browseConfigService.browseConfig.callBackUrl;
  }

  /**
   * Find contract by contractId
   * @param {number} contractId
   * @returns {ContractInterface}
   */
  private getContractByID(contractId: number): ContractInterface {
    if (this.customerService.customer) {
      return this.customerService.customer.contracts.find(function (contract) {
        return contract.id === contractId;
      });
    }
  }

  private genereCancelType(): void {
    if (this.isConsultAccessoryPricePage) {
      this.cancelType = 'accessory';
    } else if (this.isDispatchPage || this.isSummaryPage || !this.cartService.currentSchemeUniqueId) {
      this.cancelType = 'cart';
    } else {
      this.cancelType = 'other';
    }
  }

  private setIsCartDisplay(): void {
    if (
      (this.cartService.getCurrentScheme(false) && this.router.url.indexOf('recapitulatif') === -1) ||
      this.router.url.indexOf('dispatch') !== -1
    ) {
      this.isCartdisplay = true;
    } else {
      this.isCartdisplay = false;
    }
  }

  public navigateToSimulator(): void {
    const queryParams = this.customerService.mapToSimulatorCustomer();
    window.open(this.topBarService.getSimulatorUrl(queryParams), '_blank');
  }

  private isScanEnabled(): boolean {
    const currentScheme = this.cartService.getCurrentScheme();
    return !this.router.url.includes('/fai/') && (currentScheme?.isRenew() || !currentScheme?.hasReplaceSim());
  }

  private initIsScanEnabled(): void {
    this.scanEnable = this.isScanEnabled();
    this.subscriptions.add(
      this.cartService.afterRefresh.subscribe(() => {
        this.scanEnable = this.isScanEnabled();
      }),
    );
  }
}
