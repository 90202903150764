import { Observable, of as observableOf, Subscription as rxjsSubscription } from 'rxjs';
import { catchError, finalize, mergeMap, map, tap } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnInit, ElementRef, DoCheck, OnDestroy, viewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Oauth2RessourceService } from '../../../../oauth2/oauth2-resources.service';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { CartService } from '@services/cart.service';
import { CustomerService } from '../customer.service';
import { StepCustomerComponent } from '../step-customer.component';
import { CustomerCategory, CustomerStepEnum, CustomerType, LastStepEnum } from '../customer.interface';
import { GenericAddressForm, IGenericAddressParams } from './address.form.class';
import { GcpNs } from '../../../../address/interfaces/gcp';
import { PandoraDocument } from '@model/PandoraDocument.abstract';
import { PandoraDocumentsFactory } from '@model/pandoraDocument.factory';
import { Subscription } from '@model/catalog/products/subscription';
import { ControlGcp } from '../../../../context/child/customer.interface';
import { AppErrorCodes } from '@interfaces/cart.interface';
import { AlertService } from '@services/alert.service';
import { Customer } from '../customer';
import { BrowseConfigService } from '../../../../context/browse-config.service';
import { UserService } from '@services/user.service';
import { SchemeHelper } from '../../scheme.helper';
import { IAddressPro } from '@interfaces/screening.interface';
import { DematService } from '@services/demat.service';
import { AsteriskDirective } from '@base/directive/asterisk.directive';
import { NgClass } from '@angular/common';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';

interface IAddress {
  address?: string;
  numeroRue?: string;
  rue?: string;
  complementAdresse?: string;
  codePostal?: string;
  ville?: string;
}

@Component({
  selector: 'rcbt-cart-customer-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  standalone: true,
  imports: [NgClass, FormsModule, ReactiveFormsModule, AsteriskDirective, NgxGpAutocompleteModule],
})
export class AddressComponent extends StepCustomerComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {
  readonly address = viewChild<ElementRef>('address');
  readonly codePostal = viewChild<ElementRef>('codePostal');
  readonly ville = viewChild<ElementRef>('ville');
  readonly rue = viewChild<ElementRef>('rue');
  readonly complementAdresse = viewChild<ElementRef>('complementAdresse');
  readonly numeroRue = viewChild<ElementRef>('numeroRue');

  @Input() public isPro: boolean;
  public message = '';
  public addressDocuments: PandoraDocument[];
  public schemeHasSubscription: boolean;
  public isMandatory: boolean;
  public isAcquisitionFix = false;
  public addressParams: IGenericAddressParams;
  public needCheck = false;
  public isAuto = true;
  public documentForm: UntypedFormGroup;
  public customer: Customer;
  public isDisabled = false;
  public msgAddress = '';
  public instanceOfGenericForm: GenericAddressForm;
  public isGcpKO = false;
  public gcpKOCode: string;
  public addressForm: UntypedFormGroup;
  public addressModel: string;
  public options = { type: 'address', componentRestrictions: { country: 'FR' } };
  public googleIsLoaded = false;
  public errorGcp: string[] = ['1', '2', '-3', '-1', '-2', '-4', '-5', '-6', '9', '-100', '-200', '-201', '-202'];
  private subscription = new rxjsSubscription();
  private proAddress: IAddressPro;

  constructor(
    protected oauth2RessourceService: Oauth2RessourceService,
    protected stepperService: CheckoutStepperService,
    protected cartService: CartService,
    protected formBuilder: UntypedFormBuilder,
    protected alertService: AlertService,
    protected customerService: CustomerService,
    private browseConfigService: BrowseConfigService,
    protected userService: UserService,
    protected dematService: DematService,
  ) {
    super(oauth2RessourceService, cartService, customerService, userService);
    this.isAcquisitionFix = this.cartService.getCurrentScheme().isAcquisitionFix();
    this.addressDocuments = new PandoraDocumentsFactory(
      this.cartService.getCurrentScheme(),
      this.customerService.customer,
    ).getProofOfAddress();
    this.schemeHasSubscription = !!this.cartService.getCurrentScheme().getProductByType(Subscription);
    this.customer = this.customerService.customer;
  }

  public ngOnInit(): void {
    this.isSaleOnly =
      !SchemeHelper.hasPlan(this.cartService.getCurrentScheme()) ||
      SchemeHelper.isPrepaid(this.cartService.getCurrentScheme());
    window.scrollTo(0, 0);

    this.isDisabled = this.shouldAddressBeDisabled() || this.customerService.shouldForceDisabledAddress;

    if (SchemeHelper.hasPlan(this.cartService.getCurrentScheme())) {
      this.needCheck = true;
    }
    this.isMandatory = this.cartService.getCurrentScheme().isAcquisitionFix();

    this.setAddressParams();
    this.subscription.add(
      this.customerService.addressProEvent.subscribe(proAddress => {
        this.proAddress = proAddress;
        this.setAddressParams();
        this.initFormData();
      }),
    );
    this.populateDocuments();
    this.googleIsLoaded = true;
    this.instanceOfGenericForm = new GenericAddressForm(this.formBuilder, this.cartService, this.isDisabled);
    this.initFormData();
    this.subscription.add(
      this.customerService.forceDisableAddress.subscribe((shouldDisabled: boolean) => {
        this.isDisabled = shouldDisabled;
        this.instanceOfGenericForm = new GenericAddressForm(this.formBuilder, this.cartService, this.isDisabled);
      }),
    );
  }

  private initFormData(): void {
    this.addressForm = this.instanceOfGenericForm.build(this.addressParams);
    this.addressModel = this.instanceOfGenericForm.getAddressText();
    this.addressForm.updateValueAndValidity();
  }

  public ngAfterViewInit(): void {
    this.customerService.changeValidityStep(CustomerStepEnum.address, !this.submitDisabled());
    this.subscription.add(
      this.addressForm.valueChanges.subscribe(() => {
        this.isSubmitted = false;
        this.customerService.changeValidityStep(CustomerStepEnum.address, !this.submitDisabled());
      }),
    );
    this.subscription.add(
      this.dematService.mapScannedDataEvent.subscribe(() => {
        this.update();
      }),
    );
  }

  public ngDoCheck(): void {
    this.stepperService.valideSubmit.emit();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onAddressSubmit(): Observable<boolean> {
    this.customerService.setIsLoading(true, 'Address');
    return this.onBillingAddressSubmit().pipe(
      mergeMap(() => {
        if (
          !SchemeHelper.hasPlan(this.cartService.getCurrentScheme()) ||
          SchemeHelper.isPrepaid(this.cartService.getCurrentScheme())
        ) {
          if (this.cartService.getCurrentScheme().hasRepriseMobile()) {
            return this.orderRepriseMobile();
          } else {
            this.isSubmitted = true;
            return observableOf(true);
          }
        } else {
          this.isSubmitted = true;
          return observableOf(true);
        }
      }),
      mergeMap(data => {
        if (
          this.addressForm.value.codePostal &&
          (!this.addressForm.value.rue || this.addressForm.value.rue.length < 3)
        ) {
          this.message = 'Adresse non valide';
          this.isDisabled = false;
          setTimeout(() => this.addressForm.enable());
          return observableOf(false);
        }
        if (this.customerService.nbSteps === LastStepEnum.address) {
          this.isSubmitted = true;
          return observableOf(true);
        }

        if (this.gcpKOCode !== null && this.errorGcp.indexOf(this.gcpKOCode) !== -1) {
          this.message =
            this.gcpKOCode === '-11'
              ? 'Adresse non valide'
              : "L'adresse saisie n'est pas reconnue des systèmes, " + 'veuillez choisir un justificatif de domicile.';
        }

        // NEED FORCE passage if gcp KO. this.billingComponent.address.gcpKOCode === null
        const force = true;
        if (
          force ||
          this.documentForm.value.documentAdresse ||
          this.customerService.customer.type === CustomerType.client
        ) {
          if (this.isActive) {
            this.customerService.changeCustomerStep({ step: CustomerStepEnum.payment, ignoreIsLoading: true });
          }
          return observableOf(true);
        }
      }),
      finalize(() => this.customerService.setIsLoading(false, 'Address')),
      catchError(err => {
        console.warn(err);
        this.customerService.setIsLoading(false, 'Address');
        return observableOf(false);
      }),
    );
  }

  public submit(): Observable<boolean> {
    return this.onAddressSubmit();
  }

  public valideSubmit(): boolean {
    if (!this.isSaleOnly) {
      return !this.submitDisabled() && this.isSubmitted;
    }
    return !this.submitDisabled();
  }

  public submitDisabled(): boolean {
    let resultIsDisabled = false;
    resultIsDisabled = this.checkButtonDisabled();
    this.customerService.changeValidityStep(CustomerStepEnum.address, !resultIsDisabled);
    this.setMsgAddress(resultIsDisabled);
    return resultIsDisabled;
  }

  public update(): void {
    this.setAddressParams();
    this.addressForm = this.instanceOfGenericForm.build(this.addressParams);
    this.submitDisabled();
  }

  public onBillingAddressSubmit(): Observable<GcpNs.IGcp> {
    return this.onGenericAddressSubmit().pipe(
      mergeMap((result: GcpNs.IGcp) => {
        let control: ControlGcp;
        if (this.isGcpKO) {
          if (
            this.gcpKOCode !== null &&
            this.errorGcp.indexOf(this.gcpKOCode) &&
            this.customerService.customer.type === CustomerType.prospect
          ) {
            control = {
              retour: 'KO',
              motif: 'KO_GCP',
              decisionPrise: this.gcpKOCode === '-11' ? 'Adresse non valide' : 'forcé avec scan justif domicile',
              codeRetour: this.gcpKOCode,
            };
          } else {
            control = { retour: 'KO_TECHNIQUE' };
          }
        } else {
          control = { retour: 'OK' };
        }
        return this.customerService
          .updateCustomer(
            {
              ...Object.assign(
                this.addressForm.value,
                this.documentForm && this.documentForm.value && this.documentForm.value.documentAdresse
                  ? this.documentForm.value
                  : { documentAdresse: null },
              ),
              controlAddress: control,
            },
            this.browseConfigService.browseConfig,
            this.cartService.getCurrentScheme(),
            this.cartService.cart.schemes,
          )
          .pipe(
            mergeMap(() => this.cartService.updateScheme()),
            tap(
              res => res,
              e => {
                if (e.error && e.error.code === AppErrorCodes.shopdetail) {
                  this.alertService.errorEmitter.next(
                    'Impossible de récupérer les informations de la boutique, veuillez réessayer.',
                  );
                }
              },
            ),
            map((res: boolean) => result),
          );
      }),
    );
  }

  public onGenericAddressSubmit(): Observable<GcpNs.IGcp> {
    this.message = '';
    this.gcpKOCode = null;
    if (this.addressForm.valid || this.addressForm.disabled || this.needCheck) {
      this.customerService.setIsLoading(true, 'GAddress');
      return this.gcpCheck(this.isGcpKO || this.needCheck, this.addressForm.getRawValue()).pipe(
        map((data: GcpNs.IGcp) => {
          if (data.codeRetour && this.errorGcp.indexOf(data.codeRetour.codeRetour) !== -1) {
            this.isGcpKO = true; // kind of error
            this.gcpKOCode = data.codeRetour.codeRetour;
            this.isAuto = false;
            return data;
          } else {
            this.gcpToForm(data, this.addressForm.controls.complementAdresse.value);
            return data;
          }
        }),
        catchError(e => {
          this.isGcpKO = true;
          this.isAuto = false;
          if (e.error && this.errorGcp.indexOf(e.error.codeRetour) !== -1) {
            this.gcpKOCode = e.error.codeRetour;
          }
          return observableOf(this.getGcpData(this.addressForm.getRawValue()));
        }),
        finalize(() => this.customerService.setIsLoading(false, 'GAddress')),
      );
    } else {
      return observableOf(null);
    }
  }

  public getAddress(place: google.maps.places.PlaceResult): void {
    const numeroRue = place.address_components.find(x => x.types.includes('street_number'));
    const rue = place.address_components.find(x => x.types.includes('route'));
    const rueSansNumero = place.address_components.find(x => x.types.includes('neighborhood'));
    const codePostal = place.address_components.find(x => x.types.includes('postal_code'));
    const ville = place.address_components.find(x => x.types.includes('locality'));

    const address: IAddress = {
      address: place.formatted_address,
      complementAdresse: this.complementAdresse().nativeElement.value,
      codePostal: codePostal ? codePostal.short_name : undefined,
      ville: ville ? ville.short_name : undefined,
    };
    if (numeroRue?.short_name && rue?.short_name) {
      address.numeroRue = numeroRue.short_name;
      address.rue = rue.short_name;
    } else if (rueSansNumero?.short_name || rue?.short_name) {
      address.numeroRue = '';
      address.rue = rueSansNumero?.short_name ?? rue?.short_name;
    }
    this.addressForm.patchValue(address);
  }

  public resetAddress(): void {
    this.addressForm.patchValue({
      address: '',
      numeroRue: '',
      rue: '',
      complementAdresse: '',
      codePostal: '',
      ville: '',
    });
  }

  private gcpToForm(data: GcpNs.IGcp, address2?: string): void {
    data.complement = data.complement || address2 || '';
    const patchValue = {
      numeroRue: data.numero,
      complementAdresse: data.complement,
      rue: data.voie,
      codePostal: data.codePostal,
      ville: data.ville,
    };
    this.addressForm.patchValue(patchValue);
  }

  private gcpCheck(force: boolean, arg: IAddress): Observable<GcpNs.IGcp> {
    if (force) {
      return this.oauth2RessourceService.ventes().setLocalService().useSalesApi().gcp().post(this.getGcpData(arg));
    }
    return observableOf(this.getGcpData(arg, true));
  }

  private getGcpData(arg: IAddress, previousControl: boolean = false): GcpNs.IGcp {
    return {
      numero: arg.numeroRue ? arg.numeroRue : '',
      voie: arg.rue,
      complement: arg.complementAdresse || '',
      codePostal: arg.codePostal,
      ville: arg.ville,
      origineST: 'LABOUTIQUEBYTEL',
      origineIP: '127.0.0.1',
      codeRetour: previousControl ? { codeRetour: this.customerService.customer.controlAddress?.codeRetour } : null,
    };
  }

  private checkButtonDisabled(): boolean {
    if (!this.isAuto && this.cartService.checkValidityAddress(this.addressForm)) {
      const streetNumber = this.addressForm.controls['numeroRue']?.value;
      const street = this.addressForm.controls['rue']?.value;
      const additionalInfo = this.addressForm.controls['complementAdresse']?.value;
      const postalCode = this.addressForm.controls['codePostal']?.value;
      const city = this.addressForm.controls['ville']?.value;
      const address = `${streetNumber} ${street} ${additionalInfo} ${postalCode} ${city}`;
      this.addressForm.controls['address']?.setValue(address.replace(/\s+/g, ' ').trim());
    }

    if (this.documentForm && this.addressForm) {
      return !(this.addressForm.valid || this.addressForm.disabled);
    } else {
      if (!this.isDisabled && this.cartService.getCurrentScheme().hasRepriseMobile()) {
        if (!this.addressForm || (!this.addressForm.disabled && !this.addressForm.valid)) {
          return true;
        }
      }
    }
    return false;
  }

  private setMsgAddress(disabled: boolean): void {
    if (disabled) {
      if (
        this.isDisabled &&
        this.msgAddress === '' &&
        this.customer.isClient &&
        this.customer.category === CustomerCategory.pro
      ) {
        this.isDisabled = false;
        if (this.addressForm) {
          setTimeout(() => this.addressForm.enable());
        }
        this.msgAddress = "Adresse pré-remplie incorrecte. Veuillez renseigner la bonne adresse pour passer l'étape.";
      }
    } else if (this.msgAddress !== '') {
      this.msgAddress = '';
    }
  }

  private setAddressParams(): void {
    if (this.proAddress) {
      this.addressParams = <IGenericAddressParams>{
        streetNumber: this.proAddress.num,
        street: this.proAddress.voie,
        city: this.proAddress.ville,
        postcode: this.proAddress.cp,
      };
    } else {
      this.addressParams = <IGenericAddressParams>{
        streetNumber: this.customer.streetNumber,
        street: this.customer.street,
        city: this.customer.city,
        postcode: this.customer.postcode,
        street2: this.customer.street2,
      };
    }
  }

  private populateDocuments(): void {
    let existingDocument;
    if (this.customer && this.customer.addressDocument && this.customer.addressDocument.name) {
      existingDocument = this.addressDocuments.find(doc => doc.name === this.customer.addressDocument.name);
    }
    if (this.schemeHasSubscription) {
      this.documentForm = this.formBuilder.group({
        documentAdresse: [existingDocument, []],
      });
      this.documentForm.patchValue({ documentAdresse: existingDocument });
    }
  }
}
