import {
  forkJoin as observableForkJoin,
  forkJoin,
  from as observableFrom,
  Observable,
  Observer,
  of,
  of as observableOf,
  Subject,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, publishLast, refCount, switchMap, tap } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { Product } from '@model/catalog/products/product';
import { ContextStorageService } from '../context/context-storage.service';
import { Scheme } from '@model/scheme.class';
import { CheckoutStorageService } from '@services/checkout-storage.service';
import { SchemeSerialized } from '@interfaces/scheme.interface';
import { StockService, TypesStock } from '@services/stock.service';
import { ProductsService } from '@services/products.service';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { AppErrorCodes, CartSerialized } from '@interfaces/cart.interface';
import { IProductConfiguration } from '@model/catalog/products/IProductConfiguration';
import { AlertService } from '@services/alert.service';
import { PromotionsService } from '@promotions/promotionsService';
import { JsonCatalog, JsonProduct } from '@model/catalog/products/interface/context';
import { Promotion } from '@promotions/promotion.class';
import { PromoActionTypes, PromotionTypes } from '@promotions/promotions.interfaces';
import { PromotionFactory } from '@promotions/promotionFactory.class';
import { ProductSerialized } from '@model/catalog/products/interface/configurable';
import { Agility } from '@promotions/promotion/agility.class';
import { AgilityCa } from '@promotions/promotion/agilityCa.class';
import { Plan } from '@model/catalog/products/subscription/plan';
import { Manual } from '@promotions/promotion/manual.class';
import { Phone } from '@model/catalog/products/equipement/complex/phone';
import { ICartHasReservedProdResponse, StockType } from '@interfaces/types';
import { ModalProductUnreservationComponent } from '@components/top-bar/scan/form-scan/modal-product-unreservation/modal-product-unreservation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  PromotionalEligibilityInputCartDto,
  PromotionalEligibilityInputDto,
  PromotionalEligibilityInputProductDto,
  PromotionalEligibilityInputSchemeDto,
  TypeParcoursEnums,
} from '../constants/promotional-eligibility-input.dto';
import {
  PromotionalEligibilityProductDto,
  PromotionalEligibilityPromotionExtV2Dto,
  PromotionalEligibilityPromotionsDto,
  PromotionalEligibilityResultDto,
} from '@model/promotional-eligibility-output.dto';
import { SchemeHelper } from '@checkout/cart/scheme.helper';
import { CaProduct } from '@model/catalog/products/caProduct/caProduct';
import { IPlanConfiguration } from '@model/catalog/products/subscription/IPlanConfiguration';
import { InsuranceConfig } from '../partner/partner.dto';
import { FundingEnum, FundingStorageInterface, IPostFundingResponse } from '@interfaces/funding.interface';
import { FundingStorageService } from '../fundings/services/funding-storage.service';
import { FundingService } from '../fundings/services/funding.service';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { Cart } from '@checkout/cart/cart';
import { SchemeService } from '@services/scheme.service';
import { RenewService } from '@services/renew.service';
import { BrowseConfigService } from '../context/browse-config.service';
import { UserService } from '@services/user.service';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { IDematResponse } from '@interfaces/justificatory.interface';
import { BasicObject } from '@base/base.interfaces';
import { PromoRenewService } from '@services/promo-renew.service';
import { UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

export enum PaymentMode {
  credit = 'credit',
  edp = 'edp',
  cash = 'cash',
}
@Injectable({
  providedIn: 'root',
})
export class CartService {
  public currentSchemeUniqueId: string;
  public isEligibleEdp = true;
  public currentSave: Observable<boolean> = observableFrom([true]);
  public onChangesSchemes = new Subject<Scheme[]>();
  public onAddProduct = new Subject<ProductSerialized>();
  public beforeRefresh = new Subject<Product>();
  public afterRefresh = new Subject<Product>();
  public onChangesVoips = new Subject();
  public modifiedOffer: EventEmitter<boolean> = new EventEmitter<boolean>();
  public plansConfig: { [productId: number]: IPlanConfiguration } = {};
  public schemeInsurances: { [quoteId: number]: InsuranceConfig[] } = {};
  public stateSelectedFundingMode: EventEmitter<void> = new EventEmitter<void>();
  public readonly cart: Cart = new Cart();

  constructor(
    protected checkoutStorageService: CheckoutStorageService,
    public productsService: ProductsService,
    protected oauth2Resource: Oauth2RessourceService,
    protected stockService: StockService,
    protected alertService: AlertService,
    protected promotionsService: PromotionsService,
    private modalService: NgbModal,
    private fundingStorageService: FundingStorageService,
    private fundingService: FundingService,
    private contextStorageService: ContextStorageService,
    private globalLoaderService: GlobalLoaderService,
    private schemeService: SchemeService,
    private renewService: RenewService,
    private browseConfigService: BrowseConfigService,
    private userService: UserService,
    private customerService: CustomerService,
    private promoRenewService: PromoRenewService,
  ) {
    this.onAddProduct = new Subject<ProductSerialized>();
    this.beforeRefresh = new Subject<Product>();
    this.afterRefresh = new Subject<Product>();
    this.onAddProduct.subscribe((product: ProductSerialized) => {
      this.alertService.successEmitter.next(`L'article "${product.data.name}" a été ajouté au panier`);
    });
    this.beforeRefresh.subscribe(() => this.handleBeforeRefresh());
    this.afterRefresh.subscribe(() => this.handleAfterRefresh());
    this.fundingService.switchToCash$
      .pipe(
        switchMap(() => {
          this.setBasketToCashFundingMode();
          return this.fundingService.postFundingMode(this);
        }),
      )
      .subscribe();
    this.fundingService.stateEligibleFunding.subscribe(() => this.handleEligibleFundingChanges());
  }

  public unserialize(cartData: CartSerialized): void {
    this.cart.cartId = cartData.idPanier;
    this.cart.personId = Number(cartData.personId);
    this.cart.dematId = cartData.idDossier;
    this.cart.consent = cartData.consent;
    this.cart.tokenFolder = cartData.dossierToken;
    this.cart.encryptionKey = cartData.cleDeCryptage;
    this.cart.contractSigned = cartData.contratSigne;
    this.cart.orcomId = cartData.idCommande;
    this.cart.channel = cartData.canal;
    this.cart.cartOrderId = cartData.idPanierCommande;
    this.cart.contractPrinted = cartData.contractPrinted;
    this.cart.totals = cartData.totals;
    this.cart.caTotals = cartData.caTotals;
    this.cart.bytelTotals = cartData.bytelTotals;
    this.cart.promotions = PromotionFactory.createCollection(cartData.promotions);
    this.promoRenewService.unserialize();
    this.cart.schemes = [];
    cartData.parcours.forEach((schemeSerialized: SchemeSerialized) => {
      this.cart.schemes.push(this.schemeService.unserialize(schemeSerialized));
    });
    this.selectCurrentScheme(cartData.parcourCourant);
    this.checkoutStorageService.setItem(this.checkoutStorageService.key['cart'], cartData);
    if (this.getCurrentScheme()) {
      this.onChangesSchemes.next(this.cart.schemes);
      this.checkoutStorageService.unserializeSchemeInsurances(this);
      this.setFundingMode(this.fundingStorageService.unserializeFundingMode());
    }
    this.applyRenewPromotions();
  }

  public selectCurrentScheme(uniqueId: string): void {
    this.currentSchemeUniqueId = uniqueId;
    this.emit(this.cart.schemes);
  }

  public getCurrentScheme(firstSchemeDefault: boolean = true): Scheme {
    const curScheme: Scheme = this.cart.schemes.find(
      (scheme: Scheme) => scheme.uniqueId === this.currentSchemeUniqueId && !scheme.verrouiller,
    );
    return firstSchemeDefault ? (curScheme ?? this.cart.schemes[0]) : curScheme;
  }

  public hasPlanInCurrentScheme(): boolean {
    return SchemeHelper.hasPlan(this.getCurrentScheme());
  }

  public unlockSchemeById(schemeId: string): void {
    const currentScheme: Scheme = this.getSchemeById(schemeId);
    if (currentScheme) {
      currentScheme.verrouiller = false;
    }
  }

  public getDossier(
    params: { [index: string]: string | number } = {},
  ): Observable<{ dematId: string; encryptionKey: string }> {
    if (this.cart.dematId) {
      return observableOf({ dematId: this.cart.dematId, encryptionKey: this.cart.encryptionKey });
    }
    return this.oauth2Resource
      .setLocalService(true)
      .useSalesApi()
      .ventes()
      .panier(this.cart.cartId)
      .dossier()
      .setParams(params)
      .get()
      .pipe(
        tap(res => {
          this.cart.dematId = res.idDemat;
          this.cart.encryptionKey = res.cleDeCryptage;
        }),
      );
  }

  public remplirDossier(): Observable<{ dematId: string; encryptionKey: string }> {
    return this.oauth2Resource
      .setLocalService(true)
      .useSalesApi()
      .ventes()
      .panier(this.cart.cartId)
      .dossier()
      .put({})
      .pipe(
        tap(res => {
          this.cart.dematId = res.idDemat;
          this.cart.encryptionKey = res.cleDeCryptage;
        }),
      );
  }

  public createCart(params: CartSerialized = <CartSerialized>{}): Observable<CartSerialized> {
    return this.oauth2Resource
      .ventes()
      .panier()
      .setLocalService()
      .useSalesApi()
      .post(params)
      .pipe(
        tap((cartSerialized: CartSerialized) => {
          this.cart.cartId = cartSerialized.idPanier;
          this.cart.cartOrderId = cartSerialized.idPanierCommande;
          this.save();
        }),
      );
  }

  public deleteScheme(scheme: Scheme): Observable<void> {
    return this.oauth2Resource
      .setLocalService()
      .useSalesApi()
      .ventes()
      .panier(this.cart.cartId)
      .parcours(scheme.quoteId)
      .delete()
      .pipe(
        tap(() => (this.cart.schemes = this.cart.schemes.filter(schemeFilter => schemeFilter !== scheme))),
        tap(() => this.onChangesSchemes.next(this.cart.schemes)),
        map(() => null),
      );
  }

  public deleteCartByCommandId(idPanier: string, carOrderId: string, body: { statut: string }): Observable<boolean> {
    return this.deleteAccapCart(idPanier).pipe(
      concatMap(() => this.deleteCartFromSaleApiByCommandId(idPanier)),
      concatMap(() => this.updateOrderCartStatus(carOrderId, body)),
    );
  }

  private deleteAccapCart(idPanier?: string): Observable<Response[]> {
    return this.oauth2Resource.ventes().panier(idPanier).setLocalService().useSalesApi().commande().delete();
  }

  private deleteCartFromSaleApiByCommandId(idPanier?: string): Observable<boolean> {
    return this.oauth2Resource.ventes().panier(idPanier).setLocalService().useSalesApi().delete();
  }

  private updateOrderCartStatus(cartOrderId: string, body: { statut: string }): Observable<boolean> {
    return this.oauth2Resource
      .paniersCommande(cartOrderId)
      .setLocalService()
      .useSalesApi()
      .put(body)
      .pipe(map(() => true));
  }

  public updateScheme(scheme: Scheme = this.getCurrentScheme()): Observable<boolean> {
    return this.oauth2Resource
      .setLocalService()
      .useSalesApi()
      .ventes()
      .panier(this.cart.cartId)
      .parcours(scheme.quoteId)
      .put(this.schemeService.serialize(scheme))
      .pipe(
        tap(() => this.onChangesSchemes.next(this.cart.schemes)),
        map(() => true),
      );
  }

  public updateSchemes(): Observable<boolean> {
    const updateSchemesObs: Observable<void>[] = [];
    this.cart.schemes.forEach((s: Scheme) => {
      updateSchemesObs.push(
        this.oauth2Resource
          .setLocalService()
          .useSalesApi()
          .ventes()
          .panier(this.cart.cartId)
          .parcours(s.quoteId)
          .put(this.schemeService.serialize(s)),
      );
    });
    return forkJoin(updateSchemesObs).pipe(
      tap(() => this.onChangesSchemes.next(this.cart.schemes)),
      map(() => true),
    );
  }

  public updateCart(): Observable<boolean> {
    return this.oauth2Resource
      .setLocalService()
      .useSalesApi()
      .ventes()
      .panier(this.cart.cartId)
      .put(this.serialize())
      .pipe(map(() => true));
  }

  public updateItem(product: Product, data: IProductConfiguration): void {
    product.setConfiguration(data);
    this.emit(this.cart.schemes);
  }

  public add(
    product: Product,
    qty: number = 1,
    force: boolean = false,
    schemeUniqueId?: string,
    save: boolean = true,
  ): Observable<boolean> {
    const scheme: Scheme = schemeUniqueId ? this.getSchemeById(schemeUniqueId) : this.getCurrentScheme();
    if (!(scheme instanceof Scheme)) {
      throw new Error("Aucun parcours disponible, impossible d'ajouter le produit !");
    }
    return this._performAdd(product, qty, force, scheme, save);
  }

  /**
   * A terme, quand on aura décom le /produit tout les ajouts de produits passeront par cette méthode
   * cette remplacera la méthode add
   * @param body
   * @param force
   */
  public addProduct(body = {}, force: boolean = false): Observable<ProductSerialized> {
    const scheme: Scheme = this.getCurrentScheme();
    if (!(scheme instanceof Scheme)) {
      throw new Error("Aucun parcours disponible, impossible d'ajouter le produit !");
    }
    return this.oauth2Resource
      .setLocalService()
      .ventes()
      .panier(this.cart.cartId)
      .parcours(scheme.quoteId)
      .produits()
      .useSalesApi()
      .setParams({ force: Number(force) })
      .post(body)
      .pipe(
        tap(
          (productSerialized: ProductSerialized) => {
            this.onAddProduct.next(productSerialized);
          },
          (exception: HttpErrorResponse) => {
            // renvoyer l'erreur du back
            throw exception.error;
          },
        ),
      );
  }

  public removeAllProducts(productsToKeep: Product[] = []): Observable<boolean> {
    const scheme = this.getSchemeById(this.currentSchemeUniqueId);
    return this.removeProductsRecur(scheme, productsToKeep);
  }

  public addToObsQueue(obs: Observable<boolean>): Observable<boolean> {
    // Save current obs
    const copyCurrentSave: Observable<boolean> = this.currentSave;
    // Change current obs
    this.currentSave = new Observable((observer: Observer<boolean>) => {
      // Wait last obs
      copyCurrentSave.subscribe(() => {
        obs.subscribe(
          () => {
            observer.next(null);
            observer.complete();
          },
          () => {
            observer.next(null);
            observer.complete();
          },
        );
      });
    }).pipe(publishLast(), refCount());
    return this.currentSave;
  }

  public delete(cartId?: number): Observable<Response[]> {
    return this.oauth2Resource
      .ventes()
      .panier(cartId ? cartId : this.cart.cartId)
      .setLocalService()
      .useSalesApi()
      .delete();
  }

  /**
   * Returns an object with two proprties quantity and gencode
   *
   * @param  {string} id imei or gencode
   * @return {Observable<StockType>}
   */
  public getItemFromStockOrReservedProducts(
    scanCode: string,
    type?: string,
    checkCatalog: boolean = true,
  ): Observable<StockType[]> {
    return this.stockService
      .getStockItemById(scanCode, type)
      .pipe(mergeMap(res => this.analyseCheckStock(res, checkCatalog, scanCode)));
  }

  public getItemFromStockForStandalone(scanCode: string, type?: string): Observable<StockType[]> {
    return this.stockService
      .getStockItemById(scanCode, type)
      .pipe(mergeMap(res => this.analyseCheckStockCheckCatalog(res)));
  }

  public getCurrentCart(): Observable<CartSerialized> {
    return this.oauth2Resource.setLocalService().ventes().panier(this.cart.cartId).useSalesApi().get();
  }

  public demat(
    action?: string,
    isAutoComplete: boolean = false,
    nbOthersDoc?: number,
    autoRedirect?: boolean,
  ): Observable<IDematResponse> {
    action = action || '';
    const callbackUrl = isAutoComplete
      ? `${window.origin}/panier/titulaire`
      : `${window.origin}/panier/pieces-justificatives-v2`;

    const hasCreditString: string = !!this.cart.creditData ? 'YC' : '';
    return this.oauth2Resource
      .ventes()
      .setLocalService()
      .useSalesApi()
      .panier(this.cart.cartId)
      .demat(
        action,
        callbackUrl,
        this.fundingService.hasGrantedCredit() ? 'YC_GRANTED' : hasCreditString,
        nbOthersDoc,
        autoRedirect,
      )
      .get();
  }

  /**
   * todo voir si on peut supprimer le paramètre override
   * @param product
   * @param schemeUniqueId
   * @param override
   */
  public update(product: Product, schemeUniqueId: string, override = {}): Observable<BasicObject> {
    const product_copy = Object.assign(product.serialize(), override);
    if (product?.gencode === 'RMBC000000') {
      delete product_copy.prix;
    }
    delete product_copy.secondOffersIds;
    return this.oauth2Resource
      .setLocalService()
      .ventes()
      .panier(this.cart.cartId)
      .parcours(this.getSchemeById(schemeUniqueId).quoteId)
      .produits(product.itemId)
      .useSalesApi()
      .put(product_copy);
  }

  public remove(productUniqueId: string, schemeUniqueId: string): Observable<boolean> {
    const scheme: Scheme = this.getSchemeById(schemeUniqueId);
    if (!(scheme instanceof Scheme)) {
      throw new Error('Aucun parcours disponible, impossible de supprimer le produit !');
    }

    const product: Product = this.getSchemeProductById(scheme, productUniqueId);
    if (!product) {
      return observableOf(true);
    }

    let productsToDelete: Product[] = scheme.rules.getRelatedProductToDelete(scheme, product);
    // only in backend, delete conflicted products except main product
    productsToDelete = productsToDelete.filter(
      (productToDelete: Product) => productToDelete.uniqueId !== product.uniqueId,
    );

    return this._backendRemove(product, scheme).pipe(
      tap(() => {
        productsToDelete
          .filter(productToDelete => productToDelete.uniqueId !== product.uniqueId)
          .forEach((productToDelete: Product) => scheme.remove(productToDelete.uniqueId));
        scheme.remove(productUniqueId);
      }),
      map(() => null),
    );
  }

  public getSchemeById(uniqueId: string): Scheme {
    return this.cart.schemes && this.cart.schemes.find((scheme: Scheme) => scheme.uniqueId === uniqueId);
  }

  public getSchemeByQuoteId(quoteId: number): Scheme {
    return this.cart.schemes && this.cart.schemes.find((scheme: Scheme) => scheme.quoteId === quoteId);
  }

  public getSchemeProductById(scheme: Scheme, productUniqueId: string): Product {
    return scheme && scheme.products.find(obj => obj.uniqueId === productUniqueId);
  }

  public getMainScheme(): Scheme {
    return this.cart.schemes && this.cart.schemes.length > 0 ? this.cart.schemes[this.cart.schemes.length - 1] : null;
  }

  public serialize(): CartSerialized {
    const schemesSerialized: SchemeSerialized[] = [];
    for (const key in this.cart.schemes) {
      schemesSerialized.push(this.schemeService.serialize(this.cart.schemes[key]));
    }
    const currentScheme: Scheme = this.getCurrentScheme();
    return {
      canal: this.cart.channel || null,
      consent: this.cart.consent,
      parcourCourant: currentScheme ? currentScheme._uniqueId : null,
      parcours: schemesSerialized,
      idPanier: this.cart.cartId || null,
      idPanierCrypte: this.cart.cartEncryptedId,
      idDossier: this.cart.dematId || null,
      dossierToken: this.cart.tokenFolder || null,
      cleDeCryptage: this.cart.encryptionKey || null,
      idCommande: this.cart.orcomId || null,
      contratSigne: this.cart.contractSigned || null,
      idPanierCommande: this.cart.cartOrderId || null,
      totals: this.cart.totals,
      bytelTotals: this.cart.bytelTotals,
      caTotals: this.cart.caTotals,
      promotions: this.cart.promotions,
      contractPrinted: this.cart.contractPrinted,
      personId: this.cart.personId ? this.cart.personId.toString() : undefined,
    };
  }

  public save(): this {
    this.checkoutStorageService.setItem(this.checkoutStorageService.key['cart'], this.serialize());
    return this;
  }

  public lockScheme(quoteId: number): Observable<boolean> {
    return this.oauth2Resource
      .ventes()
      .panier(this.cart.cartId)
      .parcours(quoteId)
      .lock()
      .useSalesApi()
      .setLocalService()
      .post({});
  }

  public unlockScheme(quoteId: number): Observable<boolean> {
    return this.oauth2Resource
      .ventes()
      .panier(this.cart.cartId)
      .parcours(quoteId)
      .lock()
      .useSalesApi()
      .setLocalService()
      .delete();
  }

  /**
   *
   * @param typeProduct
   * @returns {T[]}
   */
  public getProductsByType<T extends Product>(typeProduct: typeof Product): T[] {
    let items: T[] = [];
    for (const scheme of this.cart.schemes) {
      items = items.concat(scheme.getProductsByType<T>(typeProduct));
    }
    return items;
  }

  public getAllProducts(): Product[] {
    let items: Product[] = [];
    for (const scheme of this.cart.schemes) {
      items = items.concat(scheme.products);
    }
    return items;
  }

  public getAgility(): Agility {
    return <Agility>this.cart.promotions.find((promo: Promotion) => PromotionsService.isAgility(promo));
  }

  public getAgilityCa(): AgilityCa {
    return <AgilityCa>this.cart.promotions.find((promo: Promotion) => PromotionsService.isAgilityCa(promo));
  }

  public getPromoAlreadyApplied(promoId: number, regulGenCod?: string): Promotion {
    let promo: Promotion = this.cart.promotions.find((p: Promotion) => promoId === p.id && p.isApplied());
    if (promo) {
      return promo;
    } else {
      promo = this.cart.archivedPromotions.find((p: Promotion) => promoId === p.id && p.isApplied());
      if (promo) {
        return promo;
      }
    }
    for (const scheme of this.cart.schemes) {
      promo = scheme.promotions.find((p: Promotion) => promoId === p.id && p.isApplied());
      if (promo) {
        return promo;
      } else {
        promo = scheme.archivedPromotions.find((p: Promotion) => promoId === p.id && p.isApplied());
        if (promo) {
          return promo;
        }
      }
      for (const product of scheme.products) {
        promo = product.promotions.find(
          (p: Promotion) =>
            promoId === p.id &&
            p.isApplied() &&
            (!regulGenCod || p.application.produit.gencode?.IN?.includes(regulGenCod)),
        );
        if (promo) {
          return promo;
        } else {
          promo = product.archivedPromotions.find(
            (p: Promotion) =>
              promoId === p.id &&
              p.isApplied() &&
              (!regulGenCod || p.application.produit.gencode?.IN?.includes(regulGenCod)),
          );
          if (promo) {
            return promo;
          }
        }
      }
    }
    return null;
  }

  /**
   * returns true if promotion is applied for one item
   * @param {number} promoId
   * @returns {boolean}
   */
  public isPromoAlreadyApplied(promoId: number): boolean {
    return !!this.getPromoAlreadyApplied(promoId);
  }

  public isPromoApplicable(promoId: number): boolean {
    if (this.cart.promotions.find((p: Promotion) => promoId === p.id)) {
      return true;
    }

    for (const scheme of this.cart.schemes) {
      if (scheme.promotions.find((p: Promotion) => promoId === p.id)) {
        return true;
      }
    }

    for (const scheme of this.cart.schemes) {
      for (const product of scheme.products) {
        if (product.promotions.find((p: Promotion) => promoId === p.id)) {
          return true;
        }
      }
    }
    return false;
  }

  public archivePromotions(cleanPromotions?: boolean): void {
    this.cart.archivedPromotions = PromotionFactory.createCollection(this.cart.promotions);
    if (cleanPromotions) {
      this.cart.promotions = [];
    }
    this.cart.schemes.forEach((s: Scheme) => s.archivePromotions(cleanPromotions));
  }

  public restorePromotion(): void {
    this.cart.promotions = this.cart.archivedPromotions;
    this.cart.schemes.forEach((s: Scheme) => s.restorePromotion());
  }

  public removeProductsRecur(scheme: Scheme, productsToKeep: Product[] = []): Observable<boolean> {
    if (!scheme) {
      return observableOf(true);
    }

    const productsToDelete: Product[] = scheme.products.filter(
      (product: Product) => !productsToKeep.some((prod: Product) => prod.uniqueId === product.uniqueId),
    );
    if (!productsToDelete.length) {
      return observableOf(true);
    }

    const productToDelete: Product = productsToDelete[0];
    return this.remove(productToDelete.uniqueId, scheme.uniqueId).pipe(
      mergeMap(res => (res ? this.removeProductsRecur(scheme, productsToKeep) : of(null))),
    );
  }

  public simulateProductPromotions(productsToSimulate: Product[]): Observable<unknown> {
    const currentSchemeIndex: number = this.cart.schemes.findIndex(
      (s: Scheme) => s.uniqueId === this.getCurrentScheme().uniqueId,
    );
    const mainCartBody: PromotionalEligibilityInputDto = this.getCartAsEligPromoBody();
    const eligPromoBody = this.promotionsService.addProductsToEligPromoBody(
      mainCartBody,
      this.convertProducts(productsToSimulate),
      currentSchemeIndex,
    );
    return this.promotionsService.eligibilitePromotionnelle(eligPromoBody).pipe(
      tap((result: PromotionalEligibilityResultDto[]) => {
        let virtualCartResult: PromotionalEligibilityResultDto;
        let productToSimulate: Product;
        let productToSimulateIndex: number;
        for (let i = 1; i < result.length; i++) {
          virtualCartResult = result[i];
          productToSimulate = productsToSimulate[i - 1];
          productToSimulateIndex = this.getProductIndex(
            virtualCartResult.parcours[currentSchemeIndex].produits,
            productToSimulate.gencode,
          );
          if (productToSimulateIndex >= 0) {
            productToSimulate.simulatedPromotions =
              virtualCartResult.parcours[currentSchemeIndex].produits[productToSimulateIndex].promotions;
          }
          productToSimulate.price = productToSimulate.getSimulationPrice();
        }
      }),
    );
  }

  public simulateVirtualSchemes(schemes: Scheme[], edp: boolean = false): Observable<Scheme[]> {
    const plans: Plan[] = this.getSchemePlans(schemes);
    const currentSchemeIndex: number = this.cart.schemes.findIndex(
      (s: Scheme) => s.uniqueId === this.getCurrentScheme().uniqueId,
    );
    const mainCartBody: PromotionalEligibilityInputDto = this.getCartAsEligPromoBody();
    this.spliceCurrentProducts(mainCartBody, currentSchemeIndex);
    this.addPhoneToEligPromoBody(mainCartBody, schemes[0].getProductByType(Phone));
    if (edp) {
      this.addEdpToEligPromoBody(mainCartBody);
    }
    this.promotionsService.addProductsToEligPromoBody(mainCartBody, this.convertProducts(plans), currentSchemeIndex);
    return this.promotionsService.eligibilitePromotionnelle(mainCartBody).pipe(
      tap((result: PromotionalEligibilityResultDto[]) => this.setVirtualSchemesPromotions(schemes, plans, result)),
      map(() => schemes),
    );
  }

  public setVirtualSchemesPromotions(
    schemes: Scheme[],
    plans: Plan[],
    result: PromotionalEligibilityResultDto[],
  ): void {
    const currentSchemeIndex: number = this.cart.schemes.findIndex(
      (s: Scheme) => s.uniqueId === this.getCurrentScheme().uniqueId,
    );
    let virtualCartResult: PromotionalEligibilityResultDto;
    let planToSimulate: Plan;
    let productToSimulateIndex: number;
    let phoneIndex: number;
    let phoneToSimulate: Product;
    let phoneScheme: Scheme;
    this.setMainSchemePromotions(result[0], schemes);
    for (let i = 1; i < result.length; i++) {
      virtualCartResult = result[i];
      planToSimulate = plans.find((p: Plan) => p.gencode === virtualCartResult.avecLeProduit);
      productToSimulateIndex = virtualCartResult.parcours[currentSchemeIndex].produits.findIndex(
        (p: PromotionalEligibilityProductDto) => p.gencode === planToSimulate.gencode,
      );
      planToSimulate.simulatedPromotions =
        virtualCartResult.parcours[currentSchemeIndex].produits[productToSimulateIndex].promotions;
      planToSimulate.price = planToSimulate.getSimulationPrice();

      phoneScheme = schemes.find((s: Scheme) => s.getProductByType(Plan)?.gencode === planToSimulate.gencode);
      phoneToSimulate = phoneScheme.getProductByType(Phone);
      phoneIndex = virtualCartResult.parcours[currentSchemeIndex].produits.findIndex(
        (p: PromotionalEligibilityProductDto) => p.gencode === phoneToSimulate.gencode,
      );
      phoneToSimulate.simulatedPromotions =
        virtualCartResult.parcours[currentSchemeIndex].produits[phoneIndex].promotions;
      phoneToSimulate.price = phoneToSimulate.getSimulationPrice();
    }
  }

  public isNegativeTotalTodayAmount(isCA?: boolean): boolean {
    let todayTotalCA: number = this.cart.caTotals.today || 0;
    let todayTotalBytel: number = this.cart.bytelTotals.today || 0;
    const agility: Agility = isCA ? this.getAgilityCa() : this.getAgility();
    if (agility && agility.isApplied()) {
      if (isCA) {
        todayTotalCA = Number((todayTotalCA - agility.application.actionPromotion.amount).toFixed(2));
      } else {
        todayTotalBytel = Number((todayTotalBytel - agility.application.actionPromotion.amount).toFixed(2));
      }
    }
    if (todayTotalCA < 0) {
      this.alertService.errorEmitter.next(
        `Impossible de valider un panier d'un montant de ${todayTotalCA.toLocaleString()}€. Veuillez compléter le panier.`,
      );
    }
    if (todayTotalBytel + todayTotalCA < 0) {
      this.alertService.errorEmitter.next(
        `Impossible de valider un panier d'un montant de ${(
          todayTotalBytel + todayTotalCA
        ).toLocaleString()}€. Veuillez compléter le panier.`,
      );
    }
    return todayTotalCA < 0 || todayTotalBytel + todayTotalCA < 0;
  }

  public isLoanEligible(skipVerifEmail?: boolean): boolean {
    return this.cart.schemes.some(
      (scheme: Scheme) => !!scheme.isLoanEligible() && (!!this.customerService.customer.email || skipVerifEmail),
    );
  }

  public getTotalBytelCartPromotions(isCalculatingFundableAmount?: boolean, withAgility: boolean = true): number {
    return this.getBytelAppliedPromotions(isCalculatingFundableAmount).reduce((acc: number, current: Promotion) => {
      if (!current.isAgility() || withAgility) {
        acc += Number(current.application.actionPromotion.amount);
      }
      return acc;
    }, 0);
  }

  public getCartAsEligPromoBody(): PromotionalEligibilityInputDto {
    const promotionalEligibilityInputDto: PromotionalEligibilityInputDto = new PromotionalEligibilityInputDto();
    promotionalEligibilityInputDto.typeClient = this.customerService.customer.category;
    promotionalEligibilityInputDto.idContract = this.getCurrentScheme().contractId;
    promotionalEligibilityInputDto.idPU = this.customerService.customer.personId;

    const panier: PromotionalEligibilityInputCartDto = new PromotionalEligibilityInputCartDto();
    panier.promotionsManuelles = this.cart.promotions.filter(p => p.isApplied() && p instanceof Manual).map(p => p.id);
    panier.parcours = [];
    let idVirtuel = 0;
    this.cart.schemes.forEach((scheme: Scheme) => {
      panier.parcours.push(this.getSchemeAsEligPromoBody(scheme, idVirtuel));
      idVirtuel++;
    });
    panier.coupons = this.getCartCoupons();
    promotionalEligibilityInputDto.panier = panier;
    promotionalEligibilityInputDto.produits = [];
    return promotionalEligibilityInputDto;
  }

  public getSchemeAsEligPromoBody(scheme: Scheme, idVirtuel: number): PromotionalEligibilityInputSchemeDto {
    const parcours: PromotionalEligibilityInputSchemeDto = new PromotionalEligibilityInputSchemeDto();
    parcours.idVirtuel = idVirtuel;
    parcours.type = TypeParcoursEnums[scheme.browseType];
    parcours.promotionsManuelles = scheme.promotions.filter(p => p.isApplied() && p instanceof Manual).map(p => p.id);
    parcours.produits = [];
    scheme.products.forEach((product: Product) =>
      parcours.produits.push(this.getProductAsEligPromoBody(product, idVirtuel)),
    );
    return parcours;
  }

  public getProductAsEligPromoBody(product: Product, idVirtuel: number): PromotionalEligibilityInputProductDto {
    const produit: PromotionalEligibilityInputProductDto = new PromotionalEligibilityInputProductDto();
    produit.rio = product instanceof Plan ? product.rio?.codeRio : undefined;
    produit.gencode = product.gencode;
    produit.idVirtuel = idVirtuel;
    produit.promotionsManuelles = product.promotions.filter(p => p.isApplied() && p instanceof Manual).map(p => p.id);
    if (product instanceof CaProduct) {
      produit.ca = true;
    }
    if (product.getRegularization()) {
      produit.promotionsDemandees = [];
      produit.promotionsDemandees.push({
        type: 'REGULARISATION',
        valeur: product.getRegularizationAmount(),
      });
    }
    return produit;
  }

  public refreshCart(refreshEligibleFunding: boolean = true, refreshEligiblePromo: boolean = true): Observable<void> {
    this.beforeRefresh.next();
    this.alertService.emitBeenLoading(true);
    return this.oauth2Resource
      .setLocalService()
      .ventes()
      .panier(this.cart.cartId)
      .useSalesApi()
      .get()
      .pipe(
        tap((cartSerialized: CartSerialized) => this.unserialize(cartSerialized)),
        map(() => {
          const observables: Observable<unknown>[] = [];
          if (refreshEligibleFunding && this.getCurrentScheme()) {
            observables.push(this.fundingService.getEligibleFundingModes(this.cart.cartId));
          }
          if (refreshEligiblePromo && this.getCurrentScheme()) {
            observables.push(this.promotionsService.eligibilitePromotionnelle(this.getCartAsEligPromoBody()));
          }
          return observables;
        }),
        mergeMap(observables => (observables.length ? forkJoin([...observables]) : of(null))),
        tap(() => this.afterRefresh.next()),
        finalize(() => {
          this.alertService.emitBeenLoading(false);
        }),
        map(() => null),
      );
  }

  public getPortaPromotions(product: Product): PromotionalEligibilityPromotionExtV2Dto[] {
    return this.getProductPromotions(product).incitations.filter(
      (p: PromotionalEligibilityPromotionExtV2Dto) =>
        !!p.aUnePortabilite || !!p.proprietesSupplementaires.typesMarketing.includes('PORTABILITY'),
    );
  }

  public getProductPromotions(product: Product): PromotionalEligibilityPromotionsDto {
    const eligPromoResult = this.promotionsService.getLastEligPromoResult();
    if (eligPromoResult) {
      const schemeIndex = this.cart.schemes.findIndex((s: Scheme) => s.uniqueId === this.getCurrentScheme().uniqueId);
      const productIndex = this.getCurrentScheme().products.findIndex((p: Product) => p.uniqueId === product?.uniqueId);
      return eligPromoResult.parcours[schemeIndex].produits[productIndex].promotions;
    } else {
      return {
        automatiques: [],
        manuelles: [],
        manuellesAppliquees: [],
        incitations: [],
        coupons: [],
        couponsUniques: [],
      } as PromotionalEligibilityPromotionsDto;
    }
  }

  public setCreditFundingMode(type: FundingEnum): void {
    if (!this.isFundingModeCredit(type)) {
      throw new Error('type de crédit invalide');
    }
    this.cart.creditData = { type };
    this.removeAllEdp();
    this.fundingStorageService.serializeFundingMode(this.buildSessionStorageFundingData());
    this.stateSelectedFundingMode.next();
  }

  public removeCreditFundingMode(): void {
    this.cart.creditData = null;
    this.fundingStorageService.serializeFundingMode(this.buildSessionStorageFundingData());
    this.stateSelectedFundingMode.next();
  }

  public setEdpFundingMode(quoteId: number = this.getCurrentScheme().quoteId): void {
    const scheme: Scheme = this.getSchemeByQuoteId(quoteId);
    scheme.hasEdp = true;
    this.cart.creditData = null;
    this.fundingStorageService.serializeFundingMode(this.buildSessionStorageFundingData());
    this.stateSelectedFundingMode.next();
  }

  public setCashFundingMode(quoteId: number = this.getCurrentScheme()?.quoteId): void {
    if (quoteId) {
      const scheme: Scheme = this.getSchemeByQuoteId(quoteId);
      scheme.hasEdp = false;
    }
    this.fundingStorageService.serializeFundingMode(this.buildSessionStorageFundingData());
    this.stateSelectedFundingMode.next();
  }

  public setBasketToCashFundingMode(): void {
    this.cart.creditData = null;
    this.cart.schemes.forEach(scheme => (scheme.hasEdp = false));
    this.fundingStorageService.serializeFundingMode(this.buildSessionStorageFundingData());
    this.stateSelectedFundingMode.next();
  }

  public setFundingMode(fundingStorageData: FundingStorageInterface): void {
    if (!fundingStorageData) {
      return;
    }
    if (this.isFundingModeCredit(fundingStorageData.type)) {
      this.setCreditFundingMode(fundingStorageData.type);
      this.fundingService.selectedNbMonth$$.next(FundingService.extractNbMonths(fundingStorageData.type));
      this.fundingService.stateCredit.next(true);
    } else if (fundingStorageData.type === FundingEnum.multiProduit) {
      fundingStorageData.idParcoursAvecEdp.forEach((quoteId: number) => this.setEdpFundingMode(quoteId));
    } else {
      this.setCashFundingMode();
    }
  }

  public setFundingModeFromFapi(postFundingResponse: IPostFundingResponse): void {
    if (!postFundingResponse) {
      return;
    }
    if (postFundingResponse?.modesDeFinancement?.type === FundingEnum.multiProduit) {
      postFundingResponse.parcours.forEach(({ idParcours, produits }) => {
        const hasEdp = produits.some(produit => produit.modeDeFinancementProduit.type === FundingEnum.edp);
        if (hasEdp) {
          this.setEdpFundingMode(Number(idParcours));
        } else {
          this.setCashFundingMode(Number(idParcours));
        }
      });
    } else if (
      [FundingEnum.younitedBy3, FundingEnum.younitedBy12, FundingEnum.younitedBy24, FundingEnum.younitedBy36].includes(
        postFundingResponse?.modesDeFinancement?.type,
      )
    ) {
      this.setCreditFundingMode(postFundingResponse.modesDeFinancement.type as unknown as FundingEnum);
      this.fundingService.selectedNbMonth$$.next(
        FundingService.extractNbMonths(postFundingResponse.modesDeFinancement.type),
      );
      this.fundingService.stateCredit.next(true);
    } else {
      this.setCashFundingMode();
    }
  }

  public cancelBasket(isAccapActive: boolean): Observable<boolean> {
    return this.addToObsQueue(this.delete().pipe(map(() => true))).pipe(
      finalize(() => {
        sessionStorage.clear();
        window.location.href = isAccapActive
          ? location.origin + '/accap/welcome?typeAction=CUSTOM_WELCOME'
          : this.browseConfigService.browseConfig.cancelCallBackUrl;
      }),
    );
  }

  public deleteGrantedLoan(): Observable<unknown> {
    if (this.fundingService.hasGrantedCredit()) {
      this.setBasketToCashFundingMode();
      return this.fundingService
        .postFundingMode(this)
        .pipe(concatMap(() => this.fundingService.getEligibleFundingModes(this.cart.cartId)));
    }
    return of(null);
  }

  public checkValidityAddress(addressForm: UntypedFormGroup): boolean {
    for (const key in addressForm.controls) {
      if (key !== 'address' && addressForm.controls[key]?.invalid) {
        return false;
      }
    }
    return true;
  }

  public isProductAvailable(product: Product): boolean {
    if (this.getCurrentScheme().isRenew() && !product.data.categories.includes('renewal_rcbt')) {
      return false;
    }
    return true;
  }

  protected emit(schemes: Scheme[]): void {
    this.onChangesSchemes.next(schemes);
  }

  protected _backendAdd(product: Product, scheme: Scheme, force: boolean): Observable<BasicObject> {
    return this.oauth2Resource
      .setLocalService()
      .ventes()
      .panier(this.cart.cartId)
      .parcours(scheme.quoteId)
      .produits()
      .useSalesApi()
      .setParams({ force: Number(force) })
      .post(product.serialize())
      .pipe(
        tap(
          (productSerialized: ProductSerialized) => {
            product.itemId = productSerialized.idProduit;
            product.quoteId = productSerialized.idParcours;
          },
          (exception: HttpErrorResponse) => {
            // renvoyer l'erreur du back
            throw exception.error;
          },
        ),
      );
  }

  protected _backendRemove(product: Product, scheme: Scheme): Observable<boolean> {
    return this.oauth2Resource
      .setLocalService()
      .ventes()
      .panier(this.cart.cartId)
      .parcours(scheme.quoteId)
      .produits(product.itemId)
      .useSalesApi()
      .delete()
      .pipe(
        mergeMap(() => of(true)),
        catchError(err => {
          if (err?.error?.code === AppErrorCodes.lockedCart) {
            window.location.href = this.browseConfigService.browseConfig.cancelCallBackUrl;
          }
          throw err;
        }),
      );
  }

  /**
   * @param product
   * @param qty
   * @param force
   * @returns {boolean}
   * @private
   */
  protected _performAdd(
    product: Product,
    qty: number,
    force: boolean,
    scheme: Scheme,
    save: boolean = true,
  ): Observable<boolean> {
    return this._backendAdd(product, scheme, force).pipe(
      tap((productSerialized: ProductSerialized) => {
        this.onAddProduct.next(productSerialized);
      }),
      mergeMap(() => of(true)),
    );
  }

  private buildSessionStorageFundingData(): { type: FundingEnum; idParcoursAvecEdp?: number[] } {
    if (!!this.cart.creditData) {
      return { type: this.cart.creditData.type };
    }

    if (this.cart.schemes.some(({ hasEdp }) => hasEdp)) {
      return {
        type: FundingEnum.multiProduit,
        idParcoursAvecEdp: this.cart.schemes.filter(({ hasEdp }) => !!hasEdp).map(({ quoteId }) => quoteId),
      };
    }
    return { type: FundingEnum.cash };
  }

  private getSchemePlans(schemes: Scheme[]): Plan[] {
    const plans: Plan[] = [];
    schemes.forEach((s: Scheme) => {
      if (s.getProductByType(Plan)) {
        plans.push(s.getProductByType(Plan));
      }
    });
    return plans;
  }

  private setMainSchemePromotions(virtualCartResult: PromotionalEligibilityResultDto, schemes: Scheme[]): void {
    const currentSchemeIndex: number = this.cart.schemes.findIndex(
      (s: Scheme) => s.uniqueId === this.getCurrentScheme().uniqueId,
    );
    const tnuScheme: Scheme = schemes.find((s: Scheme) => !s.getProductByType(Plan));
    if (tnuScheme) {
      const tnuPhone: Phone = tnuScheme.getProductByType(Phone);
      const phoneIndex: number = virtualCartResult.parcours[currentSchemeIndex].produits.findIndex(
        (p: PromotionalEligibilityProductDto) => p.gencode === tnuPhone.gencode,
      );
      tnuPhone.simulatedPromotions = virtualCartResult.parcours[currentSchemeIndex].produits[phoneIndex].promotions;
      tnuPhone.price = tnuPhone.getSimulationPrice();
    }
  }

  private getCartCoupons(): string[] {
    const coupons: string[] = this.cart.promotions
      .map(({ type, application }) => {
        if (type === PromotionTypes.manual) {
          return;
        }
        return application?.actionPromotion?.coupon;
      })
      .filter(item => !!item);

    this.cart.schemes.forEach((scheme: Scheme) => {
      scheme.products.forEach((product: Product) => {
        product.promotions.forEach((promo: Promotion) => {
          if (
            !!promo.application?.actionPromotion?.coupon &&
            !coupons.includes(promo.application.actionPromotion.coupon)
          ) {
            coupons.push(promo.application.actionPromotion.coupon);
          }
        });
      });
    });
    return coupons;
  }

  private addPhoneToEligPromoBody(mainCartBody: PromotionalEligibilityInputDto, phone: Phone): void {
    const currentSchemeIndex: number = this.cart.schemes.findIndex(
      (s: Scheme) => s.uniqueId === this.getCurrentScheme().uniqueId,
    );
    mainCartBody.panier.parcours[currentSchemeIndex].produits.push(
      this.getProductAsEligPromoBody(phone, mainCartBody.panier.parcours[currentSchemeIndex].produits.length),
    );
  }

  private addEdpToEligPromoBody(mainCartBody: PromotionalEligibilityInputDto): void {
    const currentSchemeIndex: number = this.cart.schemes.findIndex(
      (s: Scheme) => s.uniqueId === this.getCurrentScheme().uniqueId,
    );
    const edpProductToAdd: PromotionalEligibilityInputProductDto = new PromotionalEligibilityInputProductDto();
    edpProductToAdd.gencode = 'EPD0000000';
    edpProductToAdd.idVirtuel = mainCartBody.panier.parcours[currentSchemeIndex].produits.length;
    mainCartBody.panier.parcours[currentSchemeIndex].produits.push(edpProductToAdd);
  }

  private spliceCurrentProducts(
    promotionalEligibilityInputDto: PromotionalEligibilityInputDto,
    schemeIndex: number,
  ): PromotionalEligibilityInputDto {
    [Phone, Plan].forEach(instance => {
      const currentProduct: Phone = this.getCurrentScheme().getProductByType(instance);
      if (currentProduct) {
        const currentPhoneIndex: number = promotionalEligibilityInputDto.panier.parcours[
          schemeIndex
        ].produits.findIndex((p: PromotionalEligibilityInputProductDto) => p.gencode === currentProduct.gencode);
        promotionalEligibilityInputDto.panier.parcours[schemeIndex].produits.splice(currentPhoneIndex, 1);
      }
    });
    return promotionalEligibilityInputDto;
  }

  private analyseCheckStockCheckCatalog(data: StockType[]): Observable<StockType[]> {
    const productsObs: Observable<JsonCatalog>[] = [of(null)];
    let productsRcbt: JsonCatalog = {};
    let productsCa: JsonCatalog = {};
    data.forEach(stockType => {
      if (stockType.quantity) {
        productsObs.push(
          this.loadProduct(stockType.gencode, stockType.typeStock as TypesStock).pipe(
            tap(result => {
              if (stockType.typeStock === TypesStock.bytel) {
                productsRcbt = result;
              } else {
                productsCa = result;
              }
            }),
          ),
        );
      }
    });

    return observableForkJoin(productsObs).pipe(
      mergeMap(() => {
        const productInStock: JsonProduct[] = [];
        data.forEach(elt => {
          if (elt.typeStock === TypesStock.bytel && productsRcbt[elt.gencode]) {
            productInStock.push(productsRcbt[elt.gencode]);
          } else if (elt.typeStock === TypesStock.ca && productsCa[elt.gencode]) {
            productInStock.push(productsCa[elt.gencode]);
          }
        });
        if (!productInStock.length) {
          return observableThrowError('Produit désactivé');
        }
        return observableOf(data);
      }),
    );
  }

  private analyseCheckStock(data: StockType[], checkCatalog: boolean, scanCode: string): Observable<StockType[]> {
    if (!this.stockService.isInStock(data, !checkCatalog)) {
      return this.tryUnreserveProduct(data, scanCode);
    }

    if (checkCatalog) {
      return this.analyseCheckStockCheckCatalog(data);
    } else {
      return observableOf(data);
    }
  }

  private tryUnreserveProduct(data: StockType[], scanCode: string): Observable<StockType[]> {
    let idPanier: string;
    return this.getCartWithReservedProduct(scanCode).pipe(
      mergeMap((res: ICartHasReservedProdResponse) => {
        if (!res) {
          return observableThrowError(StockService.productNotInStock);
        }
        const gencode = res.gencode;
        if (gencode) {
          return this.productsService.getJsonProductsByFilter({ listGencode: gencode }).pipe(
            mergeMap((products: JsonCatalog) => {
              if (
                products[gencode].type_id === 'box' ||
                products[gencode].type_id.includes('phone') ||
                products[gencode].type_id.includes('sim')
              ) {
                const byetlStock: StockType[] = data.filter(elt => elt.typeStock === TypesStock.bytel);
                if (!byetlStock || !byetlStock.length) {
                  data.push({ gencode, quantity: 1, typeStock: TypesStock.bytel });
                } else {
                  byetlStock[0].quantity = 1;
                  byetlStock[0].gencode = gencode;
                }
                return observableOf(res);
              } else {
                return observableThrowError(StockService.productNotInStock);
              }
            }),
          );
        } else {
          return observableThrowError(StockService.productNotInStock);
        }
      }),
      mergeMap((res: ICartHasReservedProdResponse) => {
        idPanier = res.idPanier;
        if (idPanier && Number(idPanier) !== this.cart.cartId) {
          if (!this.modalService.hasOpenModals()) {
            this.globalLoaderService.setForceLoadingStatusOff(true);
            const modalRef = this.modalService.open(ModalProductUnreservationComponent, { size: 'lg' });
            modalRef.componentInstance.productUnreservationDto = res;
            return observableFrom(modalRef.result);
          }
          return observableFrom(null);
        } else {
          return observableThrowError(StockService.productNotInStock);
        }
      }),
      mergeMap(response =>
        response
          ? this.delete(Number(idPanier)).pipe(mergeMap(() => observableOf(data)))
          : observableThrowError(StockService.productNotInStock),
      ),
    );
  }

  private getCartWithReservedProduct(imei: string): Observable<ICartHasReservedProdResponse> {
    return this.oauth2Resource
      .setLocalService()
      .useSalesApi()
      .ventes()
      .panierAvecProduitReserve()
      .setParams({
        imei,
        codeEnseigne: this.userService.user.codeEns,
        codePointVente: this.userService.user.codePdv,
      })
      .get();
  }

  private getBytelAppliedPromotions(isCalculatingFundableAmount?: boolean): Promotion[] {
    return this.cart.promotions.filter(
      promo =>
        !promo.siren &&
        !promo.isAgilityCa() &&
        promo.isApplied() &&
        (promo.application.actionPromotion.type === PromoActionTypes.byFixed || !isCalculatingFundableAmount),
    );
  }

  /**
   * si le même genode existe dans le panier et dans les produitaajouter (cas de CA+bytel par exemple)
   * on va se retrouver avec 2 produits avec le même gencode dans le résultat
   * si on prend le premier avec le bon gencode, on va appliquer la promo d'un mauvais produit (présent dans le panier)
   * => l'index du produit doit être supérieur au nombre des produits déjà présent dans le panier
   * @param produits
   * @param gencode
   * @private
   */
  private getProductIndex(produits: PromotionalEligibilityProductDto[], gencode: string): number {
    const nbOccurrence = this.getCurrentScheme().products.filter(p => p.gencode === gencode).length;
    let productInCartIndex = 0;
    return produits.findIndex((p: PromotionalEligibilityProductDto) => {
      productInCartIndex++;
      return productInCartIndex >= nbOccurrence && p.gencode === gencode;
    });
  }

  private removeAllEdp(): void {
    this.cart.schemes.forEach((s: Scheme) => (s.hasEdp = false));
  }

  private handleBeforeRefresh(): void {
    this.checkoutStorageService.serializePlansConfig(this);
    this.checkoutStorageService.serializeSchemeInsurances(this);
    this.fundingStorageService.serializeFundingMode(this.buildSessionStorageFundingData());
  }

  private handleAfterRefresh(): void {
    this.checkoutStorageService.unserializePlansConfig(this);
    this.checkoutStorageService.unserializeSchemeInsurances(this);
    this.setFundingMode(this.fundingStorageService.unserializeFundingMode());
  }

  private handleEligibleFundingChanges(): void {
    if (
      this.cart.creditData &&
      !this.fundingService.getFundingYounitedForCart().length &&
      !this.fundingService.hasGrantedCredit()
    ) {
      this.removeCreditFundingMode();
    }
    if (!this.fundingService.isSchemeEligibleEdp(this.getCurrentScheme())) {
      this.setCashFundingMode();
    }
  }

  private isFundingModeCredit(type: FundingEnum): boolean {
    return [
      FundingEnum.younitedBy3,
      FundingEnum.younitedBy12,
      FundingEnum.younitedBy24,
      FundingEnum.younitedBy36,
    ].includes(type);
  }

  private convertProducts(products: Product[]): JsonProduct[] {
    const jsonProductsToSimulate: JsonProduct[] = [];
    products.forEach(p =>
      jsonProductsToSimulate.push({
        gencode: p.gencode,
        type_id: p.type_id,
        name: p.name,
        sku: p.sku,
        plan_bonus: p.plan_bonus,
      }),
    );
    return jsonProductsToSimulate;
  }

  private applyRenewPromotions(): void {
    const renewPromotionsAmount = this.promoRenewService.getRenewPromotionsAmount(this.cart.schemes);
    this.cart.bytelTotals.everyMonth -= renewPromotionsAmount;
    this.cart.totals.everyMonth -= renewPromotionsAmount;
  }

  private loadProduct(gencode: string, stockType: TypesStock): Observable<JsonCatalog> {
    const loadProductObs: Observable<JsonCatalog> =
      stockType === TypesStock.bytel
        ? this.productsService.getJsonProductsByFilter({ listGencode: gencode })
        : this.productsService.getJsonCaProductsByFilter({ listGencode: gencode });
    return loadProductObs;
  }
}

export type ResetManualPromotions = (promo: Promotion, baseAmount: number, gencode?: string) => void;
