import { Rules } from '@checkout/cart/rules/rules.class';
import { RulesFactory } from '@checkout/cart/rules/rules-factory.factory';
import { Product } from './catalog/products/product';
import { Promotion } from '@promotions/promotion.class';
import { ICartTotal } from '@promotions/promotions.interfaces';
import { InsuranceConfig } from '../partner/partner.dto';
import { PromotionFactory } from '@promotions/promotionFactory.class';
import { Phone } from './catalog/products/equipement/complex/phone';
import { Plan } from './catalog/products/subscription/plan';
import { Box } from './catalog/products/equipement/complex/phone/box';
import { Sensation } from './catalog/products/subscription/plan/premium/sensation';
import { Ideo } from './catalog/products/subscription/plan/premium/ideo';
import { FaimSensation } from './catalog/products/subscription/plan/premium/faim_sensation';
import { Sowo } from './catalog/products/subscription/plan/sowo';
import { Simo } from './catalog/products/subscription/plan/simo';
import { Faim } from './catalog/products/subscription/plan/fai/faim';
import { Fai } from './catalog/products/subscription/plan/fai/fai';
import { FaimUnlimited } from './catalog/products/subscription/plan/fai/faim-unlimited';
import { Prepaid } from './catalog/products/subscription/plan/prepaid';
import { RulesSAV } from '@checkout/cart/rules/rules-sav.class';
import { InsurancePartner } from './catalog/products/subscription/insurance-partner';
import { BrowseType } from '../constants/browse-type';
import { ProductContextSerializedInterface } from '../context/child/product-context-serialized.interface';
import { MobileTakeBack } from './catalog/products/mobileTakeBack';
import { SchemeSerialized } from '@interfaces/scheme.interface';
import { CheckoutNs } from '@interfaces/checkout.interface';
import { SimReplace } from './catalog/products/equipement/sim/sim-replace';

export abstract class Scheme {
  public quoteId: number;
  public contractId: number; // todo alimenter ce champ au choix du parcours dans la page dispatch
  public products: Product[] = [];
  public verrouiller: boolean;
  public rules: Rules;
  public deviceId: string;
  public userRegistrationNumber: string;
  public financedAmount: number;
  public promotions: Promotion[] = [];
  public archivedPromotions: Promotion[] = [];
  public totals: ICartTotal = { today: 0, everyMonth: 0, reported: 0 };
  public caTotals: ICartTotal = { today: 0, everyMonth: 0, reported: 0 };
  public bytelTotals: ICartTotal = { today: 0, everyMonth: 0, reported: 0 };
  public addedInsurances: InsuranceConfig[] = [];
  public abstract browseType: BrowseType;
  public _uniqueId: string;
  public hasEdp = false;
  public paymentAccount: CheckoutNs.IPayment;

  constructor(rules?: string, uniqueId?: string) {
    this.setUniqueId(uniqueId || null);
    this.setRules(rules);
    this.uniqueId = !uniqueId ? Date.now() + '-' + Math.floor(Math.random() * 1000) : uniqueId; // NOSONAR
  }

  get uniqueId(): string {
    return this._uniqueId;
  }

  set uniqueId(val: string) {
    this._uniqueId = val;
  }

  /**
   * Check if you have product with gencode set in params
   * @param gencode
   * @returns {boolean}
   */
  public hasProduct(gencode: string): boolean {
    return this.products.some(obj => obj.gencode === gencode);
  }

  public isLoanEligible(): boolean {
    return (
      (this.isRenew() || this.isAcquisitionMobile()) &&
      this.getProductsByType<Phone>(Phone).some(p => !!p.eligibleCredit)
    );
  }

  public setUniqueId(uniqueId?: string): this {
    const multiplier = 1000;
    this._uniqueId = !uniqueId ? `${Date.now()}-${Math.floor(Math.random() * multiplier)}` : uniqueId;

    return this;
  }

  public setRules(rules?: string): this {
    if (!rules && this.isBigtrustSav()) {
      this.rules = new RulesSAV(this);
    } else if (rules) {
      this.rules = RulesFactory.getNewInstanceOf(rules, this);
    } else {
      this.rules = RulesFactory.getNewInstanceOf('default', this);
    }
    return this;
  }

  public isContractual(): boolean {
    return this.hasEdp || !!this.getProductByType(SimReplace) || !!this.getProductByType(Plan);
  }

  /**
   * Remove products
   * @returns {SchemeBase}
   */
  public clear(): this {
    this.products = [];
    return this;
  }

  /**
   * TODO Vérifier que les erreurs sapi concernant les produits en conflit sont bien géré au moment de l'ajout
   * @param product
   * @param qty
   * @param force
   */
  public add(product: Product): void {
    this.products.push(product);
  }

  /**
   * Remove product and call func updateSchemeOnRemove
   * (Exp : if you remove phone, updateSchemeOnRemove remove EDP if you have one in your scheme)
   * @param uniqueId
   */
  public remove(uniqueId: string): boolean {
    const product: Product = this.getProductById(uniqueId);
    if (!product) {
      return false;
    }
    this.removeProductById(uniqueId);
    return true;
  }

  public getProductById(id: string): Product {
    return this.products.find(obj => obj.uniqueId === id);
  }

  public getSchemeProductById(productUniqueId: string): Product {
    return this.products.find(obj => obj.itemId.toString() === productUniqueId.toString());
  }

  public removeProductById(id: string): this {
    this.products = this.products.filter(obj => obj.uniqueId !== id);
    return this;
  }

  /**
   * Return product by uniqueID
   * @param gencode
   * @returns {string}
   */
  public getProductByGencode(gencode: string): string {
    const product = this.products.find(obj => obj.gencode === gencode);
    return product ? product.uniqueId : null;
  }

  public getProductByType<T extends Product>(typeProduct: typeof Product): T {
    return <T>this.products.find(obj => obj instanceof typeProduct);
  }

  public getPhoneTypeSim(): string {
    const terminal = this.getProductByType(Phone);
    return terminal && terminal.data ? terminal.data.sim_type : undefined;
  }

  public getProductsByType<T extends Product>(typeProduct: typeof Product): T[] {
    return <T[]>this.products.filter(obj => obj instanceof typeProduct);
  }

  public isRenew(): boolean {
    return this.browseType === BrowseType.renew;
  }

  public isAcquisitionMobile(): boolean {
    return this.browseType === BrowseType.acquisition;
  }

  public isAcquisitionFix(): boolean {
    return this.browseType === BrowseType.acquisitionFix;
  }

  public isBigtrustSav(): boolean {
    return this.browseType === BrowseType.sav;
  }

  public isEmpty(): boolean {
    return this.products.length === 0 && this.addedInsurances.length === 0;
  }

  public isPrepaid(): boolean {
    return this.getProductsByType(Prepaid).length > 0;
  }

  public hasRepriseMobile(): boolean {
    const vMobileTakeBack: MobileTakeBack = this.getProductByType<MobileTakeBack>(MobileTakeBack);
    return vMobileTakeBack && !vMobileTakeBack.isCessionCoupon();
  }

  public hasPartnerInsurance(): boolean {
    return !!this.getProductByType<InsurancePartner>(InsurancePartner);
  }

  public hasDisposalTicket(): boolean {
    const vMobileTakeBack: MobileTakeBack = this.getProductByType<MobileTakeBack>(MobileTakeBack);
    return vMobileTakeBack && vMobileTakeBack.isCessionCoupon();
  }

  public archivePromotions(cleanPromotions?: boolean): void {
    this.archivedPromotions = PromotionFactory.createCollection(this.promotions);
    if (cleanPromotions) {
      this.promotions = [];
    }
    this.products.forEach((p: Product) => p.archivePromotions(cleanPromotions));
  }

  public restorePromotion(): void {
    this.promotions = this.archivedPromotions;
    this.products.forEach((p: Product) => p.restorePromotion());
  }

  public isAcquisitionNue(): boolean {
    return (
      (this.getProductsByType(Phone).length > 0 || this.getProductsByType(Box).length > 0) &&
      !(this.getProductsByType(Sensation).length > 0 || this.getProductsByType(Ideo).length > 0) &&
      this.getProductsByType(FaimSensation).length <= 0
    );
  }

  public isAcquisitionPremium(): boolean {
    return this.getProductsByType(FaimSensation).length > 0 || this.getProductsByType(Sensation).length > 0;
  }

  public isSowo(): boolean {
    return this.getProductsByType(Sowo).length > 0 || this.getProductsByType(Simo).length > 0;
  }

  public isFaim(): boolean {
    return this.getProductsByType(Faim).length > 0;
  }

  public isFai(): boolean {
    return this.getProductsByType(Fai).length > 0;
  }

  public isFaimUnlimited(): boolean {
    return this.getProductsByType(FaimUnlimited).length > 0;
  }

  public isEligibleEDP(isEligibleEDP: boolean, plan?: string): boolean {
    const phone: Phone = this.getProductByType(Phone);
    if (!phone) {
      return false;
    }
    const appliedPromotionsAmount: number = phone.oldPrice - phone.price;
    if ((!plan || this.isSowo()) && this.isAcquisitionMobile()) {
      return isEligibleEDP && phone.getEdpDataPhone() && phone.getEdpDataPhone().price - appliedPromotionsAmount >= 1;
    } else if (plan && this.isAcquisitionMobile()) {
      return phone.getOneOffWithPlan(plan, null) - appliedPromotionsAmount >= 1;
    }
  }

  public getProductWithTheLastPreOrderDate(): Product {
    return this.products.find((product: Product) => product instanceof Phone && !!product.getData('preorder_to_date'));
  }

  public isFnb(): boolean {
    return (
      this.isAcquisitionMobile() &&
      !!(
        this.getProductsByType(FaimSensation).length ||
        this.getProductsByType(Sowo).length ||
        this.getProductsByType(Sensation).length ||
        this.getProductsByType(Faim).length ||
        this.getProductsByType(Simo).length ||
        this.getProductsByType(Ideo).length
      )
    );
  }

  public getMainOffers(): string[] {
    const result: string[] = [];
    const products: Plan[] = this.getProductsByType(Plan) || [];
    products.forEach((product: Plan) => {
      result.push(product.gencode);
    });
    return result;
  }

  public abstract unserializeSpecificData(schemeSerialized: SchemeSerialized): void;

  private getSerializedProductContext(): ProductContextSerializedInterface {
    // todo
    return {};
  }
}
