import { Step } from '@components/stepper/step.abstract';
import { Injectable } from '@angular/core';
import { CartService } from '@services/cart.service';
import { Fai } from '@model/catalog/products/subscription/plan/fai/fai';
import { FaiService } from '@fai-widget/fai.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentStepService extends Step {
  public code = 'appointment';
  public path = '/panier/appointment';
  public label = 'Rendez-vous';

  constructor(
    private cartService: CartService,
    private faiService: FaiService,
  ) {
    super();
  }

  get isAllowed(): boolean {
    const currentScheme = this.cartService.getCurrentScheme(false);
    if (!currentScheme) {
      return false;
    }
    const planFai: Fai = currentScheme.getProductByType(Fai);
    const techno =
      currentScheme.getProductByType(Fai) && currentScheme.getProductByType(Fai).data
        ? currentScheme.getProductByType(Fai).data.technology
        : '';
    if (planFai && this.faiService.faiData) {
      const isFTTH = techno === 'FTTH';
      const isXDSL = techno === 'ADSL' || techno === 'VDSL';
      const isCDL =
        this.faiService.faiData.housing.NDRouting && this.faiService.faiData.housing.accessStatusDSL === 'INEXISTANT';
      if (isFTTH || (isXDSL && isCDL)) {
        return true;
      }
    }
    return false;
  }

  set isAllowed(val: boolean) {
    return;
  }
}
