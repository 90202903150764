import { Injectable } from '@angular/core';
import { CartService } from '@services/cart.service';
import { Step } from '@components/stepper/step.abstract';
import { SimReplace } from '@model/catalog/products/equipement/sim/sim-replace';
import { EsimReplace } from '@model/catalog/products/equipement/sim/esim-replace';

@Injectable({
  providedIn: 'root',
})
export class ReplaceSimStepService extends Step {
  public code = 'replace-sim';
  public path = '/panier/replace-sim';
  public label = 'Replace Sim';

  constructor(private cartService: CartService) {
    super();
  }

  get isAllowed(): boolean {
    const currentScheme = this.cartService.getCurrentScheme(false);
    if (!currentScheme || currentScheme.isRenew()) {
      return false;
    }
    return !!currentScheme.getProductByType(SimReplace) || !!currentScheme.getProductByType(EsimReplace);
  }
  set isAllowed(val: boolean) {
    return;
  }
}
