import {
  IFundingCtx,
  IPostCatalogResponse,
  IVirtualProductCtx,
} from '../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { PaymentMode } from '@services/cart.service';
import { PaymentType } from '@interfaces/cart.interface';
import { PriceTypes } from '@model/catalog/products/interface/price-type.enum';
import { VirtualProduct } from './virtual-product';

export class VirtualScheme {
  public equipement: VirtualProduct;
  public plan: VirtualProduct;

  constructor() {
    this.equipement = new VirtualProduct(PriceTypes.today);
    this.plan = new VirtualProduct(PriceTypes.everyMonth);
  }

  public setEquipmentData(globalResult: IPostCatalogResponse, paymentMode: PaymentMode, gencode: string): void {
    const produitResult: IVirtualProductCtx = globalResult.panierSimule.parcours
      .find(p => p.estCourant)
      .produits.find(p => p.gencode === gencode);
    this.equipement.setData(produitResult);
    if (produitResult.prix.subventionne) {
      this.equipement.oldPrice = produitResult.prix.subventionne;
    } else {
      this.equipement.oldPrice = produitResult.prix.initial;
    }
    this.equipement.price = this.getProductPriceByPaymentType(produitResult, 'apportInitial', paymentMode, gencode);
    if (paymentMode === PaymentMode.edp) {
      const edpFunding: IFundingCtx = produitResult.financements.find(
        (fin: IFundingCtx) => fin.type === PaymentType.edp,
      );
      if (edpFunding) {
        this.equipement.edp = edpFunding;
      }
    } else if (paymentMode === PaymentMode.credit) {
      const creditsFunding: IFundingCtx[] = produitResult.financements.filter((fin: IFundingCtx) =>
        [PaymentType.credit3, PaymentType.credit12, PaymentType.credit24, PaymentType.credit36].includes(fin.type),
      );
      if (creditsFunding.length) {
        this.equipement.credits = creditsFunding;
      }
    }
  }

  public setPlanData(planResult: IVirtualProductCtx): VirtualScheme {
    this.plan.setData(planResult);
    return this;
  }

  private getProductPriceByPaymentType(
    produitSimule: IVirtualProductCtx,
    key: string,
    paymentMode: PaymentMode,
    gencode: string,
  ): number {
    const paymentsType: string[] = [];
    switch (paymentMode) {
      case PaymentMode.cash:
        paymentsType.push(PaymentType.cash);
        break;
      case PaymentMode.edp:
        paymentsType.push(PaymentType.edp);
        break;
      default:
        paymentsType.push(PaymentType.credit3);
        paymentsType.push(PaymentType.credit12);
        paymentsType.push(PaymentType.credit24);
        paymentsType.push(PaymentType.credit36);
        break;
    }

    let fundings: IFundingCtx[] = produitSimule.financements.filter((funding: IFundingCtx) =>
      paymentsType.includes(funding.type),
    );

    if ([PaymentMode.credit, PaymentMode.edp].includes(paymentMode) && !fundings.length) {
      fundings = produitSimule.financements.filter((funding: IFundingCtx) => funding.type === PaymentType.cash);
    }
    return fundings.length ? fundings[0][key] : 0;
  }
}
