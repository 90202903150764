import { HttpResponse as Response } from '@angular/common/http';
import { ScanConfigInterface } from '@interfaces/types';
import { ScanConfig } from '@components/top-bar/scan/scan-config.class';
import { StockService } from '@services/stock.service';
import { Observable } from 'rxjs';
import { Equipement } from '../equipement';
import { webShopEligibility } from '@fai-widget/fai-webshop.interface';
import { IGenericDeviceConfiguration } from './IGenericDeviceConfiguration';
import { IProductConfiguration } from '../IProductConfiguration';
import { ProductSerialized } from '../interface/configurable';
import { BasicObject } from '@base/base.interfaces';

/**
 * Accessory class
 */
export class Accessory extends Equipement {
  public deviceElement: webShopEligibility.DeviceLstElement;

  /**
   *
   * @type {ScanConfigInterface}
   */
  public scanConfig: ScanConfigInterface = ScanConfig.gencode;

  /**
   * @param stockService
   * @param schemeUniqueId
   * @returns {Observable<Response>}
   */
  public reserve(stockService: StockService, schemeUniqueId: string): Observable<Response<BasicObject>> {
    return stockService.reserve(this.getScanCode(), this.scanConfig.code, schemeUniqueId);
  }

  /**
   *
   * @param stockService
   * @param schemeUniqueId
   * @returns {Observable<Response>}
   */
  public cancelReservation(stockService: StockService, schemeUniqueId: string): Observable<Response<BasicObject>> {
    return stockService.cancel(this.getScanCode(), this.scanConfig.code, schemeUniqueId);
  }

  public setConfiguration(data: IGenericDeviceConfiguration): void {
    this.setScanCode(data['scanCode'] || null);
    this.deviceElement = data.element;
    if (data.element && data.element.imei) {
      this.setScanCode(data.element.imei);
    }
  }

  public getConfiguration(): IGenericDeviceConfiguration | IProductConfiguration {
    return <IGenericDeviceConfiguration>{
      element: this.deviceElement,
      ...super.getConfiguration(),
    };
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }
}
