import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ScanService } from '@components/top-bar/scan/scan.service';
import { ViewDirective } from '../directive/view.directive';
import { BasicObject } from '@base/base.interfaces';
import { IPostCatalogResponse } from '../contextualized-catalog/dtos/contextualized-product-output.dto';

export interface OnCatalogCtxLoadedConfig {
  produitsContextualises: IPostCatalogResponse[];
  currentEquipment: IPostCatalogResponse;
}

@Injectable({
  providedIn: 'root',
})
/**
 * send paramaters to the quickview
 */
export class ViewService {
  public onCatalogCtxLoaded$: EventEmitter<OnCatalogCtxLoadedConfig> = new EventEmitter();
  public getAllContextualisedProducts$: EventEmitter<string> = new EventEmitter<string>();
  public getContextualizedCatalogForPlans$: EventEmitter<string> = new EventEmitter<string>();
  /**
   * [instance description]
   * @type {ViewService}
   */
  private static instance: ViewService;
  /**
   * [onChangesParent description]
   * @type {Subject}
   */
  public onChangesParent = new Subject<BasicObject>();

  public qview = false;
  public sourceScan = false;
  public inStockFilter = true;

  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {
    ViewService.instance = ViewService.instance || this;
  }

  public qviewGen(
    viewDirective: ViewDirective,
    parentCode: string,
    equipmentType: string,
    childrenCodes: string[],
    bestChild: string,
    scanCode?: string,
    scanService?: ScanService,
  ): void {
    if (this.qview || (this.sourceScan && scanService === undefined)) {
      return;
    }
    this.qview = true;

    viewDirective.createView();
    if (scanService !== undefined) {
      scanService.onEndScan.next(true);
    }
    if (equipmentType === 'box') {
      childrenCodes = [parentCode];
      bestChild = parentCode;
    }
    this.onChangesParent.next({
      parentCode: parentCode,
      equipmentType: equipmentType,
      childrenCodes: childrenCodes,
      bestChild: bestChild,
      scanCode: scanCode,
    });
  }
}
