import { Component, forwardRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JsonCatalog } from '@model/catalog/products/interface/context';
import { Category } from '@model/category.model';
import { CrossSellComponent } from './cross-sell/cross-sell.component';
import { CategoryNavBarComponent } from './category-nav-bar/category-nav-bar.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ReplaceSimComponent } from './replaceSim/replace-sim.component';

@Component({
  selector: 'rcbt-category',
  templateUrl: './category.component.html',
  standalone: true,
  styleUrls: ['./category.component.scss'],
  imports: [CrossSellComponent, forwardRef(() => CategoryNavBarComponent), ProductListComponent, ReplaceSimComponent],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class CategoryComponent implements OnInit {
  /**
   * name of category
   * @type {string}
   */
  public category: Category;
  // all products in json catalog
  public products: JsonCatalog;
  // all productsKeys from json catalog, used for wall loop
  public productsKeys: string[];

  constructor(protected route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.category = params['category'];
    });
  }
}
