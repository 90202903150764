import { Injectable } from '@angular/core';
import { ConfigService } from '@services/config.service';
import { Observable, of, throwError } from 'rxjs';
import { InsurancePartner } from '@model/catalog/products/subscription/insurance-partner';
import { catchError, tap } from 'rxjs/operators';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { CartService } from '@services/cart.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PrismeLoggerService } from '../../shared/prisme/prisme-logger.service';
import { PrismeLogType } from '../../shared/prisme/prisme-log-type.enum';
import { CustomerService } from '../cart/customer/customer.service';
import { AppErrorCodes } from '@interfaces/cart.interface';
import { FraudModalComponent } from './fraud-modal/fraud-modal.component';

@Injectable({
  providedIn: 'root',
})
export class FraudService {
  public isFraudSelected = false;
  public isFraudActive: boolean;

  constructor(
    private configService: ConfigService,
    private oauth2RessourceService: Oauth2RessourceService,
    private cartService: CartService,
    private modalService: NgbModal,
    private prismeLogger: PrismeLoggerService,
    private customerService: CustomerService,
  ) {
    this.isFraudActive = !!this.configService.data.fraud?.active;
  }

  public saveFraudData(isFraudSelected): Observable<InsurancePartner> {
    if (!this.isFraudActive || isFraudSelected === this.isFraudSelected) {
      return of(null);
    }
    this.prismeLogger.sendDataToPrisme(PrismeLogType.customLog, {
      message: isFraudSelected ? 'Fraude Potentielle cochée' : 'Fraude potentielle décochée',
      idPu: this.customerService.customer.personId,
    });
    return this.oauth2RessourceService
      .metadonnees(this.cartService.cart.cartId.toString())
      .post({ fraudePotentielle: isFraudSelected })
      .pipe(
        tap(() => (this.isFraudSelected = isFraudSelected)),
        catchError(() =>
          throwError({
            error: {
              error: AppErrorCodes.error,
              error_description: 'Problème lors de la sauvegarde des meta données',
            },
          }),
        ),
      );
  }

  public openModal(): void {
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      keyboard: false,
    };
    this.modalService.open(FraudModalComponent, options);
  }
}
