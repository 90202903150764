import { Component } from '@angular/core';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'rcbt-alert-cart',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
})
export class AlertComponent {
  constructor(public alertService: AlertService) {}
}
