import { AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  alphaNumCheck,
  birthDateMajorValidate,
  emailCheck,
  identityNumberCheck,
  phoneMobileNumberCheck,
  validateCountry,
  validateDepartment,
  validateName,
  validatePhoneAndEmail,
} from '../form/validators';
import { CartService } from '@services/cart.service';
import { SchemeHelper } from '../../scheme.helper';
import { ToolsService } from '@base/services/tools.service';
import { CustomerType, InsuranceDataIdentity } from '../customer.interface';
import { IPandoraDocumentSerialize } from '@interfaces/IPandoraDocumentSerialize';
import { Customer } from '../customer';
import { BasicObject } from '@base/base.interfaces';
import { Plan } from '@model/catalog/products/subscription/plan';
import { Phone } from '@model/catalog/products/equipement/complex/phone';
import { AccessOnlineOrder } from '@model/catalog/products/equipement/accessory/accessOnlineOrder';
import { SimReplace } from '@model/catalog/products/equipement/sim/sim-replace';

export class IdentityForm {
  public form: UntypedFormGroup;
  private controlsConfig: BasicObject;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customer: Customer,
    private cartService: CartService,
  ) {}

  public static formatBirthPlace(code: string | number): string {
    if (typeof code === 'number') {
      code = JSON.stringify(code);
    }
    if (code && code.length === 1) {
      code = '0' + code;
    }
    return code;
  }

  public build(
    documents: IPandoraDocumentSerialize[],
    identityNumberMandatory: boolean,
    emailAndPhoneNumberMandatory: boolean,
    insuranceDataIdentity?: InsuranceDataIdentity,
  ): UntypedFormGroup {
    this.controlsConfig = {
      civilite: [ToolsService.civilityFormat(this.customer.civility), [Validators.required]],
      nom: [this.customer.lastname || '', [Validators.required, validateName]],
      prenom: [this.customer.firstname || '', [Validators.required, validateName]],
      documentIdentiteNumero: [this.customer.identityDocumentNumber ? this.customer.identityDocumentNumber : null],
      powerletter: [this.customer.powerletter, [Validators.nullValidator]],
    };
    if (documents.length > 0) {
      this.updateControlsConfigWithDocuments(identityNumberMandatory, documents);
    }
    if (SchemeHelper.hasPlan(this.cartService.getCurrentScheme())) {
      this.controlsConfig['email'] = [this.customer.email || '', [emailCheck]];
      this.controlsConfig['telephone'] = [
        this.customer.phoneNumber,
        [Validators.maxLength(10), phoneMobileNumberCheck],
      ];
    }
    if (insuranceDataIdentity) {
      this.controlsConfig['email'] = [this.customer.email || '', [Validators.required, emailCheck]];
      this.controlsConfig['telephone'] = [
        this.customer.phoneNumber,
        [Validators.required, Validators.maxLength(10), phoneMobileNumberCheck],
      ];
      this.updateControlsConfigHasMedi7(insuranceDataIdentity);
    }

    if (this.hasPlanOrSimReplace()) {
      this.updateControlsConfigHasPlan();
    }
    this.form = emailAndPhoneNumberMandatory
      ? this.formBuilder.group(this.controlsConfig, { validator: validatePhoneAndEmail } as AbstractControlOptions)
      : this.formBuilder.group(this.controlsConfig);
    this.form.controls.documentIdentiteNumero.setValidators(
      identityNumberMandatory
        ? [Validators.required, alphaNumCheck, identityNumberCheck(this.form)]
        : [alphaNumCheck, identityNumberCheck(this.form)],
    );
    this.afterBuild();
    return this.form;
  }

  public afterBuild(): void {
    if (this.customer.editable && this.cartService.cart.schemes.length < 2) {
      return;
    }
    for (const key in this.form.controls) {
      if (
        !['documentIdentite', 'documentIdentiteNumero'].includes(key) &&
        this.form.controls[key].valid &&
        this.form.controls[key].value &&
        this.customer.lockedInfo.includes(key) &&
        (!this.customer.editable ||
          (this.customer.type === CustomerType.prospect &&
            this.cartService.cart.schemes.length > 1 &&
            this.shouldByPassIdentity()))
      ) {
        this.form.controls[key].disable();
      }
    }
  }

  private hasPlanOrSimReplace(): boolean {
    return (
      SchemeHelper.hasPlan(this.cartService.getCurrentScheme()) ||
      this.cartService.getProductsByType(SimReplace).length > 0
    );
  }

  private updateControlsConfigWithDocuments(
    identityNumberMandatory: boolean,
    documents: IPandoraDocumentSerialize[],
  ): void {
    this.controlsConfig['documentIdentite'] = [
      this.customer.identityDocumentType
        ? documents.filter(doc => doc.name === this.customer.identityDocumentType.name)[0]
        : null,
      identityNumberMandatory ? [Validators.required, Validators.min(1)] : [Validators.nullValidator],
    ];
  }

  private updateControlsConfigHasPlan(): void {
    this.controlsConfig['dateNaissance'] = [
      ToolsService.dateFormat(this.customer.dob, true),
      [Validators.required, Validators.maxLength(10), birthDateMajorValidate],
    ];
    this.controlsConfig['departementNaissance'] = [
      IdentityForm.formatBirthPlace(this.customer.birthplace),
      [Validators.required, Validators.minLength(2), validateDepartment],
    ];
  }

  private updateControlsConfigHasMedi7(insuranceDataIdentity: InsuranceDataIdentity): void {
    let countryValue: string;
    if (!!insuranceDataIdentity?.birthplaceCountry) {
      countryValue = insuranceDataIdentity?.birthplaceCountry;
    } else {
      countryValue = this.customer.birthplace === '99' || this.customer.birthplace === '' ? '' : 'FR';
    }
    this.controlsConfig['paysNaissance'] = [
      countryValue,
      [Validators.required, Validators.minLength(2), validateCountry],
    ];
    this.controlsConfig['villeNaissance'] = [
      insuranceDataIdentity?.birthplaceCity || '',
      [Validators.required, Validators.minLength(2)],
    ];
    let nationalityValue: string;
    if (!!insuranceDataIdentity?.nationality) {
      nationalityValue = insuranceDataIdentity?.nationality;
    } else {
      nationalityValue = this.customer.birthplace === '99' || this.customer.birthplace === '' ? '' : 'FR';
    }
    this.controlsConfig['nationalite'] = [
      nationalityValue,
      [Validators.required, Validators.minLength(2), validateCountry],
    ];
    const specificInsuranceEmail =
      !!insuranceDataIdentity?.insuranceEmail && insuranceDataIdentity?.insuranceEmail !== this.customer.email;
    this.controlsConfig['emailAssurance'] = [
      specificInsuranceEmail ? insuranceDataIdentity?.insuranceEmail : '',
      specificInsuranceEmail ? [Validators.required, emailCheck] : [Validators.nullValidator],
    ];
    this.controlsConfig['specificInsuranceEmail'] = [specificInsuranceEmail, [Validators.nullValidator]];
  }

  private shouldByPassIdentity(): boolean {
    const hasPlan = this.cartService.cart.schemes[0].getProductsByType(Plan).length > 0;
    const hasPhone = this.cartService.cart.schemes[0].getProductsByType(Phone).length > 0;
    const hasAccessOnlineOrder = this.cartService.cart.schemes[0].getProductsByType(AccessOnlineOrder).length > 0;
    return hasPlan || hasPhone || hasAccessOnlineOrder;
  }
}
