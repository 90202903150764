import { AfterViewInit, Component, importProvidersFrom, OnDestroy, OnInit, viewChild } from '@angular/core';
import { CartService } from '@services/cart.service';
import { IdentityProComponent } from './identity/pro/identity.pro.component';
import { IdentityComponent } from './identity/identity.component';
import { AddressComponent } from './address/address.component';
import { PaymentComponent } from './payment/payment.component';
import { CustomerService, OPEN_BANKING_SOURCE } from './customer.service';
import { Step } from '@components/stepper/step.abstract';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { Observable, Subscription } from 'rxjs';
import { CustomerStep, CustomerStepEnum, LastStepEnum } from './customer.interface';
import { StepCustomerComponent } from './step-customer.component';
import { LocationService } from '@base/services/location.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';

export interface User {
  name: string;
  address?: {
    street?: string;
    postcode?: string;
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const provideCustomer = () => [
  importProvidersFrom(CommonModule, ReactiveFormsModule, FormsModule, NgxGpAutocompleteModule),
  {
    provide: Loader,
    useValue: new Loader({
      apiKey: '',
      libraries: ['places'],
    }),
  },
];

@Component({
  selector: 'rcbt-cart-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  standalone: true,
  imports: [IdentityProComponent, IdentityComponent, AddressComponent, PaymentComponent],
})
export class CustomerComponent extends Step implements OnInit, AfterViewInit, OnDestroy {
  readonly stepIdentityPro = viewChild<IdentityProComponent>('stepIdentityPro');
  readonly stepIdentity = viewChild<IdentityComponent>('stepIdentity');
  readonly stepAddress = viewChild<AddressComponent>('stepAddress');
  readonly stepPayment = viewChild<PaymentComponent>('stepPayment');

  public currentStep: CustomerStep;
  public isPro: boolean;
  public addressDisabled: boolean;
  public paymentDisabled: boolean;
  public subscriptions: Subscription = new Subscription();
  public readonly customerSteps = CustomerStepEnum;
  public readonly maxSteps = LastStepEnum;
  private defaultStepIndex = 0;

  constructor(
    protected cartService: CartService,
    public customerService: CustomerService,
    protected stepperService: CheckoutStepperService,
    private locationService: LocationService,
  ) {
    super();
    this.setCurrentStepComponent(this.stepperService);
  }

  public ngOnInit(): void {
    if (this.locationService.params['source'] === OPEN_BANKING_SOURCE) {
      this.defaultStepIndex = 2;
    }
    this.isPro = this.customerService.customer.isPro();
    this.customerService.resetCurrentStep(this.defaultStepIndex);
    this.currentStep = this.customerService.currentStep;
    this.customerService.setNbSteps(this.cartService.getCurrentScheme());
    this.setCurrentStepComponent(this.stepperService);
    this.subscriptions.add(
      this.customerService.forceSubmitAddress.subscribe(() => {
        this.stepAddress().onAddressSubmit().subscribe();
      }),
    );

    this.subscriptions.add(this.customerService.changeStepRequest.subscribe(step => this.onChangeStep(step)));
  }

  public ngAfterViewInit(): void {
    if (this.customerService.isClient() || this.cartService.cart.schemes.length > 1) {
      this.addressDisabled = false;
      this.paymentDisabled = false;
    } else {
      this.addressDisabled = this.currentStep.value === CustomerStepEnum.identity;
      this.paymentDisabled = this.addressDisabled || this.currentStep.value === CustomerStepEnum.address;
    }
  }

  public onChangeStep(nextStep: CustomerStep): void {
    if (nextStep.order < this.customerService.currentStep.order) {
      this.setCurrentStep(nextStep);
    } else if (nextStep.order === this.customerService.currentStep.order + 1) {
      if (!this.getStep().submitDisabled()) {
        if (nextStep.submit) {
          this.getStep().submitStep();
        } else {
          this.setCurrentStep(nextStep);
        }
      }
    }
  }

  public valideSubmit(): boolean {
    const stepId: StepCustomerComponent = this.isPro ? this.stepIdentityPro() : this.stepIdentity();
    switch (this.customerService.nbSteps) {
      case LastStepEnum.identity:
        return stepId?.valideSubmit();
      case LastStepEnum.address:
        return (
          !stepId?.submitDisabled() &&
          this.stepAddress()?.valideSubmit() &&
          this.currentStep.value === CustomerStepEnum.address
        );
      case LastStepEnum.payment:
        return (
          !stepId?.submitDisabled() &&
          !this.stepAddress()?.submitDisabled() &&
          this.stepPayment()?.valideSubmit() &&
          this.currentStep.value === CustomerStepEnum.payment
        );
    }
  }

  public submit(): Observable<boolean> {
    const stepId: Step = this.isPro ? this.stepIdentityPro() : this.stepIdentity();
    switch (this.customerService.nbSteps) {
      case LastStepEnum.identity:
        return stepId?.submit();
      case LastStepEnum.address:
        return this.stepAddress().submit();
      case LastStepEnum.payment:
        return this.stepPayment().submit();
    }
  }

  public ngOnDestroy(): void {
    this.customerService.resetCurrentStep();
    this.subscriptions.unsubscribe();
  }

  private getStep(step?: CustomerStepEnum): StepCustomerComponent {
    step = step || this.currentStep.value;
    switch (step) {
      case CustomerStepEnum.identity:
        return this.isPro ? this.stepIdentityPro() : this.stepIdentity();
      case CustomerStepEnum.address:
        return this.stepAddress();
      case CustomerStepEnum.payment:
        return this.stepPayment();
    }
  }

  private setCurrentStep(step: CustomerStep): void {
    delete step.submit;
    this.currentStep = step;
    this.customerService.currentStep = step;
    this.customerService.changeStepNotif.emit(step);

    if ([CustomerStepEnum.address, CustomerStepEnum.payment].includes(this.currentStep.value)) {
      this.customerService.updateDataOnStepEvent(this.getStep());
    }
    if (!this.customerService.isClient() && this.cartService.cart.schemes.length <= 1) {
      this.addressDisabled = this.currentStep.value === CustomerStepEnum.identity;
      this.paymentDisabled = this.addressDisabled || this.currentStep.value === CustomerStepEnum.address;
    }
  }
}
