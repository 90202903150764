import { Injectable } from '@angular/core';
import { StorageService } from '@base/services/storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Step } from '@components/stepper/step.abstract';
import { StepSubmitInterface } from '@interfaces/step-submit.interface';
import { StepperSerialized } from '@interfaces/stepper-serialized.interface';
import { StepSerialized } from '@interfaces/step-serialized.interface';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable()
export class StepperService {
  public changesCurrentStep: BehaviorSubject<Step> = new BehaviorSubject(null);
  public onChangesCurrentStep: Observable<Step> = this.changesCurrentStep.asObservable();
  public currentStepComponent: StepSubmitInterface;
  public steps: Step[] = [];
  protected stepsOrder: string[] = [];

  constructor(
    protected stepperStorageService: StorageService,
    protected router: Router,
    protected location: Location,
  ) {}

  public reset(): void {
    this.steps.forEach(step => (step.isActive = false));
  }
  public getStepByCode(code: string): Step {
    return this.steps.filter(step => step.code === code)[0];
  }

  /**
   * Deactivate current step and switch to parameter step
   * Update storage
   * @param index
   */
  public goToStep(step: Step, params: Record<string, string> = {}): void {
    const currentStepCode = this.getCurrentStep().code;
    this.getCurrentStep().isActive = false;
    step.isActive = true;
    if (step.hasPath()) {
      if (!this.router.isActive(step.path, true) || !this.location.isCurrentPathEqualTo(step.path)) {
        this.changeStep(step, currentStepCode, params);
      }
    }
    this.changesCurrentStep.next(step);
    this.save(this.serialize());
  }

  /**
   * Return current step object
   * @returns {Step}
   */
  public getCurrentStep(): Step {
    if (this.steps.filter(step => step.isActive).length > 0) {
      return this.steps.filter(step => step.isActive)[0];
    }
    for (const step of this.steps) {
      if (step.isAllowed) {
        return step;
      }
    }
    return this.steps[0];
  }

  public canSelectStep(step: Step): boolean {
    const currentStep: Step = this.getCurrentStep();
    const currentStepPosition: number = this.getStepPosition(currentStep);
    const newStepPosition: number = this.getStepPosition(step);

    return (
      newStepPosition <= currentStepPosition + (this.getCurrentStep().isSubmitted ? 1 : 0) &&
      newStepPosition < this.steps.length &&
      newStepPosition >= 0
    );
  }

  public getStepPosition(step: Step): number {
    if (step) {
      return this.stepsOrder.indexOf(step.code);
    } else {
      return 0;
    }
  }

  public getNextStep(): Step {
    let stepPosition: number = this.getStepPosition(this.getCurrentStep());
    while (stepPosition + 1 < this.steps.length) {
      stepPosition++;
      if (this.steps[stepPosition].isAllowed) {
        return this.steps[stepPosition];
      }
    }
    return null;
  }
  public getSummaryStep(): Step {
    return this.steps.find((step: Step) => step.code === 'summary');
  }

  public getPreviousStep(): Step {
    let stepPosition: number = this.getStepPosition(this.getCurrentStep());
    while (stepPosition > 0) {
      stepPosition--;
      if (this.steps[stepPosition].isAllowed) {
        return this.steps[stepPosition];
      }
    }
    return null;
  }

  /**
   * Create steps object from unserialized storage data
   */
  public unserialize(): void {
    if (this.stepperStorageService.key['steps']) {
      const stepperData = this.stepperStorageService.getItem<StepperSerialized>(
        this.stepperStorageService.key['steps'],
      );
      if (stepperData && stepperData !== null) {
        const tmpSteps: Step[] = [];
        stepperData.steps.forEach(step => {
          tmpSteps.push(this.unserializeStep(step));
        });
        this.steps = tmpSteps;
      }
    }
  }

  /**
   * Serialize and save steps into storage
   * @returns {StepperSerialized}
   */
  public serialize(): StepperSerialized {
    const stepperSerialized: StepperSerialized = { steps: [] };
    for (const key in this.steps) {
      stepperSerialized.steps.push(this.steps[key].serialize());
    }
    return stepperSerialized;
  }

  public getStepsOrder(): string[] {
    return this.stepsOrder;
  }

  public createStep(stepCode: string): Step {
    for (const step of this.steps) {
      if (step.code === stepCode) {
        return step;
      }
    }
    throw new Error('cannot instanciate Step.');
  }

  public unserializeStep(serializedData: StepSerialized): Step {
    const step: Step = this.createStep(serializedData.code);
    step.isActive = serializedData.isActive;
    step.isAllowed = serializedData.isAllowed;
    step.isReady = serializedData.isReady;
    step.isSubmitted = serializedData.isSubmitted;
    return step;
  }

  public findFirstStep(): Step {
    return this.steps.find((step: Step) => step.isAllowed);
  }

  /**
   * Save serialized steps into storage
   * @param data
   */
  protected save(data: StepperSerialized): void {
    if (this.stepperStorageService.key['steps']) {
      this.stepperStorageService.setItem(this.stepperStorageService.key['steps'], data);
    }
  }

  protected changeStep(step: Step, currentStepCode: string, params): void {
    this.router.navigate([step.path], { queryParams: params });
  }
}
