import { Component, OnInit } from '@angular/core';
import { CartService } from '@services/cart.service';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { BrowseType } from '../../../constants/browse-type';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
  selector: 'rcbt-category-nav-bar',
  templateUrl: 'category-nav-bar.component.html',
  standalone: true,
  styleUrls: ['./category-nav-bar.component.scss'],
  imports: [RouterLink, RouterLinkActive, NgClass],
})
export class CategoryNavBarComponent implements OnInit {
  public telephoneRoute: string[];
  public isRenew = false;
  public category: string;
  public hasSim: boolean;
  constructor(
    private cartService: CartService,
    private customerService: CustomerService,
  ) {}

  public ngOnInit(): void {
    // les CA on le droit d'acceder à l'onglet accessoire pas cohenrent avec la sag vs pratique
    // const codeEns = this.contextService.get<UserContextService>(UserContextService).codeEns;
    // if (Number(codeEns) === USER_ENS_TYPE.CA) {
    //     this.isCA = true;
    // }
    this.isRenew = this.cartService.getCurrentScheme().browseType === BrowseType.renew;
    this.telephoneRoute = this.isRenew ? ['/category/', 'telephones', 'renew'] : ['/category/', 'telephones'];
    this.hasSim =
      this.customerService.customer.ligneMarche === 'FNB' ||
      (['1', '2'].indexOf(this.customerService.customer.ligneMarche) !== -1 &&
        this.customerService.customer.telephonePrepaye.length > 0);
  }
}
