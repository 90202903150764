import {
  CartScheme,
  ConsulterCatalogueContextualiseAvecPanier200Response,
  WallGetResponseDetailProductDto,
  WallGetResponseProductDto,
  WallGetResponseProductFundingDto,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import {
  IFundingCtx,
  IPostCatalogResponse,
  IVirtualProductCtx,
  IVirtualSchemeCtx,
} from './contextualized-product-output.dto';
import { PaymentType } from '@interfaces/cart.interface';

export class CatalogOutputHelperDto {
  public static convertCatalogResponse(
    catalogResult: ConsulterCatalogueContextualiseAvecPanier200Response,
  ): IPostCatalogResponse[] {
    return catalogResult.produits.map(product => CatalogOutputHelperDto.toProductContextualized(product));
  }

  private static toVirtualProductCtx(virtualProduct: WallGetResponseProductDto): IVirtualProductCtx {
    return {
      ...virtualProduct,
      financements: CatalogOutputHelperDto.ToFundingsCtx(virtualProduct.financements),
      eligibilites: virtualProduct['eligibilites'],
    };
  }

  private static ToFundingsCtx(fundings: WallGetResponseProductFundingDto[]): IFundingCtx[] {
    return fundings.map(funding => ({ ...funding, type: CatalogOutputHelperDto.fundingTypeToEnum(funding.type) }));
  }

  private static toProductContextualized(productDetail: WallGetResponseDetailProductDto): IPostCatalogResponse {
    return {
      ...CatalogOutputHelperDto.toVirtualProductCtx(productDetail),
      panierSimule: {
        promotions: { ...(productDetail.panierSimule?.promotions || []) },
        parcours: CatalogOutputHelperDto.toVirtualSchemesCtx(productDetail.panierSimule?.parcours || []),
        financements: CatalogOutputHelperDto.ToFundingsCtx(productDetail.panierSimule?.financements || []),
      },
    };
  }

  private static toVirtualSchemesCtx(schemes: CartScheme[]): IVirtualSchemeCtx[] {
    return schemes.map(scheme => ({
      promotions: scheme.promotions,
      produits: scheme.produits.map(schemeProduct => CatalogOutputHelperDto.toVirtualProductCtx(schemeProduct)),
      estCourant: scheme.estCourant,
    }));
  }

  private static fundingTypeToEnum(fundingType: string): PaymentType {
    switch (fundingType) {
      case 'EDP':
        return PaymentType.edp;
      case 'YOUNITED_X3':
        return PaymentType.credit3;
      case 'YOUNITED_X12':
        return PaymentType.credit12;
      case 'YOUNITED_X24':
        return PaymentType.credit24;
      case 'YOUNITED_X36':
        return PaymentType.credit36;

      default:
        return PaymentType.cash;
    }
  }
}
