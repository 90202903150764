<div class="main-content">
  <div class="rows">
    <div class="row p-y-0 is-flex-nowrap">
      @if (!isRenew) {
        <div class="column is-half p-0 m-0">
          <div class="label has-text-tertiary m-0">Offre</div>
        </div>
      }
      <div class="column is-half p-0 m-0">
        <div class="label has-text-tertiary m-0">Paiement</div>
      </div>
    </div>

    <div class="row p-y-0 is-flex-nowrap">
      @if (!isRenew) {
        <div class="column is-half p-0 m-0">
          <div class="is-flex is-justified-start">
            @for (categoryPlan of plansType; track categoryPlan) {
              @if (!isPro || isBox ? true : marketLine === 'PRO' ? categoryPlan.isPro || categoryPlan.category === 'byou_rcbt' : !categoryPlan.isPro) {
                <div class="radio-tile is-horizontal m-y-10 m-r-10">
                  <input
                    type="radio"
                    name="planType"
                    id="{{ categoryPlan.type }}"
                    [disabled]="loading$ | async"
                    [(ngModel)]="planType"
                    value="{{ categoryPlan.type }}"
                    (ngModelChange)="updateCategory()"
                  />
                  <label for="{{ categoryPlan.type }}" class="radio-label" [attr.data-cy]="'categoryPlan_QV_' + categoryPlan.category"
                    ><span class="radio-title">{{ categoryPlan.label }}</span></label
                  >
                </div>
              }
            }
          </div>
        </div>
      }
      <div class="column is-half p-0 m-0">
        <div class="is-flex is-justified-start">
          <div class="radio-tile is-horizontal m-y-10 m-r-10">
            <input type="radio" name="switch" id="radioCash" value="cash" [disabled]="loading$ | async" [(ngModel)]="paymentMode" (ngModelChange)="refreshPlans()" />
            <label for="radioCash" class="radio-label" data-cy="comptant"><span class="radio-title">Comptant</span></label>
          </div>
          @if (showEdpPayment()) {
            <div class="radio-tile is-horizontal m-y-10 m-r-10">
              <input type="radio" name="switch" id="radioEdp" value="edp" [disabled]="loading$ | async" [(ngModel)]="paymentMode" (ngModelChange)="refreshPlans()" />
              <label for="radioEdp" class="radio-label" data-cy="edp"><span class="radio-title">EDP</span></label>
            </div>
          }
          @if (sapicCreditAvailable && isPremiumSelected()) {
            <div class="radio-tile is-horizontal m-y-10 m-r-10">
              <input
                type="radio"
                name="switch"
                id="radioCredit"
                value="credit"
                [disabled]="(loading$ | async) || !isPremiumSelected()"
                [(ngModel)]="paymentMode"
                (ngModelChange)="refreshPlans()"
              />
              <label for="radioCredit" class="radio-label" data-cy="credit"><span class="radio-title">Crédit</span></label>
            </div>
          }
        </div>
      </div>
    </div>

    <div class="row">
      <div class="divider dividerStyle m-0"></div>
      <ng-container>
        <div class="row plans-case p-t-20" [ngClass]="{ 'with-line-choice': isPro && !isBox }">
          <!-- Plan list -->
          <ul class="plan-list column p-t-0" data-cy="plans-list">
            @for (planQvScheme of plansQvSchemes; track planQvScheme) {
              <li
                class="row plan-item p-y-0 p-b-0"
                [ngClass]="{
                  'plan-in-cart has-background-grey-light': currentPlan === planQvScheme.plan.ctxProduct.gencode,
                  'plan-owned has-background-grey-light': renewOwnedPlan === planQvScheme.plan.ctxProduct.gencode,
                }"
                [attr.data-cy]="'plans-row' + (renewOwnedPlan === planQvScheme.plan.ctxProduct.gencode ? '-owned' : '')"
              >
                <!-- Price Premium -->
                <div class="columns">
                  @if (!planQvScheme.equipement.credits) {
                    <div class="column p-y-0 is-4 plan-price premium">
                      <!-- Old price -->
                      <div class="price is-size-4 is-striked">
                        @if (planQvScheme.equipement.oldPrice > planQvScheme.equipement.price) {
                          <div [innerHTML]="planQvScheme.equipement.oldPrice | customCurrency"></div>
                        }
                      </div>
                      <!-- Regular price -->
                      <div class="price is-size-1 m-l-5 p-y-0 row" [innerHTML]="planQvScheme.equipement.price | customCurrency"></div>
                      <!-- EDP -->
                      @if (planQvScheme.equipement.edp) {
                        <div class="price is-main is-inlined has-text-info price-size p-t-0">
                          +
                          <span [innerHTML]="planQvScheme.equipement.edp.montantMensualite | customCurrency"></span>
                          <span class="period">/mois</span>
                        </div>
                      }
                    </div>
                  } @else {
                    <div class="column p-y-0 is-4 plan-price premium">
                      @for (credit of planQvScheme.equipement.credits; track credit) {
                        <div class="has-text-weight-semibold">
                          <span class="has-text-info is-size-5" [innerHtml]="credit.apportInitial | customCurrency"></span>
                          +<span class="has-text-info is-size-6" [innerHtml]="credit.montantMensualite | customCurrency"></span>
                          <span>x {{ credit.nbrEcheances }} mois</span>
                          <span class="has-text-primary"> TAEG {{ credit.tAEG }} % </span>
                        </div>
                      }
                      @for (credit of planQvScheme.equipement.credits; track credit) {
                        <div>
                          @if (credit.prefere) {
                            <div class="inline m-t-1 is-size-8">
                              (Coût total sur {{ credit.nbrEcheances }} mois<span
                                class="regular-price price has-text-info p-l-0 has-text-weight-semibold is-size-4 m-l-10"
                                [innerHtml]="credit.coutTotalFinancement | customCurrency"
                              ></span>
                              )
                            </div>
                          }
                        </div>
                      }
                    </div>
                    <br />
                  }
                  <!-- Plan name -->
                  <div class="column p-y-0 is-6">
                    <span class="row text is-level-1 has-text-weight-semibold textMargin">
                      {{ planQvScheme.plan.ctxProduct.nom }}
                    </span>
                    <!-- Plan price -->
                    <div class="row">
                      <rcbt-price
                        class="price-component is-flex is-justified-start"
                        [mainPrice]="true"
                        [fontLevel]="5"
                        [priceType]="1"
                        [oldPrice]="planQvScheme.plan.oldPrice"
                        [price]="planQvScheme.plan.price"
                      >
                      </rcbt-price>
                    </div>
                    @if (planQvScheme?.plan?.eligibilites?.length > 0 && planQvScheme?.plan?.eligibilites?.includes('Foyer')) {
                      <div class="tag is-large is-hat m-t-20">
                        <span class="is-flex is-justified-center">B.iG</span>
                      </div>
                    }
                  </div>
                  <!-- Actions -->
                  @if (scanCode) {
                    <div class="column is-2">
                      <button
                        type="button"
                        class="button is-primary is-icon-only"
                        (click)="add(planQvScheme.equipement.ctxProduct.gencode, planQvScheme.plan)"
                        [attr.data-cy]="'plan_QV_' + planQvScheme.plan.ctxProduct.gencode"
                        [ngClass]="{ 'action-feedback': (loading$ | async) || addLoading }"
                        [disabled]="(loading$ | async) || addLoading"
                      >
                        <span class="icon is-small" aria-label="Ajout au panier">
                          <i class="tri-shopping-cart" aria-hidden="true"></i>
                        </span>
                      </button>
                    </div>
                  }
                </div>
              </li>
              <div class="divider"></div>
            }
          </ul>
        </div>
        <div class="p-y-0 row phone-only">
          <!-- Phone only -->
          @if (!!equipmentQvScheme?.equipement) {
            <span class="column has-background-grey-light">
              <div class="columns">
                @if (!showCreditPaymentFlag) {
                  <div class="column is-4">
                    @if (equipmentQvScheme.equipement.oldPrice > equipmentQvScheme.equipement.price) {
                      <div class="old-price">
                        <div [innerHTML]="equipmentQvScheme.equipement.oldPrice | customCurrency"></div>
                      </div>
                    }
                    <div class="title is-level-4" [innerHTML]="equipmentQvScheme.equipement.price | customCurrency"></div>
                    @if (equipmentQvScheme.equipement.edp) {
                      <div class="has-text-weight-semibold flat-price">
                        +<span class="has-text-info" [innerHTML]="equipmentQvScheme.equipement.edp.montantMensualite | customCurrency"></span>/mois
                      </div>
                    }
                  </div>
                }
                @if (showCreditPaymentFlag) {
                  <div class="column is-4">
                    <div [innerHTML]="equipmentQvScheme.equipement.oldPrice | customCurrency"></div>
                  </div>
                }
                <h4 class="column is-6">{{ productLabel }} seul</h4>
                @if (scanCode && !isRenew) {
                  <div class="column is-2">
                    <button
                      class="button is-primary is-icon-only"
                      data-cy="phone-only"
                      [ngClass]="{ 'action-feedback': (loading$ | async) || addLoading === equipmentQvScheme.equipement.ctxProduct.gencode }"
                      [disabled]="(loading$ | async) || addLoading"
                      (click)="addPhoneOnly()"
                    >
                      <span class="icon is-small" aria-label="Ajout au panier">
                        <i class="tri-shopping-cart" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                }
              </div>
            </span>
          }
        </div>
      </ng-container>
    </div>
  </div>
</div>
