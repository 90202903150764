import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LogService } from '@base/services/log.service';
import { TimeoutService } from '@base/services/timeout.service';

@Injectable({
  providedIn: 'root',
})
export class HttpCustomInterceptor implements HttpInterceptor {
  constructor(
    private logService: LogService,
    private timeoutService: TimeoutService,
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone();

    return next.handle(request).pipe(
      tap(
        event => event,
        err => {
          if (err instanceof HttpErrorResponse) {
            this.logService.setFrontStorageLogs(request, err);
            if (err.status === 502 || err.status === 503 || err.status === 504) {
              if (
                err.url &&
                err.url.indexOf('/ventes/') > -1 &&
                this.isApiUrlAllowed(err.url) &&
                this.isAngularUrlAllowed()
              ) {
                this.timeoutService.notify();
              }
            }
          }
        },
      ),
    );
  }

  private isApiUrlAllowed(url: string): boolean {
    const blackList: string[] = ['listerAvantages', 'demat'];
    return blackList.every((s: string) => !url.match(s));
  }

  private isAngularUrlAllowed(): boolean {
    const blackList: string[] = ['prix-accessoires'];
    return blackList.every((s: string) => !window.location.href.match(s));
  }
}
