import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Medi7PaymentMode } from './payment.component';
import { CheckoutNs } from '@interfaces/checkout.interface';
import { BasicObject } from '@base/base.interfaces';

export interface IPaymentForm {
  iban: string;
  proofOfPayment: string;
  paymentMode: CheckoutNs.PaymentMode;
}

export class PaymentForm {
  public form: UntypedFormGroup;
  public submit = false;
  private controlsConfig: BasicObject;

  constructor(private formBuilder: UntypedFormBuilder) {}

  public build(hasMedi7: boolean, paymentAccount: CheckoutNs.IPayment): UntypedFormGroup {
    this.controlsConfig = {
      iban: [paymentAccount ? paymentAccount.iban : null, [Validators.required]],
      proofOfPayment: [paymentAccount ? paymentAccount.proofOfPayment : '', [Validators.nullValidator]],
      paymentMode: [CheckoutNs.PaymentMode.prelevement, [Validators.required]],
      paymentModeFMS: ['0', [Validators.nullValidator]],
    };

    if (hasMedi7) {
      this.controlsConfig['insurancePaymentMode'] = [Medi7PaymentMode.bytelIban, [Validators.required]];
      this.controlsConfig['insuranceIban'] = ['', [Validators.nullValidator]];
      this.controlsConfig['insuranceBic'] = ['', [Validators.nullValidator]];
    }

    this.form = this.formBuilder.group(this.controlsConfig);
    this.afterBuild(paymentAccount);
    return this.form;
  }

  private afterBuild(paymentAccount: CheckoutNs.IPayment): void {
    if (paymentAccount) {
      this.form.patchValue({ iban: paymentAccount.iban });
    }
  }
}
