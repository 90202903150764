import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CartService } from '@services/cart.service';
import { Scheme } from '@model/scheme.class';
import { Product } from '@model/catalog/products/product';
import { Subscription } from '@model/catalog/products/subscription';
import { Customer } from '../customer';
import { BrowseConfig } from '../../../../context/child/browse-config.class';

export interface IGenericAddressParams {
  streetNumber?: string;
  street?: string;
  city?: string;
  postcode?: string;
  street2?: string;
}

export class GenericAddressForm {
  public form: UntypedFormGroup;
  public addressParams: IGenericAddressParams;

  // isDisable :
  //    true : fields are disable
  //    false : automatic detection of disable
  //    undefined : fields are systematicaly enable
  constructor(
    private formBuilder: UntypedFormBuilder,
    private cartService: CartService,
    private isDisabled?: boolean,
  ) {}

  public getAddressText(): string {
    let addresstext = '';
    if (this.addressParams && this.addressParams.streetNumber) {
      addresstext += this.addressParams.streetNumber;
    }
    if (this.addressParams && this.addressParams.street) {
      addresstext += ` ${this.addressParams.street}`;
    }
    if (this.addressParams && this.addressParams.postcode) {
      addresstext += ` ${this.addressParams.postcode}`;
    }
    if (this.addressParams && this.addressParams.city) {
      addresstext += ` ${this.addressParams.city}, France`;
    }
    return addresstext;
  }

  public build(
    address: IGenericAddressParams,
    browseConfig?: BrowseConfig,
    customerContext?: Customer,
  ): UntypedFormGroup {
    this.addressParams = address;
    const config = {
      address: [this.getAddressText(), [Validators.required]],
      numeroRue: [this.addressParams?.streetNumber ?? '', [Validators.maxLength(15)]],
      rue: [this.addressParams?.street ?? '', [Validators.required, Validators.nullValidator]],
      complementAdresse: [this.addressParams?.street2 ?? '', [Validators.nullValidator]],
      ville: [this.addressParams?.city ?? '', [Validators.required, Validators.minLength(1)]],
      codePostal: [this.addressParams?.postcode ?? '', [Validators.required, Validators.pattern('[0-9]{5}')]],
    };
    if (
      customerContext &&
      !customerContext.isClient &&
      browseConfig &&
      !(browseConfig.civility && browseConfig.firstname && browseConfig.lastname)
    ) {
      config['civility'] = this.formBuilder.control(this.civilityFormat(customerContext.civility), [
        Validators.required,
      ]);
      config['firstname'] = this.formBuilder.control((customerContext.firstname || '').substring(0, 30), [
        Validators.required,
      ]);
      config['lastname'] = this.formBuilder.control((customerContext.lastname || '').substring(0, 30), [
        Validators.required,
      ]);
    }

    this.form = this.formBuilder.group(config);
    if (this.isDisabled) {
      this.afterBuild();
    }
    return this.form;
  }

  public afterBuild(): void {
    for (const key in this.form.controls) {
      if (this.form.valid && this.form.controls[key].valid && (this.isSchemeAlreadyHasAddress() || this.isDisabled)) {
        this.form.controls[key].disable();
      }
    }
  }

  protected isSchemeAlreadyHasAddress(): boolean {
    if (this.cartService.cart.schemes.length > 1) {
      if (
        this.cartService.cart.schemes.some(
          (scheme: Scheme) =>
            scheme.uniqueId !== this.cartService.getCurrentScheme().uniqueId &&
            scheme.products.some((p: Product) => !!(p instanceof Subscription)),
        )
      ) {
        return true;
      }
    }
    return false;
  }

  private civilityFormat(civility: string): string {
    const FORMAT = {
      mlle: /^mlle/i,
      mme: /^mme/i,
      madame: /^madam$/i,
      me: /^me$/i,
      mr: /^mr$/i,
      m: /^m$/i,
      monsieur: /^m$/i,
    };
    switch (true) {
      case FORMAT.mlle.test(civility):
      case FORMAT.mme.test(civility):
      case FORMAT.madame.test(civility):
      case FORMAT.me.test(civility):
        return 'MME';
      case FORMAT.mr.test(civility):
      case FORMAT.m.test(civility):
      case FORMAT.monsieur.test(civility):
        return 'M';
    }
    return '';
  }
}
