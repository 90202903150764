<div class="fixed-bottom">
  @if (comparatorService.active) {
    <div class="comparator-parameters">
      <div class="columns">
        <div class="column is-9">
          <div class="columns">
            @for (index of [0, 1, 2]; track index; let phone = $index) {
              <div class="column is-4">
                @if (comparatorService.selectedPhones[index]) {
                  <div class="card">
                    <div class="is-flex is-fullwidth">
                      <div class="phone-image is-flex is-justified-center">
                        @if (connectivityIconMap[comparatorService.selectedPhones[index].connectivite]) {
                          <span class="icon icon-left is-stretched is-small"><i [class]="connectivityIconMap[comparatorService.selectedPhones[index].connectivite]" aria-hidden="true"></i></span>
                        }
                        <img
                          defaultImage="/assets/images/placeholder.png"
                          alt="{{comparatorService.selectedPhones[index].marque}} {{comparatorService.selectedPhones[index].nom}} {{comparatorService.selectedPhones[index].capacite}} {{comparatorService.selectedPhones[index].couleurLibelle}}"
                          [src]="comparatorService.selectedPhones[index].image | media"
                          >
                      </div>
                      <div class="phone-text is-flex is-vertical is-aligned-center is-justified-center has-text-weight-semibold">
                        <p>{{comparatorService.selectedPhones[index].marque}} {{comparatorService.selectedPhones[index].nom}}</p>
                        <p>{{comparatorService.selectedPhones[index].capacite}} {{comparatorService.selectedPhones[index].couleurLibelle}}</p>
                      </div>
                    </div>
                    <div class="phone-close">
                      <span class="icon is-small" aria-label="Icone de taille small" (click)="comparatorService.removeProduct(comparatorService.selectedPhones[index].gencode)">
                        <i class="tri-times-circle" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                }
                @if (!comparatorService.selectedPhones[index]) {
                  <div class="card empty-card">
                    Sélectionner un téléphone ci-dessous
                  </div>
                }
              </div>
            }
          </div>
        </div>
        <div class="rows is-3 is-flex is-vertical is-aligned-center is-marginless">
          <div class="row">
            <button type="button" class="button is-primary" [disabled]="!comparatorService.isCompareButtonEnabled()"
            (click)="goToComparator()">Comparer</button>
          </div>
          <div class="row">
            <a (click)="resetComparator($event)" class="link">
              Tout supprimer
            </a>
          </div>
        </div>
      </div>
    </div>
  }
</div>
