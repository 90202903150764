/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from '@components/container/container.component';
import { ContainerBootstrapComponent } from '@base/container-bootstrap/container-bootstrap.component';
import { faiEligResolver } from '@fai-widget/resolverFn';
import { contextResolver } from './context/contextResolverFn';
import { prismeLoggerResolver } from './shared/prisme/prisme-logger.resolver';
import { CategoryComponent } from '@components/category/category.component';
import { renewResolver } from '@checkout/cart/renewResolverFn';
import { PricingUserService } from '@services/pricing-user-service';
import { CheckoutChainService } from '@services/checkout-chain.service';

const appRoutes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    resolve: { context: contextResolver },
    children: [
      {
        path: 'fai',
        component: ContainerBootstrapComponent,
        loadChildren: () => import('./fai-widget/fai-routing.module').then(m => m.FaiRoutingModule),
        resolve: { faiEligResolver },
      },
      {
        path: 'panier',
        component: ContainerBootstrapComponent,
        loadChildren: () => import('./checkout/checkout-routing.module').then(m => m.CheckoutRoutingModule),
        resolve: { checkoutChain: CheckoutChainService, prismeLoggerResolver },
      },
      {
        path: 'comparator',
        component: ContainerBootstrapComponent,
        children: [
          {
            path: '',
            loadComponent: () => import('@components/comparator/comparator.component').then(c => c.ComparatorComponent),
            resolve: { prismeLoggerResolver },
          },
        ],
      },
      {
        path: 'dispatch',
        component: ContainerBootstrapComponent,
        children: [
          {
            path: '',
            loadComponent: () => import('@components/dispatch/dispatch.component').then(c => c.DispatchComponent),
            resolve: { prismeLoggerResolver },
          },
        ],
      },
      {
        path: 'category/plan',
        component: ContainerBootstrapComponent,
        resolve: {
          prismeLoggerResolver,
        },
        children: [
          {
            loadComponent: () =>
              import('@components/category/category-plan/category-plan.component').then(c => c.CategoryPlanComponent),
            path: '',
            resolve: { prismeLoggerResolver },
          },
        ],
      },
      {
        path: 'category/:category',
        component: ContainerBootstrapComponent,
        resolve: {
          prismeLoggerResolver,
        },
        children: [
          {
            component: CategoryComponent,
            path: '',
            resolve: { prismeLoggerResolver },
            children: [
              {
                path: 'renew',
                component: CategoryComponent,
                resolve: { prismeLoggerResolver, renewResolver },
              },
            ],
          },
        ],
      },
      {
        path: 'pret-galet',
        component: ContainerBootstrapComponent,
        resolve: { prismeLoggerResolver },
        children: [
          {
            path: '',
            loadComponent: () => import('@components/pret-galet/pret-galet.component').then(c => c.PretGaletComponent),
            resolve: { prismeLoggerResolver },
          },
        ],
      },
      {
        path: 'prix-accessoires',
        component: ContainerBootstrapComponent,
        children: [
          {
            path: '',
            loadComponent: () =>
              import('@components/pricing-accessory/pricing-accessory.component').then(
                c => c.PricingAccessoryComponent,
              ),
            resolve: { context: contextResolver, userService: PricingUserService },
          },
        ],
      },
    ],
  },
  {
    path: 'comparator-pdf',
    component: ContainerBootstrapComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@components/comparator/comparator-pdf/comparator-pdf.component').then(c => c.ComparatorPdfComponent),
        resolve: { prismeLoggerResolver },
      },
    ],
  },
  {
    path: '403',
    component: ContainerBootstrapComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('@components/403/403.component').then(c => c.ForbiddenComponent),
      },
    ],
  },
  {
    path: 'client-configuration/preview',
    component: ContainerBootstrapComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('@components/preview/preview.component').then(c => c.PreviewComponent),
      },
    ],
  },
  {
    path: 'launcher',
    loadComponent: () => import('@components/launcher/launcher.component').then(c => c.LauncherComponent),
  },
  {
    path: 'scandit',
    loadComponent: () =>
      import('@components/scandit-external/scandit-external.component').then(c => c.ScanditExternalComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
