<div class="row nav-product-bar">
  <div class="col-12 p-0">
    <ul class="row product-bar" [ngClass]="{'hasSimReplace': !isRenew && hasSim}">
      @if (!isRenew) {
        <li class="col-12 col-md-auto list-item" >
          <a id="linnk_plans" class="item" [routerLink]="['/category','plan']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
            <span class="item-text">Forfaits</span>
          </a>
        </li>
      }

      <li class="col-12 col-md-auto list-item">
        <a id="link_telephones" class="item" [routerLink]="telephoneRoute" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <span class="item-text">Téléphones@if (!isRenew) {
            <span> & Hotspots</span>
          }</span>
        </a>
      </li>
      @if (!isRenew) {
        <li class="col-12 col-md-auto list-item" >
          <a id="link_cross_sell" class="item" [routerLink]="['/category','ventes-complementaires']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
            <span class="item-text">Ventes complémentaires</span>
          </a>
        </li>
      }
      @if (!isRenew && hasSim) {
        <li class="col-12 col-md-auto list-item">
          <a id="link_replaceSim" class="item" [routerLink]="['/category', 'replace-sim']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
            <span class="item-text">Replace Sim</span>
          </a>
        </li>
      }
    </ul>
  </div>
</div>
