import { Promotion } from './promotion.class';
import { IPromotion } from './promotions.interfaces';
import { PromotionTypes } from '@promotions/promotions.interfaces';
import { Regularization } from './promotion/regularization.class';
import { Agility } from './promotion/agility.class';
import { Automatic } from './promotion/automatic.class';
import { Manual } from './promotion/manual.class';
import { Cpu } from './promotion/cpu.class';
import { Coupon } from './promotion/coupon.class';
import { RegularizationCa } from './promotion/regularizationCa.class';
import { AgilityCa } from './promotion/agilityCa.class';

export class PromotionFactory {
  public static create<T extends Promotion>(promotionData: IPromotion): T {
    let instance: Promotion;
    switch (promotionData.type) {
      case PromotionTypes.regularization:
        instance = new Regularization();
        break;
      case PromotionTypes.agility:
        instance = new Agility();
        break;
      case PromotionTypes.automatic:
        instance = new Automatic();
        break;
      case PromotionTypes.manual:
        instance = new Manual();
        break;
      case PromotionTypes.cpu:
        instance = new Cpu();
        break;
      case PromotionTypes.coupon:
        instance = new Coupon();
        break;
      case PromotionTypes.regularizationCa:
        instance = new RegularizationCa();
        break;
      case PromotionTypes.agilityCa:
        instance = new AgilityCa();
        break;
      default:
        throw new Error('Type de promotion invalide !');
    }
    Object.assign(instance, JSON.parse(JSON.stringify(promotionData)));
    return <T>instance;
  }

  public static createCollection(promotionsData: IPromotion[]): Promotion[] {
    const promotions: Promotion[] = [];
    promotionsData.forEach((promotionData: IPromotion) => promotions.push(PromotionFactory.create(promotionData)));
    return promotions;
  }
}
