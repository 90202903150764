<article class="accordion" [ngClass]="{'is-active': isActive }">
  <div class="accordion-header columns" (click)="onClickTab(customerService.customerSteps.identity);">
    <div class="title is-size-1 column is-3" data-cy="title-id">Identité</div>
    @if (isAutoCompleteEnabled && customer.editable) {
      <div class="column is-justified-end is-aligned-end is-flex is-aligned-center"
        >
        <auto-complete-scan></auto-complete-scan>
      </div>
    }

    <button class="toggle is-3" aria-label="toggle"></button>
  </div>
  <div class="accordion-body">
    <hr>
      @if (identityForm) {
        <div class="accordion-content">
          <form class="form-data" [formGroup]="identityForm" novalidate data-cy="identity-form">
            <div class="fields-row">
              <div class="radio">
                <input id="radio-title-1" data-cy="radio-civilite" type="radio" value="MME" formControlName="civilite" name="radio-name"
                  [ngClass]="{'active': identityForm.get('civilite').value === 'MME', 'disabled': identityForm.get('civilite').disabled}">
                <label for="radio-title-1" class="radio-label" tabIndex="0">Madame</label>
              </div>
              <div class="radio">
                <input id="radio-title-2" data-cy="radio-civilite" type="radio" value="M" formControlName="civilite" name="radio-name"
                  [ngClass]="{'active': identityForm.get('civilite').value === 'M', 'disabled': identityForm.get('civilite').disabled}">
                <label for="radio-title-2" class="radio-label" tabIndex="1">Monsieur</label>
              </div>
              <small class="has-text-danger"
                [hidden]="!identityForm.controls.civilite.errors || identityForm.controls.civilite.valid || !hasPlan">
                Veuillez choisir la civilité !
              </small>
            </div>
            <div class="fields-row">
              <div class="field">
                <div class="control has-icons-right has-dynamic-placeholder">
                  <input id="input-nom" data-cy="input-nom" autocomplete="off" type="text" class="input" formControlName="nom"
                                [ngClass]="{'is-success' : identityForm.controls.nom.valid && identityForm.value.nom.length !== 0,
                                'is-danger' : identityForm.controls.nom.errors}">
                  <label for="input-nom" rcbtAsterisk [formcontrol]="identityForm.controls.nom">Nom</label>
                  <span class="icon">
                    @if (identityForm.controls.nom.valid && identityForm.value.nom.length !== 0) {
                      <i class="tri-check-circle has-background-success-60"
                      ></i>
                    }
                    @if (identityForm.controls.nom.errors) {
                      <i class="tri-exclamation-circle has-background-error-60"
                      ></i>
                    }
                  </span>
                </div>
                @if (identityForm.controls.nom.errors && identityForm.controls.nom.errors.nom) {
                  <small class="has-text-danger"
                    >
                    {{ identityForm.controls.nom.errors.nom.errors }}
                  </small>
                }
              </div>
              <div class="field">
                <div class="control has-icons-right has-dynamic-placeholder">
                  <input id="input-prenom" data-cy="input-prenom" autocomplete="off" type="text" class="input"
                                formControlName="prenom" [ngClass]="{'is-success' : identityForm.controls.prenom.valid && identityForm.value.prenom.length !== 0,
                                'is-danger' : identityForm.controls.prenom.errors}">
                  <label for="input-prenom" rcbtAsterisk
                  [formcontrol]="identityForm.controls.prenom">Prénom</label>
                  <span class="icon">
                    @if (identityForm.controls.prenom.valid && identityForm.value.prenom.length !== 0) {
                      <i class="tri-check-circle has-background-success-60"
                      ></i>
                    }
                    @if (identityForm.controls.prenom.errors) {
                      <i class="tri-exclamation-circle has-background-error-60"
                      ></i>
                    }
                  </span>
                </div>
                @if (identityForm.controls.prenom.errors && identityForm.controls.prenom.errors.prenom) {
                  <small class="has-text-danger"
                    >
                    {{ identityForm.controls.prenom.errors.prenom.errors }}
                  </small>
                }
              </div>
            </div>
            @if (hasPlan || hasReplaceSim()) {
              <div class="fields-row">
                <div class="field">
                  <div class="control has-icons-right has-dynamic-placeholder">
                    <input id="input-dateNaissance" data-cy="input-dateNaissance" autocomplete="off" type="text"
                      [dropSpecialCharacters]="false" mask="00/00/0000" class="input" formControlName="dateNaissance"
                                [ngClass]="{'is-success' : identityForm.controls.dateNaissance.valid,
                                'is-danger' : identityForm.controls.dateNaissance.errors,
                                'is-warning': birthDateEmancipeWarning}">
                    <label for="input-dateNaissance" rcbtAsterisk
                      [formcontrol]="identityForm.controls.dateNaissance">Date de naissance
                    (JJ/MM/AAAA)</label>
                    <span class="icon">
                      @if (identityForm.controls.dateNaissance.valid && !birthDateEmancipeWarning) {
                        <i class="tri-check-circle has-background-success-60"
                        ></i>
                      }
                      @if (identityForm.controls.dateNaissance.errors) {
                        <i class="tri-exclamation-circle has-background-error-60"
                        ></i>
                      }
                      @if (birthDateEmancipeWarning) {
                        <i class="tri-infos-circle has-background-warning-80"
                        ></i>
                      }
                    </span>
                  </div>
                  @if (identityForm.controls.dateNaissance.errors && identityForm.controls.dateNaissance.errors.dateNaissance && hasPlan) {
                    <small class="has-text-danger"
                      >
                      {{ identityForm.controls.dateNaissance.errors.dateNaissance.errors }}
                      {{ identityForm.controls.dateNaissance.errors.dateNaissance.major }}
                    </small>
                  }
                  @if (birthDateEmancipeWarning) {
                    <small class="has-text-warning-80">
                      {{ birthDateEmancipeWarning }}
                    </small>
                  }
                </div>
                <div class="field">
                  <div class="control has-dynamic-placeholder">
                    <rcbt-auto-complete [label]="'Département de naissance'" [idName]="'departementNaissance'" [suggestions]="departments" [inputControl]="identityForm.controls.departementNaissance"></rcbt-auto-complete>
                  </div>
                </div>
              </div>
            }
            @if (!isSaleOnly) {
              <div class="fields-row">
                <div class="field">
                  <div class="control has-icons-right has-dynamic-placeholder">
                    <input id="input-tel" data-cy="input-tel" autocomplete="off" type="text" class="input"
                                formControlName="telephone" maxlength="14" [mask]="'00 00 00 00 00'" [ngClass]="{'is-success' : identityForm.controls.telephone.valid && identityForm.controls.telephone.value?.length !== 0,
                                'is-danger' : identityForm.controls.telephone.errors || identityForm.errors?.telephone?.errors}">
                    <label for="input-tel" rcbtAsterisk [formcontrol]="identityForm.controls.telephone">Numéro
                      de téléphone mobile
                    </label>
                    <span class="icon">
                      @if (identityForm.controls.telephone.valid && identityForm.controls.telephone.value?.length !== 0 && !identityForm.errors?.telephone?.errors) {
                        <i class="tri-check-circle has-background-success-60"
                        ></i>
                      }
                      @if (identityForm.controls.telephone.errors || identityForm.errors?.telephone?.errors) {
                        <i class="tri-exclamation-circle has-background-error-60"
                        ></i>
                      }
                    </span>
                  </div>
                  <small class="has-text-danger"
                    [hidden]="!identityForm.controls.telephone.errors || identityForm.controls.telephone.valid || !hasPlan">
                    {{ identityForm.controls.telephone?.errors?.value }}
                  </small>
                  @if (!identityForm.controls.email?.value && !identityForm.controls.telephone?.value) {
                    <small class="has-text-danger">
                      {{ identityForm.errors?.email.errors }}
                    </small>
                  }
                </div>
                <div class="field">
                  <div class="control has-icons-right has-dynamic-placeholder">
                    <input id="input-email" data-cy="input-email" autocomplete="off" type="text" class="input"
                                formControlName="email" [ngClass]="{'is-success' : identityForm.controls.email.valid && identityForm.value.email.length !== 0,
                                'is-danger' : identityForm.controls.email.errors}">
                    <label for="input-email">
                      E-mail
                    </label>
                    <span class="icon">
                      @if (identityForm.controls.email.valid && identityForm.value.email.length !== 0) {
                        <i class="tri-check-circle has-background-success-60"
                        ></i>
                      }
                      @if (identityForm.controls.email.errors) {
                        <i class="tri-exclamation-circle has-background-error-60"
                        ></i>
                      }
                    </span>
                  </div>
                  <small class="has-text-danger"
                    [hidden]="!identityForm.controls.email.errors || identityForm.controls.email.valid || !hasPlan">
                    {{ identityForm.controls.email.errors && identityForm.controls.email.errors.value }}
                  </small>
                  @if (rCheckMailError) {
                    <small class="has-text-danger">{{ rCheckMailError }}</small>
                  }
                  @if (identityForm.controls.email && !identityForm.controls.email.value) {
                    <small class="has-text-info"
                      >
                      L'e-mail est nécessaire pour réceptionner les contrats dématérialisés et le code provisoire
                      de création de l'espace client permettant de recevoir ses factures.
                    </small>
                  }
                  @if (cartHasCredit && !identityForm.controls.email?.value) {
                    <small class="has-text-info"
                      ><br />L'email est nécessaire
                    pour souscrire à un crédit</small>
                  }
                </div>
              </div>
            }
            @if (!!medi7Product) {
              <div>
                <div class="fields-row">
                  <div class="field">
                    <div class="control has-dynamic-placeholder">
                      <rcbt-auto-complete [label]="'Pays de naissance'" [idName]="'paysNaissance'" [suggestions]="countries" [inputControl]="identityForm.controls.paysNaissance"></rcbt-auto-complete>
                    </div>
                    <small class="has-text-danger"
                      [hidden]="!identityForm.controls.paysNaissance.errors || identityForm.controls.paysNaissance.valid">
                      Veuillez choisir le pays de naissance !
                    </small>
                  </div>
                  <div class="field">
                    <div class="control has-icons-right has-dynamic-placeholder">
                      <input id="input-villeNaissance" data-cy="input-villeNaissance" autocomplete="off" type="text"
                                    class="input uppercase" formControlName="villeNaissance" [ngClass]="{'is-success' : identityForm.controls.villeNaissance.valid && identityForm.value.villeNaissance.length !== 0,
                                    'is-danger' : identityForm.controls.villeNaissance.errors}">
                      <label for="input-villeNaissance" rcbtAsterisk
                      [formcontrol]="identityForm.controls.villeNaissance">Ville de naissance</label>
                      <span class="icon">
                        @if (identityForm.controls.villeNaissance.valid && identityForm.value.villeNaissance.length !== 0) {
                          <i class="tri-check-circle has-background-success-60"
                          ></i>
                        }
                        @if (identityForm.controls.villeNaissance.errors) {
                          <i class="tri-exclamation-circle has-background-error-60"
                          ></i>
                        }
                      </span>
                    </div>
                    <small class="has-text-danger"
                      [hidden]="!identityForm.controls.villeNaissance.errors || identityForm.controls.villeNaissance.valid">
                      Veuillez saisir la ville de naissance !
                    </small>
                  </div>
                </div>
                <div class="fields-row">
                  <div class="field">
                    <div class="control has-dynamic-placeholder">
                      <rcbt-auto-complete [label]="'Nationalité'" [idName]="'nationalite'" [suggestions]="countries" [inputControl]="identityForm.controls.nationalite"></rcbt-auto-complete>
                    </div>
                    <small class="has-text-danger"
                      [hidden]="!identityForm.controls.nationalite.errors || identityForm.controls.nationalite.valid">
                      Veuillez choisir la nationalité !
                    </small>
                  </div>
                  @if (identityForm.value.specificInsuranceEmail) {
                    <div class="field">
                      <div class="control has-icons-right has-dynamic-placeholder">
                        <input id="input-emailAssurance" data-cy="input-emailAssurance" autocomplete="off" type="text"
                          class="input" formControlName="emailAssurance"
                                    [ngClass]="{'is-success' : identityForm.controls.emailAssurance.valid && identityForm.value.emailAssurance.length !== 0 && !rCheckInsuranceMailError,
                                    'is-danger' : identityForm.controls.emailAssurance.errors || rCheckInsuranceMailError}">
                        <label for="input-emailAssurance" rcbtAsterisk
                        [formcontrol]="identityForm.controls.emailAssurance">E-mail pour assurance</label>
                        <span class="icon">
                          @if (identityForm.controls.emailAssurance.valid && identityForm.value.emailAssurance.length !== 0 && !rCheckInsuranceMailError) {
                            <i class="tri-check-circle has-background-success-60"
                            ></i>
                          }
                          @if (identityForm.controls.emailAssurance.errors || rCheckInsuranceMailError) {
                            <i class="tri-exclamation-circle has-background-error-60"
                            ></i>
                          }
                        </span>
                      </div>
                      <small class="has-text-danger"
                        [hidden]="!identityForm.controls.emailAssurance.errors || identityForm.controls.emailAssurance.valid">
                        Veuillez saisir l'e-mail pour l'assurance !
                      </small>
                      @if (rCheckInsuranceMailError) {
                        <small class="has-text-danger">{{ rCheckInsuranceMailError
                        }}</small>
                      }
                    </div>
                  }
                </div>
              </div>
            }
            @if (identityNumberMandatory) {
              <div class="fields-row">
                @if (identityDocuments.length) {
                  <div class="field">
                    <div class="control has-dynamic-placeholder">
                      <select id="input-docsId" data-cy="input-docsId" formControlName="documentIdentite"
                                (change)="validateIdentityDocumentNumber()" class="select has-text-tertiary" [ngClass]="{'is-success' : identityForm.controls.documentIdentite.valid && identityForm.value.documentIdentite.length !== 0,
                                'is-danger' : identityForm.controls.documentIdentite.errors}">
                        @if (!identityForm.controls.documentIdentite.value) {
                          <option [ngValue]="null" selected>
                          </option>
                        }
                        @for (identityDocument of identityDocuments; track identityDocument) {
                          <option [ngValue]="identityDocument">
                            {{identityDocument.name}}
                          </option>
                        }
                      </select>
                      <label for="input-docsId" rcbtAsterisk
                      [formcontrol]="identityForm.controls.documentIdentite">Justificatif d'identité</label>
                    </div>
                    <small class="has-text-danger"
                      [hidden]="!identityForm.controls.documentIdentite.errors || identityForm.controls.documentIdentite.valid || !hasPlan">
                      Veuillez choisir un type de pièce d'identité !
                    </small>
                  </div>
                }
                <div class="field">
                  <div class="control has-icons-right has-dynamic-placeholder">
                    <input id="input-docId" data-cy="input-docId" autocomplete="off" type="text" class="input"
                                formControlName="documentIdentiteNumero" [ngClass]="{'is-success' : identityForm.controls.documentIdentiteNumero.valid && identityForm.value.documentIdentiteNumero.length !== 0,
                                'is-danger' : identityForm.controls.documentIdentiteNumero.errors}">
                    <label for="input-docId" rcbtAsterisk
                      [formcontrol]="identityForm.controls.documentIdentiteNumero">Numéro de pièce
                    d'identité</label>
                    <span class="icon">
                      @if (identityForm.controls.documentIdentiteNumero.valid && identityForm.value.documentIdentiteNumero.length !== 0) {
                        <i class="tri-check-circle has-background-success-60"
                        ></i>
                      }
                      @if (identityForm.controls.documentIdentiteNumero.errors) {
                        <i class="tri-exclamation-circle has-background-error-60"
                        ></i>
                      }
                    </span>
                  </div>
                  <small class="has-text-danger"
                    [hidden]="!identityForm.controls.documentIdentiteNumero.errors || identityForm.controls.documentIdentiteNumero.valid">
                    {{ identityForm.controls.documentIdentiteNumero.errors &&
                    identityForm.controls.documentIdentiteNumero.errors.required ? 'Veuillez saisir le numéro de
                    la pièce d\'identité !' : ''}}
                    {{ identityForm.controls.documentIdentiteNumero.errors &&
                    identityForm.controls.documentIdentiteNumero.errors.identityNumberCheck ? 'Numéro incorrect,
                    veuillez vérifier le numéro saisi' : ''}}
                    {{ identityForm.controls.documentIdentiteNumero.errors &&
                    identityForm.controls.documentIdentiteNumero.errors.alphanum ?
                    identityForm.controls.documentIdentiteNumero.errors.alphanum : ''}}
                  </small>
                </div>
              </div>
            }
            @if (!!medi7Product) {
              <div class="fields-row">
                <div class="field checkbox">
                  <input id="checkbox-specificInsuranceEmail" type="checkbox"
                    [checked]="identityForm.value.specificInsuranceEmail === true"
                    formControlName="specificInsuranceEmail" (change)="handleSpecificInsuranceEmail()">
                  <label for="checkbox-specificInsuranceEmail" class="checkbox-label">Autre adresse E-mail pour
                  l'assurance</label>
                </div>
              </div>
            }
            @if (isSimOnly) {
              <div class="fields-row">
                <div class="field checkbox">
                  <div class="control">
                    <input class="is-checkradio" id="checkbox-powerletter" type="checkbox"
                      [checked]="identityForm.controls.powerletter.value === true"
                      formControlName="powerletter">
                    <label for="checkbox-powerletter">
                      Procuration
                      <u>*</u>
                    </label>
                  </div>
                </div>
              </div>
            }
            @if (isSimOnly) {
              <div>
                <u>*</u>
                Je certifie que la personne présente en boutique dispose bien d’une procuration et des pièces d’identité nécessaires pour effectuer le remplacement de carte SIM pour le compte du titulaire de la ligne concernée
              </div>
            }
            @if (errorMessage.length > 0) {
              <div class="error-message">
                <hr>
                  <small class="has-text-danger">{{ errorMessage }}</small>
                </div>
              }
              <rcbt-fraud [identityForm]="identityForm"></rcbt-fraud>
            </form>
          </div>
        }
      </div>
    </article>