import { Injectable } from '@angular/core';

import { StorageService } from './base/services/storage.service';
import { ConfigService } from '@services/config.service';
import { StringObject } from './base/base.interfaces';

@Injectable()
export class AppStorageService extends StorageService {
  public key: StringObject = {
    versionKey: 'storage_version',
  };

  protected prefix = 'app_';
  protected localStorageKeys: string[] = [this.key['versionKey']];

  /**
   * Save or update storage version
   */
  constructor(private config: ConfigService) {
    super();
    if (this.getItem(this.key['versionKey']) !== this.config.data.storage_version) {
      this.clear();
      this.setItem(this.key['versionKey'], this.config?.data.storage_version);
    }
  }
}
