import { PromoActionTypes } from '@promotions/promotions.interfaces';
import { IPromoAction } from './promotions.interfaces';

export class PromotionsHelper {
  public static getDiscountAmount(originalPrice: number, action: IPromoAction): number {
    if (action.type === PromoActionTypes.byFixed) {
      return action.amount;
    } else {
      return Math.ceil(originalPrice * action.percent) / 100;
    }
  }

  public static getDiscountedPrice(
    originalPrice: number,
    action: IPromoAction,
    subventionnedPriceDiscounted?: number,
  ): number {
    if (action.minPrice) {
      if (originalPrice < action.minPrice) {
        action.amount = 0;
        return Number(originalPrice.toFixed(2));
      } else {
        if (!action.baseAmount) {
          action.baseAmount = action.amount;
        }
        action.amount = action.baseAmount;
        const promotionAmount: number = PromotionsHelper.getDiscountAmount(
          subventionnedPriceDiscounted || originalPrice,
          action,
        );
        const promotedPrice = Number((originalPrice - promotionAmount).toFixed(2));

        if (promotedPrice < action.minPrice) {
          action.amount = originalPrice - action.minPrice;
          return Number(action.minPrice.toFixed(2));
        }
      }
    }
    if (action.type === PromoActionTypes.byFixed) {
      return Number((originalPrice - action.amount).toFixed(2));
    }
    return Number(
      (
        originalPrice - PromotionsHelper.getDiscountAmount(subventionnedPriceDiscounted || originalPrice, action)
      ).toFixed(2),
    );
  }
}
