import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '@services/cart.service';
import { mergeMap, tap } from 'rxjs/operators';
import { AlertService } from '@services/alert.service';
import { ConfigService } from '@services/config.service';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { Observable, Subscription } from 'rxjs';
import { ProductsService } from '@services/products.service';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { ServicesListService } from '@services/services-list.service';
import { WallGetResponseDetailProductDto } from '@bytel/pt-ihm-api-portailvente-sapic-catalogue/dist/models/components/schemas/WallGetResponseDetailProductDto';
import { ProductSerialized } from '@model/catalog/products/interface/configurable';
import { NgClass, AsyncPipe } from '@angular/common';
import { PriceComponent } from '@base/price/price.component';

export enum EServiceStates {
  available = 'available',
  added = 'added',
  incompatible = 'incompatible',
}

export interface IServiceState {
  code: string;
  name: string;
  price: number;
  state: EServiceStates;
}

const LOADING_ACTIONS = {
  loadServices: '[ServicesComponent] loadServices',
};

@Component({
  selector: 'rcbt-category-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  standalone: true,
  imports: [NgClass, PriceComponent, AsyncPipe],
})
export class ServicesComponent implements OnInit, OnDestroy {
  @Input()
  public showHeader = false;

  public servicesList: IServiceState[] = [];
  public subscriptions: Subscription = new Subscription();
  public loadServicesError = false;
  public readonly serviceStates = EServiceStates;
  private servicesIncompatiblesList: string[][] = [];
  private allServices: WallGetResponseDetailProductDto[] = [];

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected cartService: CartService,
    protected alertService: AlertService,
    protected productsService: ProductsService,
    private readonly configService: ConfigService,
    private globalLoaderService: GlobalLoaderService,
    private checkoutStepperService: CheckoutStepperService,
    private servicesListService: ServicesListService,
  ) {
    //    super(route);
  }

  public ngOnInit(): void {
    if (!!this.configService.data.servicesIncompatibles) {
      this.servicesIncompatiblesList = this.configService.data.servicesIncompatibles;
    }
    this.loadServices();
  }

  public loadServices(): void {
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.servicesListService.loadServices().pipe(
          tap(
            (services: WallGetResponseDetailProductDto[]) => {
              this.allServices = services;
              this.setServicesList(true);
              this.loadServicesError = false;
            },
            () => (this.loadServicesError = true),
          ),
        ),
        LOADING_ACTIONS.loadServices,
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addService(gencode: string): void {
    const obs = this.cartService.addProduct({ gencode }, true);
    this.updateCart(obs);
  }

  public removeService(gencode: string): void {
    const productUniqueId = this.cartService.getCurrentScheme().getProductByGencode(gencode);
    if (!productUniqueId) {
      return;
    }
    const obs = this.cartService.remove(productUniqueId, this.cartService.getCurrentScheme().uniqueId);
    this.updateCart(obs);
  }

  private updateCart(obs: Observable<ProductSerialized | boolean>): void {
    this.subscriptions.add(
      this.globalLoaderService
        .showLoaderUntilFinalize(
          obs.pipe(
            mergeMap(() => this.cartService.refreshCart()),
            tap(() => {
              this.setServicesList();
              this.checkoutStepperService.redirectIfEmptyScheme(this.cartService.getCurrentScheme().isEmpty());
            }),
          ),
          '[ServicesComponent] updateCart',
        )
        .subscribe(),
    );
  }

  private setServicesList(init: boolean = false): void {
    const productsScheme = this.cartService.getCurrentScheme().products;
    if (init) {
      this.servicesList = [];
      this.allServices.forEach((service: WallGetResponseDetailProductDto) => {
        this.servicesList.push({
          code: service.gencode,
          name: service.nom,
          price: service.prix.final,
          state: productsScheme.some(p => p.gencode === service.gencode)
            ? EServiceStates.added
            : EServiceStates.available,
        });
      });
    } else {
      this.servicesList.forEach(s => {
        s.state = productsScheme.some(p => p.gencode === s.code) ? EServiceStates.added : EServiceStates.available;
      });
    }

    this.servicesIncompatiblesList.forEach(l =>
      l.forEach(g => {
        if (this.servicesList.some(s => s.code === g && s.state === EServiceStates.added)) {
          l.filter(s => !this.servicesList.some(s2 => s2.code === s && s2.state === EServiceStates.added)).forEach(
            i => {
              this.servicesList.find(s3 => s3.code === i && s3.state === EServiceStates.available).state =
                EServiceStates.incompatible;
            },
          );
        }
      }),
    );
  }
}
