import { Injectable } from '@angular/core';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { PromoParams, PromotionTypes } from '@promotions/promotions.interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IPromotion } from './promotions.interfaces';
import { PromotionalEligibilityResultDto } from '@model/promotional-eligibility-output.dto';
import {
  ProductCatalog,
  PromotionalEligibilityInputDto,
  PromotionalEligibilityInputProductToAddDto,
} from '../constants/promotional-eligibility-input.dto';
import { JsonProduct } from '@model/catalog/products/interface/context';
import { PromotionContextSerializedInterface } from '../context/child/promotion-context-serialized.interface';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService {
  public static readonly rioPartner: string = 'BTBD';
  public personId: string;
  public hasRioPartner: boolean;

  public loadingPromotionsOK = true;
  public cartEligPromoResult$: Observable<PromotionalEligibilityResultDto>;
  protected promotions: { [index: string]: IPromotion[] } = {};
  protected loadedPromotions: { [index: string]: IPromotion[] } = {};
  private cartEligPromoResult: PromotionalEligibilityResultDto;

  private cartEligPromoResult$$ = new BehaviorSubject(null);

  constructor(protected oauth2RessourceService: Oauth2RessourceService) {
    this.cartEligPromoResult$ = this.cartEligPromoResult$$.asObservable();
  }

  public static isAgility(promo: IPromotion): boolean {
    return promo.type === PromotionTypes.agility;
  }

  public static isAgilityCa(promo: IPromotion): boolean {
    return promo.type === PromotionTypes.agilityCa;
  }

  public addPromoById(id: number, cartId: number, params: PromoParams = {}): Observable<void> {
    return this.oauth2RessourceService
      .setLocalService()
      .useSalesApi()
      .ventes()
      .panier(cartId)
      .promotions()
      .post(Object.assign(params, { id }))
      .pipe(
        tap(() => {
          // todo vérifier si cette méthode est tjr utilisé
          // this.emitChanges(cartService);
        }),
      );
  }

  public deletePromo(id: number, cartId: number, gencode?: string): Observable<void> {
    return this.oauth2RessourceService
      .setLocalService()
      .useSalesApi()
      .ventes()
      .panier(cartId)
      .promotions(id, gencode)
      .delete()
      .pipe(map(() => null));
  }

  public applyPromoCode(code: string, cartId: number): Observable<unknown> {
    return this.oauth2RessourceService
      .setLocalService()
      .useSalesApi()
      .ventes()
      .panier(cartId)
      .promotions()
      .post({ code });
  }

  public getStore(): string {
    return this.oauth2RessourceService.store;
  }

  /**
   * On met à jour le résultat de l'élig promo du cart (1er élément du résultat) suite à chaque appels
   * pour pouvoir le consommer partout Exemple dans la popin promotion
   * @param promotionalEligibilityInputDto
   */
  public eligibilitePromotionnelle(
    promotionalEligibilityInputDto: PromotionalEligibilityInputDto,
  ): Observable<PromotionalEligibilityResultDto[]> {
    return this.oauth2RessourceService
      .ventes()
      .eligibilitePromotionnelle()
      .useSalesApi()
      .setLocalService()
      .post(promotionalEligibilityInputDto)
      .pipe(
        tap((result: PromotionalEligibilityResultDto[]) => {
          this.cartEligPromoResult$$.next(result[0]);
          this.cartEligPromoResult = result[0];
        }),
        catchError(err => of([])),
      );
  }

  public addProductsToEligPromoBody(
    mainCartBody: PromotionalEligibilityInputDto,
    products: JsonProduct[],
    indexScheme: number,
  ): PromotionalEligibilityInputDto {
    const produitsIn: PromotionalEligibilityInputProductToAddDto[] = [];
    products.forEach((jsonProduct: JsonProduct) => {
      const produitIn: PromotionalEligibilityInputProductToAddDto = {
        gencode: jsonProduct.gencode,
        ajouterDansLeParcours: indexScheme,
      };
      if (jsonProduct.type_id === 'produit_ca') {
        produitIn.catalogue = ProductCatalog.partenaire;
      }
      produitsIn.push(produitIn);
    });
    mainCartBody.produits = produitsIn;
    return mainCartBody;
  }

  public getLastEligPromoResult(): PromotionalEligibilityResultDto {
    return this.cartEligPromoResult;
  }

  public serialize(): PromotionContextSerializedInterface {
    return {
      convergences: null,
      idPersonne: this.personId,
      offresPrincipales: null,
      hasRioPartner: this.hasRioPartner,
    };
  }

  protected resetPromotions(): void {
    this.loadedPromotions = {
      rcbt: [],
      default: [],
      telesales: [],
      aco: [],
      mcommerce: [],
    };
  }

  protected appendPromotions(store: string, promotions: IPromotion[]): void {
    if (
      !promotions.some(function (promo) {
        return promo === null;
      })
    ) {
      this.loadedPromotions[store] = this.loadedPromotions[store].concat(promotions);
    }
  }

  protected setPromotions(store: string, promotions: IPromotion[]): void {
    this.promotions[store] = promotions;
  }
}
