<article class="accordion" [ngClass]="{'is-active': isActive }">
  <div class="accordion-header" (click)="onClickTab(customerService.customerSteps.identity);">
    <div class="title is-size-1" data-cy="title-idpro">Identité</div>
    <button class="toggle" aria-label="toggle"></button>
  </div>
  <div class="accordion-body">
    <hr>
      <div class="accordion-content">

        @if (!sirenOk) {
          <rcbt-cart-customer-identity-pro-siren
            (screeningDataEvent)="onScreeningDataEvent($event)"
            (sirenOkEvent)="onSirenOkEvent($event)"
            [errorMessage]="errorSiren">
          </rcbt-cart-customer-identity-pro-siren>
        }

        @if (sirenOk && !isClient && nbSchemes === 1) {
          <div class="data-siren-row">
            <div class="control has-icons-right has-dynamic-placeholder">
              <input id="input-pro-siren" data-cy="input-pro-siren" autocomplete="off" type="text" class="input" [value]="siren" maxlength="9" disabled>
              <label for="input-pro-siren">Siren<span class="has-text-danger">*</span></label>
            </div>
            <button class="button is-secondary" data-cy="edit-siren" (click)="editSiren()">Modifier</button>
          </div>
        }

        @if (identityProForm && (sirenOk || isClient)) {
          <form class="form-data" data-cy="identityPro-form"
            [formGroup]="identityProForm" novalidate>
            <div class="siren-side">
              <div class="siren-infos" data-cy="siren-infos">
                <p>
                  <span class="info-label">Raison Sociale</span><br>
                  {{customer.company.raisonSociale || 'Inconnu'}}
                </p>
                <p>
                  <span class="info-label">Création</span><br>
                  {{customer.company.dateCreation | date:'dd/MM/yyyy'}}
                </p>
                <p>
                  <span class="info-label">Forme Juridique</span><br>
                  {{customer.company.formeJuridique || 'Inconnu'}}
                </p>
                <p>
                  <span class="info-label">NAF</span><br>
                  {{customer.company.codeApeNaf || 'Inconnu'}}
                </p>
                <p>
                  <span class="info-label">Effectif</span><br>
                  {{customer.company.codeEffectif || 'Inconnu'}}
                </p>
              </div>
              <div class="check-powerletter">
                <div class="field">
                  <div class="control">
                    <input class="is-checkradio" id="checkbox-powerletter" data-cy="checkbox-powerletter" type="checkbox" [checked]="identityProForm.controls.powerletter.value === true" formControlName="powerletter">
                    <label for="checkbox-powerletter">Procuration</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="representative-side">
              <div class="title-label">Représentant légal</div>
              <div class="fields-row">
                <div class="radio">
                  <input id="radio-title-pro-1" data-cy="radio-pro-civilite" type="radio" value="MME" formControlName="civilite" name="radio-name"
                    [ngClass]="{'active': identityProForm.get('civilite').value === 'MME', 'disabled': identityProForm.get('civilite').disabled}">
                  <label for="radio-title-pro-1" class="radio-label" tabIndex="0">Madame</label>
                </div>
                <div class="radio">
                  <input id="radio-title-pro-2" data-cy="radio-pro-civilite" type="radio" value="M" formControlName="civilite" name="radio-name"
                    [ngClass]="{'active': identityProForm.get('civilite').value === 'M', 'disabled': identityProForm.get('civilite').disabled}">
                  <label for="radio-title-pro-2" class="radio-label" tabIndex="1">Monsieur</label>
                </div>
                <small class="has-text-danger" [hidden]="!identityProForm.controls.civilite.errors || identityProForm.controls.civilite.valid || !hasPlan">
                  Veuillez choisir la civilité !
                </small>
              </div>
              <div class="fields-row">
                <div class="field">
                  <div class="control has-icons-right has-dynamic-placeholder">
                    <input id="input-pro-nom" data-cy="input-pro-nom" autocomplete="off" type="text" class="input" formControlName="nom"
                                    [ngClass]="{'is-success' : identityProForm.controls.nom.valid && identityProForm.value.nom.length !== 0,
                                    'is-danger' : identityProForm.controls.nom.errors}">
                    <label for="input-pro-nom" rcbtAsterisk [formcontrol]="identityProForm.controls.nom">Nom</label>
                    <span class="icon">
                      @if (identityProForm.controls.nom.valid && identityProForm.value.nom.length !== 0) {
                        <i class="tri-check-circle has-background-success-60"></i>
                      }
                      @if (identityProForm.controls.nom.errors) {
                        <i class="tri-exclamation-circle has-background-error-60"></i>
                      }
                    </span>
                  </div>
                  @if (identityProForm.controls.nom.errors && identityProForm.controls.nom.errors.nom) {
                    <small class="has-text-danger">
                      {{ identityProForm.controls.nom.errors.nom.errors }}
                    </small>
                  }
                </div>
                <div class="field">
                  <div class="control has-icons-right has-dynamic-placeholder">
                    <input id="input-pro-prenom" data-cy="input-pro-prenom" autocomplete="off" type="text" class="input" formControlName="prenom"
                                    [ngClass]="{'is-success' : identityProForm.controls.prenom.valid && identityProForm.value.prenom.length !== 0,
                                    'is-danger' : identityProForm.controls.prenom.errors}">
                    <label for="input-pro-prenom" rcbtAsterisk [formcontrol]="identityProForm.controls.prenom">Prénom</label>
                    <span class="icon">
                      @if (identityProForm.controls.prenom.valid && identityProForm.value.prenom.length !== 0) {
                        <i class="tri-check-circle has-background-success-60"></i>
                      }
                      @if (identityProForm.controls.prenom.errors) {
                        <i class="tri-exclamation-circle has-background-error-60"></i>
                      }
                    </span>
                  </div>
                  @if (identityProForm.controls.prenom.errors && identityProForm.controls.prenom.errors.prenom) {
                    <small class="has-text-danger">
                      {{ identityProForm.controls.prenom.errors.prenom.errors }}
                    </small>
                  }
                </div>
              </div>
              <div class="fields-row">
                <div class="field">
                  <div class="control has-icons-right has-dynamic-placeholder">
                    <input id="input-pro-dateNaissance" data-cy="input-pro-dateNaissance" autocomplete="off" type="text" [dropSpecialCharacters]="false" mask="00/00/0000" class="input" formControlName="dateNaissance"
                                    [ngClass]="{'is-success' : identityProForm.controls.dateNaissance.valid && identityProForm.value.dateNaissance.length !== 0,
                                    'is-danger' : identityProForm.controls.dateNaissance.errors,
                                    'is-warning': birthDateEmancipeWarning}">
                    <label for="input-pro-dateNaissance" rcbtAsterisk [formcontrol]="identityProForm.controls.dateNaissance">Date de naissance (JJ/MM/AAAA)</label>
                    <span class="icon">
                      @if (identityProForm.controls.dateNaissance.valid && !birthDateEmancipeWarning && identityProForm.value.dateNaissance.length !== 0) {
                        <i class="tri-check-circle has-background-success-60"
                        ></i>
                      }
                      @if (identityProForm.controls.dateNaissance.errors) {
                        <i class="tri-exclamation-circle has-background-error-60"
                        ></i>
                      }
                      @if (birthDateEmancipeWarning) {
                        <i class="tri-infos-circle has-background-warning-80"
                        ></i>
                      }
                    </span>
                  </div>
                  @if (identityProForm.controls.dateNaissance.errors && identityProForm.controls.dateNaissance.errors.dateNaissance && hasPlan) {
                    <small class="has-text-danger">
                      {{ identityProForm.controls.dateNaissance.errors.dateNaissance.errors }} {{ identityProForm.controls.dateNaissance.errors.dateNaissance.major }}
                    </small>
                  }
                  @if (birthDateEmancipeWarning) {
                    <small class="has-text-warning-80">
                      {{ birthDateEmancipeWarning }}
                    </small>
                  }
                </div>
                <div class="field">
                  <div class="control has-dynamic-placeholder">
                    <rcbt-auto-complete [label]="'Département de naissance'" [idName]="'pro-departementNaissance'" [suggestions]="departments" [inputControl]="identityProForm.controls.departementNaissance"></rcbt-auto-complete>
                  </div>
                  <small class="has-text-danger" [hidden]="!identityProForm.controls.departementNaissance.errors || identityProForm.controls.departementNaissance.valid || !hasPlan">
                    Veuillez choisir le département de naissance !
                  </small>
                </div>
              </div>
              @if (!isSaleOnly) {
                <div class="fields-row">
                  <div class="field">
                    <div class="control has-icons-right has-dynamic-placeholder">
                      <input id="input-pro-tel" data-cy="input-pro-tel" autocomplete="off" type="text" class="input" formControlName="telephone" maxlength="14"
                        [mask]="'00 00 00 00 00'"
                                    [ngClass]="{'is-success' : identityProForm.controls.telephone.valid && identityProForm.value.telephone.length !== 0,
                                    'is-danger' : identityProForm.controls.telephone.errors}">
                      <label for="input-pro-tel" [formcontrol]="identityProForm.controls.telephone">Numéro de téléphone mobile
                        @if (emailAndPhoneNumberMandatory && (identityProForm.controls.email.errors || !identityProForm.controls.email.value)) {
                          <span class="has-text-danger">*</span>
                        }
                      </label>
                      <span class="icon">
                        @if (identityProForm.controls.telephone.valid && identityProForm.value.telephone.length !== 0) {
                          <i class="tri-check-circle has-background-success-60"></i>
                        }
                        @if (identityProForm.controls.telephone.errors) {
                          <i class="tri-exclamation-circle has-background-error-60"></i>
                        }
                      </span>
                    </div>
                    <small class="has-text-danger" [hidden]="!identityProForm.controls.telephone.errors || identityProForm.controls.telephone.valid || !hasPlan">
                      {{ identityProForm.controls.telephone.errors && identityProForm.controls.telephone.errors.value }}
                    </small>
                    @if (!identityProForm.controls.email?.value && !identityProForm.controls.telephone?.value) {
                      <small class="has-text-danger">
                        {{ identityProForm.errors?.email.errors }}
                      </small>
                    }
                  </div>
                  <div class="field">
                    <div class="control has-icons-right has-dynamic-placeholder">
                      <input id="input-pro-email" data-cy="input-pro-email" autocomplete="off" type="text" class="input" formControlName="email"
                                    [ngClass]="{'is-success' : identityProForm.controls.email.valid && identityProForm.value.email.length !== 0,
                                    'is-danger' : identityProForm.controls.email.errors}">
                      <label for="input-pro-email">
                        E-mail
                        @if (emailAndPhoneNumberMandatory && (identityProForm.controls.telephone.errors || !identityProForm.controls.telephone.value)) {
                          <span class="has-text-danger">*</span>
                        }
                      </label>
                      <span class="icon">
                        @if (identityProForm.controls.email.valid && identityProForm.value.email.length !== 0) {
                          <i class="tri-check-circle has-background-success-60"></i>
                        }
                        @if (identityProForm.controls.email.errors) {
                          <i class="tri-exclamation-circle has-background-error-60"></i>
                        }
                      </span>
                    </div>
                    <small class="has-text-danger" [hidden]="!identityProForm.controls.email.errors || identityProForm.controls.email.valid || !hasPlan">
                      {{ identityProForm.controls.email.errors && identityProForm.controls.email.errors.value }}
                    </small>
                    @if (rCheckMailError) {
                      <small class="has-text-danger">{{ rCheckMailError }}</small>
                    }
                    @if (identityProForm.controls.email && !identityProForm.controls.email.value) {
                      <small class="has-text-info">
                        L'e-mail est nécessaire pour réceptionner les contrats dématérialisés et le code provisoire de création de l'espace client permettant de recevoir ses factures.
                      </small>
                    }
                  </div>
                </div>
              }
              @if (identityNumberMandatory) {
                <div class="fields-row">
                  @if (identityDocuments.length) {
                    <div class="field">
                      <div class="control has-dynamic-placeholder">
                        <select id="input-pro-docsId" data-cy="input-pro-docsId" formControlName="documentIdentite" (change)="validateIdentityDocumentNumber()"
                          class="select has-text-tertiary"
                                    [ngClass]="{'is-success' : identityProForm.controls.documentIdentite.valid && identityProForm.value.documentIdentite.length !== 0,
                                    'is-danger' : identityProForm.controls.documentIdentite.errors}">
                          @if (!identityProForm.controls.documentIdentite.value) {
                            <option [ngValue]="null" selected></option>
                          }
                          @for (identityDocument of identityDocuments; track identityDocument) {
                            <option [ngValue]="identityDocument">
                              Gérant {{identityDocument.name}}
                            </option>
                          }
                        </select>
                        <label for="input-pro-docsId" rcbtAsterisk [formcontrol]="identityProForm.controls.documentIdentite">Justificatif d'identité</label>
                      </div>
                      <small class="has-text-danger" [hidden]="!identityProForm.controls.documentIdentite.errors || identityProForm.controls.documentIdentite.valid || !hasPlan">
                        Veuillez choisir un type de pièce d'identité !
                      </small>
                    </div>
                  }
                  <div class="field">
                    <div class="control has-icons-right has-dynamic-placeholder">
                      <input id="input-pro-docId" data-cy="input-pro-docId" autocomplete="off" type="text" class="input" formControlName="documentIdentiteNumero"
                                    [ngClass]="{'is-success' : identityProForm.controls.documentIdentiteNumero.valid && identityProForm.value.documentIdentiteNumero.length !== 0,
                                    'is-danger' : identityProForm.controls.documentIdentiteNumero.errors}">
                      <label for="input-pro-docId" rcbtAsterisk [formcontrol]="identityProForm.controls.documentIdentiteNumero">Numéro de pièce d'identité</label>
                      <span class="icon">
                        @if (identityProForm.controls.documentIdentiteNumero.valid && identityProForm.value.documentIdentiteNumero.length !== 0) {
                          <i class="tri-check-circle has-background-success-60"></i>
                        }
                        @if (identityProForm.controls.documentIdentiteNumero.errors) {
                          <i class="tri-exclamation-circle has-background-error-60"></i>
                        }
                      </span>
                    </div>
                    <small class="has-text-danger" [hidden]="!identityProForm.controls.documentIdentiteNumero.errors || identityProForm.controls.documentIdentiteNumero.valid || !hasPlan">
                      {{ identityProForm.controls.documentIdentiteNumero.errors && identityProForm.controls.documentIdentiteNumero.errors.required ? 'Veuillez saisir le numéro de la pièce d\'identité !' : ''}}
                      {{ identityProForm.controls.documentIdentiteNumero.errors && identityProForm.controls.documentIdentiteNumero.errors.identityNumberCheck ? 'Numéro incorrect, veuillez vérifier le numéro saisi' : ''}}
                      {{ identityProForm.controls.documentIdentiteNumero.errors && identityProForm.controls.documentIdentiteNumero.errors.alphanum ? identityProForm.controls.documentIdentiteNumero.errors.alphanum : ''}}
                    </small>
                  </div>
                </div>
              }
              <rcbt-fraud [identityForm]="identityProForm"></rcbt-fraud>
            </div>
            @if (errorMessage) {
              <div class="error-message">
                <hr>
                  <small class="has-text-danger">{{ errorMessage }}</small>
                </div>
              }
            </form>
          }
        </div>
      </div>
    </article>
