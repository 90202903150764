import { Component, CUSTOM_ELEMENTS_SCHEMA, input, output } from '@angular/core';
import { ConfigService } from '@services/config.service';

@Component({
  selector: 'rcbt-scanner-wrapper',
  templateUrl: './scanner-wrapper.component.html',
  styleUrls: ['./scanner-wrapper.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ScannerWrapperComponent {
  isOpen = input<boolean>(false);
  isMultiScan = input<boolean>(false);
  onScan = output<string>();
  onClose = output<void>();
  constructor(private configService: ConfigService) {}

  get license(): string {
    return this.configService.getScanditLicense();
  }

  public _onScan(event: CustomEvent): void {
    this.onScan.emit(event.detail);
  }

  public _onClose(): void {
    this.onClose.emit();
  }
}
