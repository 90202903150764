/* eslint-disable */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccapAdapterDirective } from './accap-adapter.directive';
import { contextResolver } from '../../context/contextResolverFn';
import { AccapAdpterComponent } from './accap-adpter.component';

const AUTH_TEMP_URL = 'AUTH_TEMP_URL';

const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('@components/container/container.component').then(m => m.ContainerComponent),
        resolve: { context: contextResolver },
        children: [
            {
                path: AUTH_TEMP_URL,
                loadComponent: () => import('@base/container-bootstrap/container-bootstrap.component').then(m => m.ContainerBootstrapComponent),
            },
            {
                path: '',
                loadComponent: () => import('@base/container-bootstrap/container-bootstrap.component').then(m => m.ContainerBootstrapComponent),
                children: [{
                    path: '**',
                    loadComponent: () => import('./accap-adpter.component').then(m => m.AccapAdpterComponent),
                }]
            },

        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes), AccapAdpterComponent, AccapAdapterDirective],
    exports: [AccapAdpterComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccapAdapterModule {}
