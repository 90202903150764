import { EventEmitter, Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CartService } from '@services/cart.service';
import { FaiEligibilityModel, WidgetContextInterface } from './fai.interfaces';
import { fromPromise } from 'rxjs/internal-compatibility';
import { mergeMap, tap } from 'rxjs/operators';
import { Oauth2Service } from '../oauth2/oauth2.service';
import { ConfigService } from '@services/config.service';
import { DomService } from '@base/services/dom.service';
import { Plan } from '@model/catalog/products/subscription/plan';
import { Fai } from '@model/catalog/products/subscription/plan/fai/fai';
import { BrowseType } from '../constants/browse-type';
import { FaiScheme } from '@model/fai-scheme';
import { BrowseConfigService } from '../context/browse-config.service';

@Injectable({
  providedIn: 'root',
})
export class FaiService {
  faiData: FaiEligibilityModel;
  reservedVoip: string;
  reservedLogin: string;
  isLoginCompoVisible$ = new EventEmitter();
  forceUnreserveVoipLogin = false;

  constructor(
    private cartService: CartService,
    private oauth2Service: Oauth2Service,
    private configService: ConfigService,
    private domService: DomService,
    private browseConfigService: BrowseConfigService,
  ) {}

  public resolve(state: RouterStateSnapshot): Observable<FaiEligibilityModel> {
    if (
      this.cartService.getCurrentScheme()?.browseType === BrowseType.acquisitionFix ||
      this.browseConfigService.browseConfig.browseActionType === 'ELIG'
    ) {
      return this.loadScript().pipe(mergeMap(() => this.loadFaiData(state)));
    }
    return of(null);
  }

  public loadScript(): Observable<boolean> {
    return this.domService
      .loadScript([
        {
          src: this.configService.data?.fai?.widget.url_a7,
        },
      ])
      .pipe(
        tap(result => {
          if (!result) {
            throw new Error('Impossible de charger le widget');
          }
        }),
      );
  }

  public createWetoContext(
    xVersion: string = undefined,
    enablePersistentData = false,
  ): Partial<WidgetContextInterface> {
    const envTrv = !this.configService.data.oauth2.envTrv ? '' : '/' + this.configService.data.oauth2.envTrv;
    return {
      hiddenController: 'true',
      typeActeur: 'CDV',
      typeVente: 'VA',
      enablePersistentData,
      hostApp: 'RCBT',
      api: {
        baseUrl: this.configService.data.oauth2.api + envTrv,
        token: this.oauth2Service.accessToken ?? null,
        xVersion,
        xSource: 'portailvente_rcbt',
      },
    };
  }

  /**
   * Lors du parcous d'élig on ne charge pas le panier corbis
   * En cas de reprise depuis le parcours d'élig faiEligId est null donc on ne fait pas le load
   * @private
   */
  public loadFaiData(state: RouterStateSnapshot): Observable<FaiEligibilityModel> {
    if (
      state.url.startsWith('/panier') &&
      this.cartService.getCurrentScheme() &&
      (this.cartService.getCurrentScheme() as FaiScheme).faiEligId
    ) {
      return fromPromise(
        window.wetoUtils.getCorbisCart(
          this.createWetoContext(),
          (this.cartService.getCurrentScheme() as FaiScheme).faiEligId,
        ),
      ).pipe(
        tap((result: FaiEligibilityModel) => {
          this.faiData = result;
          this.reservedVoip = this.faiData.reservedVoip;
          this.reservedLogin = this.faiData.reservedLogin;
          const plan: Plan = this.cartService.getCurrentScheme().getProductByType(Fai);
          this.reservedVoip = this.reservedVoip || plan?.rio?.phoneNumber;
          this.reservedLogin = this.reservedLogin || plan?.logins?.[0]?.login;
        }),
      );
    }
    return of(null);
  }
}
