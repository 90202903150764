import { DOCUMENT } from '@angular/common';
import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { HttpBackend } from '@angular/common/http';

import { appInitializerFactory } from './app-initializer.factory';

export const APP_INITIALIZER_PROVIDER: EnvironmentProviders = provideAppInitializer(() => {
  const initializerFn = appInitializerFactory(inject(HttpBackend), inject(DOCUMENT));
  return initializerFn();
});
