import { ProductSerialized } from './interface/configurable';
import { ScanConfigInterface } from '@interfaces/types';
import { OpSource, RenewPromotion, RenewPromotions } from '@interfaces/promotion.interface';
import { EdpData, IPrices, JsonProduct } from './interface/context';
import { Promotion } from '@promotions/promotion.class';
import { IProductConfiguration } from './IProductConfiguration';
import { Observable, of as observableOf } from 'rxjs';
import { Regularization } from '@promotions/promotion/regularization.class';
import { PromotionTypes } from '@promotions/promotions.interfaces';
import { PromotionFactory } from '@promotions/promotionFactory.class';
import {
  PromotionalEligibilityPromotionExtV2Dto,
  PromotionalEligibilityPromotionsDto,
} from '../../promotional-eligibility-output.dto';
import { PriceTypes } from './interface/price-type.enum';
import { AddResult } from '@interfaces/cart.interfaces';

export enum CompatibilityMessages {
  simNotCompatible = "Attention : la carte SIM/eSIM n'est pas compatible avec le terminal",
  simTypeNotCompatible = 'Type de carte SIM erroné',
  simFNBMondatory = 'SIM FNB demandée en vente mobile',
  boxFai = "Attention : le BoxFai n'est pas compatible avec le Parcours",
}

export interface RulesRenew {
  planType?: string[];
}

export abstract class Product {
  public static typeBonus = 'bonus';
  public cartId: number;
  public quoteId: number;
  public schemeId: string;
  public itemId: number;
  public price: number;
  public oldPrice: number;
  public baseCost: number;
  public priceType: PriceTypes = PriceTypes.today;
  public childs: string[];
  public name: string;
  public gencode: string;
  public sku: string;
  public prices: IPrices;
  public data: JsonProduct;
  public medias: string[] = [];
  public qty = 1;
  // public pricesDetail: Prices;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public price_month_duration = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public plan_bonus: string;
  public renewPromotion: RenewPromotion = null;
  public opSource: OpSource = null;
  public scanConfig: ScanConfigInterface;
  public promotions: Promotion[] = [];
  public simulatedPromotions: PromotionalEligibilityPromotionsDto;
  public archivedPromotions: Promotion[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public type_id: string;
  public secondOffersIds: { idOffre: string }[] = [];
  public _uniqueId: string;
  public compatibility: string;
  public compatible_device_required: string;
  protected _scanCode = '';

  constructor(data?: JsonProduct, uniqueId: string = '') {
    this._uniqueId = uniqueId === '' ? Date.now() + '-' + Math.floor(Math.random() * 1000) : uniqueId;
    if (data) {
      this.setJsonData(data);
    }
  }

  get uniqueId(): string {
    return this._uniqueId;
  }

  set uniqueId(val: string) {
    this._uniqueId = val;
  }

  public getScanCode(): string {
    return this._scanCode;
  }

  /**
   * Returns false if product has specific scan config that dosn't match to scanned code
   * @param value
   * @returns {boolean}
   */
  public setScanCode(value: string): boolean {
    if (this.scanConfig && value && value.length !== this.scanConfig.size) {
      return false;
    }
    this._scanCode = value;
    return true;
  }

  // set contextService(value: ContextService) {
  //     this._contextService = value;
  // }

  public serialize(): ProductSerialized {
    return {
      idParcours: this.quoteId,
      idProduit: this.itemId,
      gencode: this.gencode,
      idUnique: this._uniqueId,
      prix: this.price,
      sku: this.sku,
      promotions: this.promotions,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      type_id: this.type_id || this.data?.type_id,
      secondOffersIds: this.secondOffersIds,
      scanCode: this.getScanCode(),
      baseCost: this.baseCost,
    };
  }

  public unserialise(productSerialized: ProductSerialized): void {
    this.setScanCode(productSerialized.scanCode);
    this.itemId = productSerialized.idProduit;
    this.quoteId = productSerialized.idParcours;
    this.price = productSerialized.prix;
    if (productSerialized.baseCost) {
      this.oldPrice = productSerialized.baseCost;
    }
    this.promotions = PromotionFactory.createCollection(productSerialized.promotions);
  }

  public setConfiguration(data: IProductConfiguration | JsonProduct): void {
    // méthode surchargée
  }

  public getConfiguration(): IProductConfiguration {
    return {};
  }

  public updateConfiguration(data: IProductConfiguration | JsonProduct): Observable<boolean> {
    this.setConfiguration(data);
    return observableOf(true);
  }

  public setJsonData(data?: JsonProduct): this {
    this.data = data;
    this.gencode = data['gencode'];
    this.sku = data['sku'];
    this.prices = data['prices'];
    this.childs = data['product_ids'];
    this.name = data['name'];
    this.price = Number(data['price']);
    this.baseCost = Number(data['baseCost']);
    this.medias = data['medias'];
    this.oldPrice = this.getData('price') ? Number.parseFloat(this.getData('price')) : 0;
    this.setConfiguration(data);
    this.plan_bonus = data['plan_bonus'];
    this.type_id = data['type_id'];
    this.secondOffersIds = data['secondOffersIds'];
    this.compatible_device_required = data['compatible_device_required'];
    this.compatibility = data['compatibility'];
    return this;
  }

  public getJsonData(): Object {
    return this.data;
  }

  public getData(key: string): string {
    return this.data[key];
  }

  public getOdrData(): { amount: number; file: string } {
    return this.data['odr'];
  }

  public getEdpDataPhone(): EdpData {
    return this.data['edp_phone'];
  }

  public canAdd(browseType: string, isPrepaid: boolean, isFnb: boolean): AddResult {
    return { status: true, productsConflict: [] };
  }

  public getRegularizationAmount(): number {
    return this.getRegularization().application.actionPromotion.amount || 0;
  }

  public getRegularization(): Regularization {
    return <Regularization>(
      this.promotions.find(
        (p: Promotion) => p.type === PromotionTypes.regularization || p.type === PromotionTypes.regularizationCa,
      )
    );
  }

  public archivePromotions(cleanPromotions?: boolean): void {
    this.archivedPromotions = PromotionFactory.createCollection(this.promotions);
    if (cleanPromotions) {
      this.promotions = [];
    }
  }

  public restorePromotion(): void {
    this.promotions = this.archivedPromotions;
  }

  public setPromotedStatus(status: string): void {
    // methode surchargée
  }

  public getSimulationPrice(): number {
    let simulationPrice = this.price;
    const simulatedPromotionsList = [
      ...(this.simulatedPromotions?.automatiques?.filter(p => !p.odr) ?? []),
      ...(this.simulatedPromotions?.manuellesAppliquees ?? []),
      ...(this.simulatedPromotions?.coupons ?? []),
      ...(this.simulatedPromotions?.couponsUniques ?? []),
    ];
    simulatedPromotionsList.forEach(
      (p: PromotionalEligibilityPromotionExtV2Dto) => (simulationPrice -= p.valeurRemiseReelle),
    );
    return simulationPrice;
  }

  public getAppliedPromotions(): PromotionalEligibilityPromotionExtV2Dto[] {
    return this.simulatedPromotions.automatiques.concat(
      this.simulatedPromotions.manuellesAppliquees,
      this.simulatedPromotions.coupons || [],
      this.simulatedPromotions.couponsUniques || [],
    );
  }

  public updatePromotion(renewPromo: RenewPromotions): void {
    this.initRenewPromotion(renewPromo);
    if (this.opSource) {
      this.price = this.opSource.tarifRemiseTTC;
    } else if (this.renewPromotion) {
      this.price = this.renewPromotion.offrePrincipaleCible?.tarifRemiseTTC;
    }
  }

  protected initRenewPromotion(renewPromo: RenewPromotions): void {
    if (!renewPromo) {
      return;
    }
    if (renewPromo.opSource?.idOffre === this.gencode && renewPromo.opSource?.montantRemiseTTC < 0) {
      this.opSource = renewPromo.opSource;
    } else if (renewPromo.opSource) {
      for (const promotion of renewPromo.simulation) {
        if (
          !promotion.message &&
          this.gencode === promotion.offrePrincipaleCible?.idOffre &&
          promotion.offrePrincipaleCible?.montantRemiseTTC < 0
        ) {
          this.renewPromotion = promotion;
          break;
        }
      }
    }
  }
}
