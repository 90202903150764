import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CatalogCtxService } from '../contextualized-catalog/services/catalog-ctx.service';
import {
  ConsulterCatalogueContextualiseAvecPanier200Response,
  ConsulterCatalogueContextualiseAvecPanierBodyRequest,
  ConsulterCatalogueContextualiseAvecPanierQuery,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { map } from 'rxjs/operators';
import { WallGetResponseDetailProductDto } from '@bytel/pt-ihm-api-portailvente-sapic-catalogue/dist/models/components/schemas/WallGetResponseDetailProductDto';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { CatalogInputHelperDto } from '../contextualized-catalog/dtos/catalog-input-helper.dto';
import { CartService } from '@services/cart.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesListService {
  private static category = 'services_rcbt';
  private ctxProductsCache = new Map<string, WallGetResponseDetailProductDto[]>();

  constructor(
    private catalogCtxService: CatalogCtxService,
    private customerService: CustomerService,
    private cartService: CartService,
  ) {}

  public loadServices(): Observable<WallGetResponseDetailProductDto[]> {
    const services: WallGetResponseDetailProductDto[] = this.ctxProductsCache.get(ServicesListService.category);
    if (services) {
      return of(services);
    }
    return this.catalogCtxService.postCatalogCtx(this.getRequestBody(), this.getRequestQueryParams()).pipe(
      map((result: ConsulterCatalogueContextualiseAvecPanier200Response) => {
        this.ctxProductsCache.set(ServicesListService.category, result.produits);
        return result.produits;
      }),
    );
  }

  private getRequestBody(): ConsulterCatalogueContextualiseAvecPanierBodyRequest {
    return CatalogInputHelperDto.buildBodyCtxWithCart(
      this.customerService.customer,
      this.cartService.cart,
      null,
      this.cartService.getCurrentScheme()?.uniqueId,
    );
  }

  private getRequestQueryParams(): ConsulterCatalogueContextualiseAvecPanierQuery {
    return {
      modePourFinancement: 'auto',
      categorie: ServicesListService.category,
    };
  }
}
