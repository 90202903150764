import { Component, ViewChild } from '@angular/core';
import { ComparatorService } from '@services/comparator.service';
import { MultiSelectComponent } from '@base/components/multi-select/multi-select.component';
import { CONNECTIVITY_ICON_MAP } from '../../../constants/product-list.constant';
import { Router } from '@angular/router';

import { MediaPipe } from '@base/pipes/media.pipe';

@Component({
  selector: 'rcbt-comparator-parameters',
  templateUrl: './comparator-parameters.component.html',
  styleUrls: ['./comparator-parameters.component.scss'],
  standalone: true,
  imports: [MediaPipe],
})
export class ComparatorParametersComponent {
  @ViewChild('multiSelect') multiSelect: MultiSelectComponent;
  public connectivityIconMap = CONNECTIVITY_ICON_MAP;

  constructor(
    public readonly comparatorService: ComparatorService,
    private router: Router,
  ) {}

  public resetComparator(event): void {
    event.preventDefault();
    this.comparatorService.empty();
    this.multiSelect.reset();
  }

  public goToComparator(): void {
    this.router.navigate(['/comparator']);
  }
}
