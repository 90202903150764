import { Component, effect, model, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { ProductsService } from '@services/products.service';
import { CartService } from '@services/cart.service';
import { forkJoin, from as observableFrom, Observable, of as observableOf, of, Subscription } from 'rxjs';
import { ScanForm, StockType } from '@interfaces/types';
import { Catalog } from '@model/catalog/products/catalog';

import { ViewDirective } from '@directive/view.directive';
import { ViewService } from '@services/view.service';
import { ScanService } from '../scan.service';
import { JsonCatalog, JsonProduct } from '@model/catalog/products/interface/context';
import { Complex } from '@model/catalog/products/equipement/complex';
import { Accessory } from '@model/catalog/products/equipement/accessory';
import { PricingProductsInterface } from '@components/pricing-products/pricing-products.interface';
import { PricingAccessoryService } from '@components/pricing-accessory/pricing-accessory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StockChoiceComponent } from './stock-choice/modal-stock-choice.component';
import { Product } from '@model/catalog/products/product';
import { ScanditService } from '@services/scandit.service';
import { InputForScan, ScanditFieldInterface } from '@interfaces/scandit.interface';
import { ComparatorService } from '@services/comparator.service';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { BrowseConfigService } from '../../../../context/browse-config.service';
import { FormsModule } from '@angular/forms';
import { ScanDirective } from '../scan.directive';

enum TypesStock {
  bytel = '0001',
  ca = 'CA01',
}

@Component({
  selector: 'rcbt-form-scan',
  templateUrl: './form-scan.component.html',
  styleUrls: ['./form-scan.component.scss'],
  standalone: true,
  imports: [FormsModule, ScanDirective, ViewDirective],
})
export class FormScanComponent implements OnInit, OnDestroy {
  readonly viewDirective = viewChild(ViewDirective);
  scanCode = model<string>();
  previousCode = signal<string | undefined>(undefined);

  public products: JsonCatalog;
  public productsCa: JsonCatalog;
  public stockData: StockType[];
  public message = '';
  public scanLoading = false;
  public scanListener: Subscription;
  protected codeInput = '';
  private consultingAccessoryPrices = false;
  private lastScanCodeInput = '';
  private lastScanCodeInputTimed = '';
  private lastScanCodeTimeout: NodeJS.Timeout;

  /**
   * [constructor description]
   * @param  {StockService}    privatestock           [description]
   * @param  {ProductsService} privateproductsService [description]
   * @return {[type]}                                 [description]
   */
  constructor(
    private productsService: ProductsService,
    private cartService: CartService,
    private view: ViewService,
    private scanService: ScanService,
    protected pricingAccessoryService: PricingAccessoryService,
    private modalService: NgbModal,
    private scanditService: ScanditService,
    private comparatorService: ComparatorService,
    private browseConfigService: BrowseConfigService,
  ) {
    // TODO: DEMANDE À GREGOOSE PK
    effect(() => {
      const currentCode = this.scanCode();
      if (currentCode && currentCode !== this.previousCode() && !this.view.sourceScan && currentCode?.length) {
        this.codeInput = this.scanCode();
        this.onSubmit({ scanCode: this.scanCode() });
        this.previousCode.set(currentCode);
      }
    });
  }

  /**
   * [ngOnInit description]
   * @return {[type]} [description]
   */
  public ngOnInit(): void {
    this.consultingAccessoryPrices = this.browseConfigService.browseConfig.browseActionType === 'IHMPRIXACCESSOIRE';
    if (this.scanditService.isOnTablet() || this.browseConfigService.browseConfig.debug) {
      this.scanditService.openScanForField(InputForScan.scanTopBar, true);
      this.scanListener = this.scanditService.scanForField.subscribe((data: ScanditFieldInterface) => {
        if (data.field === InputForScan.scanTopBar) {
          this.onSubmit({ scanCode: data.scanCode });
        }
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.scanListener) {
      this.scanListener.unsubscribe();
    }
  }

  get _scanCode(): string {
    return this.scanCode();
  }

  public scanChange(event: string): void {
    let _scanCode = event;
    // Gestion des codes barre UPC-A via douchette :
    //    Les codes barres UPC-A (12 digits) sont une sous-partie des EAN (13 digits) mais commençant par un '0'
    //    Les douchettes gèrent les UPC-A en n'envoyant que les 12 digits, et en ignorant le '0' de début.
    //    De plus les douchettes envoie les digits un par un, mais très vite.
    //    Palliatif :
    //      - lastScanCodeInput sert à stocker la dernière valeur saisie dans l'input
    //      - lastScanCodeTimeout sert à gérer le timeout de mise à jour de la valeur tant que l'on modifie dans les 300ms, on ne met pas à jour
    //          Ainsi, une saisie manuelle va mettre à jour la variable lastScanCodeInput à chaque caractère, mais un copier/coller ou la saisie rapide de la douchette ne la mettra pas à jour.
    //      - s'il n'y avait pas de valeur, et que l'on passe directement à 12 digit, alors on rajoute le '0' implicite en début du scanCode
    clearTimeout(this.lastScanCodeTimeout);
    const copyPaste = !this.lastScanCodeInput && _scanCode?.length === 12;
    this.lastScanCodeTimeout = setTimeout(() => {
      if (!this.lastScanCodeInputTimed && _scanCode?.length === 12) {
        // this.scanCode.set('0' + this.scanCode());
        _scanCode = '0' + _scanCode;
        if (!copyPaste) {
          // this.onSubmit({ scanCode: this.scanCode() });
          this.scanCode.set(_scanCode);
        }
      }
      this.lastScanCodeInputTimed = this.scanCode();
    }, 300);
    this.lastScanCodeInput = this.scanCode();
    this.message = '';
  }

  public onSubmit(value: ScanForm): void {
    this.lastScanCodeInput = value.scanCode;
    this.view.sourceScan = true;
    this.onSubmitCode(value)
      .pipe(
        finalize(() => {
          this.view.sourceScan = false;
        }),
      )
      .subscribe();
  }

  public scan(): void {
    const _scanCode = this.scanCode()?.length ? this.scanCode() : this.lastScanCodeInput;
    const products: JsonProduct[] = [];
    this.stockData.forEach(p => {
      if (p.quantity) {
        if (p.typeStock === TypesStock.bytel && this.products && this.products[p.gencode]) {
          products.push(this.products[p.gencode]);
        } else if (p.typeStock === TypesStock.ca && this.productsCa && this.productsCa[p.gencode]) {
          this.productsCa[p.gencode].type_id = 'produit_ca';
          products.push(this.productsCa[p.gencode]);
        }
      }
    });
    // if several products choice popin
    let addProductObs: Observable<unknown>;
    if (products.length > 1) {
      const modalRef = this.modalService.open(StockChoiceComponent, { size: 'lg' });
      modalRef.componentInstance.sourceProducts = products;
      modalRef.componentInstance.currentScheme = this.cartService.getCurrentScheme();
      addProductObs = observableFrom(modalRef.result);
    } else {
      addProductObs = observableOf(Catalog.getInstance(products[0]));
    }

    addProductObs.subscribe((productInstance: Product) => {
      if (this.consultingAccessoryPrices) {
        if (!(productInstance instanceof Accessory)) {
          this.message = 'Le code barre ne correspond pas à un accessoire.';
          return;
        } else {
          const inputsComponent: PricingProductsInterface = {
            name: productInstance.name,
            thumbnail: productInstance.data.thumbnail,
            price: productInstance.price,
            oldPrice: productInstance.price,
            quantity: this.stockData[0].quantity,
            gencode: productInstance.gencode,
          };
          this.pricingAccessoryService.sendMessage([inputsComponent]);
        }
        return;
      }

      if (
        productInstance.type_id &&
        productInstance.data.priorite_sav &&
        productInstance.type_id === 'box' &&
        productInstance.data.priorite_sav !== ''
      ) {
        this.message = 'Non vendable sur ce parcours';
        return;
      }

      if (!productInstance.setScanCode(_scanCode)) {
        this.message = 'Code barre invalide';
        return;
      }
      if (
        this.cartService.getCurrentScheme().isAcquisitionFix() &&
        ['produit_ca', 'accessory'].indexOf(productInstance.type_id) === -1
      ) {
        this.message = "Type d'element scanné non autorisé sur ce type parcours!";
        return;
      }

      switch (productInstance.type_id) {
        case 'accessory':
        case 'produit_ca':
          this.cartService
            .add(productInstance)
            .pipe(mergeMap(() => this.cartService.refreshCart()))
            .subscribe(
              () => (this.message = ''),
              () => (this.message = "Impossible d'ajouter l'accessoire"),
            );
          break;
        case 'phone_simple':
        case 'box':
          if (!productInstance.data.parent_code && productInstance.type_id === 'phone_simple') {
            this.message = 'Produit mal configuré';
            return;
          }
          const parentCode: string = productInstance.data.parent_code
            ? productInstance.data.parent_code
            : this.stockData[0].gencode;
          if (
            !this.cartService.isProductAvailable(productInstance) ||
            (this.cartService.getCurrentScheme().isAcquisitionMobile() && this.hasCategory(productInstance, 'rcbt_fai'))
          ) {
            this.message = 'Vente non autorisée';
            return;
          }
          this.qview(parentCode, this.stockData[0].gencode, this.scanCode(), productInstance.type_id);
          break;
        default:
          break;
      }
      this.lastScanCodeInput = '';
      this.codeInput = '';
      this.scanCode.set('');
    });
  }

  /**
   * [qview description]
   * @return {[type]} [description]
   */
  public qview(parentCode: string, childCode: string, scanCode: string, childTypeId: string): void {
    if (!this.products[childCode]) {
      this.message = 'Produit désactivé';
      return;
    }
    const _scanCode = scanCode?.length ? scanCode : this.previousCode();
    this.view.qviewGen(
      this.viewDirective(),
      parentCode,
      childTypeId,
      [childCode],
      childCode,
      _scanCode,
      this.scanService,
    );
  }

  public loadScan(): void {
    if (this.browseConfigService.browseConfig.debug || this.scanditService.isOnTablet()) {
      this.scanditService.openScanForField(InputForScan.scanTopBar);
    }
  }

  protected _getScanCode(scanCode: string): string {
    while (scanCode.length < 13) {
      scanCode = '0' + scanCode;
    }
    return scanCode;
  }

  private onSubmitCode(value: ScanForm): Observable<void> {
    this.comparatorService.reset();
    this.scanLoading = true;
    this.message = '';
    if (this.consultingAccessoryPrices) {
      this.pricingAccessoryService.sendMessage([]);
    }
    this.scanCode.set(this._getScanCode(value.scanCode));

    const product = this.cartService
      .getAllProducts()
      .find(tmpProduct => tmpProduct instanceof Complex && tmpProduct.getScanCode() === this.scanCode());
    if (product) {
      this.stockData = [{ gencode: product.gencode, quantity: 1, typeStock: TypesStock.bytel }];
      this.scanLoading = false;
      return this.loadProducts().pipe(map(() => this.scan()));
    }

    const obs = this.consultingAccessoryPrices
      ? this.cartService.getItemFromStockForStandalone(this.scanCode())
      : this.cartService.getItemFromStockOrReservedProducts(this.scanCode());
    return obs.pipe(
      mergeMap((item: StockType[]) => {
        this.stockData = item;
        return this.loadProducts();
      }),
      mergeMap(() => {
        this.scanLoading = false;
        this.scan();
        return of(void 0);
      }),
      catchError(error => {
        this.message = error && error.message ? error.message : error;
        this.scanLoading = false;
        return of(void 0);
      }),
    );
  }

  private hasCategory(product: Product, category: string): boolean {
    return product.data.categories.some(cat => cat === category);
  }

  private loadProducts(): Observable<void> {
    const loadProductsObs: Observable<JsonCatalog>[] = [];
    this.stockData.forEach(data => {
      if (data.quantity) {
        loadProductsObs.push(this.loadProduct(data.gencode, data.typeStock as TypesStock));
      }
    });
    return loadProductsObs.length ? forkJoin(loadProductsObs) : of(null);
  }

  private loadProduct(gencode: string, stockType: TypesStock): Observable<JsonCatalog> {
    const loadProductObs: Observable<JsonCatalog> =
      stockType === TypesStock.bytel
        ? this.productsService.getJsonProductsByFilter({ listGencode: gencode })
        : this.productsService.getJsonCaProductsByFilter({ listGencode: gencode });
    return loadProductObs.pipe(
      tap(result => {
        if (stockType === TypesStock.bytel) {
          this.products = result;
        } else {
          this.productsCa = result;
        }
      }),
    );
  }
}
