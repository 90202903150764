<div class="is-tri m-t-30">

    @if (error) {
      <div class="is-flex is-fullwidth is-justified-center m-t-10">
        <button class="button is-info" (click)="reload()" [disabled]="globalLoaderService.isAppOnLoadingStatus$ | async">
          <span class="icon is-small"><i class="tri-exclamation-redo" aria-hidden="true"></i></span>    Recharger
        </button>
      </div>
    }

    @if (!error) {
      <div class="columns">
        <rcbt-side-bar-filter class="column sideBar m-b-0 p-b-0" (filtersChangedEmitter) = "onFiltersChanged()"></rcbt-side-bar-filter>
        <div class="is-flex is-vertical is-fullwidth m-r-5">
            <rcbt-comparator-parameters class="is-fullwidth m-t-5"></rcbt-comparator-parameters>
            @if (allPlans) {
              <rcbt-top-bar-filter class="columns m-r-10"
                [allPlans]="allPlans"
                (filtersChangedEmitter) = "onFiltersChanged()"
                [nbCurrentPhones]="(equipments$ | async).length"
                [nbAllPhones]="nbAllPhones">
              </rcbt-top-bar-filter>
            }
            <div class="product-list">
              <rcbt-infinite-scroll (scrolled)="onScroll()" [hasFetchedAllEquipments]="hasFetchedAllEquipments" [scrollInProgress]="scrollInProgress">
                <div class="is-flex is-multiline g-5" rcbtQview>
                  @for (equipment of equipments$ | async; track trackByFn(i, equipment); let i = $index) {
                    <div
                      [id]="equipment.gencode"
                      class="is-flex one-device is-vertical is-justified-end"
                      [ngClass]="{'isReco': equipment.recommande, 'selectedForCompare': isParentSelected(equipment)}"
                      (click)="productClick(equipment)">
                      @if (equipment.recommande) {
                        <div class="isRecoTitle">Sélectionné pour votre client</div>
                      }
                      <rcbt-product-item
                        class="component-device"
                        [index]="i"
                        [equipment]="equipment">
                      </rcbt-product-item>
                    </div>
                  }
                </div>
              </rcbt-infinite-scroll>
              @if (scrollInProgress) {
                <div class="is-flex is-fullwidth is-justified-center is-aligned-center">
                  <div class="notification has-body has-background-warning-20 is-warning m-y-5">
                    <div class="icon">
                      <i class="tri-exclamation-circle" aria-hidden="true"></i>
                    </div>
                    <div class="body">
                      <div class="text is-size-3">Chargement en cours ...</div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
      </div>
    }
</div>
