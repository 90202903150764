import { Injectable } from '@angular/core';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FundingCart } from '../models/funding.cart';
import {
  CommercialPropApi,
  CommercialProposal,
  FundingEnum,
  FundingModeApi,
  FundingModeParcoursApi,
  FundingModeProductApi,
  FundingProduct,
  FundingScheme,
  GetFundingModeApiResult,
  IPostFundingResponse,
  PaymentMode,
  PostFundingModeApiPayload,
  PostFundingPathwayPayload,
  GetCurrentFundingModeApiResult,
} from '@interfaces/funding.interface';
import { FundingMethod } from '../models/funding.method';
import { ConfigService } from '@services/config.service';
import { CartService } from '@services/cart.service';
import { Scheme } from '@model/scheme.class';
import { Phone } from '@model/catalog/products/equipement/complex/phone';
import { FundingCurrent } from '../models/funding.current';

@Injectable({
  providedIn: 'root',
})
export class FundingRepository {
  public readonly paymentModeDetails: PaymentMode[] = [
    {
      id: 'CB',
      value: 'CB',
      icon: 'credit-card',
      position: 0,
    },
    {
      id: 'PAYPAL',
      value: 'PAYPAL',
      icon: 'shape-icon-cb-paypal',
      position: 1,
    },
    {
      id: 'PAYLIB',
      value: 'PAYLIB',
      icon: 'shape-icon-cb-paylib',
      position: 2,
    },
    {
      id: 'CREDIT_X3',
      value: 'CREDIT_X3',
    },
    {
      id: 'CREDIT_MENSUEL',
      value: 'CREDIT_MENSUEL',
    },
    {
      id: 'YOUNITED',
      value: 'YOUNITED',
    },
    {
      id: 'HYBRIDE',
      value: 'HYBRIDE',
      position: 4,
    },
    {
      id: 'SANS_PAIEMENT',
      value: 'SANS_PAIEMENT',
    },
  ];

  public readonly fundingYounitedKeys = ['YOUNITED_X3', 'YOUNITED_X12', 'YOUNITED_X24', 'YOUNITED_X36'];

  private readonly fundingMethodDetails = {
    comptant: { label: 'Paiement comptant' },
    younitedBy3: { label: 'Paiment en 3x avec Younited' },
    younitedBy12: { label: 'Paiment en 12x avec Younited' },
    younitedBy24: { label: 'Paiment en 24x avec Younited' },
    younitedBy36: { label: 'Paiment en 36x avec Younited' },
  };

  constructor(
    private oauth2Resource: Oauth2RessourceService,
    private config: ConfigService,
  ) {}

  /**
   * Get funding modes for current cart
   * @param cartId Current cart id
   * @returns Fundings modes
   */
  public getFundingModes(cartId: number): Observable<FundingCart> {
    return this.oauth2Resource
      .ventes()
      .panier(cartId)
      .modesFinancement()
      .buildQueryParams({
        force: true,
        mode: 'min-one-off',
      })
      .useSalesApi()
      .get<GetFundingModeApiResult>()
      .pipe(map((response: GetFundingModeApiResult) => this._createFundingCartModel(response)));
  }

  public getCurrentFundingModes(cartId: number): Observable<IPostFundingResponse> {
    return this.oauth2Resource.ventes().panier(cartId).modesFinancement(true).useSalesApi().get<IPostFundingResponse>();
  }

  public getCurrentFundingMode(cartId: number): Observable<FundingCurrent> {
    return this.oauth2Resource
      .ventes()
      .panier(cartId)
      .modesFinancement(true)
      .useSalesApi()
      .get<GetCurrentFundingModeApiResult>()
      .pipe(map((response: GetCurrentFundingModeApiResult) => this.createCurrentFundingCartModel(response)));
  }

  public postFundingMode(
    cartService: CartService,
    forceCashFundingMode: boolean = false,
  ): Observable<IPostFundingResponse> {
    return this.oauth2Resource
      .ventes()
      .panier(cartService.cart.cartId)
      .modesFinancement()
      .buildQueryParams({
        force: true,
      })
      .useSalesApi()
      .post(this.buildPostFundingModePayload(cartService, forceCashFundingMode));
  }

  public createCurrentFundingCartModel(data: GetCurrentFundingModeApiResult): FundingCurrent {
    return new FundingCurrent({
      id: data.id,
      status: data.status,
      raison: data.raison,
      modeDeFinancement: data.modesDeFinancement.type,
      commercialProposal: this._createCommercialProposals([data.modesDeFinancement.propositionsCommerciales])?.[0],
    });
  }

  private buildPostFundingModePayload(
    cartService: CartService,
    forceCashFundingMode: boolean = false,
  ): PostFundingModeApiPayload {
    const parcours: PostFundingPathwayPayload[] =
      cartService.cart.creditData || forceCashFundingMode
        ? undefined
        : this.buildPostFundingModeEdpPayload(cartService);
    return {
      modeDeFinancement: forceCashFundingMode ? FundingEnum.cash : this.getSelectedFundingMode(parcours, cartService),
      modeDePaiement: 'CAISSE',
      consentement: true,
      urlDeRappel: `${window.origin}/panier/pieces-justificatives-v2?source=YC`,
      parcours: parcours,
    };
  }

  private getSelectedFundingMode(parcours: PostFundingPathwayPayload[], cartService: CartService): FundingEnum {
    if (cartService.cart.creditData) {
      return cartService.cart.creditData.type;
    }
    if (parcours?.length) {
      return FundingEnum.multiProduit;
    }
    return FundingEnum.cash;
  }

  private buildPostFundingModeEdpPayload(cartService: CartService): PostFundingPathwayPayload[] {
    const parcours: PostFundingPathwayPayload[] = [];
    cartService.cart.schemes.forEach((s: Scheme) => {
      if (!!s.hasEdp) {
        parcours.push({
          idParcours: String(s.quoteId),
          produits: [
            {
              idProduit: String(s.getProductByType(Phone).itemId),
              modeDeFinancement: FundingEnum.edp,
            },
          ],
        });
      }
    });
    return parcours;
  }

  private _createFundingCartModel(data: GetFundingModeApiResult): FundingCart {
    return new FundingCart({
      idCart: data.idPanier,
      fundingMethods: data.modesDeFinancement?.map(el => this._createFundingModeModel(el)),
      fundingSchemes: data.parcours?.map(p => this._createFundingScheme(p)),
    });
  }

  private _createFundingScheme(data: FundingModeParcoursApi): FundingScheme {
    return {
      idScheme: data.idParcours,
      products: data.produits?.map(p => this._createFundingSchemeProduct(p)),
    };
  }

  private _createFundingSchemeProduct(data: FundingModeProductApi): FundingProduct {
    return {
      idProduct: data.idProduit,
      gencode: data.gencode,
      cartFundingModes: data.modesDeFinancementPanier,
      productsFundingMode: data.modesDeFinancementProduits?.map(el => this._createFundingModeModel(el)),
    };
  }

  /**
   * Transform api data to funding mode model
   * @param data API data
   * @returns Return funding mode model
   */
  private _createFundingModeModel(data: FundingModeApi): FundingMethod {
    const commercialProposal: CommercialProposal[] = this._createCommercialProposals(data?.propositionsCommerciales);
    return new FundingMethod({
      label: this._getFundingMethodLabel(data.type) ?? data?.libelle,
      paymentModes: data?.modesDePaiement?.map(mp => this.paymentModeDetails.find(el => el.id === mp.type)),
      commercialProposal: commercialProposal,
      bestCommercialProposal: this.getBestCommercialProposals(commercialProposal),
      type: data?.type,
    });
  }

  private getBestCommercialProposals(proposals: CommercialProposal[]): CommercialProposal {
    return proposals.length === 0 ? undefined : proposals[0];
  }

  /**
   * Create commercial proposal array - for younited in case of multiple proposal
   * for same duration take the on with the cheapest initialAmount
   * @param data api proposals
   * @returns commercial proposal array
   */
  private _createCommercialProposals(data: CommercialPropApi[]): CommercialProposal[] {
    const proposals: CommercialProposal[] = [];

    if (!data) {
      return proposals;
    }

    data.forEach(cp => {
      const existing = proposals.find(p => p.nbLoan === cp.nbrEcheances);
      const proposal: CommercialProposal = {
        initialAmount: cp?.apportInitial,
        fundingFinalCost: cp?.coutTotalFinancement,
        amountToFund: cp?.montantAFinancer,
        interestAmount: cp?.montantInterets,
        monthlyAmount: cp?.montantMensualite,
        lastMonthlyAmount: cp?.dernierMontantMensualite,
        nbLoan: cp?.nbrEcheances,
        tAEG: cp?.tAEG,
        fixedRate: cp?.tauxDebiteurFixe,
        nbrEcheances: cp?.nbrEcheances,
      };

      if (existing && existing.initialAmount > cp.apportInitial) {
        proposals.splice(
          proposals.findIndex(p => p === existing),
          1,
          proposal,
        );
      } else {
        proposals.push(proposal);
      }
    });

    return proposals;
  }

  private _getFundingMethodLabel(type: string): string {
    return this.fundingMethodDetails[type]?.label ?? null;
  }
}
