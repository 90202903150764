import { Product } from '@model/catalog/products/product';

export interface AddResult {
  status: boolean;
  productsConflict: Product[];
  addCheck?: ResultAddMessage;
}

export interface ResultAddMessage {
  type: TypeResult;
  message?: string;
}

export enum TypeResult {
  warning = 'WARNING',
  ok = 'OK',
  error = 'ERROR',
}
