import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, viewChild } from '@angular/core';
import { ToolLinkInterface } from '@base/configuration.interface';
import { ConfigService } from '@services/config.service';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { TopBarService } from '@services/top-bar.service';
import { Oauth2RessourceService } from '../../../oauth2/oauth2-resources.service';
import { AlertService } from '@services/alert.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'rcbt-tool-links',
  templateUrl: './tool-links.component.html',
  standalone: true,
  styleUrls: ['./tool-links.component.scss'],
  imports: [NgClass],
})
export class ToolLinksComponent implements AfterViewInit, OnDestroy {
  readonly dropdownContainer = viewChild<ElementRef>('dropdownContainer');

  public showDropdownMenu = false;
  public links: ToolLinkInterface[];

  private globalClickListener: () => void;

  constructor(
    private configService: ConfigService,
    private customerService: CustomerService,
    private topBarService: TopBarService,
    private renderer: Renderer2,
    private oauth2RessourceService: Oauth2RessourceService,
    private alertService: AlertService,
  ) {
    this.links = this.getLinks();
  }

  public ngAfterViewInit(): void {
    this.globalClickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      if (!this.dropdownContainer().nativeElement.contains(event.target)) {
        this.showDropdownMenu = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.globalClickListener();
  }

  public navigateTo(link: ToolLinkInterface): void {
    if (link.title.toLowerCase() === 'esav') {
      this.oauth2RessourceService
        .setLocalService()
        .useSalesApi()
        .applications()
        .sav()
        .redirection()
        .get()
        .subscribe({
          next: data => window.open(data.url, '_blank'),
          error: error => this.alertService.errorEmitter.next('Problème technique pour récupérer le lien'),
        });
    } else {
      window.open(link.url, '_blank');
    }
  }

  private getLinks(): ToolLinkInterface[] {
    const toolBoxElements = this.configService.data.toolsBox;
    if (!toolBoxElements) {
      return [];
    }
    if (!this.configService.data.simulator?.active && toolBoxElements.simulator) {
      delete toolBoxElements.simulator;
    }
    return Object.entries(this.configService.data.toolsBox).map(([key, value]: [string, ToolLinkInterface]) => {
      if (key === 'simulator') {
        const queryParams = this.customerService.mapToSimulatorCustomer();
        return { url: this.topBarService.getSimulatorUrl(queryParams), icon: value.icon, title: value.title };
      } else {
        return value;
      }
    });
  }
}
