import { BasicObject } from '@base/base.interfaces';

export interface IPromotionRule {
  panier?: BasicObject;
  parcours?: BasicObject;
}

export interface IPromotionApplication {
  panier?: BasicObject;
  parcours?: BasicObject;
  produit?: BasicObject;
  actionPromotion?: IPromoAction;
}

export enum PromotionTypologies {
  product = 'product',
  plan = 'plan',
}

export interface IPromotion {
  id: number;
  type: PromotionTypes;
  typology: PromotionTypologies;
  name?: string;
  marketName?: string;
  description?: string;
  priority: number;
  incompatibilities?: number[];
  beforeApplyMsg?: string;
  afterApplyMsg?: string;
  condition: IPromotionRule;
  application: IPromotionApplication;
  opsCode: string;
  odr: boolean;
  additionalProperties?: { [index: string]: string | number | boolean };
}

export interface IRequestRegularizationAdminRights {
  logonManager: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Fonctionnality: string;
}

export interface IResponseRegularizationAdminRights {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Granted: string;
  codeRetour: string;
  diagnostic?: string;
}

export interface ICampaign {
  id: number;
  name: string;
  code: string;
  status: number;
  start: Date;
  end: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface IReason {
  id: string;
  statut: boolean;
  libelle: string;
}

export enum UpdatePromoParamsActions {
  add = 'add',
  remove = 'remove',
}

export interface UpdatePromoParams {
  amount?: number;
  action: UpdatePromoParamsActions;
  gencode?: string;
  reasonCode?: string;
  comment?: string;
  coupon?: string;
  type?: PromoActionTypes;
  percent?: number;
}

export interface PromoParams {
  amount?: number;
  percent?: number;
  gencode?: string;
  reasonCode?: string;
  comment?: string;
  type?: string;
}

export interface IPromoAction {
  minPrice?: number;
  baseAmount?: number;
  amount?: number;
  percent?: number;
  type: PromoActionTypes;
  visible?: boolean;
  duration: number;
  addProduct?: unknown;
  isManuallyApplied?: boolean;
  reasonCode?: string;
  coupon?: string;
  cpuId?: number;
  comment?: string;
  isDeferred?: boolean;
}

export interface IGencode {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IN: string[];
}
export interface ISchemeId {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IN: string[];
}

export interface ICartTotal {
  today: number;
  everyMonth: number;
  reported: number;
}

export enum PromoActionTypes {
  byFixed = 'by_fixed',
  byPercent = 'by_percent',
}

export enum PromotionTypes {
  agilityCa = 'AGILITY_CA',
  agility = 'AGILITY',
  automatic = 'AUTOMATIC',
  manual = 'MANUAL',
  regularization = 'REGULARIZATION',
  regularizationCa = 'REGULARIZATION_CA',
  coupon = 'COUPON',
  cpu = 'CPU',
}

export enum ProprietesSupplementaires {
  etiquette = 'etiquette',
  fanion = 'fanion',
  blocPorta = 'blocPorta',
  typesMarketing = 'typesMarketing',
}

export interface IProprietesSupplementaires {
  [ProprietesSupplementaires.etiquette]?: string;
  [ProprietesSupplementaires.fanion]?: string;
  [ProprietesSupplementaires.blocPorta]?: string;
  [ProprietesSupplementaires.typesMarketing]?: MarketingTypes[];
}

export enum MarketingTypes {
  convergence = 'CONVERGENCE',
  portabilite = 'PORTABILITE',
  codepostaleligibilitefixe = 'CODE_POSTAL_ELIGIBILITE_FIXE',
}
