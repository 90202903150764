<div class="topBarFilter is-flex is-fullwidth is-spaced-between g-5 m-l-10" [ngClass]="{'m-b-25': takebackService.takebackPriceEstimation !== undefined}">

  <div class="nbPhones is-flex is-aligned-bottom text is-size-6 has-text-weight-semibold">
    {{nbCurrentPhones}}/{{nbAllPhones}}&nbsp;téléphones
  </div>

  <div class="selectPlans field is-flex is-aligned-center m-0">
    <div class="control has-dynamic-placeholder">
      <select id="select-banc" class="select has-text-tertiary select-banc p-l-10" [(ngModel)]="filterService.phoneFiltersAndSort.planSelected"
        (change)="emitFiltersAndSort()">
        @for (plan of plansToDisplay; track plan) {
          <option [value]="plan.gencode">{{plan.name}}</option>
        }
      </select>
      <label for="select-banc">Offre</label>
    </div>
  </div>

  @if (isMtbStandAloneActive && !isRenew) {
    <rcbt-takeback-button [componentSource]="componentSourceTakeback" [disableButton]="hasMobileTakeBack"
    class="buttonTakeBack"></rcbt-takeback-button>
  }

  @if (plansForComparator) {
    <div class="toggleComparator is-flex is-aligned-center">
      <rcbt-comparator-toggle [plansForComparator]="plansForComparator"></rcbt-comparator-toggle>
    </div>
  }

  <div class="selectSort field is-flex is-aligned-center m-0">
    <div class="control has-dynamic-placeholder">
      <select id="select-banc" class="select has-text-tertiary select-banc p-l-10" [(ngModel)]="filterService.phoneFiltersAndSort.sortSelected"
        (change)="emitFiltersAndSort()">
        @for (option of filterService.triOptions; track option) {
          <option [value]="option.key">{{option.label}}</option>
        }
      </select>
      <label for="select-banc">Trié par</label>
    </div>
  </div>

</div>