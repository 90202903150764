import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '@services/cart.service';
import { Oauth2RessourceService } from '../../../oauth2/oauth2-resources.service';
import { of as observableOf, Observable, Subscription, of } from 'rxjs';
import { map, catchError, mergeMap, tap, finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JsonCatalog } from '@interfaces/types';
import { Catalog } from '@model/catalog/products/catalog';
import {
  IMobileTakeBackConfiguration,
  IMobileTakeBackTerminal,
  IMobileTakeBackQualificationCriterion,
  IDisposalTicket,
  IDataQuestion,
  ICriteresReprise,
} from '@model/catalog/products/mobileTakeBack.interfaces';
import { PartnerProductsQuestions } from '@interfaces/takeback.interface';
import { DOCUMENT, NgClass } from '@angular/common';
import { ScanditService } from '@services/scandit.service';
import { saveAs } from 'file-saver';
import { MobileTakeBackService } from '@components/top-bar/scan/mobileTakeBackService';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { UserService } from '@services/user.service';
import { BasicObject } from '@base/base.interfaces';
import { v4 as uuidv4 } from 'uuid';
import { BlacklistElement200Response } from '@bytel/pt-ihm-api-npbt-ressource-imei';
import { format, parseISO } from 'date-fns';
import { PriceComponent } from '@base/price/price.component';
import { ScannerWrapperComponent } from '@components/scanner-wrapper/scanner-wrapper.component';

@Component({
  selector: 'rcbt-takeback-modal',
  templateUrl: './takeback-modal.component.html',
  styleUrls: ['./takeback-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgbPopover, PriceComponent, NgClass, ScannerWrapperComponent],
})
export class TakebackModalComponent implements OnInit, OnDestroy {
  public productlist: Array<BasicObject> = [];
  public questionsList: Array<BasicObject> = [];
  public questionForm: UntypedFormGroup;
  public products: JsonCatalog;
  public mobileTakeBackCfg: IMobileTakeBackConfiguration;
  public isFormvalid = false;
  public dataQuestion: IDataQuestion = {};
  public mobileTakeBackAmount: number;
  public error: string;
  public success: boolean;
  public inventoryError: string;
  public inventoryWarning: string;
  public geolocalisationError: string;
  public isLoading: boolean;
  public searchValue: string;
  public phone: BasicObject;
  public imeiForm: UntypedFormGroup;
  public onTablet: boolean;
  public scanListener: Subscription;
  protected scanditOpen = false;
  private popover;

  constructor(
    private activeModal: NgbActiveModal,
    public cartService: CartService,
    public oauth2RessourceService: Oauth2RessourceService,
    private formBuilder: UntypedFormBuilder,
    @Inject(DOCUMENT) private document: HTMLElement,
    private scanditService: ScanditService,
    private mobileTakeBackService: MobileTakeBackService,
    private stepperService: CheckoutStepperService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.imeiForm = this.formBuilder.group({
      imei: this.formBuilder.control(null, [
        Validators.minLength(15),
        Validators.maxLength(17),
        Validators.pattern('^BTEL[0-9A-Z]{13}$|^[0-9]{15}$'),
      ]),
      phoneModel: this.formBuilder.control(null, []),
    });
    this.document.addEventListener('scroll', this.onScroll, true);
    this.onTablet = this.scanditService.isOnTablet();
  }

  public togglePopover(_popover): void {
    this.popover = _popover;
  }

  public isValidImei(): boolean {
    return this.imeiForm.valid;
  }

  public close(): void {
    this.activeModal.close();
  }

  public search(event: BasicObject): void {
    if (event.target && event.target.value !== '') {
      this.searchMtbProduct(event.target.value);
    }
  }

  public searchMtbProduct(searchInput: string): void {
    this.reset();
    if (searchInput.length === 15 && searchInput.substring(0, 4) !== 'BTEL' && this.imeiForm.valid) {
      this.isLoading = true;
      this.checkBlacklistedPhone(searchInput)
        .pipe(
          mergeMap((isImeiBlacklisted: boolean) => {
            if (isImeiBlacklisted) {
              this.productlist = [];
              return of(null);
            } else {
              return this.searchMtbByImei(searchInput);
            }
          }),
          finalize(() => (this.isLoading = false)),
        )
        .subscribe();
    } else if (searchInput.length === 17 && searchInput.substring(0, 4) === 'BTEL') {
      this.callDisposalTicketTakeBack(searchInput);
    }
  }

  private searchMtbByImei(imei: string): Observable<BasicObject> {
    this.productlist = [];
    return this.oauth2RessourceService
      .produitsPartenaires(imei)
      .get()
      .pipe(
        catchError(error => {
          throw new Error(`error produitsPartenaires: ${error.message}`);
        }),
        tap(
          (data: BasicObject) => {
            if (data.items?.length) {
              data.items.forEach(element => {
                this.productlist.push(element);
              });
            }
            if (this.productlist.length === 1) {
              this.getFormQuestionAndSelectFirst(this.productlist[0]);
            }
            if (this.productlist.length === 0) {
              this.error = 'IMEI inconnu!';
            }
          },
          (error: string) => (this.error = error),
        ),
      );
  }

  private checkBlacklistedPhone(imei: string): Observable<boolean> {
    return this.mobileTakeBackService
      .checkIsMobileBlacklisted({
        idAppelant: 'RCBT',
        idFlux: uuidv4(),
        IMEI: imei,
        mode: 'encours',
      })
      .pipe(
        catchError(() => of({})),
        map((result: BlacklistElement200Response) => {
          const blacklistElt = result.blacklistElement?.find(elt => elt.Mouvement === 'BLACKLISTAGE');
          if (blacklistElt) {
            this.error = "La reprise mobile n'est pas possible - IMEI blacklisté.";
          }
          return !!blacklistElt;
        }),
      );
  }

  public scanImei(): void {
    this.scanditOpen = true;
  }

  public onScan(event): void {
    this.imeiForm.controls.imei.setValue(event);
    this.searchMtbProduct(event);
    this.onClose();
  }
  public onClose(): void {
    this.scanditOpen = false;
  }

  public getFormQuestion(): void {
    this.reset();
    if (this.imeiForm.controls.phoneModel.value) {
      this.phone = this.productlist.find((elt: BasicObject) => elt.id === this.imeiForm.controls.phoneModel.value.id);
      this.isLoading = true;
      this.error = undefined;
      this.getQuestions(this.imeiForm.controls.phoneModel.value.id)
        .pipe(
          catchError(error => {
            throw new Error(`error getQuestions: ${error.message}`);
          }),
        )
        .subscribe(
          () => {
            this.isLoading = false;
          },
          (error: string) => {
            this.error = error;
            this.isLoading = false;
          },
        );
    }
  }

  public getFormQuestionAndSelectFirst(productDevice: BasicObject): void {
    this.reset();
    if (productDevice.id) {
      this.imeiForm.controls.phoneModel.setValue(productDevice);
      this.phone = productDevice;
      this.isLoading = true;
      this.error = undefined;
      this.getQuestions(productDevice.id)
        .pipe(
          catchError(error => {
            throw new Error(`error getQuestions: ${error.message}`);
          }),
        )
        .subscribe(
          () => {
            this.isLoading = false;
          },
          (error: string) => {
            this.error = error;
            this.isLoading = false;
          },
        );
    }
  }

  public initFormQuestion(data: BasicObject): void {
    const obj = {};
    obj['productId'] = data.id;
    obj['manufacturer'] = data.marque;
    obj['name'] = data.nom;
    data.criteresRepriseProduit.forEach((element, index) => {
      const questionId = element.id;
      obj[questionId] = this.formBuilder.control(element.questionId || '');
      obj[`response-${questionId}`] = this.formBuilder.control(undefined, [Validators.required]);
    });
    this.questionForm = this.formBuilder.group(obj);
    this.onChanges();
  }

  public onChanges(): void {
    this.questionForm.valueChanges.subscribe(val => {
      this.isLoading = true;
      this.error = undefined;
      this.inventoryError = undefined;
      this.createDevis();
      this.isFormvalid = true;
      const form = this.questionForm.controls;
      if (this.questionForm && this.questionForm.valid && !!this.phone) {
        this.oauth2RessourceService
          .produitsPartenairesQuestions(form['productId'].value)
          .devis()
          .post(this.dataQuestion)
          .pipe(
            catchError(error => {
              this.isLoading = false;
              if (error.status === 400 && error.error?.error === 'CONFLIT_DOUBLON_IMEI') {
                throw new Error('Attention, cet IMEI a déjà été repris. Reprise impossible.');
              } else if (error.status === 400) {
                this.inventoryError =
                  'Un inventaire est en attente pour cette boutique. ' +
                  'Nous vous rappelons que vous devez effectuer un inventaire au minimum une fois par semaine. ' +
                  "L'interface est actuellement bloquée. Pour la débloquer, veuillez procéder " +
                  "à l'inventaire de vos produits repris";
                return observableOf(null);
              } else {
                throw new Error(`error getDevis: ${error.message}`);
              }
            }),
          )
          .subscribe(
            (data: BasicObject) => {
              if (data && !this.inventoryError) {
                if (data.inventaire && data.inventaire.statut === 'AVERTISSEMENT') {
                  this.inventoryWarning =
                    'Un inventaire est en attente pour cette boutique. ' +
                    'Nous vous rappelons que vous devez effectuer un inventaire au minimum une fois par semaine. ' +
                    "Sans action de votre part, l'interface Reprise Mobile sera inaccessible le " +
                    format(parseISO(data.inventaire.dateBlocage), 'dd/MM/yyyy') +
                    '.';
                } else {
                  this.inventoryWarning = null;
                }
                if (data.statutVerrouillage === 'BLOQUE') {
                  this.geolocalisationError =
                    'La fonction "Localiser mon iPhone" est active. ' +
                    'Pour pouvoir effectuer la reprise mobile, cette fonction doit être désactivée.';
                } else {
                  this.error = undefined;
                  this.mobileTakeBackCfg = {} as IMobileTakeBackConfiguration;
                  this.mobileTakeBackCfg.terminal = {} as IMobileTakeBackTerminal;
                  this.mobileTakeBackCfg.terminal.idProduit = form['productId'].value;
                  this.mobileTakeBackCfg.terminal.nom = form['name'].value;

                  this.mobileTakeBackCfg.terminal.marque = form['manufacturer'].value;
                  if (this.searchValue && this.searchValue.length > 14) {
                    this.mobileTakeBackCfg.terminal.imei = this.searchValue;
                  }
                  this.mobileTakeBackCfg.idDevisPartenaire = data.id;
                  this.mobileTakeBackCfg.dataQuestion = this.dataQuestion;
                  this.buildCritereQualification(this.dataQuestion.criteresReprise);
                  this.mobileTakeBackAmount = data.montantEstimeReprise;
                }
              }
              this.isLoading = false;
            },
            (error: string) => {
              this.error = error.toString().replace('Error: ', '');
              this.isLoading = false;
            },
          );
      }
    });
  }

  public onValid(): void {
    this.addMobileTakeBackProduct();
  }

  public onCancel(): void {
    this.close();
  }

  public createDevis(): void {
    const responses: ICriteresReprise[] = [];
    const form = this.questionForm.controls;
    const user = this.userService.user;
    for (const propertyName in form) {
      this.dataQuestion.acteurCreateur = {
        login: user.login,
      };
      this.dataQuestion.entiteReseauDistributionOrigine = {
        codeEnseigne: user.codeEns,
        codePointVente: user.codePdv,
      };
      if (propertyName.indexOf('response') !== -1) {
        const data = propertyName.split('-');
        const res = {
          reponse: {
            id: this.questionForm.controls[propertyName].value,
          },
          id: data[1],
        };
        responses.push(res);
      }
      this.dataQuestion.criteresReprise = responses;
      this.dataQuestion.imei = this.imeiForm.controls['imei'].value;
    }
  }

  public isValid(): boolean {
    return ((this.questionForm && this.questionForm.valid) || this.success) && !this.error;
  }

  public onScroll = (): void => {
    if (this.popover) {
      this.popover.close();
    }
  };

  public ngOnDestroy(): void {
    this.document.removeEventListener('scroll', this.onScroll, true);
    this.scanListener?.unsubscribe();
  }

  private getQuestions(crt: string): Observable<void> {
    this.mobileTakeBackAmount = undefined;
    return this.oauth2RessourceService
      .produitsPartenairesQuestions(crt)
      .get()
      .pipe(
        catchError(error => {
          throw new Error(`error getQuestions: ${error.message}`);
        }),
      )
      .pipe(
        map((data: PartnerProductsQuestions) => {
          this.questionsList = [];
          if (data.criteresRepriseProduit && data.criteresRepriseProduit.length > 0) {
            data.criteresRepriseProduit.forEach(element => {
              element.reponses.sort((reponseA, reponseB) =>
                parseInt(reponseB.id, 10) > parseInt(reponseA.id, 10) ? -1 : 1,
              );
              this.questionsList.push(element);
            });
            this.initFormQuestion(data);
            this.updateShowQrCode();
          }
        }),
      );
  }

  private updateShowQrCode(): void {
    this.questionsList.forEach(question => {
      if (question.libelle.includes('écran')) {
        question.qrCode = true;
      }
    });
  }

  private buildCritereQualification(data: BasicObject): void {
    if (data && data.length > 0) {
      this.dataQuestion.criteresReprise.forEach(critere => {
        const question = this.questionsList.find(x => x.id === critere.id);
        const response = question.reponses.find(x => x.id === critere.reponse.id);
        const crt: IMobileTakeBackQualificationCriterion = {
          critere: question.libelle,
          valeur: response.libelle === 'Oui',
        } as IMobileTakeBackQualificationCriterion;
        if (!this.mobileTakeBackCfg.criteresQualification) {
          this.mobileTakeBackCfg.criteresQualification = [];
        }
        this.mobileTakeBackCfg.criteresQualification.push(crt);
      });
    }
  }

  private addMobileTakeBackProduct(): void {
    this.isLoading = true;
    const productInstance = Catalog.getInstanceByType('mobile-take-back');
    productInstance.gencode = 'RMBC000000';
    productInstance.setConfiguration(this.mobileTakeBackCfg);
    productInstance.oldPrice = -Number(this.mobileTakeBackAmount);
    productInstance.price = -Number(this.mobileTakeBackAmount);

    this.cartService
      .add(productInstance, 1, true)
      .pipe(
        mergeMap(() => this.cartService.refreshCart()),
        tap(() => this.close()),
        finalize(() => {
          this.isLoading = false;
          this.stepperService.changesCurrentStep.next(null);
        }),
      )
      .subscribe();
  }

  private reset(): void {
    this.mobileTakeBackAmount = undefined;
    this.phone = undefined;
    this.questionsList = [];
    this.inventoryError = undefined;
    this.geolocalisationError = undefined;
    this.error = undefined;
    this.success = false;
  }

  private callDisposalTicketTakeBack(imei: string): void {
    this.success = false;
    this.mobileTakeBackService
      .callCheckDisposalTicket(imei)
      .pipe(
        mergeMap((data: IDisposalTicket) => {
          this.setMobileTakeBackConfig(data);
          this.mobileTakeBackCfg.scanCode = imei;
          return this.mobileTakeBackService.printDisposalTicket(this.mobileTakeBackCfg.lienBonDeCession);
        }),
      )
      .subscribe(
        (content: object) => {
          this.success = true;
          saveAs(content, 'bonDeCession.pdf');
        },
        err => {
          const codeError = err.error ? err.error.error : '';
          this.error = this.mobileTakeBackService.handleErrorDisposalTicket(err.status, codeError);
        },
      );
  }

  private setMobileTakeBackConfig(data: IDisposalTicket): void {
    if (
      !data.idCommandePartenaire ||
      !data.idDevis ||
      !data._links.lienBonDeCession.href ||
      !data.produitPartenaire.marque ||
      !data.produitPartenaire.nom ||
      !data.produitPartenaire.imei ||
      !data.produitPartenaire.id ||
      !data.produitPartenaire.nom ||
      !data.tarif
    ) {
      throw new Error('Erreur technique');
    }
    this.phone = {};
    this.phone.nom = data.produitPartenaire.nom;
    this.phone.marque = data.produitPartenaire.marque;
    this.mobileTakeBackCfg = {} as IMobileTakeBackConfiguration;
    this.mobileTakeBackCfg.idCommandePartenaire = data.idCommandePartenaire;
    this.mobileTakeBackCfg.idDevisPartenaire = data.idDevis;
    this.mobileTakeBackCfg.lienBonDeCession = data._links.lienBonDeCession.href;
    this.mobileTakeBackCfg.terminal = {
      marque: data.produitPartenaire.marque,
      imei: data.produitPartenaire.imei,
      idProduit: data.produitPartenaire.id,
      nom: data.produitPartenaire.nom,
    };
    this.mobileTakeBackAmount = data.tarif;
  }
}
