import {
  ConsulterCatalogueContextualiseAvecPanierBodyRequest,
  ProductsContextRequestProductDto,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { Equipement } from '@model/catalog/products/equipement';
import { Cart } from '@checkout/cart/cart';
import { Product } from '@model/catalog/products/product';
import { TypeParcoursEnums } from '../../constants/promotional-eligibility-input.dto';
import { Plan } from '@model/catalog/products/subscription/plan';
import { Customer } from '@checkout/cart/customer/customer';
import { CustomerCategory } from '@checkout/cart/customer/customer.interface';

export class CatalogInputHelperDto {
  public static buildBodyCtxWithCart(
    customer: Customer,
    cart: Cart,
    payloadProduct?: ProductsContextRequestProductDto,
    currentSchemeId?: string,
  ): ConsulterCatalogueContextualiseAvecPanierBodyRequest {
    return {
      panier: {
        parcours: cart.schemes.map(scheme => {
          const isCurrentScheme = scheme.uniqueId == currentSchemeId;
          const defaultProducts: ProductsContextRequestProductDto[] =
            payloadProduct && isCurrentScheme ? [payloadProduct] : [];
          return {
            type: TypeParcoursEnums[scheme.browseType],
            estCourant: isCurrentScheme,
            idContrat: scheme.contractId ? String(scheme.contractId) : undefined,
            produits: scheme.products.reduce((acc, product) => {
              if (CatalogInputHelperDto.canAddProductToSapicInput(product, scheme.uniqueId, currentSchemeId)) {
                const p: ProductsContextRequestProductDto = {
                  catalogue: CatalogInputHelperDto.getCatalogue(product),
                  gencode: product.gencode,
                };
                acc.push(p);
              }
              return acc;
            }, defaultProducts),
          };
        }),
        client: {
          categorie: customer.category === CustomerCategory.gp ? CustomerCategory.gp : CustomerCategory.pro,
          idPersonne: customer.personId ? customer.personId.toString() : undefined,
        },
      },
    };
  }

  private static getCatalogue(product: Product): ProductsContextRequestProductDto['catalogue'] {
    return product.type_id === 'produit_ca' ? 'PARTENAIRE' : 'BYTEL';
  }

  private static canAddProductToSapicInput(p: Product, schemeUniqueId: string, currentSchemeId: string): boolean {
    return schemeUniqueId !== currentSchemeId || !(p instanceof Plan || p instanceof Equipement);
  }
}
