import { EventEmitter, Injectable } from '@angular/core';
import { MetaDataOutputDTO } from '@checkout/cart/customer/customer.interface';
import { FraudService } from '@checkout/fraud/fraud.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { CartService } from '@services/cart.service';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  public metadataLoaded$: EventEmitter<MetaDataOutputDTO> = new EventEmitter();

  constructor(
    private fraudService: FraudService,
    private oauth2RessourceService: Oauth2RessourceService,
    private cartService: CartService,
  ) {
    this.metadataLoaded$.subscribe(
      (metadata: MetaDataOutputDTO) => (this.fraudService.isFraudSelected = !!metadata?.fraudePotentielle),
    );
  }

  public loadMetadata(): Observable<MetaDataOutputDTO> {
    if (this.cartService.cart.schemes.some(s => !s.isEmpty())) {
      return this.oauth2RessourceService
        .metadonnees(this.cartService.cart.cartId.toString())
        .get()
        .pipe(
          tap((data: MetaDataOutputDTO) => this.metadataLoaded$.emit(data)),
          catchError(e => {
            if (e.status === 404) {
              return of(null);
            }
            return throwError(new Error('Problème lors de la récupération des meta données'));
          }),
        );
    }
    return of(null);
  }
}
