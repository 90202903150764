import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateUniversal } from '@base/class/date-universal.class';
import { FormPatterns } from '@base/class/form-patterns';
import { countries, departments } from './select-options';

export function birthDateMajorValidate(control: UntypedFormControl): {
  dateNaissance: { errors?: string; major?: string };
} {
  const regexp = new RegExp(/[0-9]{2}\/[0-1][0-9]\/[0-9]{4}/); // NOSONAR
  const date: DateUniversal = new DateUniversal(control.value);
  const maxDateForMinor: DateUniversal = new DateUniversal('now').removeYear(16);
  const minDateForValidation: DateUniversal = new DateUniversal('now').removeYear(118);

  if (!regexp.test(control.value) || date.value.toString() === 'Invalid Date') {
    return {
      dateNaissance: {
        errors: 'Date invalide !',
      },
    };
  }

  if (maxDateForMinor.value.getTime() < date.value.getTime()) {
    return {
      dateNaissance: {
        major: "L'âge du client doit être supérieur à 16 ans.",
      },
    };
  }

  if (minDateForValidation.value.getTime() > date.value.getTime()) {
    return {
      dateNaissance: {
        major: 'Date invalide !',
      },
    };
  }

  return null;
}

export function birthDateEmancipeValidate(birthDate: string): string | null {
  const date: DateUniversal = new DateUniversal(birthDate);
  const maxDateForMinor: DateUniversal = new DateUniversal('now').removeYear(16);
  const minDateForAdult: DateUniversal = new DateUniversal('now').removeYear(18);

  if (
    maxDateForMinor.value.getTime() >= date.value.getTime() &&
    minDateForAdult.value.getTime() < date.value.getTime()
  ) {
    return 'Attention, entre 16 et 18 ans, le client doit être émancipé.';
  }

  return null;
}

export function isEmail(email: string): boolean {
  return FormPatterns.emailBasic.test(email);
}

export function isPhoneMobileNumber(phone): boolean {
  return FormPatterns.regExpPhoneMobile.test(phone);
}

export function emailsAndPhonesCheck(): RegExp {
  return new RegExp('(' + FormPatterns.regExpPhoneMobile.source + ')|(' + FormPatterns.emailBasic.source + ')');
}

export function phoneMobileNumberCheck(control: UntypedFormControl): { value: string } {
  const regexpPhone = new RegExp(/^0[6-7]\d{8}$/);

  if (control.value && !regexpPhone.test(control.value)) {
    return {
      value: 'Veuillez saisir un numéro de téléphone mobile !',
    };
  }

  return null;
}

export function phoneNumberCheck(control: UntypedFormControl): { value: string } {
  const regexpPhone = new RegExp(/^0\d{9}$/);

  if (!control.value || (control.value && !regexpPhone.test(control.value))) {
    return {
      value: 'Veuillez saisir un numéro de téléphone correct !',
    };
  }

  return null;
}

export function identityNumberCheck(form: UntypedFormGroup): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    // Si le champ numéro de pièce d'identité est absent (!identityNumberMandatory) on laisse passer le formulaire
    if (!form.controls.documentIdentite?.value || (form.controls.documentIdentite.value && !control.value)) {
      return null;
    }
    switch (form.controls.documentIdentite.value?.name) {
      case null:
        return null;
      case "Carte d'identité":
      case "Carte d'identité PRO":
        if (control.value.length === 12 || control.value.length === 9) {
          return null;
        }
        break;
      case 'Passeport':
      case 'Passeport PRO':
        if (control.value.length === 9) {
          return null;
        }
        break;
      case 'Carte de séjour':
      case 'Carte de séjour PRO':
        if (control.value.length === 10) {
          return null;
        }
        break;
      case 'Permis de conduire':
        if (control.value.length === 9 || (control.value.length >= 12 && control.value.length <= 19)) {
          return null;
        }
        break;
    }
    return { identityNumberCheck: true };
  };
}

export function emailCheck(control: UntypedFormControl): { value: string } {
  const regexpEmail: RegExp = FormPatterns.emailBasic;

  if (control.value && !regexpEmail.test(control.value)) {
    return { value: "L'email est incorrect !" };
  }

  return null;
}

export function bicCheck(control: UntypedFormControl): { value: string } {
  const bic8Regexp = /^[a-zA-Z]{6}[0-9a-zA-Z]{2}$/;
  const bic11Regexp = /^[a-zA-Z]{6}[0-9a-zA-Z]{5}$/;
  if (control.value && !control.value.match(bic8Regexp) && !control.value.match(bic11Regexp)) {
    return {
      value: 'Le BIC est invalide !',
    };
  }
  return null;
}

export function alphaNumCheck(control: UntypedFormControl): { alphanum: string } {
  if (control.value && control.value.match(/[^0-9a-zA-Z]/)) {
    return {
      alphanum: 'Le champ ne doit contenir que des lettres ou des chiffres !',
    };
  }
  return null;
}

export function validatePhoneAndEmail(group: UntypedFormGroup): {
  telephone?: { errors: string };
  email?: { errors: string };
} {
  const telephone = group.controls['telephone'];
  const regexpPhone = new RegExp(/^0\d{9}$/);
  const email = group.controls['email'];
  const regexpEmail: RegExp = FormPatterns.emailBasic;

  if (telephone && !telephone.value && email && !email.value) {
    return {
      telephone: {
        errors: "L'email ou le téléphone est obligatoire !",
      },
      email: {
        errors: "L'email ou le téléphone est obligatoire !",
      },
    };
  }
  if (telephone && telephone.value && !regexpPhone.test(telephone.value)) {
    return {
      telephone: {
        errors: "Le telephone n'est pas correct !",
      },
    };
  }
  if (email && email.value && !regexpEmail.test(email.value)) {
    return {
      email: {
        errors: "L'email n'est pas correct !",
      },
    };
  }
  return null;
}

export function validateDepartment(control: AbstractControl): ValidationErrors {
  return departments.find(department => department.code === control?.value?.split(' ')[0])
    ? null
    : {
        validate: {
          valid: false,
        },
      };
}

export function validateCountry(control: AbstractControl): ValidationErrors {
  return countries.find(country => country.code === control?.value?.split(' ')[0])
    ? null
    : {
        validate: {
          valid: false,
        },
      };
}

export function validateName(control: UntypedFormControl): { nom: { errors: string }; prenom: { errors: string } } {
  const regexpName = new RegExp(/^[^!"#$%&+/;<=>?`^|~¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶•¸¹º»¼½¾¿ÆÐÑÕ×ØÝÞßæ÷øþ*]{2,32}$/);
  if (!regexpName.test(control.value)) {
    return {
      nom: {
        errors:
          'Le nom doit contenir de 2 à 32 caractères alphabétiques (accents, tirets, espace et apostrophes autorisés)',
      },
      prenom: {
        errors:
          'Le prénom doit contenir de 2 à 32 caractères alphabétiques (accents, tirets, espace et apostrophes autorisés)',
      },
    };
  }
  return null;
}
