import { IPandoraDocumentSerialize } from '@interfaces/IPandoraDocumentSerialize';
import { PandoraDocument } from '@model/PandoraDocument.abstract';
import { CheckoutNs } from '@interfaces/checkout.interface';
import { ContractInterface } from '../contract.interface';
import { IScreening } from '@interfaces/screening.interface';
import { CustomerCategory, CustomerType } from '@checkout/cart/customer/customer.interface';

export interface BrowseConfigSerializedInterface {
  categorieProspect?: CustomerCategory;
  baseRoute?: string[];
  phoneRoute?: string[];
  phone?: string[];
  nomProspect?: string;
  prenomProspect?: string;
  dateNaissanceProspect?: string;
  lieuNaissanceProspect?: string;
  raisonSocialeProspect?: string;
  siren?: string;
  dateCreationEntrepriseProspect?: string;
  emailContact?: string;
  telephoneContact?: string;
  motifSouscriptionCle?: string;
  activationAutomatique?: boolean;
  typeParcours?: string;
  typeAction?: string;
  idPanier?: number;
  referenceExterne?: string;
  idParcours?: number;
  urlCallBack?: string;
  urlAnnulation?: string;
  typeVisiteur?: CustomerType;
  matricule?: string;
  referenceAppareil?: string;
  debug?: string;
  idContrat?: string;
  idPU?: number;
  ligneMarche?: string;
  telephonePrepaye?: string;
  numeroRue?: string;
  adresseNum?: string;
  rue?: string;
  adresseRue?: string;
  complementAdresse?: string;
  codePostal?: string;
  adresseCP?: string;
  ville?: string;
  adresseVille?: string;
  bic?: string;
  iban?: string;
  civilite?: string;
  typeDocumentIdentite?: IPandoraDocumentSerialize;
  documentIdentiteNumero?: string;
  addressDocument?: PandoraDocument;
  idCommande?: string;
  comptePayeur?: CheckoutNs.IPayment;
  idPanierCommande?: string;
  emailAccesPartenaire?: string;
  parcoursOffreAdditionnelle?: string;
  portabilite?: boolean;
  dateEffet?: string;
  partnerCustomerId?: string;
  vip?: boolean;
  trackerId?: string;
  browseConfig?: number;
  idDevice?: string;
  LEAD_ID?: string;
}

export class BrowseConfig {
  public contracts?: ContractInterface[];
  public baseRoute?: string[];
  public phoneRoute?: string[];
  public category?: CustomerCategory;
  public firstname?: string;
  public lastname?: string;
  public dob?: string;
  public civility?: string;
  public birthplace?: string;
  public companyName?: string;
  public companyRegistrationNumber?: string;
  public companyCreationDate?: string;
  public email?: string;
  public phone?: string;
  public cartId?: number;
  public cartOrderId: string;
  public orcomId?: string;

  public personId?: number;
  public browseType?: string;
  public browseActionType?: string;
  public callBackUrl?: string;
  public cancelCallBackUrl?: string;
  public visitorType?: CustomerType;
  public deviceId?: string;
  public debug?: boolean;
  public cartExternalAmount?: string;
  public contractId?: number;
  public subscriptionReason?: string;
  public autoActivation?: boolean;
  public ignoreElig?: boolean;
  public lineSector?: string;
  public prepaidPhone?: string;
  public streetNumber?: string;
  public postcode: string;
  public street?: string;
  public street2?: string;
  public city?: string;
  public bic?: string;
  public iban?: string;
  public identityDocumentType?: IPandoraDocumentSerialize;
  public identityDocumentNumber?: string;
  public addressDocument?: PandoraDocument;
  public paymentAccount?: CheckoutNs.IPayment;
  public company?: IScreening;
  public emailAccesPartenaire?: string;
  public parcoursOffreAdditionnelle?: string;
  public portability?: boolean;
  public dateEffet?: string;
  public trackerId: string;
  public partnerCustomerId?: string;
  public vip?: boolean;
  public idProspect?: string;

  public unserialize(data: BrowseConfigSerializedInterface): void {
    this.category = data.categorieProspect || null;
    this.phoneRoute = data.phoneRoute || null;
    this.baseRoute = data.baseRoute || null;
    this.lastname = data.nomProspect || null;
    this.firstname = data.prenomProspect || null;
    this.dob = data.dateNaissanceProspect || null;
    this.birthplace = data.lieuNaissanceProspect || null;
    this.companyName = data.raisonSocialeProspect || null;
    this.companyRegistrationNumber = data.siren || null;
    this.companyCreationDate = data.dateCreationEntrepriseProspect || null;
    this.email = data.emailContact || null;
    this.phone = data.telephoneContact || null;
    this.civility = data.civilite || null;
    this.streetNumber = data.numeroRue || data.adresseNum || null;
    this.postcode = data.codePostal || data.adresseCP || null;
    this.street = data.rue || data.adresseRue || null;
    this.street2 = data.complementAdresse || null;
    this.city = data.ville || data.adresseVille || null;
    this.bic = data.bic || null;
    this.iban = data.iban || null;
    this.identityDocumentType = data.typeDocumentIdentite || null;
    this.identityDocumentNumber = data.documentIdentiteNumero || null;
    this.cartOrderId = data.idPanierCommande || null;
    this.cartId = data.idPanier || null;
    this.orcomId = data.idCommande || null;
    this.idProspect = data.LEAD_ID || null;
    this.browseType = data.typeParcours || null;
    this.visitorType = data.typeVisiteur || null;
    this.deviceId = data.referenceAppareil || null;
    this.contractId = data.idContrat ? Number(data.idContrat) : null;
    this.personId = data.idPU || null;
    this.paymentAccount = data.comptePayeur || null;
    this.lineSector = data.ligneMarche || null;
    this.prepaidPhone = data.telephonePrepaye || null;
    this.subscriptionReason = data.motifSouscriptionCle || null;
    this.autoActivation = data.activationAutomatique || null;
    let typeAction: string = null;
    if (data.typeAction) {
      typeAction = data.typeAction === 'REPRISE' ? 'MODIF' : data.typeAction;
    }
    this.browseActionType = typeAction;
    this.callBackUrl = `${
      data.urlCallBack && data.urlCallBack.indexOf('http') === -1 ? 'https://' : ''
    }${decodeURIComponent(data.urlCallBack)}`;
    this.cancelCallBackUrl = data.urlAnnulation || null;
    this.debug = data.debug && data.debug.toString() === '1';
    this.emailAccesPartenaire = data.emailAccesPartenaire;
    this.parcoursOffreAdditionnelle = data.parcoursOffreAdditionnelle;
    this.portability = data.portabilite;
    this.dateEffet = data.dateEffet;
    this.partnerCustomerId = data.partnerCustomerId;
    this.vip = data.vip;
    this.trackerId = data.trackerId;
    this.deviceId = data.idDevice || this.deviceId;
    this.cartOrderId = data.idPanierCommande || this.cartOrderId;
    if (!this.category && data.telephonePrepaye) {
      this.category = data.siren ? CustomerCategory.pro : CustomerCategory.gp;
      if (data.siren) {
        this.companyName = data.raisonSocialeProspect;
      } else {
        this.firstname = data.prenomProspect;
        this.lastname = data.nomProspect;
      }
    }
  }

  protected static getInstance(): BrowseConfig {
    return new BrowseConfig();
  }

  public serialize(): BrowseConfigSerializedInterface {
    const serializedObj: BrowseConfigSerializedInterface = {};
    Object.assign(serializedObj, {
      categorieProspect: this.category || null,
      nomProspect: this.lastname || null,
      motifSouscriptionCle: this.subscriptionReason || null,
      activationAutomatique: this.autoActivation || null,
      prenomProspect: this.firstname || null,
      dateNaissanceProspect: this.dob || null,
      lieuNaissanceProspect: this.birthplace || null,
      raisonSocialeProspect: this.companyName || null,
      siren: this.companyRegistrationNumber || null,
      dateCreationEntrepriseProspect: this.companyCreationDate || null,
      emailContact: this.email || null,
      telephoneContact: this.phone || null,
      idPanierCommande: this.cartOrderId || null,
      typeParcours: this.browseType || null,
      typeAction: this.browseActionType || null,
      urlCallBack: this.callBackUrl || null,
      urlAnnulation: this.cancelCallBackUrl || null,
      typeVisiteur: this.visitorType || null,
      referenceAppareil: this.deviceId || null,
      debug: this.debug ? '1' : '0',
      idContrat: this.contractId || null,
      idPU: this.personId || null,
      ligneMarche: this.lineSector || null,
      telephonePrepaye: this.prepaidPhone || null,
      numeroRue: this.streetNumber || null,
      codePostal: this.postcode || null,
      rue: this.street || null,
      complementAdresse: this.street2 || null,
      ville: this.city || null,
      civilite: this.civility || null,
      iban: this.iban || null,
      typeDocumentIdentite: this.identityDocumentType || null,
      documentIdentiteNumero: this.identityDocumentNumber || null,
      comptePayeur: this.paymentAccount || null,
      emailAccesPartenaire: this.emailAccesPartenaire || null,
      parcoursOffreAdditionnelle: this.parcoursOffreAdditionnelle || null,
      portabilite: this.portability || null,
      dateEffet: this.dateEffet || null,
      partnerCustomerId: this.partnerCustomerId || null,
      vip: this.vip,
      trackerId: this.trackerId || null,
      LEAD_ID: this.idProspect || null,
    });
    serializedObj.idDevice = serializedObj.referenceAppareil || serializedObj.idDevice;
    return serializedObj;
  }
}
