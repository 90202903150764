import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '@services/alert.service';

import { odrContactFormValidator } from '../odr-contact-form-control.validator';
import { SUCCESS_MESSAGE } from '../odr.config';
import { Odr } from '../odr.model';
import { OdrService } from '../odr.service';
import { formatPhoneNumber } from '../odr.utils';
import { Customer } from '@checkout/cart/customer/customer';
import { CustomerService } from '@checkout/cart/customer/customer.service';

@Component({
  selector: 'rcbt-odr-quick-view',
  templateUrl: './odr-quick-view.component.html',
  styleUrls: ['./odr-quick-view.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class OdrQuickViewComponent implements OnDestroy, OnInit {
  @Input() odr: Odr;

  contact: UntypedFormControl;

  private initContact: string;
  private subscription = new Subscription();
  private customerContextService: Customer;

  constructor(
    private alertService: AlertService,
    private odrService: OdrService,
    private customerService: CustomerService,
  ) {}

  public ngOnInit(): void {
    this.customerContextService = this.customerService.customer;

    this.initContact = this.customerContextService.phoneNumber
      ? this.customerContextService.phoneNumber
      : this.customerContextService.email;

    this.contact = new UntypedFormControl(this.initContact, {
      validators: [Validators.required, odrContactFormValidator],
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSubmit(): void {
    if (!this.contact.value) {
      return;
    }
    const value = formatPhoneNumber(this.contact.value);

    this.subscription.add(
      this.odrService
        .send(this.odr, value)
        .pipe(
          catchError(error => {
            this.alertService.successEmitter.next(error.msgRetour);
            return EMPTY;
          }),
        )
        .subscribe(() => {
          this.alertService.successEmitter.next(SUCCESS_MESSAGE);
        }),
    );
    this.resetContact();
  }

  private resetContact(): void {
    this.contact.reset();
  }
}
