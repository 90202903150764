import { Component, OnInit, Input } from '@angular/core';
import { ComparatorService } from '@services/comparator.service';
import { JsonProduct } from '@model/catalog/products/interface/context';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'rcbt-comparator-toggle',
  templateUrl: './comparator-toggle.component.html',
  imports: [FormsModule],
})
export class ComparatorToggleComponent implements OnInit {
  @Input()
  plansForComparator: JsonProduct[];

  public loading = false;

  constructor(public readonly comparatorService: ComparatorService) {}

  public ngOnInit(): void {
    this.comparatorService.processPlansForCompare(this.plansForComparator);
  }

  public updateSelection(): void {
    this.comparatorService.$comparatorActive.emit();
    if (!this.comparatorService.active) {
      this.comparatorService.reset();
    }
  }
}
