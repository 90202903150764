import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { AppErrorCodes } from '@interfaces/cart.interface';

export class ContextError extends Error {
  constructor(
    message: string,
    public status: number = Oauth2RessourceService.httpBadRequest,
    public code: string = AppErrorCodes.error,
    public data: {} = {},
  ) {
    super(message);

    // Saving class name in the property of our custom error as a shortcut.
    this.name = this.constructor.name;
  }
}
