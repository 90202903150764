<div class="is-tri">
  <form class="scan-bar" #scanForm="ngForm" (ngSubmit)="onSubmit(scanForm.value)">

    <div class="row-input field">
      <div class="control has-dynamic-placeholder">
        <input scan role="textbox" type="tel" #scancodeRef="ngModel"
          data-cy="scan-code-input"
          autocomplete="off"
          class="input is-default"
          name="scanCode"
          placeholder="Code produit"
          minlength="13"
          maxlength="17"
          pattern="^(?:[0-9]{13}$|[0-9]{15})$"
          [ngModel]="codeInput"
          (ngModelChange)="scanChange($event)"
          (click)="loadScan()"
          required ngModel>
        <label>Code produit</label>
      </div>
      <button [disabled]="!scanForm.form.valid || scanLoading" type="submit" class="button is-info" data-cy="scan-code-ok">OK</button>
    </div>

    @if ((scancodeRef.touched && scancodeRef.errors && scancodeRef.errors.pattern) || message.length > 0) {
      <div
        class="row-message alert has-body is-error">
        <span class="icon is-medium">
          <i class="tri-exclamation-circle" aria-hidden='true'></i>
        </span>
        <div class="body" data-cy="scan-code-error-message">
          @if (message) {
            <p class="title is-level-3 is-loaded">{{ message }}</p>
          }
          @if (scancodeRef?.errors?.pattern) {
            <p class="title is-level-3 is-loaded">Saisie incorrecte</p>
          }
        </div>
      </div>
    }

  </form>
</div>
<i rcbtQview><i><!-- Necessary to add the quickview below -->
