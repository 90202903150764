import { Injectable } from '@angular/core';
import { EnumFaiSteps } from '../../../shared/prisme/prisme-logger.service';
import { Step } from '@components/stepper/step.abstract';

@Injectable({
  providedIn: 'root',
})
export class HousingStepService extends Step {
  public code = 'housing';
  public path = '/fai/housing';
  public label = "Sélection/ajout de l'occupant";
  public stepPrisme: string = EnumFaiSteps.stephousing;
  public stepReturnPrisme: string = EnumFaiSteps.stephousingreturn;
}
