import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpResponse as Response } from '@angular/common/http';
import { StockType } from '@interfaces/types';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { BasicObject } from '@base/base.interfaces';

export enum TypesStock {
  bytel = '0001',
  ca = 'CA01',
}
@Injectable({
  providedIn: 'root',
})
export class StockService {
  public static productNotInStock = 'Produit hors stock';

  /**
   * service constructor
   * @return {[type]}             [description]
   */
  constructor(private oauth2RessourceService: Oauth2RessourceService) {}

  /**
   * Returns an object with two proprties quantity and gencode
   *
   * @param  {string} id imei or gencode
   * @return {Observable<StockType>}
   */
  public getStockItemById(id: string, type?: string): Observable<StockType[]> {
    let productType: string = type;
    if (!type) {
      productType = id.length === 15 ? 'imei' : 'gencode';
    }
    return (
      this.oauth2RessourceService
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .setParams({ key_type: productType })
        .setLocalService()
        .useSalesApi()
        .ventes()
        .stocks(id)
        .get()
        .pipe(catchError(this.handleStockError))
    );
  }

  /**
   * Returns an array of object each one contains two proprties quantity and gencode
   *
   * @param  {string} keys imei or gencode (multiple same type items possible separated by ",")
   * @return {Observable<StockType[]>}
   */
  public getFilteredStockItems(keys: string[], typeKeys?: string): Observable<StockType[]> {
    let type: string = keys[0].length === 15 ? 'imei' : 'gencode';
    if (typeKeys) {
      type = typeKeys;
    }
    return (
      this.oauth2RessourceService
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .setParams({ key_type: type, keys_filter: keys.join(',') })
        .setLocalService()
        .ventes()
        .useSalesApi()
        .stocks()
        .get()
        .pipe(catchError(this.handleError))
    );
  }

  /**
   *
   * @param key
   * @param keyType
   * @param cartId
   * @param qty
   * @returns {Observable<Response>}
   */
  public reserve(key: string, keyType: string, cartId: string, qty: number = 1): Observable<Response<BasicObject>> {
    return this.call(key, keyType, {
      operationCode: 'ReserverProduit',
      quantity: qty,
      cartId: cartId,
    });
  }

  /**
   *
   * @param key
   * @param keyType
   * @param cartId
   * @param qty
   * @returns {Observable<Response>}
   */
  public cancel(key: string, keyType: string, cartId: string, qty: number = 1): Observable<Response<BasicObject>> {
    // NOSONAR
    return this.call(key, keyType, {
      operationCode: 'AnnulerReservationProduit',
      quantity: qty,
      cartId: cartId,
    });
  }

  public isInStock(data: StockType[], onlyBytelCatalog: boolean): boolean {
    // 03/10/2019 Après la mise en production du 02/10/2019 on nous a remonté qu'en pratique
    // les CA peuvent parfois scanner des accessoires exemple 3526357052709 donc rollaback
    // cependant une incoherance reste c'est que les CA n'ont pas acces à l'etape accessoire ni sur le mure ni sur le file d'ariane
    // à faire ulterieurement.
    // if (Number(codeEns) === USER_ENS_TYPE.CA && (productInstance instanceof Accessory)) {
    //     this.message = 'Type d\'element scanné non autorisé sur ce type boutique!';
    //     return;
    // }
    if (onlyBytelCatalog) {
      const exist = data.find(elt => elt.typeStock === TypesStock.bytel && elt.quantity && elt.quantity > 0);
      if (!exist) {
        return false;
      }
    }

    for (const elt of data) {
      if (elt && elt.quantity && elt.quantity > 0) {
        return true;
      }
    }

    return false;
  }

  private call(key: string, keyType: string, body: {}): Observable<Response<BasicObject>> {
    return (
      this.oauth2RessourceService
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .setParams({ key_type: keyType })
        .setLocalService()
        .ventes()
        .useSalesApi()
        .stocks(key)
        .put(body)
        .pipe(catchError(this.handleError))
    );
  }

  /**
   * handle the request error
   * @param  {Response} error [description]
   * @return {[type]}         [description]
   */
  private handleError(error: Response<BasicObject>): Observable<never> {
    return observableThrowError('Server error!');
  }

  private handleStockError(error: Response<BasicObject>): Observable<never> {
    return observableThrowError('erreur lors de la vérification du produit');
  }
}
