import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '@services/cart.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { AlertService } from '@services/alert.service';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { Observable, Subscription, throwError } from 'rxjs';
import { ProductsService } from '@services/products.service';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { IPostCatalogResponse } from '../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { ProductSerialized } from '@model/catalog/products/interface/configurable';
import { CrvService } from '@services/crv.service';
import { NgClass, AsyncPipe } from '@angular/common';
import { PriceComponent } from '@base/price/price.component';

export interface ICrvState {
  code: string;
  name: string;
  price: number;
  nb: number;
}

const LOADING_ACTIONS = {
  crvList: '[CrvComponent] loadCrvList',
};

@Component({
  selector: 'rcbt-category-crv',
  templateUrl: './crv.component.html',
  styleUrls: ['../category.component.scss', './crv.component.scss'],
  standalone: true,
  imports: [NgClass, PriceComponent, AsyncPipe],
})
export class CrvComponent implements OnInit, OnDestroy {
  @Input()
  public showHeader = false;

  public crvList: ICrvState[] = [];
  public subscriptions: Subscription = new Subscription();
  public crvProducts: IPostCatalogResponse[];
  public loadingCrvListError = false;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected cartService: CartService,
    protected alertService: AlertService,
    protected productsService: ProductsService,
    private globalLoaderService: GlobalLoaderService,
    private checkoutStepperService: CheckoutStepperService,
    private crvService: CrvService,
  ) {
    //    super(route);
  }

  public ngOnInit(): void {
    this.initCrvList();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addCrv(gencode: string): void {
    const obs = this.cartService.addProduct({ gencode }, true);
    this.updateCart(obs);
  }

  public removeCrv(gencode: string): void {
    const productUniqueId = this.cartService.getCurrentScheme().getProductByGencode(gencode);
    if (!productUniqueId) {
      return;
    }
    const obs = this.cartService.remove(productUniqueId, this.cartService.getCurrentScheme().uniqueId);
    this.updateCart(obs);
  }

  public reload(): void {
    this.initCrvList();
  }

  private updateCart(obs: Observable<ProductSerialized | boolean>): void {
    const msg = "Un incident technique est survenu, impossible d'ajouter/supprimer la CRV au parcours";
    this.subscriptions.add(
      this.globalLoaderService
        .showLoaderUntilFinalize(
          obs.pipe(
            mergeMap(() => this.cartService.refreshCart()),
            tap(
              () => {
                this.setCrvList();
                this.checkoutStepperService.redirectIfEmptyScheme(this.cartService.getCurrentScheme().isEmpty());
              },
              err => {
                this.alertService.errorEmitter.next(err.error_description || msg);
              },
            ),
          ),
          '[CrvComponent] updateCart',
        )
        .subscribe(),
    );
  }

  private setCrvList(init: boolean = false): void {
    const productsScheme = this.cartService.getCurrentScheme().products;
    if (init) {
      this.crvList = [];
      this.crvProducts.forEach(crvProduct => {
        this.crvList.push({
          code: crvProduct.gencode,
          name: crvProduct?.nom,
          price: crvProduct?.prix?.final,
          nb: productsScheme.filter(p => p.gencode === crvProduct.gencode).length,
        });
      });
    } else {
      this.crvList.forEach(s => {
        s.nb = productsScheme.filter(p => p.gencode === s.code).length;
      });
    }
  }

  private initCrvList(): void {
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.crvService.loadCrvList().pipe(
          tap((result: IPostCatalogResponse[]) => {
            this.crvProducts = result;
            this.setCrvList(true);
            this.loadingCrvListError = false;
          }),
          catchError(error => {
            this.loadingCrvListError = true;
            return throwError(error);
          }),
        ),
        LOADING_ACTIONS.crvList,
      )
      .subscribe();
  }
}
