import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Directive({ standalone: true, selector: '[rcbtAsterisk]' })
export class AsteriskDirective implements OnInit {
  @Input() formcontrol: UntypedFormControl | AbstractControl;

  constructor(protected el: ElementRef) {}

  public ngOnInit(): void {
    const validator = this.formcontrol.validator({} as AbstractControl);
    if (validator?.required) {
      const asterisk = document.createElement('span');
      asterisk.innerHTML = ' *';
      asterisk.className = 'text-danger has-text-danger';
      this.el.nativeElement.appendChild(asterisk);
    }
  }
}
