import { DocumentsScan, PandoraDocument } from './PandoraDocument.abstract';
import { DocTypeBTCode } from '../constants/IDocumentCode';
import { NumberObject } from '@base/base.interfaces';

export class PandoraDocumentFactory {
  public static fromType(type: DocTypeBTCode): DocumentsScan {
    switch (type) {
      case DocTypeBTCode.cniSignataireRecto:
        return new Id().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.cniSignataireVerso:
        return new Id().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.cniGerantRecto:
        return new IdPro().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.cniGerantVerso:
        return new IdPro().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.passeportGerant:
        return new PassportPro().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.passeportSignataire:
        return new Passport().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.csSignataireRecto:
        return new TitleOfStay().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.csSignataireVerso:
        return new TitleOfStay().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.csGerantRecto:
        return new TitleOfStayPro().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.csGerantVerso:
        return new TitleOfStayPro().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.attestationAssurance:
        return new InsuranceCertificate().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.avisImposition:
        return new TaxNotice().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.factureEdfGdf:
        return new EDFInvoice().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.factureTelephone:
        return new PhoneIvoice().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.pouvoir:
        return new PowerLetter().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.quittanceLoyer:
        return new Rent().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.carteGrise:
        return new GrayCard().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.carteRefugieRecto:
        return new RefugeeCard().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.carteRefugieVerso:
        return new RefugeeCard().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.chequeAnnule:
        return new CheckCanceled().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.permisConduire:
        return new DriverLicence().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.rib:
        return new RIB().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.autre:
        return new OtherDoc().documentsScan.find((doc: DocumentsScan) => doc.docTypeBT === type);
      case DocTypeBTCode.carteMilitaireRecto:
      case DocTypeBTCode.carteMilitaireVerso:
      default:
        throw new Error('Unknown DocType');
    }
  }
}

export class CheckCanceled extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(DOC_TYPE_PANDORA.chequeAnnule, DocTypeBTCode.chequeAnnule, 'CHEQUE_ANNULE', 'Chèque annulé'),
    ];
    this.name = 'Chèque annulé';
  }
}
export class Id extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = true;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.cniSignataireRecto,
        DocTypeBTCode.cniSignataireRecto,
        'CNI - Signataire - RECTO',
        "Carte d'identité recto",
      ),
      new DocumentsScan(
        DOC_TYPE_PANDORA.cniSignataireVerso,
        DocTypeBTCode.cniSignataireVerso,
        'CNI - Signataire - VERSO',
        "Carte d'identité verso",
      ),
    ];
    this.name = "Carte d'identité";
  }
}
export class IdPro extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = true;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.cniGerantRecto,
        DocTypeBTCode.cniGerantRecto,
        'CNI - Gérant - RECTO',
        "Carte d'identité verso PRO",
      ),
      new DocumentsScan(
        DOC_TYPE_PANDORA.cniGerantVerso,
        DocTypeBTCode.cniGerantVerso,
        'CNI - Gérant - VERSO',
        "Carte d'identité verso PRO",
      ),
    ];
    this.name = "Carte d'identité PRO";
  }
}
export class GrayCard extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(DOC_TYPE_PANDORA.carteGrise, DocTypeBTCode.carteGrise, 'Carte grise', 'Carte grise'),
    ];
    this.name = 'Carte grise';
  }
}
export class Rent extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(DOC_TYPE_PANDORA.quittanceLoyer, DocTypeBTCode.quittanceLoyer, 'Quittance loyer', 'Loyer'),
    ];
    this.name = 'Loyer';
  }
}
export class PassportPro extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.passeportGerant,
        DocTypeBTCode.passeportGerant,
        'Passeport Gérant',
        'Passeport PRO',
      ),
    ];
    this.name = 'Passeport PRO';
  }
}
export class PowerLetter extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(DOC_TYPE_PANDORA.pouvoir, DocTypeBTCode.pouvoir, 'Lettre de pouvoir', 'Lettre de pouvoir'),
    ];
    this.name = 'Lettre de pouvoir';
  }
}

export class Passport extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.passeportSignataire,
        DocTypeBTCode.passeportSignataire,
        'Passeport Signataire',
        'Passeport',
      ),
    ];
    this.name = 'Passeport';
  }
}
export class TitleOfStay extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = true;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.csSignataireRecto,
        DocTypeBTCode.csSignataireRecto,
        'Carte de résident Signataire - RECTO',
        'Carte de séjour recto',
      ),
      new DocumentsScan(
        DOC_TYPE_PANDORA.csSignataireVerso,
        DocTypeBTCode.csSignataireVerso,
        'Carte de résident Signataire - VERSO',
        'Carte de séjour verso',
      ),
    ];
    this.name = 'Carte de séjour';
  }
}
export class TitleOfStayPro extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = true;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.csGerantVerso,
        DocTypeBTCode.csGerantVerso,
        'Carte de résidente Gérant - VERSO',
        'Carte de séjour verso PRO',
      ),
      new DocumentsScan(
        DOC_TYPE_PANDORA.csGerantRecto,
        DocTypeBTCode.csGerantRecto,
        'Carte de résidente Gérant - RECTO',
        'Carte de séjour recto PRO',
      ),
    ];
    this.name = 'Carte de séjour PRO';
  }
}
export class RefugeeCard extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = true;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.carteRefugieVerso,
        DocTypeBTCode.carteRefugieVerso,
        'Carte réfugié - VERSO',
        'Carte réfugié verso',
      ),
      new DocumentsScan(
        DOC_TYPE_PANDORA.carteRefugieRecto,
        DocTypeBTCode.carteRefugieRecto,
        'Carte réfugié - RECTO',
        'Carte réfugié recto',
      ),
    ];
    this.name = 'Carte réfugié';
  }
}
export class EDFInvoice extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(DOC_TYPE_PANDORA.factureEdfGdf, DocTypeBTCode.factureEdfGdf, 'FACTURE_EDF_GDF', 'Facture EDF'),
    ];
    this.name = 'Facture EDF';
  }
}
export class PhoneIvoice extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.factureTelephone,
        DocTypeBTCode.factureTelephone,
        'Facture téléphone',
        'Facture téléphone',
      ),
    ];
    this.name = 'Facture téléphone';
  }
}
export class TaxNotice extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.avisImposition,
        DocTypeBTCode.avisImposition,
        "Avis d'imposition",
        "Avis d'imposition",
      ),
    ];
    this.name = "Avis d'imposition";
  }
}
export class InsuranceCertificate extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.attestationAssurance,
        DocTypeBTCode.attestationAssurance,
        "Attestation d'assurance",
        "Attestation d'assurance",
      ),
    ];
    this.name = "Attestation d'assurance";
  }
}
export class RIB extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [new DocumentsScan(DOC_TYPE_PANDORA.rib, DocTypeBTCode.rib, 'RIB', 'RIB')];
    this.name = 'RIB';
  }
}
export class DriverLicence extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [
      new DocumentsScan(
        DOC_TYPE_PANDORA.permisConduire,
        DocTypeBTCode.permisConduire,
        'Permis de conduire',
        'Permis de conduire',
      ),
    ];
    this.name = 'Permis de conduire';
  }
}
export class OtherDoc extends PandoraDocument {
  constructor() {
    super();
    this.bothSides = false;
    this.documentsScan = [new DocumentsScan(DOC_TYPE_PANDORA.autre, DocTypeBTCode.autre, 'AUTRE', 'Autre')];
    this.name = 'Autre';
  }
}

export const DOC_TYPE_PANDORA: NumberObject = {
  cniGerantRecto: 1,
  cniSignataireRecto: 1,
  cniGerantVerso: 2,
  cniSignataireVerso: 2,
  passeportGerant: 3,
  passeportSignataire: 3,
  csGerantRecto: 4,
  csSignataireRecto: 4,
  csGerantVerso: 5,
  csSignataireVerso: 5,
  attestationAssurance: 6,
  avisImposition: 6,
  factureEdfGdf: 6,
  factureTelephone: 6,
  pouvoir: 6,
  quittanceLoyer: 6,
  carteGrise: 7,
  carteMilitaireRecto: 7,
  carteMilitaireVerso: 7,
  carteRefugieRecto: 7,
  carteRefugieVerso: 7,
  chequeAnnule: 7,
  permisConduire: 7,
  rib: 7,
  autre: 7,
};
