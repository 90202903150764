import { Injectable } from '@angular/core';
import { IError } from '@interfaces/error.interface';

@Injectable()
export class ErrorService {
  public errors: string[] = [];

  public formatErrorMessage(error: IError | string): string {
    if (typeof error === 'string') {
      return error;
    }

    const codeError = error.error;

    if (codeError === 'BANQUE_INCONNUE') {
      return 'Absent de référentiel bancaire';
    }

    return 'une erreur technique est survenue.';
  }
}
