import { Injectable } from '@angular/core';
import { CartService } from '@services/cart.service';
import { Step } from '@components/stepper/step.abstract';
import { MobileTakeBack } from '@model/catalog/products/mobileTakeBack';
import { Phone } from '@model/catalog/products/equipement/complex/phone';

@Injectable({
  providedIn: 'root',
})
export class MtbStepService extends Step {
  public code = 'mtb';
  public path = '/panier/reprise-mobile';
  public label = 'Reprise Mobile';

  constructor(private cartService: CartService) {
    super();
  }

  get isAllowed(): boolean {
    const currentScheme = this.cartService.getCurrentScheme(false);
    return currentScheme && !!currentScheme.getProductByType(MobileTakeBack) && !currentScheme.getProductByType(Phone);
  }
  set isAllowed(val: boolean) {
    return;
  }
}
