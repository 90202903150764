@if (isFaiConsistent) {
  <div class="is-tri modal-content">
    <div class="title is-level-3">Resynchronisation en cours</div>
    <p>Veuillez patienter... ({{counter}}%)</p>
    <progress class="progress is-info" [value]="counter" [max]="100"></progress>
  </div>
}

@if (!isFaiConsistent) {
  <div class="is-tri modal-content">
    <div class="title is-level-5">Une erreur technique est survenue.</div>
    <p>Nous vous invitons à vérifier l'ajout de votre offre fixe.</p>
    <button class="button is-warning" (click)="goBackToTechs()">Retour à l'étape offre</button>
  </div>
}
