import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ComparatorService } from '@services/comparator.service';
import { CartService } from '@services/cart.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { ConsulterCatalogueContextualiseAvecPanierQuery } from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { IPostCatalogResponse } from '../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { Configuration, Configurations } from '@model/catalog/products/interface/configurable';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { CatalogCtxService } from '../../../contextualized-catalog/services/catalog-ctx.service';
import { CatalogInputHelperDto } from '../../../contextualized-catalog/dtos/catalog-input-helper.dto';
import { CatalogOutputHelperDto } from '../../../contextualized-catalog/dtos/catalog-output-helper.dto';
import { MediaPipe } from '@base/pipes/media.pipe';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';

const LOADING_ACTIONS = {
  loadingProducts: '[ComparatorSelectionModalComponent] getContextualizedCatalogForPhonesAlone',
  downloadFile: '[ProductListComponent] downloadFile',
};

@Component({
  selector: 'rcbt-comparator',
  templateUrl: './comparator-selection-modal.component.html',
  styleUrls: ['./comparator-selection-modal.component.scss'],
  imports: [MediaPipe, NgStyle, NgClass, FormsModule, AsyncPipe],
})
export class ComparatorSelectionModalComponent implements OnInit {
  public loadingCtxCatalogError = false;
  @Input() public parentCode: string;
  @Input() public childrenCodes: string[] = [];
  @Input() public bestChild: string;
  @Input() public scanCode: string;
  @Input() public configuration: Configurations = { colors: [], capacities: [] };
  @Input() public selectedConfiguration: Configuration = { color: '', capacity: '' };
  public ctxPhonesOnly: IPostCatalogResponse[];
  public currentEquipment: IPostCatalogResponse;
  public contextualizedPlans = new Map<string, IPostCatalogResponse[]>();
  private phoneOnlyKey = 'Téléphones seuls';

  constructor(
    public comparatorService: ComparatorService,
    public globalLoaderService: GlobalLoaderService,
    private cartService: CartService,
    private activeModal: NgbActiveModal,
    private customerService: CustomerService,
    private catalogCtxService: CatalogCtxService,
  ) {}

  public ngOnInit(): void {
    this.loadProducts();
  }

  @HostListener('document:keydown.escape', ['$event'])
  public handleEscapeKey(): void {
    this.close();
  }

  public close(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.activeModal.close();
  }

  public selectProductToCompare(equipmentItem: IPostCatalogResponse): void {
    this.comparatorService.parentsConfig[this.parentCode] = {
      colors: this.configuration.colors,
      capacities: this.configuration.capacities,
      childrenGencodes: this.childrenCodes,
    } as Configurations;
    this.comparatorService.selectProduct(equipmentItem);
    this.close();
  }

  public loadProducts(): void {
    this.loadingCtxCatalogError = false;
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.getContextualizedCatalogForPhonesAlone().pipe(
          tap(
            () => {
              this.addAllConfigurations();
              this.updateProduct();
              this.defaultSelectProductForComparator();
            },
            () => (this.loadingCtxCatalogError = true),
          ),
        ),
        LOADING_ACTIONS.loadingProducts,
      )
      .subscribe();
  }

  /**
   * todo faire un refacto (après la migration angular) pour réutiliser le même code avec viewComponent
   * @param key
   * @param value
   */
  public updateConfiguration(key: string, value: string): void {
    if (this.scanCode) {
      return;
    }
    if (key === 'color') {
      this.selectedConfiguration.color = value;
    }

    if (key === 'capacity') {
      this.selectedConfiguration.capacity = value;
    }
    let selectedProduct: IPostCatalogResponse = this.ctxPhonesOnly.find(
      child =>
        child.couleurs[0] === this.selectedConfiguration.color &&
        child.capacite === this.selectedConfiguration.capacity &&
        child.quantite > 0,
    );
    if (!selectedProduct) {
      selectedProduct = this.ctxPhonesOnly.find(
        child =>
          child.couleurs[0] === this.selectedConfiguration.color &&
          child.capacite === this.selectedConfiguration.capacity,
      );
    }
    // filtrer uniquement avec la couleur OU la capacité pour trouver le premier tél disponible
    if (!selectedProduct) {
      selectedProduct = this.ctxPhonesOnly.find(child => {
        const filterValue = key === 'color' ? child.couleurs[0] : child.capacite;
        return filterValue === value;
      });
    }
    this.updateProduct(selectedProduct);
  }

  public getContextualizedCatalogForPhonesAlone(): Observable<void> {
    this.loadingCtxCatalogError = false;
    const mapKey = `${this.phoneOnlyKey}_${this.childrenCodes.join(',')}`;
    if (this.contextualizedPlans.get(mapKey)) {
      this.ctxPhonesOnly = this.contextualizedPlans.get(mapKey);
      this.currentEquipment = this.ctxPhonesOnly?.find(
        equipementCourant => equipementCourant.gencode === this.bestChild,
      );
      return of(null);
    } else {
      const queryParamCtx: ConsulterCatalogueContextualiseAvecPanierQuery = this.initializeContextParams();
      queryParamCtx.gencodes = this.childrenCodes.join(',');

      const catalogBodywithCart = CatalogInputHelperDto.buildBodyCtxWithCart(
        this.customerService.customer,
        this.cartService.cart,
        null,
        this.cartService.getCurrentScheme()?.uniqueId,
      );

      return this.catalogCtxService.postCatalogCtx(catalogBodywithCart, queryParamCtx).pipe(
        catchError(error => {
          this.loadingCtxCatalogError = true;
          return throwError(error);
        }),
        map(catalogResult => CatalogOutputHelperDto.convertCatalogResponse(catalogResult)),
        map(phoneOnlyResult => {
          this.ctxPhonesOnly = phoneOnlyResult;
          const currentEquipGencode = this.currentEquipment?.gencode || this.bestChild;
          this.currentEquipment = this.ctxPhonesOnly.find(phoneAlone => currentEquipGencode === phoneAlone.gencode);
          this.contextualizedPlans.set(mapKey, phoneOnlyResult);
        }),
      );
    }
  }

  private addAllConfigurations(): void {
    this.addConfigrations(this.currentEquipment);
    for (const equipmentItem of this.ctxPhonesOnly) {
      if (equipmentItem.gencode == this.currentEquipment.gencode) {
        continue;
      }
      this.addConfigrations(equipmentItem);
    }
  }

  private updateProduct(product?: IPostCatalogResponse): void {
    this.cartService.modifiedOffer.emit(true);
    this.currentEquipment = product || this.currentEquipment;
    this.selectedConfiguration = {
      color: this.currentEquipment.couleurs[0],
      capacity: this.currentEquipment.capacite,
    };
  }

  private defaultSelectProductForComparator(): void {
    if (this.ctxPhonesOnly.length === 1) {
      this.selectProductToCompare(this.ctxPhonesOnly[0]);
      this.close();
    }
  }

  private initializeContextParams(): ConsulterCatalogueContextualiseAvecPanierQuery {
    return {
      modePourFinancement: 'min-one-off',
      limite: 20,
      detail: 'true',
      gencodes: undefined,
      categorie: undefined,
      forceEdp: 'true',
    };
  }

  private addConfigrations(child: IPostCatalogResponse): void {
    const color = child.couleurs[0];
    const capacity = child.capacite;
    if (color && !this.configuration.colors.includes(color)) {
      this.configuration.colors.push(color);
    }
    if (capacity && !this.configuration.capacities.includes(capacity)) {
      this.configuration.capacities.push(capacity);
    }
  }
}
