import { Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'rcbt-container-bootstrap',
  templateUrl: './container-bootstrap.component.html',
  styleUrls: ['./container-bootstrap.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [RouterOutlet],
})
export class ContainerBootstrapComponent {}
