import { FundingScheme } from '@interfaces/funding.interface';
import { FundingMethod } from './funding.method';
import { Model } from './model';

export class FundingCart extends Model {
  public idCart: string;
  public fundingMethods: FundingMethod[];
  public fundingSchemes: FundingScheme[];

  constructor(data: Partial<FundingCart>) {
    super(data);
  }
}
