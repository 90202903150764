import { Component, Input } from '@angular/core';
import { Step } from '@components/stepper/step.abstract';
import { CartService } from '@services/cart.service';
import { PandoraDocumentsFactory } from '@model/pandoraDocument.factory';
import { Oauth2RessourceService } from '../../../oauth2/oauth2-resources.service';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs/index';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { MobileTakeBack } from '@model/catalog/products/mobileTakeBack';
import { IScreening } from '@interfaces/screening.interface';
import { CustomerService } from './customer.service';
import { CustomerCategory, CustomerStepEnum, CustomerType, LastStepEnum } from './customer.interface';
import { InsurancePartner } from '@model/catalog/products/subscription/insurance-partner';
import { IPandoraDocumentSerialize } from '@interfaces/IPandoraDocumentSerialize';
import { Customer } from './customer';
import { User } from '../../../user/user';
import { UserService } from '@services/user.service';
import { SchemeHelper } from '../scheme.helper';

@Component({
  selector: 'rcbt-cart-generic-customer',
  templateUrl: './step-customer.component.html',
  standalone: true,
})
export class StepCustomerComponent extends Step {
  @Input() public isActive: boolean;
  @Input() public isDisabled = false;
  public customer: Customer;
  public identitySubmit = false;
  public addressSubmit = false;
  public paymentSubmit = false;
  public isPro = true;
  public isClient = false;
  public isRenew = false;
  public isSav = false;
  public medi7Product: InsurancePartner;
  public identityDocuments: IPandoraDocumentSerialize[];
  public errorMessage = '';
  public hasPlan: boolean;
  public isSaleOnly: boolean;

  constructor(
    protected oauth2RessourceService: Oauth2RessourceService,
    protected cartService: CartService,
    protected customerService: CustomerService,
    protected userService: UserService,
  ) {
    super();
    this.customerService.setNbSteps(this.cartService.getCurrentScheme());
    this.customer = this.customerService.customer;
    this.isClient = this.customer.type === CustomerType.client;
    this.isPro = this.customer.category === CustomerCategory.pro;
    this.isRenew = this.cartService.getCurrentScheme().isRenew();
    this.isSav = this.cartService.getCurrentScheme().isBigtrustSav();
    this.identityDocuments = new PandoraDocumentsFactory(
      this.cartService.getCurrentScheme(),
      this.customerService.customer,
    ).getIdentity();
    this.hasPlan = SchemeHelper.hasPlan(this.cartService.getCurrentScheme());
    this.medi7Product = this.cartService.getCurrentScheme().getProductByType<InsurancePartner>(InsurancePartner);
  }

  public valideSubmit(): boolean {
    return false;
  }
  public submit(): Observable<boolean> {
    return observableOf(true);
  }

  public disable(form: UntypedFormGroup): UntypedFormGroup {
    for (const key in form.controls) {
      if (form.controls[key].valid) {
        form.controls[key].disable();
      }
    }
    return form;
  }

  public submitFormDisabled(form): boolean {
    return !form || (!form.disabled && !form.valid);
  }

  public submitDisabled(): boolean {
    return false;
  }

  public validateScreening(data: IScreening): string {
    if (
      !SchemeHelper.hasPlan(this.cartService.getCurrentScheme()) ||
      this.customerService.nbSteps === LastStepEnum.identity
    ) {
      return '';
    }

    const d: string[] = [
      'raisonSociale',
      'representantLegal',
      // 'dateCreation',
      'codeApeNaf',
      'codeEffectif',
      // 'formeJuridique',
      'situationEntreprise',
    ];
    for (const key of d) {
      if (!data[key]) {
        return key;
      }
    }
    return '';
  }

  public update(): void {
    // This is intentional
  }

  public submitStep(): void {
    this.submit().subscribe();
  }

  public onClickTab(step: CustomerStepEnum): void {
    this.customerService.changeCustomerStep({ step, submit: true });
  }

  protected shouldAddressBeDisabled(): boolean {
    return (
      this.customerService.customer.isClient ||
      !this.customerService.customer.editable ||
      this.customerService.customer.category === CustomerCategory.pro ||
      this.cartService.cart.schemes.length > 1
    );
  }

  protected orderRepriseMobile(): Observable<boolean> {
    const product: MobileTakeBack = this.cartService
      .getCurrentScheme()
      .getProductByType<MobileTakeBack>(MobileTakeBack);
    let obsCreationDevis: Observable<boolean> = observableOf(true);
    if (product.isMtbCessionCouponPrinted) {
      return observableOf(null);
    }
    const customer: Customer = this.customer;
    const user: User = this.userService.user;
    let documentType: string;
    switch (customer.identityDocumentType.name) {
      case 'Carte de séjour':
      case 'Carte de séjour PRO':
        documentType = 'CARTE_DE_SEJOUR';
        break;
      case "Carte d'identité":
      case "Carte d'identité PRO":
        documentType = 'CARTE_D_IDENTITE';
        break;
      case 'Passeport':
      case 'Passeport PRO':
        documentType = 'PASSEPORT';
        break;
      case 'Permis de conduire':
        documentType = 'PERMIS_DE_CONDUIRE';
        break;
      default:
        documentType = customer.identityDocumentType.name;
        break;
    }
    const data = {
      client: {
        adresse: {
          codePostal: customer.postcode,
          complementAdresse1: customer.street2,
          numero: customer.streetNumber || undefined,
          pays: customer.country,
          rue: customer.street,
          ville: customer.city,
        },
        civilite: customer.civility === 'M' ? 'M.' : customer.civility,
        mail: customer.email || undefined,
        modePaiement: 'DEDUIT_SUR_FACTURE',
        nom: customer.lastname,
        numeroPieceIdentite: customer.identityDocumentNumber,
        prenom: customer.firstname,
        telephone: customer.phoneNumber || null,
        typePieceIdentite: documentType,
      },
      commande: {
        acteurCreateur: {
          login: user.login,
        },
        entiteReseauDistributionOrigine: {
          codeEnseigne: user.codeEns,
          codePointVente: user.codePdv,
        },
      },
      devis: {
        id: product.quoteProposalPartnerId,
      },
      souscrireAssurance: false,
    };
    if (product.orderPartnerId) {
      obsCreationDevis = this.oauth2RessourceService
        .produitsPartenairesQuestions(product.terminal.idProduit)
        .devis()
        .post(product.dataQuestion)
        .pipe(
          catchError(error => {
            this.customerService.setIsLoading(false);
            throw new Error(`error getDevis: ${error.message}`);
          }),
          mergeMap((dataDevis: { id: string }) => {
            product.quoteProposalPartnerId = dataDevis.id;
            data.devis.id = dataDevis.id;
            return observableOf(true);
          }),
        );
    }
    return obsCreationDevis.pipe(
      mergeMap(() =>
        this.oauth2RessourceService
          .commandeRepriseMobile()
          .post(data)
          .pipe(
            mergeMap((result: { idCommandePartenaire: string; _links: { lienBonDeCession: { href: string } } }) => {
              product.orderPartnerId = result.idCommandePartenaire;
              product.mtbCessionLink = result._links.lienBonDeCession.href;
              product.mtbCessionCouponLink = result._links.lienBonDeCession.href;
              return this.cartService.update(product, this.cartService.getCurrentScheme().uniqueId).pipe(
                map(res => {
                  product.setConfiguration(res);
                  return true;
                }),
              );
            }),
            catchError(err => {
              this.customerService.setIsLoading(false);
              if (err.status === 404 || err.status === 400) {
                this.errorMessage =
                  "La commande reCommerce n'a pas pu être créée. Vérifiez les données client. (" +
                  (err.error_description ?? err.error?.error_description) +
                  ')';
              } else {
                this.errorMessage = "Erreur technique lors de l'appel de reCommerce";
              }
              return observableThrowError(this.errorMessage);
            }),
          ),
      ),
    );
  }
}
